import { RemoteConfirmOrderIzeeService } from 'src/data'

import { ConfirmOrderIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeConfirmOrderIzeeUseCase = (): ConfirmOrderIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlOrderPlaceholder = '{open-order}'
  const url = `/izee-sales/${urlOrderPlaceholder}/confirm`

  return new RemoteConfirmOrderIzeeService(authorizedHttpAdapter, url, urlOrderPlaceholder)
}
