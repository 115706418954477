import { RemoteUpdateOrderIzeeService } from 'src/data'

import { UpdateOrderIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeUpdateOrderIzeeUseCase = (): UpdateOrderIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteUpdateOrderIzeeService(authorizedHttpAdapter, '/izee-sales')
}
