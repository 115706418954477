import { RemoteFetchOrderIzeeService } from 'src/data'

import { FetchOrderIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchOrderIzeeUseCase = (): FetchOrderIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchOrderIzeeService(authorizedHttpAdapter, '/izee-sales/')
}
