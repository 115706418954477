import React, { useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'

interface Props {
  isOneOfLastItems: boolean
  orderId: number | string
  handleCancel: (orderId: number | string) => void
}

const CompletedOrderIzeeMenuItem: React.FC<Props> = ({ isOneOfLastItems, orderId }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openOrderMenuItemRef = useRef<HTMLDivElement>(null)

  const handleClickOutsideMenuItem = (event: MouseEvent) => {
    const currentRef = openOrderMenuItemRef.current

    if (currentRef && !currentRef.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenuItem)

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenuItem)
    }
  }, [])

  const menuToggleClasses = isOpen ? 'opacity-100 visible translate-y-2' : 'opacity-0 invisible'

  const lastItemsClasses = isOneOfLastItems ? '-top-20 -mt-2' : 'top-0'

  return (
    <div className="relative inline-block">
      <BsThreeDotsVertical
        className="z-0 m-auto cursor-pointer text-primary hover:text-secundary"
        size={28}
        onClick={() => setIsOpen(!isOpen)}
      />

      <span
        className={`block relative transition-all duration-200 transform origin-top-right scale-95 z-20 m-0 ${menuToggleClasses}`}
        ref={openOrderMenuItemRef}
      >
        <span
          className={`block z-20 absolute right-0 w-44 mt-0 bg-white rounded-lg shadow-lg outline-none ${lastItemsClasses}`}
        >
          <Link
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-right text-black transition-colors rounded-t-lg hover:bg-primary hover:text-white"
            to={`/izee-sales/${orderId}/view`}
          >
            <Trans i18nKey="VIEW" />
          </Link>
        </span>
      </span>
    </div>
  )
}

export default CompletedOrderIzeeMenuItem
