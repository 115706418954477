import { RemoteFetchClientPriceTablesService } from 'src/data'

import { FetchClientPriceTablesUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientPriceTablesUseCase = (): FetchClientPriceTablesUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlClientPlaceholder = '{client}'
  const url = `/clients/${urlClientPlaceholder}/price-tables`

  return new RemoteFetchClientPriceTablesService(authorizedHttpAdapter, url, urlClientPlaceholder)
}
