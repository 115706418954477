import { CityEntity } from 'src/domain'

export class CityModel {
  private static cities: CityModel[] = []

  constructor(readonly id: string | number, readonly name: string, readonly tokenized: string) {}

  static fromBrazilianJsonCollection(json: any[]) {
    this.cities = json.map(cityJson => new CityModel(cityJson?.id, cityJson?.name, cityJson?.tokenized))

    return this
  }

  static fromPortugueseJsonCollection(json: any) {
    this.cities = json?.municipios?.map(
      (cityJson: { nome: string }) => new CityModel(cityJson?.nome, cityJson?.nome, cityJson?.nome)
    )

    return this
  }

  static toEntityCollection(): CityEntity[] {
    return this.cities.map(cityModel => ({
      id: cityModel.id,
      name: cityModel.name,
      tokenized: cityModel.tokenized,
    }))
  }
}
