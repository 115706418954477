import { HttpClient, HttpStatusCode } from 'src/data'

import { UnexpectedError, ConfirmOpenOrderUseCase, OpenOrderConfirmError, ConfirmOpenOrderErrors } from 'src/domain'

export class RemoteConfirmOpenOrderService implements ConfirmOpenOrderUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlOrderPlaceholder: string
  ) {}

  async confirmOpenOrder(order: string) {
    const url = this.url.replace(this.urlOrderPlaceholder, order)

    const response = await this.httpClient.request({ method: 'post', url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
      case HttpStatusCode.notFound:
      case HttpStatusCode.serverError:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.conflict: {
        if (response.body?.message && response.body.message in ConfirmOpenOrderErrors) {
          throw new OpenOrderConfirmError(response.body.message)
        }

        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
