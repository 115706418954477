import { PlanEntity } from 'src/domain'

export interface FetchPlansUseCase {
  fetchPlans(): Promise<PlanEntity.Entity[]>
}

export namespace AddClientPlanUseCase {
  export type Params = {
    planUuid: string
    clientId: number
    seller: number | null
    modality: PlanEntity.Modality
    periodicity: PlanEntity.Periodicity
    price: number
    billingMode: PlanEntity.BillingMode
    numberOfInstallments: number
    numberOfInstallmentsNumericData: number[]
    firstBillingMonth: PlanEntity.FirstBillingMonth
    billingDay: number
    paymentDetails: string | null
  }
}

export interface AddClientPlanUseCase {
  addPlan(params: AddClientPlanUseCase.Params): Promise<void>
}
