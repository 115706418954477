import { FetchOrdersMapper, HttpClient, HttpStatusCode, OrderModel } from 'src/data'

import { FetchCanceledOrdersUseCase, OrdersPagination, UnexpectedError } from 'src/domain'

export class RemoteFetchCanceledOrdersService implements FetchCanceledOrdersUseCase {
  constructor(
    private readonly httpClient: HttpClient<OrderModel[]>,
    private readonly url: string,
    private readonly params: object
  ) {}

  async fetchCanceledOrders(pagination: OrdersPagination, franchisee?: number, search?: string) {
    const mappedPagination = FetchOrdersMapper.toJson(pagination)

    const mappedParams = {
      ...this.params,
      ...mappedPagination,
      franchisee_id: franchisee,
      search,
    }

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: mappedParams,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return OrderModel.fromJsonCollection(response.body as OrderModel[]).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
