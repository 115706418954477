import { ClientEntity } from 'src/domain'

export class ClientModel {
  private static clients: ClientModel[] = []

  constructor(
    readonly id: number,
    readonly franchisee: string,
    readonly name: string,
    readonly phone: string,
    readonly email: string,
    readonly type: string,
    readonly createdAt: Date,
    readonly balance: number,
    readonly lastOrder: Date,
    readonly disabled: boolean
  ) {}

  static fromJsonCollection(json: any[]) {
    this.clients = json.map(clientJson => {
      return new ClientModel(
        clientJson?.id,
        clientJson?.franchisee,
        clientJson?.name,
        clientJson?.phone,
        clientJson?.email,
        clientJson?.type,
        clientJson?.created_at,
        clientJson?.balance,
        clientJson?.last_order,
        clientJson?.disabled
      )
    })

    return this
  }

  static toEntityCollection(): ClientEntity[] {
    return this.clients.map(clientModel => ({
      id: clientModel.id,
      franchisee: clientModel.franchisee,
      name: clientModel.name,
      phone: clientModel.phone,
      email: clientModel.email,
      type: clientModel.type,
      createdAt: clientModel.createdAt,
      balance: clientModel.balance,
      lastOrder: clientModel.lastOrder,
      disabled: clientModel.disabled,
    }))
  }
}
