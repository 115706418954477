import React from 'react'

import { useAppDispatch } from 'src/presentation/hooks'
import { uiActions } from 'src/presentation/store/ducks/ui'

const Burguer: React.FC = () => {
  const dispatch = useAppDispatch()

  return (
    <div className="block cursor-pointer group lg:hidden" onClick={() => dispatch(uiActions.toggleSidebar())}>
      <div className="w-6 h-1 mb-1 transition-colors bg-primary group-hover:bg-secundary" />

      <div className="w-6 h-1 mb-1 transition-colors bg-primary group-hover:bg-secundary" />

      <div className="w-6 h-1 transition-colors bg-primary group-hover:bg-secundary" />
    </div>
  )
}

export default Burguer
