import { Keys } from 'src/main'

import { useAppDispatch } from 'src/presentation/hooks'
import { authActions } from 'src/presentation/store/ducks/auth'

export const useAccessToken = () => {
  const dispatch = useAppDispatch()

  return () => {
    const token = sessionStorage.getItem(Keys.ACCESS_TOKEN_STORAGE_KEY)
    const type = sessionStorage.getItem(Keys.TOKEN_TYPE_STORAGE_KEY)

    if (token && type) {
      dispatch(authActions.accessToken(`${type} ${token}`))

      return
    }

    if (location.hash) {
      const hash = location.hash.substring(1)

      if (hash !== '_=_') {
        const parameters = hash.split('&')

        if (
          parameters.length === 3 &&
          parameters[0].includes(Keys.ACCESS_TOKEN_URL_PARAM_KEY) &&
          parameters[1].includes(Keys.TOKEN_TYPE_URL_PARAM_KEY)
        ) {
          const token = parameters[0].substring(parameters[0].indexOf('=') + 1, parameters[0].length)
          const type = parameters[1].substring(parameters[1].indexOf('=') + 1, parameters[1].length)

          sessionStorage.setItem(Keys.ACCESS_TOKEN_STORAGE_KEY, token)
          sessionStorage.setItem(Keys.TOKEN_TYPE_STORAGE_KEY, type)

          document.location.hash = '_=_'

          dispatch(authActions.accessToken(`${type} ${token}`))
        }
      }
    }
  }
}
