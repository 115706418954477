import { CreateOrUpdateOrderIzeeErrors } from 'src/domain'

const createOrUpdateOrderIzeePayloadErrorToDisplay = {
  BR: {
    COMPANY_NOT_FOUND: 'Imobiliária não localizada',
    IZEE_SALE_NOT_FOUND: 'Venda não localizada',
    COMPANY_DOCUMENT_NOT_FOUND: 'Documento da imobiliária não localizado',
    COMPANY_ADDRESS_NOT_FOUND: 'Endereço da imobiliária não localizado',
    SALE_ALREADY_CONFIRMED: 'Venda já confirmada',
    INVALID_PAYMENT_FORM: 'Método de pagamento inválido',
    INVALID_NUMBER_OF_INSTALLMENTS: 'Número de parcelas inválido',
    INVALID_BILLING_PHONE_FORMAT: 'Número de telefone inválido',
    INVALID_COMPANY_DOCUMENT: 'Documento da imobiliária inválido',
    INVALID_COMPANY_ADDRESS: 'Endereço da imobiliária inválido',
    INVALID_COMPANY_CITY: 'Cidade da imobiliária inválida',
    INVALID_COMPANY_NEIGHBORHOOD: 'Bairro da imobiliária inválido',
    NEIGHBORHOOD_NOT_FOUND: 'Bairro não localizado',
    CITY_NOT_FOUND: 'Cidade não localizada',
    INVALID_COMPANY_ZIPCODE: 'CEP invádido',
    INVALID_COMPANY_EMAIL: 'E-mail de contato inválido',
    INVALID_COMPANY_NAME: 'Nome da imobiliária inválido',
    INVALID_COMPANY_TYPE: 'Tipo de empresa inválido',
    COULD_NOT_CREATE_CONTRACT: 'Não foi possível cadastrar o contrato',
    COULD_NOT_CREATE_OCCURRENCE: 'Não foi possível registrar a ocorrência do contrato',
    COULD_NOT_CREATE_PRODUCT: 'Não foi possível registrar o produto do contrato',
    SANKHYA_COMMUNICATION_ERROR: 'Não foi possível se conectar oa Sankhya',
    CANNOT_CREATE_EFI_BILLET: 'Não foi possível criar a cobrança do setup. Informe o administrador do sistema.',
    CANNOT_CREATE_EFI_CARNET: 'Não foi possível criar as cobranças do setup. Informe o administrador do sistema.',
    CANNOT_CREATE_EFI_SUBSCRIPTION:
      'Não foi possível criar a cobrança da assinatura. Informe o administrador do sistema.',
  },
  PT: {
    COMPANY_NOT_FOUND: 'Imobiliária não localizada',
    IZEE_SALE_NOT_FOUND: 'Venda não localizada',
    COMPANY_DOCUMENT_NOT_FOUND: 'Documento da imobiliária não localizado',
    COMPANY_ADDRESS_NOT_FOUND: 'Endereço da imobiliária não localizado',
    SALE_ALREADY_CONFIRMED: 'Venda já confirmada',
    INVALID_PAYMENT_FORM: 'Método de pagamento inválido',
    INVALID_NUMBER_OF_INSTALLMENTS: 'Número de prestações inválido',
    INVALID_BILLING_PHONE_FORMAT: 'Número de telefone inválido',
    INVALID_COMPANY_DOCUMENT: 'Documento da imobiliária inválido',
    INVALID_COMPANY_ADDRESS: 'Endereço da imobiliária inválido',
    INVALID_COMPANY_CITY: 'Cidade da imobiliária inválida',
    INVALID_COMPANY_NEIGHBORHOOD: 'Bairro da imobiliária inválido',
    NEIGHBORHOOD_NOT_FOUND: 'Bairro não localizado',
    CITY_NOT_FOUND: 'Cidade não localizada',
    INVALID_COMPANY_ZIPCODE: 'Código postal inválido',
    INVALID_COMPANY_EMAIL: 'E-mail de contacto inválido',
    INVALID_COMPANY_NAME: 'Nome da imobiliária inválido',
    INVALID_COMPANY_TYPE: 'Tipo de empresa inválido',
    COULD_NOT_CREATE_CONTRACT: 'Não foi possível cadastrar o contrato',
    COULD_NOT_CREATE_OCCURRENCE: 'Não foi possível registar a ocorrência do contrato',
    COULD_NOT_CREATE_PRODUCT: 'Não foi possível registar o produto do contrato',
    SANKHYA_COMMUNICATION_ERROR: 'Não foi possível conectar ao Sankhya',
    CANNOT_CREATE_EFI_BILLET: 'Não foi possível criar a cobrança do setup. Informe o administrador do sistema.',
    CANNOT_CREATE_EFI_CARNET: 'Não foi possível criar as cobranças do setup. Informe o administrador do sistema.',
    CANNOT_CREATE_EFI_SUBSCRIPTION:
      'Não foi possível criar a cobrança da assinatura. Informe o administrador do sistema.',
  },
}

export const createOrUpdateOrderIzeePayloadErrorMapper = (
  language: 'BR' | 'PT',
  err: CreateOrUpdateOrderIzeeErrors
) => {
  return createOrUpdateOrderIzeePayloadErrorToDisplay?.[language]?.[err] || ''
}
