import { OrderEntity } from 'src/domain'

export class SellerModel {
  static fromJsonToDomain(json: any[]): OrderEntity.Seller[] {
    return json.map(sellerJson => ({
      id: sellerJson?.id,
      name: sellerJson?.name,
      type: sellerJson?.type,
      franchiseeIds: sellerJson?.franchisee_ids,
    }))
  }
}
