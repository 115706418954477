import React, { useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/presentation/hooks'
import { uiActions } from 'src/presentation/store/ducks/ui'

interface Props {
  isOneOfLastItems: boolean
  clientId: number
  clientName: string
}

const ClientMenuItem: React.FC<Props> = ({ isOneOfLastItems, clientId, clientName }) => {
  const [isOpen, setIsOpen] = useState(false)

  const clientMenuItemRef = useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)

  const handleClickOutsideMenuItem = (event: MouseEvent) => {
    const currentRef = clientMenuItemRef.current

    currentRef && !currentRef.contains(event.target as Node) && setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenuItem)

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenuItem)
    }
  }, [])

  const menuToggleClasses = isOpen ? 'opacity-100 visible translate-y-2' : 'opacity-0 invisible'
  const lastItemsClasses = isOneOfLastItems ? '-top-20 -mt-2' : 'top-0'

  return (
    <div className="relative inline-block" data-cy={`client-${clientId}-menu-item`}>
      <BsThreeDotsVertical
        className="z-0 m-auto cursor-pointer text-primary hover:text-secundary"
        size={22}
        onClick={() => setIsOpen(!isOpen)}
      />

      <span
        className={`block relative transition-all duration-200 transform origin-top-right scale-95 z-20 m-0 ${menuToggleClasses}`}
        ref={clientMenuItemRef}
      >
        <span
          className={`block z-20 absolute right-0 w-44 mt-0 bg-white rounded-lg shadow-lg outline-none ${lastItemsClasses}`}
        >
          <Link
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-right text-black transition-colors rounded-t-lg hover:bg-primary hover:text-white"
            to={`/clients/${clientId}/view`}
          >
            <Trans i18nKey="VIEW" />
          </Link>

          <Link
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-right text-black transition-colors hover:bg-primary hover:text-white"
            to={`/clients/${clientId}/edit`}
          >
            <Trans i18nKey="EDIT" />
          </Link>

          {user?.hasPackageFeature && (
            <button
              className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black transition-colors hover:bg-primary hover:text-white"
              data-cy="add-package-button"
              type="button"
              onClick={() => dispatch(uiActions.togglePackageModal({ client: clientId, isOpen: true }))}
            >
              <Trans i18nKey="ADD_PACKAGE" />
            </button>
          )}

          <button
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black transition-colors hover:bg-primary hover:text-white"
            data-cy="price-tables-button"
            type="button"
            onClick={() => dispatch(uiActions.togglePriceTablesModal({ client: clientId, isOpen: true }))}
          >
            <Trans i18nKey="PRICE_LIST" />
          </button>

          {!user?.isOfficeFranchisee && (
            <button
              className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black transition-colors hover:bg-primary hover:text-white"
              data-cy="add-signature-button"
              type="button"
              onClick={() => dispatch(uiActions.toggleSignatureModal({ client: clientId, isOpen: true }))}
            >
              <Trans i18nKey="ADD_SUBSCRIPTION" />
            </button>
          )}

          <button
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black transition-colors hover:bg-primary hover:text-white"
            data-cy="auto-billing-config-button"
            type="button"
            onClick={() =>
              dispatch(uiActions.toggleAutoBillingConfigModal({ client: clientId, clientName, isOpen: true }))
            }
          >
            <Trans i18nKey="CLOSURE_OPTIONS" />
          </button>

          <button
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black rounded-b-lg transition-colors hover:bg-primary hover:text-white"
            data-cy="export-client-extract-button"
            type="button"
            onClick={() => dispatch(uiActions.toggleExportClientExtractModal({ client: clientId, isOpen: true }))}
          >
            <Trans i18nKey="EXPORT_STATEMENT" />
          </button>
        </span>
      </span>
    </div>
  )
}

export default ClientMenuItem
