import { useEffect, useMemo } from 'react'

export const usePersistedPreviousDataQuantity = (storageKey: string, data: any[]) => {
  const previousDataQuantity = useMemo(() => {
    const dataQuantity = Number(localStorage.getItem(storageKey))

    if (dataQuantity > 0 && dataQuantity <= 15) {
      return dataQuantity
    }

    if (dataQuantity === 0) {
      return 1
    }

    return 15
  }, [storageKey])

  useEffect(() => {
    return () => {
      data.length && localStorage.setItem(storageKey, String(data.length))
    }
  }, [data.length])

  return previousDataQuantity
}
