import axios from 'axios'

import { AuthorizedHttpClientDecorator } from 'src/data'

import { AxiosHttpClient } from 'src/infra'

export class AxiosAuthorizedHttpClientDecorator extends AuthorizedHttpClientDecorator {
  constructor(
    protected axiosHttpClient: AxiosHttpClient,
    readonly baseUrl: string,
    private readonly unauthorizedRedirectUrl: string,
    private readonly tokenTypeStorageKey: string,
    private readonly accessTokenStorageKey: string
  ) {
    super(axiosHttpClient)

    this.axiosHttpClient.axiosInstance = this.authorizedClient
  }

  protected get authorizedClient() {
    const tokenType = sessionStorage.getItem(this.tokenTypeStorageKey)
    const accessToken = sessionStorage.getItem(this.accessTokenStorageKey)

    const authorizedAxiosInstance = axios.create({
      baseURL: this.baseUrl,
      headers: { Authorization: `${tokenType} ${accessToken}` },
    })

    Object.assign(authorizedAxiosInstance, { CancelToken: axios.CancelToken })

    authorizedAxiosInstance.interceptors.response.use(
      response => response,
      err => {
        if (err.response && err.response.status === 401) {
          location.replace(this.unauthorizedRedirectUrl)
        }

        return Promise.reject(err)
      }
    )

    return authorizedAxiosInstance
  }
}
