const billingModalityTypes = {
  BR: {
    SEPARATE: 'Avulso',
    PRE_PAID: 'Pré-pago',
    POST_PAID: 'Pós-pago',
  },
  PT: {
    SEPARATE: 'Avulso',
    PRE_PAID: 'Pré-pago',
    POST_PAID: 'Pós-pago',
  },
}

export const billingModalityMapper = (
  language: 'BR' | 'PT',
  billingModality: 'SEPARATE' | 'PRE_PAID' | 'POST_PAID'
) => {
  return billingModalityTypes?.[language]?.[billingModality] || ''
}
