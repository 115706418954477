import { RemoteUpdateClientPriceTableService } from 'src/data'

import { UpdateClientPriceTableUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeUpdateClientPriceTableUseCase = (): UpdateClientPriceTableUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlClientPlaceholder = '{client}'
  const url = `/clients/${urlClientPlaceholder}/price-table`

  return new RemoteUpdateClientPriceTableService(authorizedHttpAdapter, url, urlClientPlaceholder)
}
