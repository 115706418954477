import React from 'react'

const ClientIzeeLoading: React.FC = () => (
  <div className="relative flex h-20 flex-none items-start justify-between md:w-full p-4 mb-4 border-2 rounded border-tertiary last-of-type:mb-0 w-[732px]">
    <div className="flex items-start justify-start mr-2">
      <div className="hidden mr-4 lg:block h-[40px] bg-senary rounded w-10 animate-pulse" />

      <div className="flex flex-col items-start justify-start">
        <div className="mb-4 w-128">
          <div className="lg:w-96 w-72 bg-senary rounded h-[18px] animate-pulse" />
        </div>
      </div>
    </div>

    <div className="flex items-start justify-start w-96">
      <div className="flex flex-col items-start justify-start mr-2">
        <div className="mb-3 text-xs w-14 bg-senary rounded animate-pulse h-[12px]" />

        <div className="w-24 text-xs bg-senary rounded h-[12px] animate-pulse" />
      </div>

      <div className="flex flex-col items-start justify-start ml-auto">
        <div className="ml-4 w-2 text-xs bg-senary rounded animate-pulse h-[26px]" />
      </div>
    </div>
  </div>
)

export default ClientIzeeLoading
