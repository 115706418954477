import { CreateOrUpdateOrderIzeeMapper, HttpClient, HttpStatusCode } from 'src/data'

import {
  CreateOrUpdateOrderIzeeErrors,
  CreateOrUpdateOrderIzeePayloadError,
  CreateOrUpdateOrderIzeeUseCase,
  UnexpectedError,
  UpdateOrderIzeeUseCase,
} from 'src/domain'

export class RemoteUpdateOrderIzeeService implements UpdateOrderIzeeUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async updateOrderIzee(order: number, params: CreateOrUpdateOrderIzeeUseCase.Params) {
    const response = await this.httpClient.request({
      method: 'put',
      url: `${this.url}/${order}`,
      body: CreateOrUpdateOrderIzeeMapper.toJson(params),
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
      case HttpStatusCode.notFound:
      case HttpStatusCode.serverError:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.conflict: {
        if (response.body?.message && response.body.message in CreateOrUpdateOrderIzeeErrors) {
          throw new CreateOrUpdateOrderIzeePayloadError(response.body.message)
        }

        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
