import { ClientDetailsEntity } from 'src/domain'

export class ClientDetailsModel {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly username: string,
    readonly auth: {
      franchisee: number
      clientId: number
      username: string
    },
    readonly billingModality: ClientDetailsEntity.BillingModality,
    readonly modality: ClientDetailsEntity.BillingModalityPanel,
    readonly searchTokens: string,
    readonly accountId: string,
    readonly approvedAt: Date,
    readonly creatorClientId: string,
    readonly type: ClientDetailsEntity.Type,
    readonly acceptedTerms: boolean,
    readonly category: string,
    readonly hotsiteConfiguration: {
      ccEmail: string
      expirationTime: string
      canRegisterMaintenances: boolean | string
    },
    readonly contact: {
      email: string
      cellphone: string
      phone: string
      website: string
      assignmentEmail: string
      assignmentEmailRule: ClientDetailsEntity.AssignmentEmailRule
    },
    readonly address: {
      city: string
      cityId: number
      complement: string
      neighborhood: string
      state: string
      stateId: number
      street: string
      zipcode: string
      number: string
    },
    readonly invalidAddress: boolean,
    readonly status: string,
    readonly document: string,
    readonly clientType: ClientDetailsEntity.ClientType,
    readonly companyName: string,
    readonly responsible: {
      name: string
      document: string
      phone: string
      cellphone: string
      email: string
    },
    readonly classification: ClientDetailsEntity.Classification,
    readonly origin: ClientDetailsEntity.Origin,
    readonly dashboardUri: string,
    readonly billingAddress: {
      city?: string
      cityId?: number
      complement?: string
      neighborhood?: string
      state?: string
      stateId?: number
      street?: string
      zipcode?: string
      number?: string
    },
    readonly propertiesWallet: {
      potentialInspections?: number
      propertiesPurchaseSell?: number
      propertiesRent?: number
    },
    readonly franchiseeId?: number,
    readonly responsibleCommercialUserId?: number,
    readonly responsibleCommercialUserName?: string,
    readonly billingContact?: string,
    readonly billingEmail?: string,
    readonly billingPhone?: string
  ) {}

  static fromJson(json: any) {
    return new ClientDetailsModel(
      json?.id,
      json?.name,
      json?.username,
      {
        franchisee: json?.auth?.franchisee,
        clientId: json?.auth?.client_id,
        username: json?.auth?.username,
      },
      json?.billing_modality,
      json?.modality,
      json?.search_tokens,
      json?.account_id,
      json?.approved_at,
      json?.creator_client_id,
      json?.type,
      json?.accepted_terms,
      json?.category,
      {
        ccEmail: json?.hotsite_configuration?.cc_email,
        expirationTime: json?.hotsite_configuration?.expiration_time,
        canRegisterMaintenances: json?.hotsite_configuration?.can_register_maintenances,
      },
      {
        email: json?.contact?.email,
        cellphone: json?.contact?.cellphone,
        phone: json?.contact?.phone,
        website: json?.contact?.website,
        assignmentEmail: json?.contact?.assignment_email,
        assignmentEmailRule: json?.contact?.assignment_email_rule,
      },
      {
        city: json?.address?.city,
        cityId: json?.address?.city_id,
        complement: json?.address?.complement,
        neighborhood: json?.address?.neighborhood,
        state: json?.address?.state,
        stateId: json?.address?.state_id,
        street: json?.address?.street,
        zipcode: json?.address?.zipcode,
        number: json?.address?.number,
      },
      json?.invalid_address,
      json?.status,
      json?.document,
      json?.client_type,
      json?.company_name,
      {
        name: json?.responsible?.name,
        document: json?.responsible?.document,
        phone: json?.responsible?.phone,
        cellphone: json?.responsible?.cellphone,
        email: json?.responsible?.email,
      },
      json?.classification,
      json?.origin,
      json?.dashboard_uri,
      {
        city: json?.billing_address?.city,
        cityId: json?.billing_address?.city_id,
        complement: json?.billing_address?.complement,
        neighborhood: json?.billing_address?.neighborhood,
        state: json?.billing_address?.state,
        stateId: json?.billing_address?.state_id,
        street: json?.billing_address?.street,
        zipcode: json?.billing_address?.zipcode,
        number: json?.billing_address?.number,
      },
      {
        potentialInspections: json?.propertiesWallet?.potential_inspections,
        propertiesPurchaseSell: json?.propertiesWallet?.properties_purchase_sell,
        propertiesRent: json?.propertiesWallet?.properties_rent,
      },
      json?.franchisee_id,
      json?.responsible_commercial_user_id,
      json?.responsible_commercial_user_name,
      json?.billing_contact,
      json?.billing_email,
      json?.billing_phone
    )
  }

  toEntity(): ClientDetailsEntity.Entity {
    return {
      id: this.id,
      name: this.name,
      username: this.username,
      auth: {
        franchisee: this.auth.franchisee,
        clientId: this.auth.clientId,
        username: this.auth.username,
      },
      billingModality: this.billingModality,
      modality: this.modality,
      searchTokens: this.searchTokens,
      accountId: this.accountId,
      approvedAt: this.approvedAt,
      creatorClientId: this.creatorClientId,
      type: this.type,
      acceptedTerms: this.acceptedTerms,
      category: this.category,
      hotsiteConfiguration: {
        ccEmail: this.hotsiteConfiguration.ccEmail,
        expirationTime: this.hotsiteConfiguration.expirationTime,
        canRegisterMaintenances: this.hotsiteConfiguration.canRegisterMaintenances,
      },
      contact: {
        email: this.contact.email,
        cellphone: this.contact.cellphone,
        phone: this.contact.phone,
        website: this.contact.website,
        assignmentEmail: this.contact.assignmentEmail,
        assignmentEmailRule: this.contact.assignmentEmailRule,
      },
      address: {
        city: this.address.city,
        cityId: this.address.cityId,
        complement: this.address.complement,
        neighborhood: this.address.neighborhood,
        state: this.address.state,
        stateId: this.address.stateId,
        street: this.address.street,
        zipcode: this.address.zipcode,
        number: this.address.number,
      },
      invalidAddress: this.invalidAddress,
      status: this.status,
      document: this.document,
      clientType: this.clientType,
      companyName: this.companyName,
      responsible: {
        name: this.responsible.name,
        document: this.responsible.document,
        phone: this.responsible.phone,
        cellphone: this.responsible.cellphone,
        email: this.responsible.email,
      },
      classification: this.classification,
      origin: this.origin,
      dashboardUri: this.dashboardUri,
      billingAddress: {
        city: this.billingAddress?.city,
        cityId: this.billingAddress?.cityId,
        complement: this.billingAddress?.complement,
        neighborhood: this.billingAddress?.neighborhood,
        state: this.billingAddress?.state,
        stateId: this.billingAddress?.stateId,
        street: this.billingAddress?.street,
        zipcode: this.billingAddress?.zipcode,
        number: this.billingAddress?.number,
      },
      propertiesWallet: {
        potentialInspections: this.propertiesWallet?.potentialInspections,
        propertiesPurchaseSell: this.propertiesWallet?.propertiesPurchaseSell,
        propertiesRent: this.propertiesWallet?.propertiesRent,
      },
      franchiseeId: this.franchiseeId,
      responsibleCommercialUserId: this.responsibleCommercialUserId,
      responsibleCommercialUserName: this.responsibleCommercialUserName,
      billingContact: this.billingContact,
      billingEmail: this.billingEmail,
      billingPhone: this.billingPhone,
    }
  }
}
