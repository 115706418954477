import { RemoteFetchCanceledOrdersIzeeService } from 'src/data'

import { FetchCanceledOrdersIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCanceledOrdersIzeeUseCase = (): FetchCanceledOrdersIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const params = {
    status: 'CANCELED',
  }

  return new RemoteFetchCanceledOrdersIzeeService(authorizedHttpAdapter, '/izee-sales/', params)
}
