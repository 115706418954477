import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AddClientPlanUseCase } from 'src/domain'

import { makeAddClientPlanUseCase } from 'src/main'

type AddClientPlanParams = AddClientPlanUseCase.Params

const addClientPlan = createAsyncThunk('@plan/addClientPlan', async (params: AddClientPlanParams) => {
  const addClientPlanUseCase = makeAddClientPlanUseCase()

  await addClientPlanUseCase.addPlan(params)
})

interface PlanState {
  loadingAddClientPlan: boolean
  addClientPlanState: 'success' | 'error' | null
}

const initialState: PlanState = {
  loadingAddClientPlan: false,
  addClientPlanState: null,
}

export const planSlice = createSlice({
  name: '@plan',
  initialState,
  reducers: {
    clearAddClientPlanState: state => {
      state.addClientPlanState = null
    },
  },
  extraReducers: builder => {
    builder.addCase(addClientPlan.pending, state => {
      state.loadingAddClientPlan = true
    })

    builder.addCase(addClientPlan.fulfilled, state => {
      state.loadingAddClientPlan = false
      state.addClientPlanState = 'success'
    })

    builder.addCase(addClientPlan.rejected, state => {
      state.loadingAddClientPlan = false
      state.addClientPlanState = 'error'
    })
  },
})

export const planActions = { ...planSlice.actions, addClientPlan }

export const planReducer = planSlice.reducer
