import { CreateAutoBillingConfigMapper, HttpClient, HttpStatusCode } from 'src/data'

import { UnexpectedError, CreateAutoBillingConfigUseCase, AutoBillingConfigEntity } from 'src/domain'

export class RemoteCreateAutoBillingConfigService implements CreateAutoBillingConfigUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlClientPlaceholder: string
  ) {}

  async createAutoBillingConfig(client: number, params: AutoBillingConfigEntity) {
    const url = this.url.replace(this.urlClientPlaceholder, String(client))

    const response = await this.httpClient.request({
      method: 'post',
      url,
      body: CreateAutoBillingConfigMapper.toJson(params),
    })

    switch (response.statusCode) {
      case HttpStatusCode.created:
        return

      default:
        throw new UnexpectedError()
    }
  }
}
