import { RemoteExportExtractService } from 'src/data'

import { ExportExtractUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeExportExtractUseCase = (): ExportExtractUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteExportExtractService(authorizedHttpAdapter, '/reports/export')
}
