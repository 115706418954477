const { hostname, port, protocol } = location

const getCommercialBaseUrl = () => {
  switch (hostname) {
    case 'comercial.redevistorias.com.br':
    case 'comercial.sandbox.redevistorias.com.br':
    case 'comercial.dev.redevistorias.com.br':
      return `${protocol}//${hostname}:${port}`

    default:
      return 'https://comercial.dev.redevistorias.com.br'
  }
}

const getCommercialEnv = (url: string) => {
  if (url.includes('.dev')) return 'development'

  if (url.includes('.sandbox')) return 'sandbox'

  return 'production'
}

const getRVPanelBaseUrl = (env: 'development' | 'sandbox' | 'production') => {
  if (env === 'production') return 'https://painel.redevistorias.com.br'

  if (env === 'sandbox') return 'https://painel.sandbox.redevistorias.com.br'

  return 'https://painel.dev.redevistorias.com.br'
}

const BASE_URL = getCommercialBaseUrl()

const AUTH_BASE_URL = BASE_URL.replace('comercial', 'auth')

const COMMERCIAL_API_BASE_URL = `${BASE_URL}/api`

const REDIRECT_URL = `${protocol}//${hostname}:${port}`

const PT_ADDRESS_BASE_URL = 'https://json.geoapi.pt/'

export const ENV = getCommercialEnv(BASE_URL)

export const Urls = {
  BASE_URL,
  AUTH_BASE_URL,
  COMMERCIAL_API_BASE_URL,
  REDIRECT_URL,
  RV_PANEL_BASE_URL: getRVPanelBaseUrl(ENV),
  ADDRESS_BASE_URL: BASE_URL.replace('comercial', 'address').replace('http:', 'https:'),
  PT_ADDRESS_BASE_URL,
  CORE_API_BASE_URL: COMMERCIAL_API_BASE_URL.replace('comercial', 'painel'),
  SENTRY_URL: 'https://b4a49e29d9cc4cd7a83adf8ca198bacf@o1002911.ingest.sentry.io/6006193',
  UNAUTHORIZED_REDIRECT_URL: `${AUTH_BASE_URL}/login?response_type=token&redirect_uri=${REDIRECT_URL}&client_id=redevistorias&scope=order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*`,
}
