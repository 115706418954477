import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserEntity } from 'src/domain'

import { makeAuthenticateUserUseCase } from 'src/main'

const authenticateUser = createAsyncThunk('@auth/authenticateUser', async () => {
  const authenticateUserUseCase = makeAuthenticateUserUseCase()

  return authenticateUserUseCase.authenticateUser()
})

interface AuthState {
  accessToken: string
  authenticationError: Error | boolean
  isAuthenticated: boolean
  user: UserEntity.Entity | null
}

const initialState: AuthState = {
  accessToken: '',
  authenticationError: false,
  isAuthenticated: false,
  user: null,
}

export const authSlice = createSlice({
  name: '@auth',
  initialState,
  reducers: {
    accessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },

    logoutUser: state => {
      state.accessToken = ''
      state.isAuthenticated = false
      state.user = null
    },
  },
  extraReducers: builder => {
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      state.authenticationError = false
      state.isAuthenticated = true
      state.user = action.payload
    })

    builder.addCase(authenticateUser.rejected, state => {
      state.accessToken = ''
      state.authenticationError = true
      state.isAuthenticated = false
      state.user = null
    })
  },
})

export const authActions = { ...authSlice.actions, authenticateUser }

export const authReducer = authSlice.reducer
