import { CreateOrUpdateClientMapper, HttpClient, HttpStatusCode } from 'src/data'

import {
  CreateOrUpdateClientPayloadError,
  CreateClientUseCase,
  UnexpectedError,
  CreateOrUpdateClientErrors,
  CreateOrUpdateClientUseCase,
} from 'src/domain'

export class RemoteCreateClientService implements CreateClientUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async createClient(params: CreateOrUpdateClientUseCase.Params) {
    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: CreateOrUpdateClientMapper.fromDomainToJson(params),
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
      case HttpStatusCode.notFound:
      case HttpStatusCode.serverError:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.conflict: {
        if (response.body?.message && response.body.message in CreateOrUpdateClientErrors) {
          throw new CreateOrUpdateClientPayloadError(response.body.message)
        }

        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
