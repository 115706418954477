import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { OrderEntity, UpdateOpenOrderUseCase } from 'src/domain'

import {
  makeConfirmOpenOrderUseCase,
  makeFetchCompletedOrderUseCase,
  makeFetchOpenOrderUseCase,
  makeUpdateOpenOrderUseCase,
  makeFetchCanceledOrderUseCase,
} from 'src/main'

interface FetchOrderParams {
  order: string
}

interface UpdateOrderParams {
  order: string
  data: UpdateOpenOrderUseCase.Params
}

interface ConfirmOrderParams {
  order: string
  data: UpdateOpenOrderUseCase.Params
}

const fetchOpenOrder = createAsyncThunk('@order/fetchOpenOrder', async ({ order }: FetchOrderParams) => {
  const fetchOpenOrderUseCase = makeFetchOpenOrderUseCase()

  return fetchOpenOrderUseCase.fetchOpenOrder(order)
})

const fetchCompletedOrder = createAsyncThunk('@order/fetchCompletedOrder', async ({ order }: FetchOrderParams) => {
  const fetchCompletedOrderUseCase = makeFetchCompletedOrderUseCase()

  return fetchCompletedOrderUseCase.fetchCompletedOrder(order)
})

const fetchCanceledOrder = createAsyncThunk('@order/fetchCanceledOrder', async ({ order }: FetchOrderParams) => {
  const fetchCanceledOrderUseCase = makeFetchCanceledOrderUseCase()

  return fetchCanceledOrderUseCase.fetchCanceledOrder(order)
})

const updateOpenOrder = createAsyncThunk('@order/updateOpenOrder', async ({ order, data }: UpdateOrderParams) => {
  const updateOpenOrderUseCase = makeUpdateOpenOrderUseCase()

  await updateOpenOrderUseCase.updateOpenOrder(order, data)
})

const confirmOpenOrder = createAsyncThunk(
  '@order/confirmOpenOrder',
  async ({ order, data }: ConfirmOrderParams, { rejectWithValue }) => {
    try {
      const updateOpenOrderUseCase = makeUpdateOpenOrderUseCase()
      const confirmOpenOrderUseCase = makeConfirmOpenOrderUseCase()

      await updateOpenOrderUseCase.updateOpenOrder(order, data)
      await confirmOpenOrderUseCase.confirmOpenOrder(order)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

interface OrderState {
  currentOpenOrder: OrderEntity.Entity | null
  currentCompletedOrder: OrderEntity.Entity | null
  currentCanceledOrder: OrderEntity.Entity | null
  loading: boolean
  fetchOpenOrderError: boolean
  fetchCompletedOrderError: boolean
  fetchCanceledOrderError: boolean
  updateOpenOrderSuccess: boolean
  updateOpenOrderError: boolean
  confirmOpenOrderSuccess: boolean
  confirmOpenOrderError: null | Error
}

const initialState: OrderState = {
  currentOpenOrder: null,
  currentCompletedOrder: null,
  currentCanceledOrder: null,
  loading: false,
  fetchOpenOrderError: false,
  fetchCompletedOrderError: false,
  fetchCanceledOrderError: false,
  updateOpenOrderSuccess: false,
  updateOpenOrderError: false,
  confirmOpenOrderSuccess: false,
  confirmOpenOrderError: null,
}

export const orderSlice = createSlice({
  name: '@order',
  initialState,
  reducers: {
    fetchOpenOrderError: state => {
      state.fetchOpenOrderError = false
    },

    fetchCompletedOrderError: state => {
      state.fetchCompletedOrderError = false
    },

    fetchCanceledOrderError: state => {
      state.fetchCanceledOrderError = false
    },

    updateOpenOrderSuccess: state => {
      state.updateOpenOrderSuccess = false
    },

    updateOpenOrderError: state => {
      state.updateOpenOrderError = false
    },

    confirmOpenOrderSuccess: state => {
      state.confirmOpenOrderSuccess = false
    },

    confirmOpenOrderError: state => {
      state.confirmOpenOrderError = null
    },

    clearCurrentOrders: state => {
      state.currentCompletedOrder = null
      state.currentOpenOrder = null
      state.currentCanceledOrder = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOpenOrder.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchOpenOrder.fulfilled, (state, action) => {
      state.loading = false
      state.currentOpenOrder = action.payload
      state.fetchOpenOrderError = false
    })

    builder.addCase(fetchOpenOrder.rejected, state => {
      state.loading = false
      state.fetchOpenOrderError = true
    })

    builder.addCase(fetchCompletedOrder.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchCompletedOrder.fulfilled, (state, action) => {
      state.loading = false
      state.currentCompletedOrder = action.payload
      state.fetchCompletedOrderError = false
    })

    builder.addCase(fetchCompletedOrder.rejected, state => {
      state.loading = false
      state.fetchCompletedOrderError = true
    })

    builder.addCase(fetchCanceledOrder.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchCanceledOrder.fulfilled, (state, action) => {
      state.loading = false
      state.currentCanceledOrder = action.payload
      state.fetchCanceledOrderError = false
    })

    builder.addCase(fetchCanceledOrder.rejected, state => {
      state.loading = false
      state.fetchCanceledOrderError = true
    })

    builder.addCase(updateOpenOrder.pending, state => {
      state.loading = true
    })

    builder.addCase(updateOpenOrder.fulfilled, state => {
      state.loading = false
      state.updateOpenOrderSuccess = true
      state.updateOpenOrderError = false
    })

    builder.addCase(updateOpenOrder.rejected, state => {
      state.loading = false
      state.updateOpenOrderSuccess = false
      state.updateOpenOrderError = true
    })

    builder.addCase(confirmOpenOrder.pending, state => {
      state.loading = true
    })

    builder.addCase(confirmOpenOrder.fulfilled, state => {
      state.loading = false
      state.confirmOpenOrderSuccess = true
      state.confirmOpenOrderError = null
    })

    builder.addCase(confirmOpenOrder.rejected, (state, action) => {
      state.loading = false
      state.confirmOpenOrderSuccess = false
      state.confirmOpenOrderError = action.payload as Error
    })
  },
})

export const orderActions = {
  ...orderSlice.actions,
  fetchOpenOrder,
  fetchCompletedOrder,
  fetchCanceledOrder,
  updateOpenOrder,
  confirmOpenOrder,
}

export const orderReducer = orderSlice.reducer
