import { HttpClient } from 'src/data'

import { AxiosHttpClient, AxiosAuthorizedHttpClientDecorator } from 'src/infra'

import { Keys, Urls } from 'src/main'

export const makeAxiosAuthorizedHttpClientDecorator = (baseUrl: string): HttpClient<any> => {
  return new AxiosAuthorizedHttpClientDecorator(
    new AxiosHttpClient(),
    baseUrl,
    Urls.UNAUTHORIZED_REDIRECT_URL,
    Keys.TOKEN_TYPE_STORAGE_KEY,
    Keys.ACCESS_TOKEN_STORAGE_KEY
  )
}
