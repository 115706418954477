import { HttpClient, HttpStatusCode, UpdateOpenOrderMapper } from 'src/data'

import { OpenOrderUpdateError, UnexpectedError, UpdateOpenOrderUseCase } from 'src/domain'

export class RemoteUpdateOpenOrderService implements UpdateOpenOrderUseCase {
  constructor(private readonly httpClient: HttpClient<void>, private readonly url: string) {}

  async updateOpenOrder(order: string, orderData: UpdateOpenOrderUseCase.Params) {
    const openOrderJson = UpdateOpenOrderMapper.toJson(orderData)

    const response = await this.httpClient.request({ method: 'put', url: `${this.url}/${order}`, body: openOrderJson })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
        throw new OpenOrderUpdateError()

      default:
        throw new UnexpectedError()
    }
  }
}
