import { RemoteConfirmOpenOrderService } from 'src/data'

import { ConfirmOpenOrderUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeConfirmOpenOrderUseCase = (): ConfirmOpenOrderUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlOrderPlaceholder = '{open-order}'
  const url = `/sales/${urlOrderPlaceholder}/confirm`

  return new RemoteConfirmOpenOrderService(authorizedHttpAdapter, url, urlOrderPlaceholder)
}
