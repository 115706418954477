import React, { ReactNode, useState, useEffect, useRef } from 'react'

import 'src/presentation/components/Modal/styles.css'

interface ModalType {
  children?: ReactNode
  isOpen: boolean
  closeClickOutside?: boolean
  closeClickEsc?: boolean
  closeModal: () => void
}

const Modal: React.FC<ModalType> = props => {
  const { children, isOpen, closeClickOutside = true, closeClickEsc = true, closeModal } = props
  const [showModal, setShowModal] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal()
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal()
    }
  }

  useEffect(() => {
    setShowModal(isOpen)

    if (closeClickEsc) {
      if (isOpen) {
        document.addEventListener('keydown', handleKeyDown)
      } else {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }

    if (closeClickOutside) {
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside)
      } else {
        document.removeEventListener('mousedown', handleClickOutside)
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isOpen, closeModal, closeClickOutside, closeClickEsc])

  return (
    <>
      {showModal && (
        <div className="ModalOverlay">
          <div className="ModalBox" ref={modalRef}>
            <div className="ModalContent">{children}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
