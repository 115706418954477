import {
  ClientEntity,
  ClientDetailsEntity,
  ClientPriceTableEntity,
  ClientSignatureEntity,
  ClientByCnpjEntity,
  ExportExtractEntity,
  ClientExtractFormats,
} from 'src/domain'

export type ClientsPagination = {
  offset?: number
  orderBy: string
  orderByDir: 'asc' | 'desc'
  limit: number
}

export interface FetchClientsUseCase {
  fetchClients(pagination: ClientsPagination, franchisee?: number, search?: string): Promise<ClientEntity[]>
}

export interface FetchClientUseCase {
  fetchClient(client: number): Promise<ClientDetailsEntity.Entity>
}

export enum CreateOrUpdateClientErrors {
  DOCUMENT_ALREADY_USED = 'DOCUMENT_ALREADY_USED',
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  EMAIL_IS_NOT_VALID = 'EMAIL_IS_NOT_VALID',
  USERNAME_ALREADY_USED = 'USERNAME_ALREADY_USED',
  USERNAME_IS_INVALID = 'USERNAME_IS_INVALID',
  USERNAME_NOT_FOUND = 'USERNAME_NOT_FOUND',
}

export namespace CreateOrUpdateClientUseCase {
  export type Params = Omit<ClientDetailsEntity.Entity, 'origin'>
}

export interface CreateClientUseCase {
  createClient(params: CreateOrUpdateClientUseCase.Params): Promise<void>
}

export interface UpdateClientUseCase {
  updateClient(client: number, params: CreateOrUpdateClientUseCase.Params): Promise<void>
}

export interface FetchClientPriceTablesUseCase {
  fetchClientPriceTables(client: number): Promise<ClientPriceTableEntity[]>
}

export interface UpdateClientPriceTableUseCase {
  updateClientPriceTable(client: number, priceTable: number): Promise<void>
}

export interface FetchClientSignatureUseCase {
  fetchClientSignature(client: number): Promise<ClientSignatureEntity | null>
}

export interface UpdateClientSignatureUseCase {
  updateClientSignature(client: number, value: number): Promise<void>
}

export interface FetchClientByCnpjUseCase {
  fetchClientByCnpj(cnpj: string): Promise<ClientByCnpjEntity>
}

export namespace ExportClientExtractUseCase {
  export type Params = {
    startDate?: string
    endDate?: string
    format: ClientExtractFormats
  }
}

export interface ExportClientExtractUseCase {
  exportClientExtract(client: number, params: ExportClientExtractUseCase.Params): Promise<ExportExtractEntity>
}
