import { addHours as dateFnsAddHours, format as dateFnsFormat, parse, format } from 'date-fns'
import localeBR from 'date-fns/locale/pt-BR'

export const useDate = () => ({
  formatDate: (date: Date | string, dateFormat = 'dd/MM/yyyy') => {
    if (!date) return ''

    try {
      if (typeof date === 'string') {
        date = new Date(date)
      }

      return dateFnsFormat(date, dateFormat, { locale: localeBR })
    } catch {
      return ''
    }
  },
  formatStringDate: (dataString: string, dateFormat = 'dd/MM/yyyy'): string => {
    if (!dataString) return ''
    const data = parse(dataString, 'dd/MM/yyyy', new Date())

    if (!isNaN(data.getTime())) {
      return format(data, dateFormat)
    } else {
      console.error('Formato de data inválido.')
      return ''
    }
  },
  now: () => Date.now(),
  toDate: (primitiveTypedDate: number | string) => new Date(primitiveTypedDate),
  addHours: (date: Date, amount: number) => dateFnsAddHours(date, amount),
})
