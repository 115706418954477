import { HttpClient, HttpStatusCode } from 'src/data'
import { CommercialResponsibleModel } from 'src/data/models/CommercialResponsibleModel'

import { FetchCommercialResponsiblesUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchCommercialResponsiblesService implements FetchCommercialResponsiblesUseCase {
  constructor(private readonly httpClient: HttpClient<CommercialResponsibleModel[]>, private readonly url: string) {}

  async fetchCommercialResponsibles(franchiseeId: number) {
    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: { franchisee_id: franchiseeId },
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return CommercialResponsibleModel.fromJsonCollection(
          response.body as CommercialResponsibleModel[]
        ).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
