import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ClientPriceTableEntity } from 'src/domain'

import { makeFetchClientPriceTablesUseCase, makeUpdateClientPriceTableUseCase } from 'src/main'

interface FetchClientPriceTablesParams {
  client: number
}

interface UpdateClientPriceTableParams {
  client: number
  priceTable: number
}

const fetchClientPriceTables = createAsyncThunk(
  '@priceTable/fetchClientPriceTables',
  async ({ client }: FetchClientPriceTablesParams) => {
    const fetchClientPriceTablesUseCase = makeFetchClientPriceTablesUseCase()

    return fetchClientPriceTablesUseCase.fetchClientPriceTables(client)
  }
)

const updateClientPriceTable = createAsyncThunk(
  '@priceTable/updateClientPriceTable',
  async ({ client, priceTable }: UpdateClientPriceTableParams) => {
    const updateClientPriceTableUseCase = makeUpdateClientPriceTableUseCase()

    return updateClientPriceTableUseCase.updateClientPriceTable(client, priceTable)
  }
)

interface PriceTableState {
  loadingFetchClientPriceTables: boolean
  loadingUpdateClientPriceTable: boolean
  priceTables: ClientPriceTableEntity[]
  updateClientPriceTableState: 'success' | 'error' | null
}

const initialState: PriceTableState = {
  loadingFetchClientPriceTables: false,
  loadingUpdateClientPriceTable: false,
  priceTables: [],
  updateClientPriceTableState: null,
}

export const priceTableSlice = createSlice({
  name: '@priceTable',
  initialState,
  reducers: {
    clearPriceTables: state => {
      state.priceTables = []
    },

    clearUpdateClientPriceTableState: state => {
      state.updateClientPriceTableState = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchClientPriceTables.pending, state => {
      state.loadingFetchClientPriceTables = true
    })

    builder.addCase(fetchClientPriceTables.fulfilled, (state, action) => {
      state.loadingFetchClientPriceTables = false
      state.priceTables = action.payload
    })

    builder.addCase(fetchClientPriceTables.rejected, state => {
      state.loadingFetchClientPriceTables = false
      state.priceTables = []
    })

    builder.addCase(updateClientPriceTable.pending, state => {
      state.loadingUpdateClientPriceTable = true
    })

    builder.addCase(updateClientPriceTable.fulfilled, state => {
      state.loadingUpdateClientPriceTable = false
      state.updateClientPriceTableState = 'success'
    })

    builder.addCase(updateClientPriceTable.rejected, state => {
      state.loadingUpdateClientPriceTable = false
      state.updateClientPriceTableState = 'error'
    })
  },
})

export const priceTableActions = { ...priceTableSlice.actions, fetchClientPriceTables, updateClientPriceTable }

export const priceTableReducer = priceTableSlice.reducer
