import { AddressEntity } from 'src/domain'

export class AddressModel {
  constructor(
    readonly zipcode: string,
    readonly street: string,
    readonly neighborhood: string,
    readonly neighborhoodId: string | number,
    readonly city: string,
    readonly cityId: string | number,
    readonly state: string,
    readonly stateId: string | number
  ) {}

  static fromBrazilianJson(json: any) {
    return new AddressModel(
      json?.zipcode,
      json?.street,
      json?.neighborhood,
      json?.neighborhood_id,
      json?.city,
      json?.city_id,
      json?.state,
      json?.state_id
    )
  }

  static fromPortugueseJson(json: any) {
    return new AddressModel(
      json?.CP,
      Array.isArray(json?.ruas) && json?.ruas?.length ? json.ruas[0] : '',
      '',
      '',
      json?.Concelho,
      json?.Concelho,
      json?.Distrito,
      json?.Distrito
    )
  }

  toEntity(): AddressEntity {
    return {
      zipcode: this.zipcode,
      street: this.street,
      neighborhood: this.neighborhood,
      neighborhoodId: this.neighborhoodId,
      city: this.city,
      cityId: this.cityId,
      state: this.state,
      stateId: this.stateId,
    }
  }
}
