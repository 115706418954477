import { CommercialResponsibleEntity } from 'src/domain'

export class CommercialResponsibleModel {
  private static commercialResponsibles: CommercialResponsibleModel[] = []

  constructor(readonly id: number, readonly username: string) {}

  static fromJsonCollection(json: any[]) {
    this.commercialResponsibles = json.map(
      commercialResponsibleJson =>
        new CommercialResponsibleModel(commercialResponsibleJson?.id, commercialResponsibleJson?.username)
    )

    return this
  }

  static toEntityCollection(): CommercialResponsibleEntity[] {
    return this.commercialResponsibles.map(commercialResponsibleModel => ({
      id: commercialResponsibleModel.id,
      username: commercialResponsibleModel.username,
    }))
  }
}
