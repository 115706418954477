import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaUser, FaUserPlus } from 'react-icons/fa'
import { MdAttachMoney, MdPayment } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import { SelectInstance } from 'react-select'

import { getDate, getDaysInMonth } from 'date-fns'
import { useFormik } from 'formik'

import { ClientDetailsEntity, PlanEntity } from 'src/domain'

import { Breadcrumb, Button, CurrencyInput, CustomSelect, Input, Select, TextArea } from 'src/presentation/components'
import { formLabelClasses, masks } from 'src/presentation/constants'
import { removeMoneySign, toMoney } from 'src/presentation/formatters'
import { useAppDispatch, useAppSelector, useLanguage, useMask, useToast } from 'src/presentation/hooks'
import {
  billingModeMapper,
  classificationMapper,
  firstBillingMonthMapper,
  originMapper,
} from 'src/presentation/mappers'
import { NumberOfInstallmentsOption } from 'src/presentation/pages'
import { Installment } from 'src/presentation/pages/ClientAddPlanPage/types'
import { clientActions } from 'src/presentation/store/ducks/client'
import { planActions } from 'src/presentation/store/ducks/plan'
import { plansActions } from 'src/presentation/store/ducks/plans'
import { sellersActions } from 'src/presentation/store/ducks/sellers'

import { useClientAddPlanValidator } from 'src/validation'

interface Params {
  clientId: string
}

type InstallmentWithRepeatedValues = {
  [installmentValue: string]: number[]
}

type SelectOption = {
  label: string
  value: Installment
}

const numberOfInstallmentsByPeriodicity = {
  [PlanEntity.Periodicity.MONTHLY]: 1,
  [PlanEntity.Periodicity.QUARTERLY]: 3,
  [PlanEntity.Periodicity.SEMIANNUAL]: 6,
  [PlanEntity.Periodicity.ANNUAL]: 12,
}

const defaultBasicPlanPrice = 11400.0

let formSubmitTimer: NodeJS.Timeout | null = null

const ClientAddPlanPage: React.FC = () => {
  const [numberOfInstallmentsOptions, setNumberOfInstallmentsOptions] = useState<Installment[]>([])
  const [numberOfInstallmentsNumericData, setNumberOfInstallmentsNumericData] = useState<number[]>([])
  const [isNumberOfInstallmentsFieldDisabled, setIsNumberOfInstallmentsFieldDisabled] = useState(false)
  const [billingDayOptions, setBillingDayOptions] = useState<number[]>([])

  const numberOfInstallmentsSelectRef = useRef()

  const dispatch = useAppDispatch()
  const { loading: loadingPlans, plans } = useAppSelector(state => state.plans)
  const { addClientPlanState, loadingAddClientPlan } = useAppSelector(state => state.plan)
  const { loading: loadingSellers, sellers } = useAppSelector(state => state.sellers)
  const { loading: loadingClient, client } = useAppSelector(state => state.client)
  const { loading: loadingFranchisees, franchisees } = useAppSelector(state => state.franchisees)

  const { clientId } = useParams<Params>()
  const { push } = useHistory()
  const { throwToast } = useToast()
  const { shape } = useClientAddPlanValidator()

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()
  const masksByCountry = useMask()

  const {
    values: formValues,
    errors: formErrors,
    isValid,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: shape,
    initialValues: {
      seller: '',
      modality: PlanEntity.Modality.BASIC,
      periodicity: PlanEntity.Periodicity.ANNUAL,
      packagePrice: defaultBasicPlanPrice,
      billingMode: PlanEntity.BillingMode.ON_TERM,
      numberOfInstallments: '',
      firstBillingMonth: PlanEntity.FirstBillingMonth.CURRENT,
      billingDay: '',
      paymentDetails: '',
    },
    onSubmit: (values, { setSubmitting }) => {
      async function handleSubmit() {
        try {
          if (
            numberOfInstallmentsNumericData.length !== Number(values.numberOfInstallments) ||
            !numberOfInstallmentsNumericData.length ||
            numberOfInstallmentsNumericData.includes(0)
          ) {
            setFieldError('numberOfInstallments', t('REQUIRED'))

            return
          }

          if (!values.billingDay) {
            setFieldError('billingDay', t('REQUIRED'))

            return
          }

          const planByModality = plans?.find(plan => plan?.name === values.modality)

          const payload = {
            planUuid: planByModality?.publicId || '',
            clientId: Number(clientId),
            seller: values.seller ? Number(values.seller) : null,
            modality: values.modality as PlanEntity.Modality,
            periodicity: values.periodicity as PlanEntity.Periodicity,
            price: Number(String(values.packagePrice).replace(',', '.')),
            billingMode: values.billingMode as PlanEntity.BillingMode,
            numberOfInstallments: Number(values.numberOfInstallments),
            numberOfInstallmentsNumericData,
            firstBillingMonth: values.firstBillingMonth as PlanEntity.FirstBillingMonth,
            billingDay: Number(values.billingDay),
            paymentDetails: values.paymentDetails,
          }

          dispatch(planActions.addClientPlan(payload))
        } catch {
          throwToast({ message: 'UNABLE_TO_ADD_PACKAGE' })
        } finally {
          formSubmitTimer = setTimeout(() => setSubmitting(false), 5000)
        }
      }

      handleSubmit()
    },
  })

  const loading = loadingClient || loadingPlans || loadingSellers
  const isFormBlockedOrInvalid = !isValid || isSubmitting
  const clientFranchisee = franchisees?.find(franchisee => franchisee?.id === client?.franchiseeId)

  const handleChangeNumberOfInstallments = (option: SelectOption) => {
    setNumberOfInstallmentsNumericData(option?.value?.numericValues || [])
    setFieldValue('numberOfInstallments', option?.value?.quantity)
  }

  const handleRefactorInstallmentsPrice = (totalAmount: number, numberOfInstallments: number) => {
    const refactoredInstallments = []
    const cents = totalAmount * 100

    refactoredInstallments.push((Math.floor(cents / numberOfInstallments) + (cents % numberOfInstallments)) / 100.0)

    for (let _ = 1; _ < numberOfInstallments; _++) {
      refactoredInstallments.push(Math.floor(cents / numberOfInstallments) / 100.0)
    }

    return refactoredInstallments
  }

  const handleFormatPlanPrice = (price: string) => (Number(removeMoneySign(price)) * 1000).toFixed(2)

  useEffect(() => {
    document.title = t('PLAN_ADDING')
  }, [])

  useEffect(() => {
    !plans.length && dispatch(plansActions.fetchPlans())
    !sellers.length && dispatch(sellersActions.fetchSellers())
  }, [dispatch])

  useEffect(() => {
    const { firstBillingMonth } = formValues
    const now = new Date()

    if (firstBillingMonth === PlanEntity.FirstBillingMonth.CURRENT) {
      const currentMonthDaysCount = getDaysInMonth(now)
      const today = getDate(now)

      const remainingDaysCount = currentMonthDaysCount - today
      const remainingDaysInMonthWithoutToday = currentMonthDaysCount - remainingDaysCount + 1

      if (remainingDaysInMonthWithoutToday > currentMonthDaysCount) {
        const nextMonthDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)
        const nextMonthDays = Array.from({ length: getDaysInMonth(nextMonthDate) }, (_, index) => index + 1)

        setBillingDayOptions(nextMonthDays)
        return
      }

      const start = remainingDaysInMonthWithoutToday
      const end = currentMonthDaysCount
      const remainingMonthDays = [...Array(end - start + 1).keys()].map(value => value + start)

      setBillingDayOptions(remainingMonthDays)
      return
    }

    const nextMonthDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    const nextMonthDays = Array.from({ length: getDaysInMonth(nextMonthDate) }, (_, index) => index + 1)

    setBillingDayOptions(nextMonthDays)
  }, [formValues.firstBillingMonth])

  useEffect(() => {
    if (addClientPlanState === 'success') {
      throwToast({ type: 'success', message: t('PACKAGE_ADDED_SUCCESSFULLY') })
      push('/clients')

      return
    }

    if (addClientPlanState === 'error') {
      throwToast({ message: t('UNABLE_TO_ADD_PACKAGE') })
    }

    return () => {
      dispatch(planActions.clearAddClientPlanState())
    }
  }, [addClientPlanState, dispatch])

  useEffect(() => {
    clientId && dispatch(clientActions.fetchClient({ client: Number(clientId) }))

    return () => {
      dispatch(clientActions.clearClient())
      clearTimeout(formSubmitTimer as NodeJS.Timeout)
    }
  }, [clientId, dispatch])

  useEffect(() => {
    const { modality, periodicity } = formValues

    if (!periodicity || !modality) return

    const planByModality = plans?.find(plan => plan?.name === modality)

    if (planByModality?.name === PlanEntity.Modality.ENTERPRISE) {
      setFieldValue('packagePrice', 0)

      return
    }

    const planPricesByPeriodicity = {
      [PlanEntity.Periodicity.MONTHLY]: handleFormatPlanPrice(planByModality?.price || ''),
      [PlanEntity.Periodicity.QUARTERLY]: handleFormatPlanPrice(planByModality?.quarterlyPrice || ''),
      [PlanEntity.Periodicity.SEMIANNUAL]: handleFormatPlanPrice(planByModality?.semiannualPrice || ''),
      [PlanEntity.Periodicity.ANNUAL]: handleFormatPlanPrice(planByModality?.annualPriceWithDiscount || ''),
    }

    setFieldValue('packagePrice', planPricesByPeriodicity[periodicity])
  }, [formValues.modality, formValues.periodicity, plans])

  useEffect(() => {
    const { billingMode, packagePrice, periodicity } = formValues

    if (!packagePrice) {
      setFieldError('packagePrice', t('ENTER_PACKAGE_PRICE'))

      return
    }

    const numberOfInstallments = numberOfInstallmentsByPeriodicity[periodicity]
    const formatedPackagePrice = Number(String(packagePrice).replaceAll(',', '.') || 0)

    if (billingMode === PlanEntity.BillingMode.IN_CASH || periodicity === PlanEntity.Periodicity.MONTHLY) {
      const inCashOptionValue = {
        quantity: 1,
        numericValues: [formatedPackagePrice],
        monetaryText: `1x ${masksByCountry.MONEY_SIGN} ${toMoney(formatedPackagePrice)}`,
      }

      setIsNumberOfInstallmentsFieldDisabled(true)
      setNumberOfInstallmentsOptions([inCashOptionValue])
      setNumberOfInstallmentsNumericData([formatedPackagePrice])
      setFieldValue('numberOfInstallments', 1)

      if (numberOfInstallmentsSelectRef.current) {
        const current = numberOfInstallmentsSelectRef?.current as unknown as SelectInstance

        current.setValue({ label: inCashOptionValue.monetaryText, value: inCashOptionValue }, 'select-option')
      }

      return
    }

    setIsNumberOfInstallmentsFieldDisabled(!client?.id)
    const newNumberOfInstallmentsOptions = []

    for (let index = 1; index <= numberOfInstallments; index++) {
      const refactoredInstallments = handleRefactorInstallmentsPrice(formatedPackagePrice, index)

      const groupedInstallmentsByValues = refactoredInstallments.reduce((acc, installmentValue) => {
        acc[installmentValue] = acc[installmentValue] || []
        acc[installmentValue].push(installmentValue)

        return acc
      }, {} as InstallmentWithRepeatedValues)

      const formattedMonetaryText = Object.entries(groupedInstallmentsByValues)
        .map(([installmentPrice, repeatedInstallments], index, groupedInstallmentsByValues) => {
          const installmentQuantity = repeatedInstallments.length || 1
          const isLastInstallmentPrice = index === groupedInstallmentsByValues.length - 1
          const formattedInstallment = toMoney(Number(installmentPrice))

          return `${installmentQuantity}x ${masksByCountry.MONEY_SIGN} ${formattedInstallment} ${
            !isLastInstallmentPrice ? '+ ' : ''
          }`
        })
        .join(' ')

      newNumberOfInstallmentsOptions.push({
        quantity: index,
        numericValues: refactoredInstallments,
        monetaryText: formattedMonetaryText,
      })
    }

    setNumberOfInstallmentsOptions(newNumberOfInstallmentsOptions)
  }, [client?.id, formValues.billingMode, formValues.modality, formValues.packagePrice, formValues.periodicity])

  return (
    <div data-cy="client-add-plan-page" className="relative w-full p-6">
      <Breadcrumb previousPageName="Clientes" previousPageUrl="/clients" currentPageName={clientId} />

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <div className="mb-6 border-b-2 border-senary">
          <h2 className="mb-4 text-base font-semibold text-black lg:text-lg">
            <Trans i18nKey="PACKAGE_ADDING" />
          </h2>
        </div>

        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex items-center justify-start mb-6">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <FaUserPlus className="ml-0.5 text-black" size={18} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_WITH_PARAM" values={{ clientId: clientId || '' }} />
            </h2>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input
                name="origin"
                label={t('ORIGIN')}
                value={client?.id && originMapper(currentLanguage, client?.origin)}
                loading={loading}
                disabled
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="franchisee"
                label={t('FRANCHISE_UNIT')}
                type="text"
                value={clientFranchisee?.name}
                loading={loading && loadingFranchisees}
                disabled
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <label className={formLabelClasses} htmlFor="clientTypeFields">
                <Trans i18nKey="CLIENT_TYPE" />
              </label>

              <div
                id="clientTypeFields"
                className="relative flex flex-wrap items-center justify-start mt-4 text-xs gap-y-1 lg:flex-nowrap lg:text-sm xl:text-base"
              >
                <div className="sm:mr-6 md:mr-16">
                  <input
                    id="clientTypePj"
                    className="mr-2 cursor-pointer"
                    name="clientTypePj"
                    data-cy="clientTypePj"
                    type="radio"
                    value={ClientDetailsEntity.ClientType.PJ}
                    checked={client?.clientType === ClientDetailsEntity.ClientType.PJ}
                    disabled
                  />

                  <label htmlFor="clientTypePj">
                    <Trans i18nKey="LEGAL_PERSON" />
                  </label>
                </div>

                <div>
                  <input
                    id="clientTypePf"
                    className="mr-2 cursor-pointer"
                    name="clientTypePf"
                    data-cy="clientTypePf"
                    type="radio"
                    value={ClientDetailsEntity.ClientType.PF}
                    checked={client?.clientType === ClientDetailsEntity.ClientType.PF}
                    disabled
                  />

                  <label htmlFor="clientTypePf">
                    <Trans i18nKey="NATURAL_PERSON" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="name"
                  value={client?.name}
                  loading={loading}
                  label={client?.clientType === ClientDetailsEntity.ClientType.PJ ? t('LEGAL_NAME') : t('NAME')}
                  disabled
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="classification"
                  label={t('CATEGORY')}
                  disabled
                  value={client?.id && classificationMapper(currentLanguage, client?.classification)}
                  loading={loading}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              {client?.clientType === ClientDetailsEntity.ClientType.PJ ? (
                <>
                  <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                    <Input
                      name="document"
                      value={client?.document}
                      loading={loading}
                      label={t('CNPJ')}
                      mask={masksByCountry.CNPJ_MASK}
                      disabled
                    />
                  </div>

                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                    <Input
                      name="companyName"
                      value={client?.companyName}
                      loading={loading}
                      label={t('LEGAL_ENTITY_NAME')}
                      disabled
                    />
                  </div>
                </>
              ) : (
                <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                  <Input
                    name="document"
                    value={client?.document}
                    loading={loading}
                    label={t('CPF')}
                    mask={masksByCountry.CPF_ONLY_MASK}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex mb-6">
            <div className="w-1/2 mb-4 mr-4 lg:mr-8">
              <Input
                name="username"
                value={client?.username}
                loading={loading}
                label={t('USER')}
                mask={masks.USERNAME_MASK}
                disabled
              />
            </div>

            <div className="w-1/2 mb-4">
              <Input
                name="contact.email"
                type="email"
                value={client?.contact?.email}
                loading={loading}
                label={t('EMAIL')}
                disabled
              />
            </div>
          </div>

          {client?.clientType === ClientDetailsEntity.ClientType.PJ && (
            <>
              <div className="flex items-center justify-start mb-6 border-senary">
                <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
                  <FaUser className="text-black" size={16} />
                </div>

                <h2 className="text-sm font-semibold lg:text-base">
                  <Trans i18nKey="RESPONSIBLE_PERSON" />
                </h2>
              </div>

              <div className="flex flex-wrap lg:flex-nowrap">
                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                    <Input
                      name="responsible.name"
                      value={client?.responsible?.name}
                      loading={loading}
                      label={t('NAME')}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                    <Input
                      name="responsible.document"
                      value={client?.responsible?.document}
                      loading={loading}
                      label={t('CPF')}
                      mask={masksByCountry.CPF_ONLY_MASK}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                    <Input
                      name="responsible.phone"
                      value={client?.responsible?.phone}
                      loading={loading}
                      label={t('PHONE_MOBILE')}
                      mask={value =>
                        value.length >= masksByCountry.CELLPHONE_MASK_LENGTH
                          ? masksByCountry.CELLPHONE_MASK
                          : masksByCountry.PHONE_MASK
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full mb-4 lg:w-1/2">
                  <Input
                    name="responsible.email"
                    type="email"
                    value={client?.responsible?.email}
                    loading={loading}
                    label={t('EMAIL')}
                    disabled
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdAttachMoney className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="SALE" />
            </h3>
          </div>

          <div className="flex flex-wrap w-full mb-0 lg:mb-6">
            <div className="w-full mb-4 lg:w-1/2">
              <Select
                name="seller"
                disabled={!client?.id}
                value={formValues.seller}
                onChange={handleChange}
                loading={loading}
                error={formErrors.seller}
                label={t('SELLER')}
              >
                <option value="" selected={!formValues.seller} disabled>
                  {t('CHOOSE_SELLER')}
                </option>

                {!!sellers?.length &&
                  sellers.map(seller => (
                    <option key={seller?.id} value={seller?.id}>
                      {seller?.name}
                    </option>
                  ))}
              </Select>
            </div>
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Select
                  name="modality"
                  disabled={!client?.id || !plans?.length}
                  value={formValues.modality}
                  onChange={handleChange}
                  loading={loading}
                  error={formErrors.modality}
                  label={t('MODALITY')}
                  required
                >
                  <option value="" disabled>
                    {t('CHOOSE_MODALITY')}
                  </option>

                  {plans?.length &&
                    plans?.map(plan => (
                      <option key={plan?.publicId} value={plan?.name}>
                        {plan?.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Select
                  name="periodicity"
                  disabled={!client?.id}
                  value={formValues.periodicity}
                  onChange={handleChange}
                  loading={loading}
                  error={formErrors.periodicity}
                  label={t('PERIOD')}
                  required
                >
                  <option value="" disabled>
                    {t('CHOOSE_PERIOD')}
                  </option>

                  <option value={PlanEntity.Periodicity.MONTHLY}>{t('MONTHLY')}</option>

                  <option value={PlanEntity.Periodicity.QUARTERLY}>{t('QUARTERLY')}</option>

                  <option value={PlanEntity.Periodicity.SEMIANNUAL}>{t('SEMIANNUAL')}</option>

                  <option value={PlanEntity.Periodicity.ANNUAL}>{t('ANNUAL')}</option>
                </Select>
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <CurrencyInput
                  name="packagePrice"
                  disabled={!client?.id}
                  value={formValues.packagePrice}
                  onValueChange={value => setFieldValue('packagePrice', value)}
                  loading={loading}
                  error={formErrors.packagePrice}
                  label={t('PACKAGE_PRICE')}
                  placeholder={t('ENTER_PACKAGE_PRICE')}
                  lng={currentLanguage}
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdPayment className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="PAYMENT" />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="sm:flex-1 sm:w-1/2 w-full mb-4 sm:mr-4 lg:mr-8">
              <Select
                name="billingMode"
                disabled={!client?.id}
                value={formValues.billingMode}
                onChange={handleChange}
                loading={loading}
                error={formErrors.billingMode}
                label={t('PAYMENT')}
                required
              >
                <option value="" disabled>
                  {t('CHOOSE_PAYMENT_METHOD')}
                </option>

                <option value={PlanEntity.BillingMode.IN_CASH}>
                  {billingModeMapper(currentLanguage, PlanEntity.BillingMode.IN_CASH)}
                </option>

                <option value={PlanEntity.BillingMode.ON_TERM}>
                  {billingModeMapper(currentLanguage, PlanEntity.BillingMode.ON_TERM)}
                </option>
              </Select>
            </div>

            <div className="sm:flex-1 sm:w-1/2 w-full mb-4">
              <CustomSelect
                name="numberOfInstallments"
                ref={numberOfInstallmentsSelectRef}
                isSearchable={false}
                isClearable={false}
                isDisabled={!client?.id || isNumberOfInstallmentsFieldDisabled}
                onChange={value => handleChangeNumberOfInstallments(value as SelectOption)}
                loading={loading}
                error={formErrors.numberOfInstallments}
                label={t('INSTALLMENT')}
                placeholder={t('CHOOSE_NUMBER_OF_INSTALLMENTS')}
                components={{ Option: NumberOfInstallmentsOption }}
                options={numberOfInstallmentsOptions.map(option => ({ label: option?.monetaryText, value: option }))}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="sm:flex-1 sm:w-1/2 w-full mb-4 sm:mr-4 lg:mr-8">
              <Select
                name="firstBillingMonth"
                disabled={!client?.id}
                value={formValues.firstBillingMonth}
                onChange={handleChange}
                loading={loading}
                error={formErrors.firstBillingMonth}
                label={t('FIRST_DUE_DATE')}
                required
              >
                <option value="" disabled>
                  {t('CHOOSE_FIRST_DUE_MONTH')}
                </option>

                <option value={PlanEntity.FirstBillingMonth.CURRENT}>
                  {firstBillingMonthMapper(currentLanguage, PlanEntity.FirstBillingMonth.CURRENT)}
                </option>

                <option value={PlanEntity.FirstBillingMonth.NEXT}>
                  {firstBillingMonthMapper(currentLanguage, PlanEntity.FirstBillingMonth.NEXT)}
                </option>
              </Select>
            </div>

            <div className="sm:flex-1 sm:w-1/2 w-full mb-4">
              <Select
                name="billingDay"
                disabled={!client?.id}
                value={formValues.billingDay}
                onChange={handleChange}
                loading={loading}
                error={formErrors.billingDay}
                label={t('DUE_DATE_BOLETO')}
                required
              >
                <option value="">{t('CHOOSE_DUE_DAY')}</option>

                {billingDayOptions.length &&
                  billingDayOptions.map(billingDayOption => (
                    <option key={billingDayOption} value={billingDayOption}>
                      {billingDayOption}
                    </option>
                  ))}
              </Select>
            </div>
          </div>

          <div className="flex">
            <div className="w-full mb-4">
              <TextArea
                name="paymentDetails"
                disabled={!client?.id}
                value={formValues.paymentDetails}
                onChange={handleChange}
                loading={loading}
                error={formErrors.paymentDetails}
                placeholder={t('DESCRIBE_DETAILS')}
                label={t('PAYMENT_DETAILS')}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-6 mt-2 border-t-2 border-senary md:flex-row">
            <Link
              className="order-1 w-full px-12 py-2 mr-0 text-sm font-semibold text-center text-white transition-colors rounded bg-error hover:bg-opacity-80 md:mr-4 md:w-auto md:order-none"
              to="/clients"
            >
              <Trans i18nKey="CANCEL" />
            </Link>

            <Button
              name="confirm-button"
              className="bg-success md:w-auto md:mb-0"
              type="submit"
              disabled={loading || isFormBlockedOrInvalid || loadingAddClientPlan}
            >
              <Trans i18nKey="CONFIRM" />
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default ClientAddPlanPage
