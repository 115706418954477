import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ClientDetailsEntity, CreateOrUpdateClientUseCase, ExportClientExtractUseCase } from 'src/domain'

import {
  makeCreateClientUseCase,
  makeExportClientExtractUseCase,
  makeFetchClientUseCase,
  makeUpdateClientUseCase,
} from 'src/main'

interface FetchClientParams {
  client: number
}

interface CreateClientParams {
  data: CreateOrUpdateClientUseCase.Params
}

interface UpdateClientParams {
  client: number
  data: CreateOrUpdateClientUseCase.Params
}

interface ExportClientExtractParams {
  client: number
  data: ExportClientExtractUseCase.Params
}

const fetchClient = createAsyncThunk('@client/fetchClient', async ({ client }: FetchClientParams) => {
  const fetchClientUseCase = makeFetchClientUseCase()

  return fetchClientUseCase.fetchClient(client)
})

const createClient = createAsyncThunk(
  '@client/createClient',
  async ({ data }: CreateClientParams, { rejectWithValue }) => {
    try {
      const createClientUseCase = makeCreateClientUseCase()

      await createClientUseCase.createClient(data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const updateClient = createAsyncThunk(
  '@client/updateClient',
  async ({ client, data }: UpdateClientParams, { rejectWithValue }) => {
    try {
      const updateClientUseCase = makeUpdateClientUseCase()

      await updateClientUseCase.updateClient(client, data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const exportClientExtract = createAsyncThunk(
  '@client/exportClientExtract',
  async ({ client, data }: ExportClientExtractParams, { rejectWithValue }) => {
    try {
      return makeExportClientExtractUseCase().exportClientExtract(client, data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

interface ClientState {
  client: ClientDetailsEntity.Entity | null
  exportedExtract: string | null
  loading: boolean
  createClientSuccess: boolean
  createClientError: Error | null
  updateClientSuccess: boolean
  updateClientError: Error | null
  loadingExportClientExtract: boolean
  exportClientExtractState: 'success' | 'error' | null
}

const initialState: ClientState = {
  client: null,
  exportedExtract: null,
  loading: false,
  createClientSuccess: false,
  createClientError: null,
  updateClientSuccess: false,
  updateClientError: null,
  loadingExportClientExtract: false,
  exportClientExtractState: null,
}

export const clientSlice = createSlice({
  name: '@client',
  initialState,
  reducers: {
    createClientSuccess: state => {
      state.createClientSuccess = false
    },

    createClientError: state => {
      state.createClientError = null
    },

    updateClientSuccess: state => {
      state.updateClientSuccess = false
    },

    updateClientError: state => {
      state.updateClientError = null
    },

    clearClient: state => {
      state.client = null
    },

    clearExportClientExtractState: state => {
      state.exportClientExtractState = null
    },

    clearExportedExtract: state => {
      state.exportedExtract = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchClient.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchClient.fulfilled, (state, action) => {
      state.loading = false
      state.client = action.payload
    })

    builder.addCase(fetchClient.rejected, state => {
      state.loading = false
    })

    builder.addCase(createClient.fulfilled, state => {
      state.createClientSuccess = true
      state.createClientError = null
    })

    builder.addCase(createClient.rejected, (state, action) => {
      state.createClientSuccess = false
      state.createClientError = action.payload as Error
    })

    builder.addCase(updateClient.fulfilled, state => {
      state.updateClientSuccess = true
      state.updateClientError = null
    })

    builder.addCase(updateClient.rejected, (state, action) => {
      state.updateClientSuccess = false
      state.updateClientError = action.payload as Error
    })

    builder.addCase(exportClientExtract.pending, state => {
      state.loadingExportClientExtract = true
    })

    builder.addCase(exportClientExtract.fulfilled, (state, action) => {
      state.loadingExportClientExtract = false
      state.exportClientExtractState = 'success'
      state.exportedExtract = action.payload.file
    })

    builder.addCase(exportClientExtract.rejected, state => {
      state.loadingExportClientExtract = false
      state.exportClientExtractState = 'error'
    })
  },
})

export const clientActions = { ...clientSlice.actions, fetchClient, createClient, updateClient, exportClientExtract }

export const clientReducer = clientSlice.reducer
