import { FetchOrdersIzeeMapper, HttpClient, HttpStatusCode, OrderIzeeModel } from 'src/data'

import { FetchCanceledOrdersIzeeUseCase, OrdersIzeePagination, UnexpectedError } from 'src/domain'

export class RemoteFetchCanceledOrdersIzeeService implements FetchCanceledOrdersIzeeUseCase {
  constructor(
    private readonly httpClient: HttpClient<OrderIzeeModel[]>,
    private readonly url: string,
    private readonly params: object
  ) {}

  async fetchCanceledOrdersIzee(pagination: OrdersIzeePagination, franchisee?: number) {
    const mappedPagination = FetchOrdersIzeeMapper.toJson(pagination)

    let mappedParams = {
      ...mappedPagination,
      franchisee_id: franchisee,
    }

    if (!franchisee) mappedParams = { ...this.params, ...mappedParams }

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: mappedParams,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return OrderIzeeModel.fromJsonCollection(response.body as OrderIzeeModel[]).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
