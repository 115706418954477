import { OrderIzeeEntity } from 'src/domain'

export class OrderIzeeModel {
  private static orders: OrderIzeeModel[] = []

  constructor(
    readonly id: number,
    readonly companyId: number,
    readonly setupPrice: number,
    readonly monthlyPrice: number,
    readonly setupNumberOfInstallments: number,
    readonly setupBillingMode: OrderIzeeEntity.BillingMode,
    readonly billingDay: number,
    readonly paymentForm: OrderIzeeEntity.PaymentForm,
    readonly firstBillingMonth: OrderIzeeEntity.FirstBillingMonth,
    readonly sellerId: number,
    readonly paymentDetails: string,
    readonly status: OrderIzeeEntity.Status,
    readonly company: OrderIzeeEntity.Company,
    readonly date: Date,
    readonly billingEmail: string,
    readonly billingPhone: string,
    readonly billingSetupBilletId: number | null | undefined,
    readonly billingSetupCarnetId: number | null | undefined,
    readonly billingSignatureId: number | null | undefined
  ) {}

  static fromJson(json: any) {
    return new OrderIzeeModel(
      json?.id,
      json?.company_id,
      json?.setup_price,
      json?.monthly_price,
      json?.setup_number_of_installments,
      json?.setup_billing_mode,
      json?.billing_day,
      json?.payment_form,
      json?.first_billing_month,
      json?.seller_id,
      json?.payment_details,
      json?.status,
      json?.company,
      json?.date,
      json?.billing_email,
      json?.billing_phone,
      json?.billing_setup_billet_id,
      json?.billing_setup_carnet_id,
      json?.billing_signature_id
    )
  }

  static fromJsonCollection(json: any[]) {
    this.orders = json.map(json => {
      return new OrderIzeeModel(
        json?.id,
        json?.company_id,
        json?.setup_price,
        json?.monthly_price,
        json?.setup_number_of_installments,
        json?.setup_billing_mode,
        json?.billing_day,
        json?.payment_form,
        json?.first_billing_month,
        json?.seller_id,
        json?.payment_details,
        json?.status,
        json?.company,
        json?.date,
        json?.billing_email,
        json?.billing_phone,
        json?.billing_setup_billet_id,
        json?.billing_setup_carnet_id,
        json?.billing_signature_id
      )
    })

    return this
  }

  toEntity(): OrderIzeeEntity.Entity {
    return {
      id: this.id,
      setupPrice: this.setupPrice,
      monthlyPrice: this.monthlyPrice,
      setupNumberOfInstallments: this.setupNumberOfInstallments,
      setupBillingMode: this.setupBillingMode,
      billingDay: this.billingDay,
      paymentForm: this.paymentForm,
      firstBillingMonth: this.firstBillingMonth,
      sellerId: this.sellerId,
      paymentDetails: this.paymentDetails,
      status: this.status,
      company: this.company,
      date: this.date,
      billingEmail: this.billingEmail,
      billingPhone: this.billingPhone,
      billingSetupBilletId: this.billingSetupBilletId,
      billingSetupCarnetId: this.billingSetupCarnetId,
      billingSignatureId: this.billingSignatureId,
    }
  }

  static toEntityCollection(): OrderIzeeEntity.Entity[] {
    return this.orders.map(orderModel => ({
      id: orderModel.id,
      setupPrice: orderModel.setupPrice,
      monthlyPrice: orderModel.monthlyPrice,
      setupNumberOfInstallments: orderModel.setupNumberOfInstallments,
      setupBillingMode: orderModel.setupBillingMode,
      billingDay: orderModel.billingDay,
      paymentForm: orderModel.paymentForm,
      firstBillingMonth: orderModel.firstBillingMonth,
      sellerId: orderModel.sellerId,
      paymentDetails: orderModel.paymentDetails,
      status: orderModel.status,
      company: orderModel.company,
      date: orderModel.date,
      billingEmail: orderModel.billingEmail,
      billingPhone: orderModel.billingPhone,
      billingSetupBilletId: orderModel.billingSetupBilletId,
      billingSetupCarnetId: orderModel.billingSetupCarnetId,
      billingSignatureId: orderModel.billingSignatureId,
    }))
  }
}
