import { RemoteResendOrderService } from 'src/data'

import { ResendOrderUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeResendOrderUseCaseFactory = (): ResendOrderUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteResendOrderService(authorizedHttpAdapter, '/sales')
}
