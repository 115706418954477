import { AddressModel, HttpClient, HttpStatusCode } from 'src/data'

import { FetchAddressByZipcodeUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchBrazilianAddressByZipcodeService implements FetchAddressByZipcodeUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  validateAndPrepareZipcode(zipcode: string) {
    if (!/^\d{5}-\d{3}$/.test(zipcode)) return null

    return zipcode.replace('-', '')
  }

  async fetchAddressByZipcode(zipcode: string) {
    const response = await this.httpClient.request({ method: 'get', url: this.url, params: { cep: zipcode } })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return AddressModel.fromBrazilianJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}

export class RemoteFetchPortugueseAddressByZipcodeService implements FetchAddressByZipcodeUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  validateAndPrepareZipcode(zipcode: string) {
    if (!/^\d{4}-\d{3}$/.test(zipcode)) return null

    return zipcode.replace('-', '')
  }

  async fetchAddressByZipcode(zipcode: string) {
    const response = await this.httpClient.request({ method: 'get', url: `${this.url}/${zipcode}` })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return AddressModel.fromPortugueseJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
