import { ClientDetailsEntity } from 'src/domain'

const assignmentEmailRuleToDisplay = {
  BR: {
    CREATOR: 'Enviar e-mail para quem solicitou o pedido',
    CUSTOM: 'Enviar e-mail para o endereço especificado abaixo',
    RESPONSIBLE: 'Enviar e-mail para o responsável informado pela integração',
    NONE: 'Não enviar e-mail na atribuição de pedidos',
  },
  PT: {
    CREATOR: 'Enviar e-mail para quem fez o pedido',
    CUSTOM: 'Enviar e-mail para o endereço especificado abaixo',
    RESPONSIBLE: 'Enviar e-mail para o responsável informado pela integração',
    NONE: 'Não enviar e-mail na atribuição de pedidos',
  },
}

export const assignmentEmailRuleMapper = (language: 'BR' | 'PT', rule: ClientDetailsEntity.AssignmentEmailRule) => {
  return assignmentEmailRuleToDisplay?.[language]?.[rule] || ''
}
