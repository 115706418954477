import { ClientDetailsEntity } from 'src/domain'

const classificationToDisplay = {
  BR: {
    IMOBILIARIA: 'Imobiliária',
    CORRETOR_DE_IMOVEIS: 'Corretor de imóveis',
    PROPRIETARIO: 'Proprietário',
    CONDOMINIO: 'Condomínio',
    ADMINISTRADORA_DE_CONDOMINIOS: 'Administradora de condomínios',
    SINDICO_PROFISSIONAL: 'Síndico profissional',
    CONSTRUTORA_INCORPORADORA: 'Construtora, Incorporadora',
    EMPREITEIRA: 'Empreiteira',
    INSTITUICAO_FINANCEIRA: 'Instituição Financeira',
    REDE_DE_VAREJO: 'Rede de Varejo',
    PESSOA_FISICA: 'Pessoa física',
    OUTROS: 'Outros',
  },
  PT: {
    IMOBILIARIA: 'Imobiliária',
    CORRETOR_DE_IMOVEIS: 'Corretor de imóveis',
    PROPRIETARIO: 'Proprietário',
    CONDOMINIO: 'Condomínio',
    ADMINISTRADORA_DE_CONDOMINIOS: 'Administradora de condomínios',
    SINDICO_PROFISSIONAL: 'Síndico profissional',
    CONSTRUTORA_INCORPORADORA: 'Construtora, Incorporadora',
    EMPREITEIRA: 'Empreiteira',
    INSTITUICAO_FINANCEIRA: 'Instituição financeira',
    REDE_DE_VAREJO: 'Rede de varejo',
    PESSOA_FISICA: 'Pessoa física',
    OUTROS: 'Outros',
  },
}

export const classificationMapper = (language: 'BR' | 'PT', classification: ClientDetailsEntity.Classification) => {
  return classificationToDisplay?.[language]?.[classification] || ''
}
