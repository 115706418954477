import { RemoteUpdateOpenOrderService } from 'src/data'

import { UpdateOpenOrderUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeUpdateOpenOrderUseCase = (): UpdateOpenOrderUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteUpdateOpenOrderService(authorizedHttpAdapter, '/sales/')
}
