import { OrderCancelErrors } from 'src/domain'

const orderCancelPayloadErrorToDisplay = {
  BR: {
    SALE_ALREADY_INVOICED: 'Venda já faturada, não é possível cancelar',
  },
  PT: {
    SALE_ALREADY_INVOICED: 'Venda já faturada, não é possível cancelar',
  },
}

export const OrderCancelMappers = (language: 'BR' | 'PT', err: OrderCancelErrors) => {
  return orderCancelPayloadErrorToDisplay?.[language]?.[err] || ''
}
