import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { FranchiseeEntity } from 'src/domain'

import { makeFetchFranchiseesUseCase } from 'src/main'

const fetchFranchisees = createAsyncThunk('@franchisees/fetchFranchisees', async () => {
  const fetchFranchiseesUseCase = makeFetchFranchiseesUseCase()

  return fetchFranchiseesUseCase.fetchFranchisees()
})

interface FranchiseesState {
  franchisees: FranchiseeEntity[]
  loading: boolean
}

const initialState: FranchiseesState = {
  franchisees: [],
  loading: false,
}

export const franchiseesSlice = createSlice({
  name: '@franchisees',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFranchisees.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchFranchisees.fulfilled, (state, action) => {
      state.franchisees = action.payload || []
      state.loading = false
    })

    builder.addCase(fetchFranchisees.rejected, state => {
      state.loading = false
    })
  },
})

export const franchiseesActions = { ...franchiseesSlice.actions, fetchFranchisees }

export const franchiseesReducer = franchiseesSlice.reducer
