import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaUserCircle } from 'react-icons/fa'
import { MdAttachMoney } from 'react-icons/md'
import { MdEdit } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'

import { useFormik } from 'formik'

import { CreateOrUpdateOrderIzeePayloadError, CreateOrUpdateOrderIzeeUseCase, OrderIzeeEntity } from 'src/domain'

import { Breadcrumb, Button, CurrencyInput, Input, Select, TextArea } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useLanguage, useMask, useToast } from 'src/presentation/hooks'
import { createOrUpdateOrderIzeePayloadErrorMapper, paymentFormMapper } from 'src/presentation/mappers'
import { clientActions } from 'src/presentation/store/ducks/clientIzee'
import { orderIzeeActions } from 'src/presentation/store/ducks/orderIzee'
import { sellersActions } from 'src/presentation/store/ducks/sellers'

import { InvalidFieldError, useOrderIzeeValidator } from 'src/validation'

interface Props {
  action: 'create' | 'edit' | 'view'
}

interface Params {
  orderId: string
  clientId: string
}

let formSubmitTimer: NodeJS.Timeout | null = null

const OrderIzeeCreateEditOrViewPage: React.FC<Props> = ({ action }) => {
  const [numberOfInstallmentsOptions, setNumberOfInstallmentsOptions] = useState<number[]>([])
  const [billingDayOptions, setBillingDayOptions] = useState<number[]>([])
  const [submitConfirmOrder, setSubmitConfirmOrder] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)

  const { orderId, clientId } = useParams<Params>()
  const { push } = useHistory()

  const { shape } = useOrderIzeeValidator()
  const { throwToast } = useToast()

  const dispatch = useAppDispatch()
  const { loading: sellersLoading, sellers } = useAppSelector(state => state.sellers)
  const {
    order,
    loading: loadingOrder,
    createOrderIzeeError,
    createOrderIzeeSuccess,
    updateOrderIzeeError,
    updateOrderIzeeSuccess,
    confirmOrderIzeeSuccess,
    confirmOrderIzeeError,
  } = useAppSelector(state => state.orderIzee)
  const { client, loading: loadingClient } = useAppSelector(state => state.clientIzee)

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()
  const masksByCountry = useMask()

  const isEditOrViewAction = action === 'edit' || action === 'view'

  const isCreateOrEditAction = action === 'create' || action === 'edit'

  const isCreateAction = action === 'create'

  const isEditAction = action === 'edit'

  const isFieldDisabled = useMemo(() => {
    if (action === 'create' || action === 'edit') return false

    if (action === 'view') return true

    return true
  }, [action, order])

  const {
    values: formValues,
    errors: formErrors,
    isValid,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: shape,
    initialValues: {
      companyId: isCreateAction ? clientId : order?.company?.id,
      setupPrice: isEditOrViewAction ? (Number(order?.setupPrice) || 0).toFixed(2) : '',
      monthlyPrice: isEditOrViewAction ? (Number(order?.monthlyPrice) || 0).toFixed(2) : '',
      setupNumberOfInstallments: isEditOrViewAction ? order?.setupNumberOfInstallments : '',
      billingDay: isEditOrViewAction ? order?.billingDay : '',
      paymentForm: isEditOrViewAction ? order?.paymentForm : '',
      sellerId: isEditOrViewAction ? order?.sellerId : '',
      paymentDetails: isEditOrViewAction ? order?.paymentDetails : '',
      billingEmail: order?.billingEmail || '',
      billingPhone: order?.billingPhone || '',
    },
    onSubmit: (submitValues, { setSubmitting }) => {
      async function handleSubmit() {
        try {
          const payload = {
            companyId: Number(submitValues.companyId),
            setupPrice: Number(parseFloat(submitValues.setupPrice.replaceAll(',', '.')).toFixed(2)),
            monthlyPrice: Number(parseFloat(submitValues.monthlyPrice.replaceAll(',', '.')).toFixed(2)),
            setupNumberOfInstallments: Number(submitValues.setupNumberOfInstallments),
            billingDay: Number(submitValues.billingDay),
            paymentForm: submitValues.paymentForm,
            sellerId: Number(submitValues.sellerId),
            paymentDetails: submitValues.paymentDetails,
            billingEmail: submitValues.billingEmail,
            billingPhone: submitValues.billingPhone,
          } as CreateOrUpdateOrderIzeeUseCase.Params

          if (action === 'create') {
            dispatch(orderIzeeActions.createOrderIzee({ data: payload }))
          } else if (action === 'edit' && orderId) {
            if (submitConfirmOrder) {
              setSubmitConfirmOrder(false)
              setLoadingConfirm(true)
              delete payload.companyId
              dispatch(orderIzeeActions.confirmOrderIzee({ order: Number(orderId), data: payload }))
            } else {
              delete payload.companyId
              dispatch(orderIzeeActions.updateOrderIzee({ order: Number(orderId), data: payload }))
            }
          }
        } catch (err) {
          if (err instanceof InvalidFieldError) {
            throwToast({ message: err.message })
          } else {
            throwToast({ message: t('UNABLE_TO_REGISTER_UPDATE_SALE') })
          }
        } finally {
          formSubmitTimer = setTimeout(() => setSubmitting(false), 3000)
        }
      }

      handleSubmit()
    },
  })

  useEffect(() => {
    document.title = action === 'create' ? `${t('NEW_SALE')} - PDV` : `${t('SALE_WITH_PARAM', { orderId })} - PDV`
  }, [action, orderId])

  useEffect(() => {
    setNumberOfInstallmentsOptions(Array.from({ length: 10 }, (_, index) => index + 1))
  }, [])

  useEffect(() => {
    const currentMonthDays = Array.from({ length: 30 }, (_, index) => index + 1)

    setBillingDayOptions(currentMonthDays)
  }, [])

  useEffect(() => {
    isEditOrViewAction && dispatch(orderIzeeActions.fetchOrderIzee({ order: Number(orderId) }))

    !sellers?.length && dispatch(sellersActions.fetchSellers())

    return () => {
      dispatch(orderIzeeActions.clearOrderIzee())

      clearTimeout(formSubmitTimer as NodeJS.Timeout)
    }
  }, [orderId, dispatch])

  useEffect(() => {
    const id = Number(clientId || order?.company?.id)
    if (id) dispatch(clientActions.fetchClientIzee({ client: id }))

    return () => {
      dispatch(clientActions.clearClient())
    }
  }, [clientId || order?.company?.id, dispatch])

  useEffect(() => {
    if (order?.id && createOrderIzeeSuccess && !createOrderIzeeError) {
      throwToast({ message: t('SALE_REGISTERED_SUCCESSFULLY'), type: 'success' })
      push(`/izee-sales/${order?.id}/edit`)

      return
    }

    if (createOrderIzeeError && !createOrderIzeeSuccess) {
      throwToast({
        message:
          createOrderIzeeError instanceof CreateOrUpdateOrderIzeePayloadError
            ? createOrUpdateOrderIzeePayloadErrorMapper(currentLanguage, createOrderIzeeError.message)
            : t('UNABLE_TO_REGISTER_SALE'),
      })

      dispatch(orderIzeeActions.createOrderIzeeError())
    }

    return () => {
      dispatch(orderIzeeActions.createOrderIzeeSuccess())
      dispatch(orderIzeeActions.createOrderIzeeError())
    }
  }, [dispatch, createOrderIzeeError, createOrderIzeeSuccess, order])

  useEffect(() => {
    if (updateOrderIzeeSuccess && !updateOrderIzeeError) {
      throwToast({ message: t('SALE_UPDATED_SUCCESSFULLY'), type: 'success' })
      push(`/izee-sales/${order?.id}/edit`)

      return
    }

    if (updateOrderIzeeError && !updateOrderIzeeSuccess) {
      throwToast({
        message:
          updateOrderIzeeError instanceof CreateOrUpdateOrderIzeePayloadError
            ? createOrUpdateOrderIzeePayloadErrorMapper(currentLanguage, updateOrderIzeeError.message)
            : t('UNABLE_TO_UPDATE_SALE'),
      })

      dispatch(orderIzeeActions.updateOrderIzeeError())
    }

    return () => {
      dispatch(orderIzeeActions.updateOrderIzeeSuccess())
      dispatch(orderIzeeActions.updateOrderIzeeError())
    }
  }, [dispatch, updateOrderIzeeError, updateOrderIzeeSuccess])

  useEffect(() => {
    if (confirmOrderIzeeSuccess && !confirmOrderIzeeError) {
      throwToast({ message: t('SALE_CONFIRMED_SUCCESSFULLY'), type: 'success' })
      push('/open-orders-izee')

      return
    }

    if (confirmOrderIzeeError && !confirmOrderIzeeSuccess) {
      setLoadingConfirm(false)
      throwToast({
        message:
          confirmOrderIzeeError instanceof CreateOrUpdateOrderIzeePayloadError
            ? createOrUpdateOrderIzeePayloadErrorMapper(currentLanguage, confirmOrderIzeeError.message)
            : t('UNABLE_TO_CONFIRM_SALE'),
      })

      dispatch(orderIzeeActions.confirmOrderIzeeError())
    }

    return () => {
      dispatch(orderIzeeActions.confirmOrderIzeeSuccess())
      dispatch(orderIzeeActions.confirmOrderIzeeError())
    }
  }, [dispatch, confirmOrderIzeeError, confirmOrderIzeeSuccess])

  const isFormInvalidOrBlocked = !!(isSubmitting || !isValid || loadingConfirm)

  return (
    <div data-cy="order-create-edit-or-view-page" className="relative w-full p-6">
      {isEditOrViewAction && (
        <Breadcrumb previousPageName={t('IZEE_ORDERS')} previousPageUrl="/izee-sales" currentPageName={orderId} />
      )}

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex items-center justify-between mb-6 border-b-2 border-senary">
            <div className="flex items-center justify-start">
              <h2 className="mb-4 text-sm font-semibold lg:text-base">
                {isEditOrViewAction ? t('SALE_WITH_PARAM', { orderId }) : t('NEW_SALE')}
              </h2>
            </div>

            {action === 'view' && (
              <Link to={`/izee-sales/${orderId}/edit`} className="inline-block">
                <MdEdit className="transition-colors text-primary hover:text-secundary" size={26} />
              </Link>
            )}
          </div>

          <div className="flex items-center justify-start mb-6">
            <FaUserCircle className="mr-2 text-black disabled:" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_IDENTIFICATION" />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input name="client" disabled value={client?.name} loading={loadingClient} label={t('NAME')} />
            </div>

            {client?.cnpj ? (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input name="cnpj" disabled value={client?.cnpj} loading={loadingClient} label={t('CNPJ')} />
              </div>
            ) : (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input name="cpf" disabled value={client?.cpf} loading={loadingClient} label={t('CPF')} />
              </div>
            )}
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="zipcode"
                  disabled
                  value={client?.address?.zipCode}
                  loading={loadingClient}
                  label={t('ZIP_CODE')}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="state"
                  disabled
                  value={client?.address?.state}
                  loading={loadingClient}
                  label={t('STATE')}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input name="city" disabled value={client?.address?.city} loading={loadingClient} label={t('CITY')} />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <Input
                  name="neighborhood"
                  disabled
                  value={client?.address?.neighborhood}
                  loading={loadingClient}
                  label={t('NEIGHBORHOOD')}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
              <Input
                name="street"
                disabled
                value={client?.address?.address}
                loading={loadingClient}
                label={t('STREET')}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="number"
                disabled
                value={client?.address?.number}
                loading={loadingClient}
                label={t('NUMBER')}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                name="complement"
                disabled
                value={client?.address?.additionalAddress}
                loading={loadingClient}
                label={t('COMPLEMENT')}
              />
            </div>
          </div>

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdAttachMoney className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="SALE" />
            </h3>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <Input
                  name="billingPhone"
                  value={formValues.billingPhone}
                  onChange={handleChange}
                  loading={loadingOrder}
                  error={formErrors.billingPhone}
                  label={t('BILLING_PHONE')}
                  mask={value =>
                    value.length >= masksByCountry.CELLPHONE_MASK_LENGTH
                      ? masksByCountry.CELLPHONE_MASK
                      : masksByCountry.PHONE_MASK
                  }
                  required
                  disabled={isFieldDisabled}
                />
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-2/3">
              <div className="flex-1">
                <Input
                  name="billingEmail"
                  type="email"
                  value={formValues.billingEmail}
                  onChange={handleChange}
                  loading={loadingOrder}
                  error={formErrors.billingEmail}
                  label={t('BILLING_EMAIL')}
                  required
                  disabled={isFieldDisabled}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <Select
                  name="sellerId"
                  disabled={isFieldDisabled}
                  value={formValues.sellerId}
                  onChange={handleChange}
                  loading={loadingOrder || sellersLoading}
                  error={formErrors.sellerId}
                  label={t('SELLER')}
                  required
                >
                  <option value="" selected={!formValues.sellerId} disabled>
                    {t('SELECT')}
                  </option>

                  {!!sellers?.length &&
                    sellers.map(seller => (
                      <option key={seller?.id} value={seller?.id}>
                        {seller?.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <CurrencyInput
                  name="monthlyPrice"
                  placeholder={`${masksByCountry.MONEY_SIGN} 0,00`}
                  value={formValues.monthlyPrice}
                  onValueChange={value => setFieldValue('monthlyPrice', value)}
                  loading={loadingOrder}
                  error={formErrors.monthlyPrice}
                  label={t('MONTHLY_FEE_AMOUNT')}
                  disabled={isFieldDisabled}
                  lng={currentLanguage}
                  required
                />
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1">
                <CurrencyInput
                  name="setupPrice"
                  placeholder={`${masksByCountry.MONEY_SIGN} 0,00`}
                  value={formValues.setupPrice}
                  onValueChange={value => setFieldValue('setupPrice', value)}
                  loading={loadingOrder}
                  error={formErrors.setupPrice}
                  label={t('SETUP_AMOUNT')}
                  disabled={isFieldDisabled}
                  lng={currentLanguage}
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <Select
                  name="setupNumberOfInstallments"
                  disabled={isFieldDisabled}
                  value={formValues.setupNumberOfInstallments}
                  onChange={handleChange}
                  loading={loadingOrder}
                  error={formErrors.setupNumberOfInstallments}
                  label={t('SETUP_INSTALLMENT')}
                  required
                >
                  <option value="" disabled>
                    {t('CHOOSE_NUMBER_OF_INSTALLMENTS')}
                  </option>

                  {numberOfInstallmentsOptions.length &&
                    numberOfInstallmentsOptions.map(numberOfInstallmentOption => (
                      <option key={numberOfInstallmentOption} value={numberOfInstallmentOption}>
                        {numberOfInstallmentOption}x
                      </option>
                    ))}
                </Select>
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <Select
                  name="paymentForm"
                  disabled={isFieldDisabled}
                  value={formValues.paymentForm}
                  onChange={handleChange}
                  loading={loadingOrder}
                  error={formErrors.paymentForm}
                  label={t('PAYMENT_METHOD')}
                  required
                >
                  <option value="" disabled>
                    {t('SELECT_PAYMENT_METHOD')}
                  </option>

                  <option value={OrderIzeeEntity.PaymentForm.PIX}>
                    {paymentFormMapper(currentLanguage, OrderIzeeEntity.PaymentForm.PIX)}
                  </option>

                  <option value={OrderIzeeEntity.PaymentForm.TICKET}>
                    {paymentFormMapper(currentLanguage, OrderIzeeEntity.PaymentForm.TICKET)}
                  </option>
                </Select>
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1">
                <Select
                  name="billingDay"
                  disabled={isFieldDisabled}
                  value={formValues.billingDay}
                  onChange={handleChange}
                  loading={loadingOrder}
                  error={formErrors.billingDay}
                  label={t('DUE_DATE_BOLETO')}
                  required
                >
                  <option value="">{t('CHOOSE_DUE_DAY')}</option>

                  {!!billingDayOptions.length &&
                    billingDayOptions.map(billingDayOption => (
                      <option key={billingDayOption} value={billingDayOption}>
                        {billingDayOption}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </div>

          <div className="flex w-full">
            <div className="flex-1 mb-6">
              <TextArea
                name="paymentDetails"
                disabled={isFieldDisabled}
                value={formValues.paymentDetails}
                onChange={handleChange}
                loading={loadingOrder}
                error={formErrors.paymentDetails}
                placeholder={t('DESCRIBE_DETAILS')}
                label={t('PAYMENT_DETAILS')}
              />
            </div>
          </div>

          {order?.status == OrderIzeeEntity.Status.CONFIRMED && (
            <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
              <div className="w-1/2 mb-4 mr-0  lg:mr-8">
                <div className="relative p-0">
                  <Input
                    name="billingSignatureId"
                    disabled
                    label={t('SUBSCRIPTION_BILLING_ID')}
                    value={order?.billingSignatureId || ''}
                    loading={loadingClient}
                  />
                </div>
              </div>

              <div className="flex-1 w-1/2 mb-4">
                <div className="relative">
                  <Input
                    name="billingSetupId"
                    type="text"
                    disabled
                    label={t('SETUP_BILLING_ID')}
                    value={order?.billingSetupBilletId || order?.billingSetupCarnetId || ''}
                    loading={loadingClient}
                  />
                </div>
              </div>
            </div>
          )}

          {isCreateOrEditAction && (
            <div className="flex flex-col items-center justify-center pt-6 mt-2 border-t-2 border-senary md:flex-row">
              <Link
                className="order-1 w-full px-12 py-2 mr-0 text-sm font-semibold text-center text-white transition-colors rounded bg-error hover:bg-opacity-80 md:mr-4 md:w-auto md:order-none"
                to="/open-orders-izee"
              >
                <Trans i18nKey="CANCEL" />
              </Link>

              <Button
                name="save-button"
                type="submit"
                className="bg-success md:mr-4 md:w-auto md:mb-0 md:order-none"
                disabled={isFormInvalidOrBlocked}
              >
                <Trans i18nKey="SAVE" />
              </Button>

              {isEditAction && (
                <Button
                  name="confirm-button"
                  className="bg-success md:w-auto md:mb-0"
                  type="submit"
                  disabled={isFormInvalidOrBlocked}
                  onClick={() => setSubmitConfirmOrder(true)}
                >
                  <Trans i18nKey="CONFIRM" />
                </Button>
              )}
            </div>
          )}
        </form>
      </section>
    </div>
  )
}

export default OrderIzeeCreateEditOrViewPage
