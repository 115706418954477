import { RemoteFetchOpenOrdersIzeeService } from 'src/data'

import { FetchOpenOrdersIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchOpenOrdersIzeeUseCase = (): FetchOpenOrdersIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const params = {
    status: 'OPEN',
  }

  return new RemoteFetchOpenOrdersIzeeService(authorizedHttpAdapter, '/izee-sales/', params)
}
