import React from 'react'

import LinearProgress from '@material/react-linear-progress'

import 'src/presentation/components/PaginateLoading/styles.css'

interface Props {
  loading: boolean
}

const PaginateLoading: React.FC<Props> = ({ loading }) => (
  <LinearProgress
    id="paginate-loading"
    className="shadow-sm"
    buffer={1}
    start={0}
    progress={0}
    closed={!loading}
    indeterminate
  />
)

export default PaginateLoading
