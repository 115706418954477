export const formLabelClasses =
  'block font-semibold mb-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs md:text-sm'

export const formFieldClasses = (error?: string) => {
  return `
    block
    w-full
    border
    border-quinary
    text-tertiary
    rounded
    py-3
    px-4
    disabled:opacity-60
    disabled:bg-background
    bg-white
    appearance-none
    text-xs
    md:text-sm
    ${error && 'border-error outline-error'}
  `
}
