import { HttpClient, HttpStatusCode } from 'src/data'

import { OpenOrderCancelUseCase, OrderEntity, UnexpectedError, OrderCancelError, OrderCancelErrors } from 'src/domain'

export class RemoteCancelOpenOrderService implements OpenOrderCancelUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async openOrderCancel(params: OrderEntity.ParamsOpenOrderCancel) {
    const id = params.id
    const data = {
      reason: params.reason,
      observation: params.observation,
    }

    const response = await this.httpClient.request({
      method: 'delete',
      url: `${this.url}/${id}`,
      body: data,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.forbidden: {
        if (response.body?.message && response.body.message in OrderCancelErrors) {
          throw new OrderCancelError(response.body.message)
        }
        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
