import { Urls } from 'src/main'

import { useAppDispatch } from 'src/presentation/hooks'
import { authActions } from 'src/presentation/store/ducks/auth'

export const useLogout = () => {
  const dispatch = useAppDispatch()

  return () => {
    dispatch(authActions.logoutUser())

    sessionStorage.clear()

    location.replace(Urls.UNAUTHORIZED_REDIRECT_URL)
  }
}
