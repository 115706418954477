export namespace OrderEntity {
  export type AutoBillingExecution = {
    summaryUrl: string
  }

  export enum BillingMode {
    IN_CASH = 'IN_CASH',
    ON_TERM = 'ON_TERM',
  }

  export enum BillingModality {
    SEPARATE = 'SEPARATE',
    PRE_PAID = 'PRE_PAID',
    POST_PAID = 'POST_PAID',
  }

  export enum Origin {
    API = 'API',
    AUTO_BILLING_EXECUTION = 'AUTO_BILLING_EXECUTION',
    WORKER = 'WORKER',
  }

  export enum PaymentForm {
    TICKET = 'TICKET',
    PIX = 'PIX',
  }

  export type Client = {
    active: boolean
    address: {
      city: string
      cityId: number
      neighborhood: string
      number: string
      state: string
      stateId: number
      street: string
      zipcode: string
      complement?: string
    }
    contactInfo: {
      email: string
      phone?: string
    }
    legalPersonInfo: {
      billingName: string
      cnpj: string
    }
    name: string
    naturalPersonInfo: {
      cpf: string
    }
    responsible: {
      cpf: string
      name: string
      email?: string
      phone?: string
    }
    type: string
  }

  export type Entity = {
    billingDay: number
    clientId: number
    franchiseeId: number
    client: Client
    createdAt: Date
    credits: number
    date: Date
    id: number
    numberOfInstallments: number
    packageData: Package
    paymentDetails: string
    price: number
    status: Status
    transactionId?: number
    updatedAt: Date
    userId: number
    billingMode?: BillingMode
    billingModality?: BillingModality
    from_single_order: string
    paymentForm?: PaymentForm
    firstBillingMonth: FirstBillingMonth
    negociationType?: number
    seller?: Seller
    canceled_at?: Date
    cancellation_reason?: string
    cancellation_observation?: string
    canceled_by?: string
    franchisee_name?: string
    origin: Origin
    autoBillingExecution?: AutoBillingExecution
  }

  export enum FirstBillingMonth {
    CURRENT = 'CURRENT',
    NEXT = 'NEXT',
  }

  export type NegociationType = {
    type: number
    description: string
    numberOfInstallments: number[]
    billingDays?: number[]
    billingMode: BillingMode[]
  }

  export type Package = {
    area: string
    credits: string
    size: number
    value: number
  }

  export type Seller = {
    id: number
    name: string
    type: string
    franchiseeIds: number[]
  }

  export enum Status {
    CLOSE = 'CLOSE',
    OPEN = 'OPEN',
    CANCEL = 'CANCEL',
  }

  export type ParamsOpenOrderCancel = {
    id: number | string
    reason: string
    observation: string
  }

  export type ParamsResendOrder = {
    id: number | string
  }
}
