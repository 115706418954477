import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { components as ReactSelectBaseComponents } from 'react-select'
import AsyncSelect from 'react-select/async'

import MenuItem from '@material-ui/core/MenuItem'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

import { makeFetchClientsUseCase } from 'src/main'

import classes from './styles.css'

const Option = ({ children, innerProps, innerRef, isFocused, isSelected }) => (
  <MenuItem
    ref={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
)

const LoadingMessage = ({ getStyles, innerProps, ...props }) => (
  <div {...innerProps} style={getStyles('loadingMessage', props)}>
    <Trans i18nKey="LOADING" />
    ...
  </div>
)

const NoOptionsMessage = ({ getStyles, innerProps, ...props }) => (
  <div {...innerProps} style={getStyles('loadingMessage', props)}>
    <Trans i18nKey="NO_OPTIONS" />
  </div>
)

const SingleValue = innerProps => {
  return <div style={{ fontSize: '14px', margin: '0px', fontWeight: 'bold' }}>{innerProps?.data?.name}</div>
}

const components = {
  Option,
  LoadingMessage,
  NoOptionsMessage,
  SingleValue,
  Input: inputProps => <ReactSelectBaseComponents.Input {...inputProps} data-cy="inspection-search-select" />,
}

const LoadClientsSelect = ({ handleSelectClient }) => {
  const [value, setValue] = useState('')
  const [mappedClients, setMappedClients] = useState([])
  const [clients, setClients] = useState([])
  const [page, setPage] = useState(0)
  const [loadMore, setLoadMore] = useState(true)
  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(false)
  const [inputSearchValue, setInputSearchValue] = useState('')
  const [inputKey, setInputKey] = useState(1)

  const { t } = useTranslation()

  const fetchClientsUseCase = makeFetchClientsUseCase()

  useEffect(async () => {
    setClients(await fetchClients('', 0))
  }, [])

  useEffect(() => {
    const mapped = clients?.map(client => {
      const label = (
        <div style={{ lineHeight: '1', textAlign: 'left' }}>
          <p style={{ fontSize: '14px', margin: '0px', fontWeight: 'bold' }}>{client?.name}</p>
        </div>
      )
      return {
        value: client?.id,
        label,
        ...client,
      }
    })
    setMappedClients(mapped)
  }, [clients])

  const onChange = selectValue => {
    setValue(selectValue)
    handleSelectClient(selectValue)
    setDefaultMenuIsOpen(false)
  }

  const onInputChange = inputValue => {
    if (inputValue === inputSearchValue) return
    setInputSearchValue(inputValue)
    handleFetchSuggestions(inputValue)
  }

  const fetchClients = async (inputValue, p = 0) => {
    const pagination = {
      limit: 15,
      offset: p * 15,
      orderBy: 'created_at',
      orderByDir: 'desc',
    }

    const fetchedClients = await fetchClientsUseCase.fetchClients(pagination, undefined, inputValue || undefined)
    if (fetchedClients.length < 15) {
      setLoadMore(false)
    }
    return fetchedClients
  }

  const handleFetchSuggestions = async inputValue => {
    setDefaultMenuIsOpen(true)
    setPage(0)

    setClients(await fetchClients(inputValue))

    setInputKey(inputKey + 1)
  }

  const handleLoadMore = async () => {
    if (loadMore) {
      setDefaultMenuIsOpen(true)
      setPage(page + 1)
      const moreClients = await fetchClients(inputSearchValue, page + 1)
      setClients([...clients, ...moreClients])
    }
  }

  return (
    <div className={classes.Search}>
      <AsyncSelect
        key={inputKey}
        defaultInputValue={inputSearchValue}
        onInputChange={debounce(onInputChange, 500)}
        value={value}
        placeholder={t('ALL')}
        components={components}
        onMenuScrollToBottom={handleLoadMore}
        defaultOptions={mappedClients}
        onChange={onChange}
        defaultMenuIsOpen={defaultMenuIsOpen}
        escapeClearsValue
        isMulti
      />
    </div>
  )
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
}

LoadingMessage.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
}

NoOptionsMessage.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
}

SingleValue.propTypes = {
  innerProps: PropTypes.object,
}

LoadClientsSelect.propTypes = {
  handleSelectClient: PropTypes.func.isRequired,
}

export default LoadClientsSelect
