import { RemoteFetchClientByCnpjService } from 'src/data'

import { FetchClientByCnpjUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientByCnpjUseCase = (): FetchClientByCnpjUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchClientByCnpjService(authorizedHttpAdapter, '/companies')
}
