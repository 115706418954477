import React, { useEffect, useLayoutEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import {
  makeCompletedOrderDetailsPage,
  makeOpenOrderEditPage,
  makeCompletedOrdersPage,
  makeOpenOrderDetailsPage,
  makeOpenOrdersPage,
  makeClientsPage,
  makeClientsIzeePage,
  makeClientIzeeDetailsPage,
  makeOrderIzeeCreateEditOrViewPage,
  makeOpenOrdersIzeePage,
  makeCompletedOrdersIzeePage,
  makeCanceledOrdersPage,
  makeCanceledOrderDetailsPage,
  makeClientCreateEditOrViewPage,
} from 'src/main'

import {
  AddPackageModal,
  AutoBillingConfigModal,
  ExportClientExtractModal,
  Header,
  PriceTablesModal,
  ScreenLoading,
  Sidebar,
  SignatureModal,
  ToastContainer,
} from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useAccessToken, useLogout, useLanguage } from 'src/presentation/hooks'
import { authActions } from 'src/presentation/store/ducks/auth'

interface ProtectedRouteProps {
  component?: React.ComponentType<any>
  render?: (props: any) => React.ReactNode
  path: string
  exact?: boolean
}

const ClientOnlyRoute: React.FC<ProtectedRouteProps> = ({ component: Component, render, ...rest }) => {
  const { user } = useAppSelector(state => state.auth)

  return (
    <Route
      {...rest}
      render={props =>
        user?.isClient ? (
          Component ? (
            <Component {...props} />
          ) : render ? (
            render(props)
          ) : null
        ) : (
          <Redirect to="/open-orders" />
        )
      }
    />
  )
}

const CommercialIzeeOnlyRoute: React.FC<ProtectedRouteProps> = ({ component: Component, render, ...rest }) => {
  const { user } = useAppSelector(state => state.auth)

  return (
    <Route
      {...rest}
      render={props =>
        user?.commercialIzee ? (
          Component ? (
            <Component {...props} />
          ) : render ? (
            render(props)
          ) : null
        ) : (
          <Redirect to="/open-orders" />
        )
      }
    />
  )
}

const Routes: React.FC = () => {
  const dispatch = useAppDispatch()
  const { authenticationError, isAuthenticated, user } = useAppSelector(state => state.auth)
  const { isSidebarOpen } = useAppSelector(state => state.ui)

  const { handleChangeLanguage } = useLanguage()
  const setAccessToken = useAccessToken()
  const logout = useLogout()

  useEffect(() => {
    setAccessToken()
  }, [])

  useEffect(() => {
    !!authenticationError && logout()
  }, [authenticationError])

  useEffect(() => {
    dispatch(authActions.authenticateUser())
  }, [dispatch])

  useLayoutEffect(() => {
    if (user?.country) handleChangeLanguage(user.country)
  }, [user])

  return (
    <BrowserRouter>
      {!isAuthenticated || !user?.id ? (
        <ScreenLoading />
      ) : (
        <div
          className={`
            flex
            justify-between
            relative
            bg-background
            w-full
            ${isSidebarOpen && 'h-screen overflow-hidden'}
          `}
        >
          <Sidebar />

          <div className="flex flex-col items-start justify-start w-full overflow-hidden">
            <Header />

            <Switch>
              <Route exact path="/open-orders" component={makeOpenOrdersPage} />
              <Route exact path="/open-orders/:orderId" component={makeOpenOrderDetailsPage} />
              <Route exact path="/open-orders/:orderId/edit" component={makeOpenOrderEditPage} />
              <Route exact path="/completed-orders" component={makeCompletedOrdersPage} />
              <Route exact path="/completed-orders/:orderId" component={makeCompletedOrderDetailsPage} />
              <Route exact path="/canceled-orders" component={makeCanceledOrdersPage} />
              <Route exact path="/canceled-orders/:orderId" component={makeCanceledOrderDetailsPage} />

              <ClientOnlyRoute exact path="/clients" component={makeClientsPage} />
              <ClientOnlyRoute exact path="/clients/new" render={() => makeClientCreateEditOrViewPage('create')} />
              <ClientOnlyRoute
                exact
                path="/clients/:clientId/view"
                render={() => makeClientCreateEditOrViewPage('view')}
              />
              <ClientOnlyRoute
                exact
                path="/clients/:clientId/edit"
                render={() => makeClientCreateEditOrViewPage('edit')}
              />

              <CommercialIzeeOnlyRoute exact path="/clients-izee" component={makeClientsIzeePage} />
              <CommercialIzeeOnlyRoute exact path="/clients-izee/:clientId" component={makeClientIzeeDetailsPage} />

              <CommercialIzeeOnlyRoute
                exact
                path="/izee-sales/new/:clientId"
                render={() => makeOrderIzeeCreateEditOrViewPage('create')}
              />
              <CommercialIzeeOnlyRoute
                exact
                path="/izee-sales/:orderId/view"
                render={() => makeOrderIzeeCreateEditOrViewPage('view')}
              />
              <CommercialIzeeOnlyRoute
                exact
                path="/izee-sales/:orderId/edit"
                render={() => makeOrderIzeeCreateEditOrViewPage('edit')}
              />

              <CommercialIzeeOnlyRoute exact path="/open-orders-izee" component={makeOpenOrdersIzeePage} />
              <CommercialIzeeOnlyRoute exact path="/completed-orders-izee" component={makeCompletedOrdersIzeePage} />

              <Route exact path="/">
                <Redirect to="/open-orders" />
              </Route>

              <Route path="*">
                <Redirect to="/open-orders" />
              </Route>
            </Switch>
          </div>

          <AddPackageModal />

          <PriceTablesModal />

          <SignatureModal />

          <AutoBillingConfigModal />

          <ExportClientExtractModal />
        </div>
      )}

      <ToastContainer />
    </BrowserRouter>
  )
}

export default Routes
