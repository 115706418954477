import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ImSpinner8 } from 'react-icons/im'
import { useHistory } from 'react-router-dom'

import { Button, CurrencyInput } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useLanguage, useToast } from 'src/presentation/hooks'
import { packageActions } from 'src/presentation/store/ducks/package'
import { uiActions } from 'src/presentation/store/ducks/ui'

const AddPackageModal: React.FC = () => {
  const [credits, setCredits] = useState('')

  const dispatch = useAppDispatch()
  const { client, isOpen } = useAppSelector(state => state.ui.packageModal)
  const { loading, purchaseError, purchaseSuccess, redirectOrderId } = useAppSelector(state => state.package)

  const { push } = useHistory()

  const { throwToast } = useToast()

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const handlePurchasePackage = () => {
    if (!client) return

    const creditsValueWithoutComma = credits.replaceAll(',', '.')
    const formattedCredits = Number((parseFloat(creditsValueWithoutComma) * 100).toFixed(2))

    if (!formattedCredits || formattedCredits <= 0) {
      throwToast({ message: t('ENTER_VALID_CREDIT_AMOUNT_GREATER_THAN_ZERO') })

      return
    }

    dispatch(packageActions.purchasePackage({ client: Number(client), credits: formattedCredits }))
  }

  const handleCreditsInputChange = (newValue?: string) => setCredits(newValue || '')

  const handleCloseModal = () => dispatch(uiActions.togglePackageModal({ client: null, isOpen: false }))

  useEffect(() => {
    if (!client || !isOpen) return

    return () => {
      setCredits('')
    }
  }, [client, dispatch, isOpen])

  useEffect(() => {
    if (purchaseError && !purchaseSuccess) {
      throwToast({ message: t('UNABLE_TO_ADD_PACKAGE') })

      return
    }

    if (!purchaseError && purchaseSuccess && redirectOrderId) {
      handleCloseModal()

      throwToast({ message: t('PACKAGE_ADDED_SUCCESSFULLY'), type: 'success' })

      push(`/open-orders/${redirectOrderId}/edit`)
    }

    return () => {
      dispatch(packageActions.purchaseError())
      dispatch(packageActions.purchaseSuccess())
      dispatch(packageActions.redirectOrderId())
    }
  }, [dispatch, purchaseError, purchaseSuccess, redirectOrderId])

  if (!isOpen || !client) return null

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black opacity-50 cursor-pointer"
        data-cy="add-package-modal-overlay"
        onClick={handleCloseModal}
      />

      <div
        className="fixed inset-0 z-50 flex flex-col items-start w-[calc(100%-22px)] py-6 sm:py-8 m-auto bg-white border-4 rounded lg:w-1/2 border-tertiary px-6 sm:px-14 h-min"
        data-cy="add-package-modal"
      >
        <h4 className="w-full mb-12 text-2xl font-medium text-center lg:text-left">
          <Trans i18nKey="ADD_PACKAGE" />
        </h4>

        <div className="relative flex flex-col items-start self-center justify-center w-full mb-8 xl:w-2/3">
          <CurrencyInput
            name="credits"
            className="!w-full px-4 py-2 mb-1 text-sm border-2 rounded sm:text-base border-tertiary text-tertiary"
            containerClassName="w-full"
            placeholder={t('ENTER_AMOUNT')}
            value={credits || ''}
            onValueChange={handleCreditsInputChange}
            loading={loading}
            lng={currentLanguage}
            prefix=" "
          />

          <span className="absolute font-bold top-2.5 right-2.5 text-tertiary">
            m<sup>2</sup>
          </span>
        </div>

        <div className="flex flex-col items-center self-center justify-center mt-4 md:flex-row">
          <Button
            name="cancel-purchase-package-button"
            className={`
              !text-xs
              bg-tertiary
              md:mr-4
              md:w-auto
              md:order-none
              order-1
              ${loading && 'pointer-events-none'}
            `}
            type="button"
            disabled={loading}
            onClick={handleCloseModal}
          >
            <Trans i18nKey="CANCEL" />
          </Button>

          <Button
            name="purchase-package-button"
            className="!text-xs bg-success md:w-auto"
            type="submit"
            disabled={loading}
            onClick={handlePurchasePackage}
          >
            {loading ? (
              <ImSpinner8 size={16} className="m-auto text-white animate-spin" />
            ) : (
              <span>
                <Trans i18nKey="ADD" />
              </span>
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default AddPackageModal
