type State = {
  id: string | number
  name: string
}

type States = {
  BR: State[]
  PT: State[]
}

export const states: States = {
  BR: [
    {
      id: 1,
      name: 'AC',
    },
    {
      id: 2,
      name: 'AL',
    },
    {
      id: 3,
      name: 'AP',
    },
    {
      id: 4,
      name: 'AM',
    },
    {
      id: 5,
      name: 'BA',
    },
    {
      id: 6,
      name: 'CE',
    },
    {
      id: 7,
      name: 'DF',
    },
    {
      id: 8,
      name: 'ES',
    },
    {
      id: 9,
      name: 'GO',
    },
    {
      id: 10,
      name: 'MA',
    },
    {
      id: 11,
      name: 'MT',
    },
    {
      id: 12,
      name: 'MS',
    },
    {
      id: 13,
      name: 'MG',
    },
    {
      id: 14,
      name: 'PR',
    },
    {
      id: 15,
      name: 'PB',
    },
    {
      id: 16,
      name: 'PA',
    },
    {
      id: 17,
      name: 'PE',
    },
    {
      id: 18,
      name: 'PI',
    },
    {
      id: 19,
      name: 'RN',
    },
    {
      id: 20,
      name: 'RS',
    },
    {
      id: 21,
      name: 'RJ',
    },
    {
      id: 22,
      name: 'RO',
    },
    {
      id: 23,
      name: 'RR',
    },
    {
      id: 24,
      name: 'SC',
    },
    {
      id: 25,
      name: 'SE',
    },
    {
      id: 26,
      name: 'SP',
    },
    {
      id: 27,
      name: 'TO',
    },
  ],
  PT: [
    {
      id: 'Aveiro',
      name: 'Aveiro',
    },
    {
      id: 'Beja',
      name: 'Beja',
    },
    {
      id: 'Braga',
      name: 'Braga',
    },
    {
      id: 'Bragança',
      name: 'Bragança',
    },
    {
      id: 'Castelo Branco',
      name: 'Castelo Branco',
    },
    {
      id: 'Coimbra',
      name: 'Coimbra',
    },
    {
      id: 'Faro',
      name: 'Faro',
    },
    {
      id: 'Guarda',
      name: 'Guarda',
    },
    {
      id: 'Leiria',
      name: 'Leiria',
    },
    {
      id: 'Lisboa',
      name: 'Lisboa',
    },
    {
      id: 'Portalegre',
      name: 'Portalegre',
    },
    {
      id: 'Porto',
      name: 'Porto',
    },
    {
      id: 'R. A. Açores',
      name: 'R. A. Açores',
    },
    {
      id: 'R. A. Madeira',
      name: 'R. A. Madeira',
    },
    {
      id: 'Santarém',
      name: 'Santarém',
    },
    {
      id: 'Setúbal',
      name: 'Setúbal',
    },
    {
      id: 'Viana do Castelo',
      name: 'Viana do Castelo',
    },
    {
      id: 'Vila Real',
      name: 'Vila Real',
    },
    {
      id: 'Viseu',
      name: 'Viseu',
    },
    {
      id: 'Évora',
      name: 'Évora',
    },
  ],
}
