import { AutoBillingConfigModel, HttpClient, HttpStatusCode } from 'src/data'

import { UnexpectedError, FetchAutoBillingConfigUseCase } from 'src/domain'

export class RemoteFetchAutoBillingConfigService implements FetchAutoBillingConfigUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlClientPlaceholder: string
  ) {}

  async fetchAutoBillingConfig(client: number) {
    const url = this.url.replace(this.urlClientPlaceholder, String(client))

    const response = await this.httpClient.request({ method: 'get', url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return AutoBillingConfigModel.fromJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
