import { HttpClient, HttpStatusCode } from 'src/data'
import { ClientByCnpjModel } from 'src/data/models/ClientByCnpjModel'

import { FetchClientByCnpjUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchClientByCnpjService implements FetchClientByCnpjUseCase {
  constructor(private readonly httpClient: HttpClient<ClientByCnpjModel>, private readonly url: string) {}

  async fetchClientByCnpj(cnpj: string) {
    const response = await this.httpClient.request({
      method: 'get',
      url: `${this.url}/${cnpj}`,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ClientByCnpjModel.fromJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
