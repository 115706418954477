import { PurchasePackageUseCase } from 'src/domain'

export namespace PurchasePackageMapper {
  export type JsonReturn = {
    sale_id?: number
  }

  export class Mapper {
    static fromJsonToDomain(json: JsonReturn): PurchasePackageUseCase.Return {
      return {
        saleId: json?.sale_id as number,
      }
    }

    static fromDomainToJson({ client, credits }: PurchasePackageUseCase.Params) {
      return {
        client_id: client,
        credits,
      }
    }
  }
}
