import { RemoteFetchBrazilianCitiesByStateService, RemoteFetchPortugueseCitiesByStateService } from 'src/data'

import { FetchCitiesByStateUseCase } from 'src/domain'

import { makeAxiosHttpClient, Urls } from 'src/main'

export const makeFetchCitiesByStateUseCase = (language: 'BR' | 'PT'): FetchCitiesByStateUseCase => {
  if (language === 'BR') {
    const httpAdapter = makeAxiosHttpClient(Urls.ADDRESS_BASE_URL)

    return new RemoteFetchBrazilianCitiesByStateService(httpAdapter, '/cities')
  }

  if (language === 'PT') {
    const httpAdapter = makeAxiosHttpClient(Urls.PT_ADDRESS_BASE_URL)

    return new RemoteFetchPortugueseCitiesByStateService(httpAdapter, '/distrito')
  }

  throw new Error('Invalid language')
}
