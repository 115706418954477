import { HttpClient, HttpStatusCode } from 'src/data'
import { OrderIzeeModel } from 'src/data/models/OrderIzeeModel'

import { FetchOrderIzeeUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchOrderIzeeService implements FetchOrderIzeeUseCase {
  constructor(private readonly httpClient: HttpClient<OrderIzeeModel>, private readonly url: string) {}

  async fetchOrderIzee(client: number) {
    const response = await this.httpClient.request({
      method: 'get',
      url: `${this.url}/${client}`,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return OrderIzeeModel.fromJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
