import { HttpClient, HttpStatusCode, UserModel } from 'src/data'

import { AuthenticateUserUseCase, UnauthorizedError, UnexpectedError } from 'src/domain'

export class RemoteAuthenticateUserService implements AuthenticateUserUseCase {
  constructor(private readonly httpClient: HttpClient<UserModel>, private readonly url: string) {}

  async authenticateUser() {
    const response = await this.httpClient.request({ method: 'get', url: this.url })

    switch (response.statusCode) {
      case HttpStatusCode.ok: {
        const user = UserModel.fromJson(response.body).toEntity()

        if (!user || !user?.isAuthorized) throw new UnauthorizedError()

        return user
      }

      case HttpStatusCode.forbidden:
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError()

      default:
        throw new UnexpectedError()
    }
  }
}
