import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ImSpinner8 } from 'react-icons/im'
import { IoDocumentText } from 'react-icons/io5'

import { Button, PriceTablesLoading } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useToast } from 'src/presentation/hooks'
import { priceTableActions } from 'src/presentation/store/ducks/priceTable'
import { uiActions } from 'src/presentation/store/ducks/ui'

const PriceTablesModal: React.FC = () => {
  const [selectedPriceTableId, setSelectedPriceTableId] = useState<number | null>(null)

  const dispatch = useAppDispatch()
  const { client, isOpen } = useAppSelector(state => state.ui.priceTablesModal)
  const { loadingFetchClientPriceTables, loadingUpdateClientPriceTable, priceTables, updateClientPriceTableState } =
    useAppSelector(state => state.priceTable)

  const { throwToast } = useToast()

  const { t } = useTranslation()

  const handleCloseModal = () => dispatch(uiActions.togglePriceTablesModal({ client: null, isOpen: false }))

  const handleSelectPriceTable: ChangeEventHandler<HTMLInputElement> = event => {
    setSelectedPriceTableId(Number(event.target.value))
  }

  const handleConfirmPriceTable = () => {
    if (!client || !selectedPriceTableId) return

    dispatch(priceTableActions.updateClientPriceTable({ client, priceTable: selectedPriceTableId }))
  }

  useEffect(() => {
    client && dispatch(priceTableActions.fetchClientPriceTables({ client }))

    return () => {
      dispatch(priceTableActions.clearPriceTables())
    }
  }, [client, dispatch])

  useEffect(() => {
    if (updateClientPriceTableState === 'error') {
      throwToast({ message: t('UNABLE_TO_UPDATE_PRICE_LIST') })

      return
    }

    if (updateClientPriceTableState === 'success') {
      handleCloseModal()

      throwToast({ message: t('PRICE_LIST_UPDATED_SUCCESSFULLY'), type: 'success' })
    }

    return () => {
      dispatch(priceTableActions.clearUpdateClientPriceTableState())
    }
  }, [dispatch, updateClientPriceTableState])

  if (!isOpen || !client) return null

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black opacity-50 cursor-pointer"
        data-cy="price-tables-modal-overlay"
        onClick={handleCloseModal}
      />

      <div
        className="fixed inset-x-0 inset-y-0 z-50 flex flex-col items-start w-[calc(100%-22px)] py-6 mx-auto my-auto bg-white border-4 rounded lg:w-max border-tertiary max-h-[calc(100%-22px)] h-min"
        data-cy="price-tables-modal"
      >
        <h4 className="w-full px-6 pb-6 border-b-tertiary border-b-2 text-xl flex justify-start gap-4 items-center font-medium text-center lg:text-left">
          <IoDocumentText className="text-octonary" size={24} />

          <strong className="font-semibold text-nonary">
            <Trans i18nKey="SELECT_PRICE_LIST" />
          </strong>
        </h4>

        <div className="relative px-6 pb-2 self-center mx-auto mb-6 w-full max-h-[calc(100%-22px)] overflow-auto">
          <table className="self-center table-auto">
            <thead>
              <tr>
                <th />

                <th className="font-semibold text-nonary text-left p-4 pb-0 truncate">
                  <Trans i18nKey="CODE" />
                </th>

                <th className="font-semibold text-nonary text-left p-4 pb-0 truncate">
                  <Trans i18nKey="MINIMUM_BILLING" />
                </th>

                <th className="font-semibold text-nonary text-left p-4 pb-0 truncate">
                  <Trans i18nKey="DESCRIPTION" />
                </th>
              </tr>
            </thead>

            <tbody>
              {loadingFetchClientPriceTables ? (
                <PriceTablesLoading />
              ) : (
                !!priceTables?.length &&
                priceTables.map(priceTable => (
                  <tr key={priceTable?.id}>
                    <td className="text-left p-4 pb-0 pl-1">
                      <input
                        id="price"
                        className="cursor-pointer"
                        name="price-table-radio-button"
                        data-cy={`price-table-radio-button-${priceTable?.id}`}
                        type="radio"
                        value={priceTable?.id}
                        defaultChecked={priceTable?.active}
                        onChange={handleSelectPriceTable}
                      />
                    </td>

                    <td className="text-nonary text-left p-4 pb-0 truncate">{priceTable?.id}</td>

                    <td className="text-nonary text-left p-4 pb-0 truncate">
                      {priceTable?.minimalPrice} m<sup>2</sup>
                    </td>

                    <td
                      className="text-nonary text-left p-4 pb-0 pr-1 truncate max-w-lg"
                      title={priceTable?.description}
                    >
                      {priceTable?.description || '----'}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col items-center self-center justify-center md:flex-row">
          <Button
            name="cancel-price-tables-button"
            className={`
              !text-xs
              bg-tertiary
              md:mr-4
              md:w-auto
              md:order-none
              order-1
              ${loadingUpdateClientPriceTable && 'pointer-events-none'}
            `}
            type="button"
            disabled={loadingUpdateClientPriceTable}
            onClick={handleCloseModal}
          >
            <Trans i18nKey="CANCEL" />
          </Button>

          <Button
            name="confirm-price-tables-button"
            className="!text-xs bg-success md:w-auto"
            type="submit"
            disabled={loadingUpdateClientPriceTable || !selectedPriceTableId}
            onClick={handleConfirmPriceTable}
          >
            {loadingUpdateClientPriceTable ? (
              <ImSpinner8 size={16} className="m-auto text-white animate-spin" />
            ) : (
              <span>
                <Trans i18nKey="CONFIRM" />
              </span>
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default PriceTablesModal
