import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ClientEntity, ClientsPagination } from 'src/domain'

import { makeFetchClientsUseCase } from 'src/main'

import { AppState } from 'src/presentation/store'
import { headerSearchActions } from 'src/presentation/store/ducks/headerSearch'

const CLIENTS_PER_PAGE = 15

interface FetchClientsParams {
  page: number | null
  franchisee: number | null
  search: string | null
}

const fetchClients = createAsyncThunk(
  '@clients/fetchClients',
  async ({ franchisee, page, search }: FetchClientsParams, { dispatch, getState }) => {
    page ? dispatch(clientsActions.loadingPagination()) : dispatch(clientsActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee || !!search))

    const fetchClientsUseCase = makeFetchClientsUseCase()

    const pagination: ClientsPagination = {
      limit: CLIENTS_PER_PAGE,
      offset: page ? page * CLIENTS_PER_PAGE : undefined,
      orderBy: 'created_at',
      orderByDir: 'desc',
    }

    const fetchedClients = await fetchClientsUseCase.fetchClients(
      pagination,
      franchisee || undefined,
      search || undefined
    )

    const {
      clients: { clients: existingClients },
    } = getState() as AppState

    dispatch(clientsActions.hasMoreClients(fetchedClients.length === CLIENTS_PER_PAGE))

    return page ? [...existingClients, ...fetchedClients] : fetchedClients
  }
)

interface ClientsState {
  clients: ClientEntity[]
  loading: boolean
  loadingPagination: boolean
  hasMoreClients: boolean
}

const initialState: ClientsState = {
  clients: [],
  loading: false,
  loadingPagination: false,
  hasMoreClients: false,
}

export const clientsSlice = createSlice({
  name: '@clients',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
    },

    loadingPagination: state => {
      state.loadingPagination = true
    },

    hasMoreClients: (state, action: PayloadAction<boolean>) => {
      state.hasMoreClients = action.payload
    },

    clearClients: state => {
      state.clients = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.clients = action.payload || []
    })

    builder.addCase(fetchClients.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreClients = false
    })
  },
})

export const clientsActions = { ...clientsSlice.actions, fetchClients }

export const clientsReducer = clientsSlice.reducer
