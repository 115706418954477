import { CityModel, HttpClient, HttpStatusCode } from 'src/data'

import { FetchCitiesByStateUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchBrazilianCitiesByStateService implements FetchCitiesByStateUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async fetchCitiesByState(state: string | number) {
    const response = await this.httpClient.request({ method: 'get', url: this.url, params: { state_id: state } })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return CityModel.fromBrazilianJsonCollection(response.body).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}

export class RemoteFetchPortugueseCitiesByStateService implements FetchCitiesByStateUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async fetchCitiesByState(state: string | number) {
    const response = await this.httpClient.request({ method: 'get', url: `${this.url}/${state}/municipios` })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return CityModel.fromPortugueseJsonCollection(response.body).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
