import { HttpClient, HttpStatusCode } from 'src/data'

import {
  CreateOrUpdateOrderIzeeErrors,
  CreateOrUpdateOrderIzeePayloadError,
  UnexpectedError,
  ConfirmOrderIzeeUseCase,
} from 'src/domain'

export class RemoteConfirmOrderIzeeService implements ConfirmOrderIzeeUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlOrderPlaceholder: string
  ) {}

  async confirmOrderIzee(order: number) {
    const url = this.url.replace(this.urlOrderPlaceholder, String(order))

    const response = await this.httpClient.request({ method: 'post', url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
      case HttpStatusCode.notFound:
      case HttpStatusCode.serverError:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.conflict: {
        if (response.body?.message && response.body.message in CreateOrUpdateOrderIzeeErrors) {
          throw new CreateOrUpdateOrderIzeePayloadError(response.body.message)
        }

        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
