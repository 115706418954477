import { ClientDetailsEntity } from 'src/domain'

const originToDisplay = {
  BR: {
    SA: 'SA',
    UNIDADE: 'Unidade',
    FRANQUIA: 'Unidade Franqueada',
  },
  PT: {
    SA: 'SA',
    UNIDADE: 'Unidade',
    FRANQUIA: 'Unidade Franqueada',
  },
}

export const originMapper = (language: 'BR' | 'PT', origin: ClientDetailsEntity.Origin) => {
  return originToDisplay?.[language]?.[origin] || ''
}
