export namespace PlanEntity {
  export enum BillingMode {
    IN_CASH = 'IN_CASH',
    ON_TERM = 'ON_TERM',
  }

  export type Detail = {
    items: Item[]
    title: string
  }

  export type Entity = {
    annualDiscount: string
    annualPrice: string
    annualPriceWithDiscount: string
    details: Detail[]
    includedInspections: number
    name: Modality
    negotiable: boolean
    price: string
    publicId: string
    quarterlyPrice: string
    semiannualPrice: string
    type: string
  }

  export enum FirstBillingMonth {
    CURRENT = 'CURRENT',
    NEXT = 'NEXT',
  }

  export type Item = {
    label: string
    value: string
  }

  export enum Modality {
    AVULSO = 'Avulso',
    BASIC = 'Basic',
    PRO = 'Pro',
    BUSINESS = 'Business',
    ENTERPRISE = 'Enterprise',
  }

  export enum Periodicity {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    SEMIANNUAL = 'SEMIANNUAL',
    ANNUAL = 'ANNUAL',
  }
}
