import { RemoteFetchCompletedOrderService } from 'src/data'

import { FetchCompletedOrderUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCompletedOrderUseCase = (): FetchCompletedOrderUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchCompletedOrderService(authorizedHttpAdapter, '/sales/')
}
