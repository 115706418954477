import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

import { HttpClient, HttpRequest } from 'src/data'

export class AxiosHttpClient implements HttpClient {
  public axiosInstance: AxiosInstance

  constructor(readonly baseUrl?: string) {
    this.axiosInstance = axios.create({ baseURL: this.baseUrl })
  }

  async request(params: HttpRequest) {
    let axiosResponse: AxiosResponse

    try {
      axiosResponse = await this.axiosInstance.request({
        url: params.url,
        method: params.method,
        data: params.body,
        params: params.params,
      })
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      axiosResponse = (err as AxiosError).response!
    }

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    }
  }
}
