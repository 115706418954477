import React, { RefObject, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import shopIcon from 'src/presentation/assets/images/shop-icon.svg'
import { PaginateLoading } from 'src/presentation/components'
import {
  useAppDispatch,
  useAppSelector,
  useDate,
  usePersistedPreviousDataQuantity,
  useScroll,
} from 'src/presentation/hooks'
import { ClientLoading, ClientMenuItem } from 'src/presentation/pages'
import { clientsActions } from 'src/presentation/store/ducks/clients'

const ClientsPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { clients, loading, loadingPagination, hasMoreClients } = useAppSelector(state => state.clients)
  const { isSearchActive, franchiseeId, freeTermText } = useAppSelector(state => state.headerSearch)

  const { formatDate } = useDate()

  const [currentPage, lastElementRef, setCurrentPage] = useScroll({ hasMoreItems: hasMoreClients, loading })

  const { t } = useTranslation()

  const storageKey = isSearchActive ? '@rvpdv:clientsSearchQuantity' : '@rvpdv:clientsQuantity'

  const clientsPreviousQuantity = usePersistedPreviousDataQuantity(storageKey, clients)

  useEffect(() => {
    document.title = `${t('CLIENTS')} - PDV`
  }, [])

  useEffect(() => {
    setCurrentPage(0)
  }, [franchiseeId, freeTermText])

  useEffect(() => {
    if (hasMoreClients && clients.length) {
      dispatch(clientsActions.fetchClients({ franchisee: franchiseeId, search: freeTermText, page: currentPage }))
    }
  }, [currentPage, dispatch])

  useEffect(() => {
    dispatch(clientsActions.fetchClients({ franchisee: franchiseeId, search: freeTermText, page: null }))

    return () => {
      dispatch(clientsActions.clearClients())
    }
  }, [dispatch, franchiseeId, freeTermText])

  return (
    <div data-cy="clients-page" className="relative z-0 flex flex-col w-full p-6 overflow-hidden">
      <div className="flex flex-col items-start justify-start relative mt-6 px-4 py-6 overflow-auto bg-white rounded shadow-md h-[calc(100vh-196px)] md:h-[calc(100vh-172px)]">
        {loading ? (
          Array.from({ length: clientsPreviousQuantity }).map((_, index) => <ClientLoading key={index} />)
        ) : clients.length ? (
          clients.map((client, index) => {
            const isOneOfLastItems =
              (index === clients.length - 1 || index === clients.length - 2) && clients.length > 1

            return (
              <div
                key={client?.id}
                className="relative flex h-32 flex-none items-start justify-between md:w-full p-4 mb-4 border-2 rounded border-tertiary last-of-type:mb-0 w-[732px]"
                data-cy={`client-item-${client?.id}`}
                {...(clients.length === index + 1 && {
                  ref: lastElementRef as unknown as RefObject<HTMLDivElement>,
                })}
              >
                <div className="flex items-start justify-start mr-2">
                  <img className="hidden w-10 h-10 mr-4 lg:block" src={shopIcon} alt="Shop Icon" />

                  <div className="flex flex-col items-start justify-start">
                    <h4
                      className="mb-2 overflow-hidden text-lg font-semibold w-128 text-nonary overflow-ellipsis whitespace-nowrap"
                      title={client?.name}
                    >
                      {client?.name}
                    </h4>

                    <p className="mb-2 text-lg text-tertiary">{client?.franchisee}</p>

                    <div className="flex items-center justify-start w-full">
                      {client?.phone && <span className="text-sm mr-14 text-tertiary">{client?.phone}</span>}

                      {client?.email && <span className="text-sm text-tertiary">{client?.email}</span>}
                    </div>
                  </div>
                </div>

                <div className="flex items-start justify-start w-96">
                  <div className="flex flex-col items-start justify-start mr-2">
                    <span className="mb-2.5 text-xs font-semibold text-tertiary">
                      <Trans i18nKey="BALANCE" /> m<sup>2</sup>
                    </span>

                    <p className="text-2xl font-semibold text-tertiary mb-0.5">
                      {Number.isInteger(client?.balance) && client?.balance / 100}
                    </p>

                    <span className="text-xs text-tertiary mb-0.5">{client?.lastOrder && t('LAST_TRANSACTION')}</span>

                    <span className="text-xs text-tertiary">
                      {client?.lastOrder && formatDate(client?.lastOrder, 'dd/MM/yyyy HH:mm')}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start ml-auto">
                    <span className="text-xs text-tertiary mb-3.5 font-semibold">
                      <Trans i18nKey="ACTIONS" />
                    </span>

                    <ClientMenuItem
                      clientId={client?.id}
                      clientName={client?.name}
                      isOneOfLastItems={isOneOfLastItems}
                    />
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="absolute inset-x-0 mt-12 bg-white" data-cy="not-found-message">
            <p className="text-lg font-semibold text-center">
              <Trans i18nKey="NO_CLIENTS_FOUND" />
            </p>
          </div>
        )}
      </div>

      <PaginateLoading loading={loadingPagination} />
    </div>
  )
}

export default ClientsPage
