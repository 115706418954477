import React from 'react'

const ClientLoading: React.FC = () => (
  <div className="relative flex h-32 flex-none items-start justify-between md:w-full p-4 mb-4 border-2 rounded border-tertiary last-of-type:mb-0 w-[732px]">
    <div className="flex items-start justify-start mr-2">
      <div className="hidden mr-4 lg:block h-[40px] bg-senary rounded w-10 animate-pulse" />

      <div className="flex flex-col items-start justify-start">
        <div className="mb-4 w-128">
          <div className="lg:w-96 w-72 bg-senary rounded h-[18px] animate-pulse" />
        </div>

        <div className="mb-4 w-48 bg-senary rounded h-[18px] animate-pulse" />

        <div className="flex items-center justify-start w-full">
          <div className="w-32 text-sm mr-14 bg-senary rounded animate-pulse h-[14px]" />

          <div className="w-32 text-sm  bg-senary rounded animate-pulse h-[14px]" />
        </div>
      </div>
    </div>

    <div className="flex items-start justify-start w-96">
      <div className="flex flex-col items-start justify-start mr-2">
        <div className="mb-3 text-xs w-14 bg-senary rounded animate-pulse h-[12px]" />

        <div className="mb-2.5 w-20 text-2xl bg-senary rounded animate-pulse h-[28px]" />

        <div className="w-32 mb-2 text-xs h-[12px] bg-senary rounded animate-pulse" />

        <div className="w-24 text-xs bg-senary rounded h-[12px] animate-pulse" />
      </div>

      <div className="flex flex-col items-start justify-start ml-auto">
        <div className="mb-1 ml-2 w-10 text-xs bg-senary rounded animate-pulse h-[12px]" />

        <div className="ml-4 w-2 text-xs bg-senary rounded animate-pulse h-[26px]" />
      </div>
    </div>
  </div>
)

export default ClientLoading
