import { FetchClientsIzeeMapper, HttpClient, HttpStatusCode, ClientIzeeModel } from 'src/data'

import { ClientsIzeePagination, FetchClientsIzeeUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchClientsIzeeService implements FetchClientsIzeeUseCase {
  constructor(private readonly httpClient: HttpClient<ClientIzeeModel[]>, private readonly url: string) {}

  async fetchClientsIzee(pagination: ClientsIzeePagination, franchisee?: number, search?: string) {
    const mappedPagination = FetchClientsIzeeMapper.toJson(pagination)

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: {
        ...mappedPagination,
        franchisee_id: franchisee,
        search,
      },
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ClientIzeeModel.fromJsonCollection(response?.body as ClientIzeeModel[]).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
