import { OrderEntity } from 'src/domain'

export class NegociationTypeModel {
  static fromJsonToDomain(json: any[]): OrderEntity.NegociationType[] {
    return json.map(negociationTypeJson => ({
      type: negociationTypeJson?.type,
      description: negociationTypeJson?.description,
      numberOfInstallments: negociationTypeJson?.number_of_installments,
      billingDays: negociationTypeJson?.billing_days,
      billingMode: negociationTypeJson?.billing_mode,
    }))
  }
}
