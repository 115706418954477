import { RemoteUpdateClientSignatureService } from 'src/data'

import { UpdateClientSignatureUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeUpdateClientSignatureUseCase = (): UpdateClientSignatureUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlClientPlaceholder = '{client}'
  const url = `/clients/${urlClientPlaceholder}/signatures`

  return new RemoteUpdateClientSignatureService(authorizedHttpAdapter, url, urlClientPlaceholder)
}
