import React, { InputHTMLAttributes } from 'react'
import MaskedInput, { Mask } from 'react-text-mask'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string
  label?: string | React.ReactElement
  loading?: boolean
  mask?: Mask | ((value: string) => Mask)
}

const Input: React.FC<Props> = ({ error, label, loading, mask, ...props }) => {
  const defaultProps = {
    id: props?.name,
    className: `
      block
      w-full
      border
      border-quinary
      text-tertiary
      rounded
      py-3
      px-4
      disabled:opacity-60
      disabled:bg-background
      bg-white
      appearance-none
      text-xs
      md:text-sm
      ${error && 'border-error outline-error'}
    `,
    'data-cy': props?.name,
    type: props?.type || 'text',
    ...props,
  }

  return (
    <>
      {label && (
        <label
          className="block mb-2 overflow-hidden text-xs font-semibold overflow-ellipsis whitespace-nowrap md:text-sm"
          htmlFor={props?.name}
        >
          {label}
        </label>
      )}

      <div className="relative">
        {!mask ? <input {...defaultProps} /> : <MaskedInput {...defaultProps} guide={false} mask={mask} />}

        <div
          className={`
            absolute
            top-0
            left-0
            w-full
            h-full
            bg-senary
            rounded
            animate-pulse
            transition-opacity
            duration-200
            ${loading ? 'opacity-100 visible' : 'opacity-0 invisible'}
          `}
        />

        {error && (
          <span
            role="alert"
            className="block mt-2 overflow-hidden text-xs outline-none text-error overflow-ellipsis whitespace-nowrap md:text-sm"
            data-cy="alert"
          >
            {error}
          </span>
        )}
      </div>
    </>
  )
}

export default Input
