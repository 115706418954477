import React from 'react'
import { MdChevronRight } from 'react-icons/md'
import { Link } from 'react-router-dom'

interface Props {
  previousPageUrl: string
  previousPageName: string
  currentPageName: string
}

const Breadcrumb: React.FC<Props> = ({ previousPageUrl, previousPageName, currentPageName }) => (
  <div className="flex flex-wrap items-center justify-start w-full mb-6">
    <Link
      className="text-sm font-semibold transition-colors text-octonary hover:text-secundary lg:text-base"
      to={previousPageUrl}
    >
      {previousPageName}
    </Link>

    {currentPageName && (
      <>
        <MdChevronRight className="text-octonary" size={16} />

        <span className="overflow-hidden text-sm font-semibold text-octonary overflow-ellipsis whitespace-nowrap lg:text-base">
          {currentPageName}
        </span>
      </>
    )}
  </div>
)

export default Breadcrumb
