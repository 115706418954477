import { FranchiseeEntity } from 'src/domain'

export class FranchiseeModel {
  private static franchisees: FranchiseeModel[] = []

  constructor(
    readonly id: number,
    readonly name: string,
    readonly type: string,
    readonly country: 'BR' | 'PT',
    readonly enabled = true
  ) {}

  static fromJsonCollection(json: any[]) {
    this.franchisees = json.map(
      franchiseeJson =>
        new FranchiseeModel(
          franchiseeJson?.id,
          franchiseeJson?.name,
          franchiseeJson?.type,
          franchiseeJson?.country || 'BR',
          franchiseeJson?.enabled
        )
    )

    return this
  }

  static toEntityCollection(): FranchiseeEntity[] {
    return this.franchisees.map(franchiseeModel => ({
      id: franchiseeModel.id,
      name: franchiseeModel.name,
      type: franchiseeModel.type,
      country: franchiseeModel.country,
      enabled: franchiseeModel.enabled,
    }))
  }
}
