const paymentFormTypes = {
  BR: {
    PIX: 'Pix',
    TICKET: 'Boleto',
  },
  PT: {
    PIX: 'Pix',
    TICKET: 'Boleto',
  },
}

export const paymentFormMapper = (language: 'BR' | 'PT', paymentForm: 'PIX' | 'TICKET') => {
  return paymentFormTypes?.[language]?.[paymentForm] || ''
}
