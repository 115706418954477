import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegFileExcel } from 'react-icons/fa6'
import { HiDocumentSearch } from 'react-icons/hi'
import { RiFileEditFill } from 'react-icons/ri'

import { OrderEntity } from 'src/domain'

import autoBillingOrderIcon from 'src/presentation/assets/images/auto-billing-order-icon.svg'
import { useLanguage } from 'src/presentation/hooks'

const icons = {
  OPEN: function getIcon(iconSize = 16) {
    return <HiDocumentSearch size={iconSize} />
  },
  CLOSE: function getIcon(iconSize = 16) {
    return <RiFileEditFill size={iconSize} />
  },
  CANCEL: function getIcon(iconSize = 16) {
    return <FaRegFileExcel size={iconSize} />
  },
}

const colors = {
  OPEN: 'bg-primary',
  CLOSE: 'bg-warning',
  CANCEL: 'bg-error',
}

const messages = {
  BR: {
    OPEN: 'Aberto',
    CLOSE: 'Aguardando Revisão',
    CANCEL: 'Cancelado',
  },
  PT: {
    OPEN: 'Aberto',
    CLOSE: 'Aguardando Revisão',
    CANCEL: 'Cancelado',
  },
}

export const orderStatusIconMapper = (order: OrderEntity.Entity, iconSize = 16) => {
  let icon

  if (order.origin === OrderEntity.Origin.AUTO_BILLING_EXECUTION) {
    icon = <img src={autoBillingOrderIcon} />
  } else {
    icon = icons[order.status](iconSize)
  }

  return icon
}

export const orderStatusColorMapper = (order: OrderEntity.Entity) => {
  let color = ''

  if (order.origin === OrderEntity.Origin.AUTO_BILLING_EXECUTION) {
    color = 'bg-[#2EA1D2]'
  } else if (order?.from_single_order) {
    color = 'bg-[#626262]'
  } else {
    color = colors[order.status]
  }

  return color
}

export const useOrderStatusTextMapper = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const handleGetOrderStatusText = (order: OrderEntity.Entity) => {
    if (order.origin === OrderEntity.Origin.AUTO_BILLING_EXECUTION) {
      return t('CLOSED_AUTOMATIC')
    }

    return messages?.[currentLanguage]?.[order.status]
  }

  return handleGetOrderStatusText
}
