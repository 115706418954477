import { RemoteFetchSellersService } from 'src/data'

import { FetchSellersUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchSellersUseCase = (): FetchSellersUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchSellersService(authorizedHttpAdapter, '/sellers')
}
