import { OrderIzeeEntity } from 'src/domain'

export type OrdersIzeePagination = {
  offset?: number
  // orderBy: string
  // orderByDir: 'asc' | 'desc'
  limit: number
}

export enum CreateOrUpdateOrderIzeeErrors {
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  IZEE_SALE_NOT_FOUND = 'IZEE_SALE_NOT_FOUND',
  COMPANY_DOCUMENT_NOT_FOUND = 'COMPANY_DOCUMENT_NOT_FOUND',
  COMPANY_ADDRESS_NOT_FOUND = 'COMPANY_ADDRESS_NOT_FOUND',
  SALE_ALREADY_CONFIRMED = 'SALE_ALREADY_CONFIRMED',
  INVALID_PAYMENT_FORM = 'INVALID_PAYMENT_FORM',
  INVALID_NUMBER_OF_INSTALLMENTS = 'INVALID_NUMBER_OF_INSTALLMENTS',
  INVALID_BILLING_PHONE_FORMAT = 'INVALID_BILLING_PHONE_FORMAT',
  INVALID_COMPANY_DOCUMENT = 'INVALID_COMPANY_DOCUMENT',
  INVALID_COMPANY_ADDRESS = 'INVALID_COMPANY_ADDRESS',
  INVALID_COMPANY_CITY = 'INVALID_COMPANY_CITY',
  INVALID_COMPANY_NEIGHBORHOOD = 'INVALID_COMPANY_NEIGHBORHOOD',
  NEIGHBORHOOD_NOT_FOUND = 'NEIGHBORHOOD_NOT_FOUND',
  CITY_NOT_FOUND = 'CITY_NOT_FOUND',
  INVALID_COMPANY_ZIPCODE = 'INVALID_COMPANY_ZIPCODE',
  INVALID_COMPANY_EMAIL = 'INVALID_COMPANY_EMAIL',
  INVALID_COMPANY_NAME = 'INVALID_COMPANY_NAME',
  INVALID_COMPANY_TYPE = 'INVALID_COMPANY_TYPE',
  COULD_NOT_CREATE_CONTRACT = 'COULD_NOT_CREATE_CONTRACT',
  COULD_NOT_CREATE_OCCURRENCE = 'COULD_NOT_CREATE_OCCURRENCE',
  COULD_NOT_CREATE_PRODUCT = 'COULD_NOT_CREATE_PRODUCT',
  SANKHYA_COMMUNICATION_ERROR = 'SANKHYA_COMMUNICATION_ERROR',
  CANNOT_CREATE_EFI_BILLET = 'CANNOT_CREATE_EFI_BILLET',
  CANNOT_CREATE_EFI_CARNET = 'CANNOT_CREATE_EFI_CARNET',
  CANNOT_CREATE_EFI_SUBSCRIPTION = 'CANNOT_CREATE_EFI_SUBSCRIPTION',
}
export interface FetchOrderIzeeUseCase {
  fetchOrderIzee(order: number): Promise<OrderIzeeEntity.Entity>
}

export namespace CreateOrUpdateOrderIzeeUseCase {
  export type Params = Omit<OrderIzeeEntity.createOrUpdateData, 'origin'>
}

export interface CreateOrderIzeeUseCase {
  createOrderIzee(params: CreateOrUpdateOrderIzeeUseCase.Params): Promise<OrderIzeeEntity.Entity>
}

export interface UpdateOrderIzeeUseCase {
  updateOrderIzee(order: number, params: CreateOrUpdateOrderIzeeUseCase.Params): Promise<void>
}

export interface ConfirmOrderIzeeUseCase {
  confirmOrderIzee(order: number): Promise<void>
}

export interface FetchOpenOrdersIzeeUseCase {
  fetchOpenOrdersIzee(pagination: OrdersIzeePagination, franchisee?: number): Promise<OrderIzeeEntity.Entity[]>
}
export interface FetchCompletedOrdersIzeeUseCase {
  fetchCompletedOrdersIzee(pagination: OrdersIzeePagination, franchisee?: number): Promise<OrderIzeeEntity.Entity[]>
}

export interface FetchCanceledOrdersIzeeUseCase {
  fetchCanceledOrdersIzee(pagination: OrdersIzeePagination, franchisee?: number): Promise<OrderIzeeEntity.Entity[]>
}
