import { RemoteFetchOpenOrdersService } from 'src/data'

import { FetchOpenOrdersUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchOpenOrdersUseCase = (): FetchOpenOrdersUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const params = {
    status: 'OPEN',
  }

  return new RemoteFetchOpenOrdersService(authorizedHttpAdapter, '/sales/', params)
}
