import { ExportExtractEntity } from 'src/domain'

export class ExportExtractModel {
  constructor(readonly file: string) {}

  static fromJson(json: any) {
    return new ExportExtractModel(json?.file)
  }

  toEntity(): ExportExtractEntity {
    return {
      file: this.file,
    }
  }
}
