import React, { useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'

import { useAppSelector } from 'src/presentation/hooks'

interface Props {
  isOneOfLastItems: boolean
  orderId: number | string
  handleCancel: (orderId: number | string) => void
  handleResend: (orderId: number | string) => void
}

const CompletedOrderMenuItem: React.FC<Props> = ({ isOneOfLastItems, orderId, handleCancel, handleResend }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAppSelector(state => state.auth)

  const completedOrderMenuItemRef = useRef<HTMLDivElement>(null)

  const handleClickOutsideMenuItem = (event: MouseEvent) => {
    const currentRef = completedOrderMenuItemRef.current

    if (currentRef && !currentRef.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  const showModalCancel = () => {
    setIsOpen(!isOpen)
    handleCancel(orderId)
  }

  const showModalResend = () => {
    setIsOpen(!isOpen)
    handleResend(orderId)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenuItem)

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenuItem)
    }
  }, [])

  const menuToggleClasses = isOpen ? 'opacity-100 visible translate-y-2' : 'opacity-0 invisible'

  const lastItemsClasses = isOneOfLastItems ? '-top-20 -mt-2' : 'top-0'

  return (
    <div className="relative inline-block">
      <BsThreeDotsVertical
        className="z-0 m-auto cursor-pointer text-primary hover:text-secundary"
        size={28}
        onClick={() => setIsOpen(!isOpen)}
      />

      <span
        className={`block relative transition-all duration-200 transform origin-top-right scale-95 z-20 m-0 ${menuToggleClasses}`}
        ref={completedOrderMenuItemRef}
      >
        <span
          className={`block z-20 absolute right-0 w-44 mt-0 bg-white rounded-lg shadow-lg outline-none ${lastItemsClasses}`}
        >
          <Link
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-right text-black transition-colors rounded-lg hover:bg-primary hover:text-white"
            to={`/completed-orders/${orderId}`}
          >
            <Trans i18nKey="VIEW" />
          </Link>

          <button
            onClick={() => showModalCancel()}
            className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black transition-colors rounded-b-lg hover:bg-primary hover:text-white"
          >
            <Trans i18nKey="CANCEL_SALE" />
          </button>

          {user?.isAdmin && (
            <button
              onClick={() => showModalResend()}
              className="flex justify-between w-full px-6 py-4 text-sm font-medium leading-5 text-left text-black transition-colors rounded-b-lg hover:bg-primary hover:text-white"
            >
              <Trans i18nKey="RESEND_SANKHYA" />
            </button>
          )}
        </span>
      </span>
    </div>
  )
}

export default CompletedOrderMenuItem
