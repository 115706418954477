import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ImSpinner8 } from 'react-icons/im'

import rvSmallBlackLogo from 'src/presentation/assets/images/rv-small-black-logo.svg'
import { Button, CurrencyInput } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useLanguage, useToast } from 'src/presentation/hooks'
import { signatureActions } from 'src/presentation/store/ducks/signature'
import { uiActions } from 'src/presentation/store/ducks/ui'

const SignatureModal: React.FC = () => {
  const [signatureValue, setSignatureValue] = useState('')

  const dispatch = useAppDispatch()
  const { client, isOpen } = useAppSelector(state => state.ui.signatureModal)
  const { loadingFetchClientSignature, loadingUpdateClientSignature, signature, updateClientSignatureState } =
    useAppSelector(state => state.signature)

  const { throwToast } = useToast()

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const handleCloseModal = () => dispatch(uiActions.toggleSignatureModal({ client: null, isOpen: false }))

  const handleClientSignatureInputChange = (newValue = '0') => setSignatureValue(newValue)

  const handleUpdateClientSignatureValue = () => {
    const signatureValueWithoutComma = signatureValue.replaceAll(',', '.')
    const formattedSignatureValue = Number((parseFloat(signatureValueWithoutComma) * 100).toFixed(2))

    if (formattedSignatureValue < 0 || !Number.isInteger(formattedSignatureValue)) {
      throwToast({ message: t('ENTER_VALID_SUBSCRIPTION_AMOUNT_GREATER_THAN_OR_EQUAL_TO_ZERO') })

      return
    }

    dispatch(signatureActions.updateClientSignature({ client: Number(client), value: formattedSignatureValue }))
  }

  useEffect(() => {
    if (signature?.value !== undefined && signature?.value >= 0) {
      setSignatureValue(String(signature.value === 0 ? 0 : signature.value / 100))
    }
  }, [signature])

  useEffect(() => {
    client && dispatch(signatureActions.fetchClientSignature({ client }))

    return () => {
      dispatch(signatureActions.clearSignature())

      setSignatureValue('')
    }
  }, [client, dispatch])

  useEffect(() => {
    if (updateClientSignatureState === 'error') {
      throwToast({ message: t('UNABLE_TO_UPDATE_SUBSCRIPTION_AMOUNT') })

      return
    }

    if (updateClientSignatureState === 'success') {
      handleCloseModal()

      throwToast({ message: t('SUBSCRIPTION_AMOUNT_UPDATED_SUCCESSFULLY'), type: 'success' })
    }

    return () => {
      dispatch(signatureActions.clearUpdateClientSignatureState())
    }
  }, [dispatch, updateClientSignatureState])

  if (!isOpen || !client) return null

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black opacity-50 cursor-pointer"
        data-cy="signature-modal-overlay"
        onClick={handleCloseModal}
      />

      <div
        className="fixed inset-x-0 inset-y-0 z-50 flex flex-col items-start w-[calc(100%-22px)] py-6 mx-auto my-auto bg-white border-4 rounded lg:w-1/3 border-tertiary max-h-[calc(100%-22px)] h-min"
        data-cy="signature-modal"
      >
        <h4 className="w-full px-6 pb-6 border-b-tertiary border-b-2 text-xl flex justify-start gap-2 items-center font-medium text-center lg:text-left">
          <img className="text-octonary" src={rvSmallBlackLogo} alt="RV Logo" />

          <strong className="font-semibold text-nonary">
            <Trans i18nKey="ADD_SUBSCRIPTION" />
          </strong>
        </h4>

        <div className="relative flex flex-col items-start self-center justify-center w-full mt-2 xl:w-5/6 p-4">
          <CurrencyInput
            name="signature"
            className="!w-full px-4 py-2 mb-1 text-sm border-2 rounded sm:text-base border-tertiary text-tertiary"
            containerClassName="w-full"
            placeholder={t('ENTER_SUBSCRIPTION_AMOUNT')}
            label={t('SUBSCRIPTION_AMOUNT')}
            value={signatureValue || ''}
            onValueChange={handleClientSignatureInputChange}
            loading={loadingFetchClientSignature}
            prefix=" "
            lng={currentLanguage}
          />

          <span className="absolute font-bold top-[50%] right-7 text-tertiary">
            m<sup>2</sup>
          </span>
        </div>

        <div className="flex flex-col items-center self-center justify-center md:flex-row p-2">
          <Button
            name="cancel-signature-update-button"
            className={`
              !text-xs
              bg-tertiary
              md:mr-4
              md:w-auto
              md:order-none
              order-1
              ${loadingUpdateClientSignature && 'pointer-events-none'}
            `}
            type="button"
            disabled={loadingUpdateClientSignature}
            onClick={handleCloseModal}
          >
            <Trans i18nKey="CANCEL" />
          </Button>

          <Button
            name="confirm-signature-update-button"
            className="!text-xs bg-success md:w-auto"
            type="submit"
            disabled={loadingUpdateClientSignature || !signatureValue}
            onClick={handleUpdateClientSignatureValue}
          >
            {loadingUpdateClientSignature ? (
              <ImSpinner8 size={16} className="m-auto text-white animate-spin" />
            ) : (
              <span>
                <Trans i18nKey="CONFIRM" />
              </span>
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default SignatureModal
