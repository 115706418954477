import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { PlanEntity } from 'src/domain'

import { makeFetchPlansUseCase } from 'src/main'

const fetchPlans = createAsyncThunk('@plans/fetchPlans', async () => {
  const fetchPlansUseCase = makeFetchPlansUseCase()

  const plans = await fetchPlansUseCase.fetchPlans()

  return plans?.filter(plan => plan?.type !== 'POST_PAID')
})

interface PlansState {
  plans: PlanEntity.Entity[]
  loading: boolean
}

const initialState: PlansState = {
  plans: [],
  loading: false,
}

export const plansSlice = createSlice({
  name: '@plans',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPlans.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchPlans.fulfilled, (state, action) => {
      state.plans = action.payload || []
      state.loading = false
    })

    builder.addCase(fetchPlans.rejected, state => {
      state.loading = false
    })
  },
})

export const plansActions = { ...plansSlice.actions, fetchPlans }

export const plansReducer = plansSlice.reducer
