import React from 'react'
import { registerLocale } from 'react-datepicker'
import ReactDOM from 'react-dom'

import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

import ptBR from 'date-fns/locale/pt-BR'

import '@material/react-linear-progress/dist/linear-progress.css'

import { App } from 'src/main'

import 'src/main/config/i18n/i18n'

import 'src/presentation/assets/css/styles.css'

registerLocale('pt-br', ptBR)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
