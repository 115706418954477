import React from 'react'

import LinearProgress from '@material/react-linear-progress'

import 'src/presentation/components/ScreenLoading/styles.css'

const ScreenLoading: React.FC = () => (
  <div className="block w-screen h-screen bg-background">
    <LinearProgress id="screen-loading" buffer={1} progress={1} indeterminate />
  </div>
)

export default ScreenLoading
