import { ClientIzeeEntity } from 'src/domain'

export class ClientIzeeModel {
  private static clients: ClientIzeeModel[] = []

  constructor(
    readonly id: number,
    readonly name: string,
    readonly prefix: string,
    readonly tradingName: string,
    readonly municipalEnrollment: string,
    readonly stateEnrollment: string,
    readonly cnpj: string,
    readonly cpf: string,
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly address: {
      zipCode: string
      state: string
      address: string
      additionalAddress: string
      number: string
      city: string
      neighborhood: string
      country: string
    },
    readonly subscriptionPlan: {
      label: string
    },
    readonly creator: {
      name: string
      email: string
    },
    readonly last_sale:
      | {
          billing_day: number
          billing_email: string
          billing_phone: string
          billing_setup_billet_id: number | null | undefined
          billing_setup_carnet_id: number | null | undefined
          billing_signature_id: number | null | undefined
          created_at: string
          date: string
          id: number
          monthly_price: number
          payment_details: string
          payment_form: string
          setup_number_of_installments: number
          setup_price: number
          status: string
          updated_at: string
          seller:
            | {
                id: number | null | undefined
                name: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
  ) {}

  static fromJson(json: any) {
    return new ClientIzeeModel(
      json?.id,
      json?.name,
      json?.prefix,
      json?.trading_name,
      json?.municipal_enrollment,
      json?.state_enrollment,
      json?.cnpj,
      json?.cpf,
      json?.created_at,
      json?.updated_at,
      {
        zipCode: json?.address?.zip_code,
        state: json?.address?.state,
        address: json?.address?.address,
        additionalAddress: json?.address?.additional_address,
        number: json?.address?.number,
        city: json?.address?.city,
        neighborhood: json?.address?.neighborhood,
        country: json?.address?.country,
      },

      {
        label: json?.subscription_plan?.label,
      },
      {
        name: json?.creator?.name,
        email: json?.creator?.email,
      },
      json?.last_sale
        ? {
          billing_day: json?.last_sale?.billing_day,
          billing_email: json?.last_sale?.billing_email,
          billing_phone: json?.last_sale?.billing_phone,
          billing_setup_billet_id: json?.last_sale?.billing_setup_billet_id,
          billing_setup_carnet_id: json?.last_sale?.billing_setup_carnet_id,
          billing_signature_id: json?.last_sale?.billing_signature_id,
          created_at: json?.last_sale?.created_at,
          date: json?.last_sale?.date,
          id: json?.last_sale?.id,
          monthly_price: json?.last_sale?.monthly_price,
          payment_details: json?.last_sale?.payment_details,
          payment_form: json?.last_sale?.payment_form,
          setup_number_of_installments: json?.last_sale?.setup_number_of_installments,
          setup_price: json?.last_sale?.setup_price,
          status: json?.last_sale?.status,
          updated_at: json?.last_sale?.updated_at,
          seller: json?.last_sale?.seller
            ? {
              id: json?.last_sale?.seller?.id,
              name: json?.last_sale?.seller?.name,
            }
            : null,
        }
        : null
    )
  }

  static fromJsonCollection(json: any[]) {
    this.clients = json.map(clientJson => {
      return new ClientIzeeModel(
        clientJson?.id,
        clientJson?.name,
        clientJson?.prefix,
        clientJson?.trading_name,
        clientJson?.municipal_enrollment,
        clientJson?.state_enrollment,
        clientJson?.cnpj,
        clientJson?.cpf,
        clientJson?.created_at,
        clientJson?.updated_at,
        {
          zipCode: clientJson?.address?.zip_code,
          state: clientJson?.address?.state,
          address: clientJson?.address?.address,
          additionalAddress: clientJson?.address?.additional_address,
          number: clientJson?.address?.number,
          city: clientJson?.address?.city,
          neighborhood: clientJson?.address?.neighborhood,
          country: clientJson?.address?.country,
        },

        {
          label: clientJson?.subscription_plan?.label,
        },
        {
          name: clientJson?.creator?.name,
          email: clientJson?.creator?.email,
        },
        clientJson?.last_sale
          ? {
            billing_day: clientJson?.last_sale?.billing_day,
            billing_email: clientJson?.last_sale?.billing_email,
            billing_phone: clientJson?.last_sale?.billing_phone,
            billing_setup_billet_id: clientJson?.last_sale?.billing_setup_billet_id,
            billing_setup_carnet_id: clientJson?.last_sale?.billing_setup_carnet_id,
            billing_signature_id: clientJson?.last_sale?.billing_signature_id,
            created_at: clientJson?.last_sale?.created_at,
            date: clientJson?.last_sale?.date,
            id: clientJson?.last_sale?.id,
            monthly_price: clientJson?.last_sale?.monthly_price,
            payment_details: clientJson?.last_sale?.payment_details,
            payment_form: clientJson?.last_sale?.payment_form,
            setup_number_of_installments: clientJson?.last_sale?.setup_number_of_installments,
            setup_price: clientJson?.last_sale?.setup_price,
            status: clientJson?.last_sale?.status,
            updated_at: clientJson?.last_sale?.updated_at,
            seller: clientJson?.last_sale?.seller
              ? {
                id: clientJson?.last_sale?.seller?.id,
                name: clientJson?.last_sale?.seller?.name,
              }
              : null,
          }
          : null
      )
    })

    return this
  }

  toEntity(): ClientIzeeEntity {
    return {
      id: this.id,
      name: this.name,
      prefix: this.prefix,
      tradingName: this.tradingName,
      municipalEnrollment: this.municipalEnrollment,
      stateEnrollment: this.stateEnrollment,
      cnpj: this.cnpj,
      cpf: this.cpf,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      address: {
        zipCode: this.address.zipCode,
        state: this.address.state,
        address: this.address.address,
        additionalAddress: this.address.additionalAddress,
        number: this.address.number,
        city: this.address.city,
        neighborhood: this.address.neighborhood,
        country: this.address.country,
      },
      subscriptionPlan: {
        label: this.subscriptionPlan.label,
      },
      creator: {
        name: this.creator.name,
        email: this.creator.email,
      },
      last_sale: this.last_sale
        ? {
          billing_day: this.last_sale.billing_day,
          billing_email: this.last_sale.billing_email,
          billing_phone: this.last_sale.billing_phone,
          billing_setup_billet_id: this?.last_sale?.billing_setup_billet_id,
          billing_setup_carnet_id: this?.last_sale?.billing_setup_carnet_id,
          billing_signature_id: this?.last_sale?.billing_signature_id,
          created_at: this.last_sale.created_at,
          date: this.last_sale.date,
          id: this.last_sale.id,
          monthly_price: this.last_sale.monthly_price,
          payment_details: this.last_sale.payment_details,
          payment_form: this.last_sale.payment_form,
          setup_number_of_installments: this.last_sale.setup_number_of_installments,
          setup_price: this.last_sale.setup_price,
          status: this.last_sale.status,
          updated_at: this.last_sale.updated_at,
          seller: this?.last_sale?.seller
            ? {
              id: this?.last_sale?.seller?.id,
              name: this?.last_sale?.seller?.name,
            }
            : null,
        }
        : null,
    }
  }

  static toEntityCollection(): ClientIzeeEntity[] {
    return this.clients.map(clientModel => ({
      id: clientModel.id,
      name: clientModel.name,
      prefix: clientModel.prefix,
      tradingName: clientModel.tradingName,
      municipalEnrollment: clientModel.municipalEnrollment,
      stateEnrollment: clientModel.stateEnrollment,
      cnpj: clientModel.cnpj,
      cpf: clientModel.cpf,
      createdAt: clientModel.createdAt,
      updatedAt: clientModel.updatedAt,
      address: {
        zipCode: clientModel.address.zipCode,
        state: clientModel.address.state,
        address: clientModel.address.address,
        additionalAddress: clientModel.address.additionalAddress,
        number: clientModel.address.number,
        city: clientModel.address.city,
        neighborhood: clientModel.address.neighborhood,
        country: clientModel.address.country,
      },
      subscriptionPlan: {
        label: clientModel.subscriptionPlan.label,
      },
      creator: {
        name: clientModel.creator.name,
        email: clientModel.creator.email,
      },
      last_sale: clientModel.last_sale
        ? {
          billing_day: clientModel.last_sale.billing_day,
          billing_email: clientModel.last_sale.billing_email,
          billing_phone: clientModel.last_sale.billing_phone,
          billing_setup_billet_id: clientModel?.last_sale?.billing_setup_billet_id,
          billing_setup_carnet_id: clientModel?.last_sale?.billing_setup_carnet_id,
          billing_signature_id: clientModel?.last_sale?.billing_signature_id,
          created_at: clientModel.last_sale.created_at,
          date: clientModel.last_sale.date,
          id: clientModel.last_sale.id,
          monthly_price: clientModel.last_sale.monthly_price,
          payment_details: clientModel.last_sale.payment_details,
          payment_form: clientModel.last_sale.payment_form,
          setup_number_of_installments: clientModel.last_sale.setup_number_of_installments,
          setup_price: clientModel.last_sale.setup_price,
          status: clientModel.last_sale.status,
          updated_at: clientModel.last_sale.updated_at,
          seller: clientModel.last_sale?.seller
            ? {
              id: clientModel.last_sale?.seller?.id,
              name: clientModel.last_sale?.seller?.name,
            }
            : null,
        }
        : null,
    }))
  }
}
