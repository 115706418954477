import { HttpClient, HttpStatusCode, SellerModel } from 'src/data'

import { FetchSellersUseCase, OrderEntity, UnexpectedError } from 'src/domain'

export class RemoteFetchSellersService implements FetchSellersUseCase {
  constructor(private readonly httpClient: HttpClient<OrderEntity.Seller[]>, private readonly url: string) {}

  async fetchSellers() {
    const response = await this.httpClient.request({ method: 'get', url: this.url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return SellerModel.fromJsonToDomain(response.body || [])

      default:
        throw new UnexpectedError()
    }
  }
}
