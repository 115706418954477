import { CreateOrUpdateClientUseCase } from 'src/domain'

export class CreateOrUpdateClientMapper {
  static fromDomainToJson(params: CreateOrUpdateClientUseCase.Params) {
    return {
      id: params.id,
      name: params.name,
      username: params.username,
      auth: {
        franchisee: params.auth.franchisee,
        client_id: params.auth.clientId,
        username: params.auth.username,
      },
      billing_modality: params.billingModality,
      modality: params.modality,
      search_tokens: params.searchTokens,
      account_id: params.accountId,
      approved_at: params.approvedAt,
      creator_client_id: params.creatorClientId,
      type: params.type,
      accepted_terms: params.acceptedTerms,
      category: params.category,
      hotsite_configuration: {
        cc_email: params.hotsiteConfiguration.ccEmail,
        expiration_time: params.hotsiteConfiguration.expirationTime,
        can_register_maintenances: params.hotsiteConfiguration.canRegisterMaintenances,
      },
      contact: {
        email: params.contact.email,
        cellphone: params.contact.cellphone,
        phone: params.contact.phone,
        website: params.contact.website,
        assignment_email: params.contact.assignmentEmail,
        assignment_email_rule: params.contact.assignmentEmailRule,
      },
      address: {
        city: params.address.city,
        city_id: params.address.cityId,
        complement: params.address.complement,
        neighborhood: params.address.neighborhood,
        state: params.address.state,
        state_id: params.address.stateId,
        street: params.address.street,
        zipcode: params.address.zipcode,
        number: params.address.number,
      },
      invalid_address: params.invalidAddress,
      status: params.status,
      document: params.document,
      client_type: params.clientType,
      company_name: params.companyName,
      responsible: {
        name: params.responsible.name,
        document: params.responsible.document,
        phone: params.responsible.phone,
        cellphone: params.responsible.cellphone,
        email: params.responsible.email,
      },
      classification: params.classification,
      dashboard_uri: params.dashboardUri,
      billing_address: {
        city: params?.billingAddress?.city,
        city_id: params?.billingAddress?.cityId,
        complement: params?.billingAddress?.complement,
        neighborhood: params?.billingAddress?.neighborhood,
        state: params?.billingAddress?.state,
        state_id: params?.billingAddress?.stateId,
        street: params?.billingAddress?.street,
        zipcode: params?.billingAddress?.zipcode,
        number: params?.billingAddress?.number,
      },
      propertiesWallet: {
        potential_inspections: params?.propertiesWallet?.potentialInspections,
        properties_purchase_sell: params?.propertiesWallet?.propertiesPurchaseSell,
        properties_rent: params?.propertiesWallet?.propertiesRent,
      },
      franchisee_id: params.franchiseeId,
      responsible_commercial_user_id: params.responsibleCommercialUserId,
      billing_contact: params?.billingContact,
      billing_email: params?.billingEmail,
      billing_phone: params?.billingPhone,
    }
  }
}
