import { HttpClient, HttpStatusCode, OrderModel } from 'src/data'

import { CompletedOrderNotFoundError, FetchCompletedOrderUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchCompletedOrderService implements FetchCompletedOrderUseCase {
  constructor(private readonly httpClient: HttpClient<OrderModel>, private readonly url: string) {}

  async fetchCompletedOrder(order: string) {
    const response = await this.httpClient.request({ method: 'get', url: `${this.url}/${order}` })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return OrderModel.fromJson(response.body).toEntity()

      case HttpStatusCode.notFound:
        throw new CompletedOrderNotFoundError()

      default:
        throw new UnexpectedError()
    }
  }
}
