import { HttpClient, HttpStatusCode, PurchasePackageMapper } from 'src/data'

import { PurchasePackageUseCase, UnexpectedError } from 'src/domain'

export class RemotePurchasePackageService implements PurchasePackageUseCase {
  constructor(
    private readonly httpClient: HttpClient<PurchasePackageMapper.JsonReturn>,
    private readonly url: string
  ) {}

  async purchasePackage({ client, credits }: PurchasePackageUseCase.Params) {
    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: PurchasePackageMapper.Mapper.fromDomainToJson({ client, credits }),
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return PurchasePackageMapper.Mapper.fromJsonToDomain(response.body as PurchasePackageMapper.JsonReturn)

      default:
        throw new UnexpectedError()
    }
  }
}
