import { HttpClient, HttpRequest } from 'src/data'

export abstract class AuthorizedHttpClientDecorator implements HttpClient {
  constructor(protected httpClient: HttpClient) {}

  protected abstract get authorizedClient(): any

  async request(params: HttpRequest) {
    return this.httpClient.request(params)
  }
}
