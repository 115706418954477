import { RemoteFetchCompletedOrdersIzeeService } from 'src/data'

import { FetchCompletedOrdersIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCompletedOrdersIzeeUseCase = (): FetchCompletedOrdersIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const params = {
    status: 'CONFIRMED',
  }

  return new RemoteFetchCompletedOrdersIzeeService(authorizedHttpAdapter, '/izee-sales/', params)
}
