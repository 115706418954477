import React from 'react'

interface Props {
  numberOfItems?: number
  mostRightElement?: boolean
  isHeaderCell?: boolean
}

const TableLoading: React.FC<Props> = ({ numberOfItems = 0, mostRightElement = false, isHeaderCell = false }) => (
  <>
    {Array.from({ length: numberOfItems }).map((_, index) => (
      <div
        className={`
          pt-1
          pl-1
          pr-0
          bg-white
          ${mostRightElement && 'pr-1'}
        `}
        key={index}
      >
        <div
          className={`
            mb-1
            w-40
            sm:w-48
            md:w-full
            h-[60px]
            bg-senary
            animate-pulse
            rounded
            ${isHeaderCell ? 'h-[48px]' : '-mt-1'}
          `}
        />
      </div>
    ))}
  </>
)

export default TableLoading
