import { FieldValidator, InvalidFieldError } from 'src/validation'

export namespace CnpjValidator {
  export type Params = {
    cnpj: string
  }

  export class Validator implements FieldValidator {
    readonly field = 'cnpj'

    async validate(input: CnpjValidator.Params) {
      const value = input[this.field].replace(/[^\d]+/g, '')

      if (
        !value ||
        value.length !== 14 ||
        value == '00000000000000' ||
        value == '11111111111111' ||
        value == '22222222222222' ||
        value == '33333333333333' ||
        value == '44444444444444' ||
        value == '55555555555555' ||
        value == '66666666666666' ||
        value == '77777777777777' ||
        value == '88888888888888' ||
        value == '99999999999999'
      ) {
        throw new InvalidFieldError()
      }

      let size = value.length - 2
      let numbers = value.substring(0, size)
      const digits = value.substring(size)
      let sum = 0
      let position = size - 7

      for (let i = size; i >= 1; i--) {
        sum += Number(numbers.charAt(size - i)) * position--

        if (position < 2) position = 9
      }

      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

      if (result !== Number(digits.charAt(0))) {
        throw new InvalidFieldError()
      }

      size = size + 1
      numbers = value.substring(0, size)
      sum = 0
      position = size - 7

      for (let i = size; i >= 1; i--) {
        sum += Number(numbers.charAt(size - i)) * position--

        if (position < 2) position = 9
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

      if (result !== Number(digits.charAt(1))) {
        throw new InvalidFieldError()
      }
    }
  }
}
