import { AddClientPlanUseCase } from 'src/domain'

export class AddClientPlanMapper {
  static fromDomainToJson(params: Omit<AddClientPlanUseCase.Params, 'planUuid'>) {
    return {
      client_id: params?.clientId,
      seller: params?.seller,
      modality: params?.modality,
      periodicity: params?.periodicity,
      price: params?.price,
      billing_mode: params?.billingMode,
      number_of_installments: params?.numberOfInstallments,
      first_billing_month: params?.firstBillingMonth,
      billing_day: params?.billingDay,
      payment_details: params?.paymentDetails,
      installments: params?.numberOfInstallmentsNumericData,
    }
  }
}
