import { RemoteFetchCanceledOrdersService } from 'src/data'

import { FetchCanceledOrdersUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCanceledOrdersUseCase = (): FetchCanceledOrdersUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const params = {
    status: 'CANCEL',
  }

  return new RemoteFetchCanceledOrdersService(authorizedHttpAdapter, '/sales/', params)
}
