import { RemoteFetchClientService } from 'src/data'

import { FetchClientUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientUseCase = (): FetchClientUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchClientService(authorizedHttpAdapter, '/clients/')
}
