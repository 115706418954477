export namespace OrderIzeeEntity {
  export enum PaymentForm {
    TICKET = 'TICKET',
    PIX = 'PIX',
  }

  export enum BillingMode {
    IN_CASH = 'IN_CASH',
    ON_TERM = 'ON_TERM',
  }

  export enum FirstBillingMonth {
    CURRENT = 'CURRENT',
    NEXT = 'NEXT',
  }

  export enum Status {
    CONFIRMED = 'CONFIRMED',
    OPEN = 'OPEN',
    CANCELED = 'CANCELED',
  }

  export type Company = {
    id: number
    name: string
  }

  export type Entity = {
    id: number
    setupPrice: number
    monthlyPrice: number
    setupNumberOfInstallments: number
    setupBillingMode: BillingMode
    billingDay: number
    paymentForm: PaymentForm
    firstBillingMonth: FirstBillingMonth
    sellerId: number
    paymentDetails: string
    status: Status
    company: Company
    date: Date
    billingEmail: string
    billingPhone: string
    billingSetupBilletId: number | null | undefined
    billingSetupCarnetId: number | null | undefined
    billingSignatureId: number | null | undefined
  }

  export type createOrUpdateData = {
    companyId?: number
    setupPrice: number
    monthlyPrice: number
    setupNumberOfInstallments: number
    billingDay: number
    paymentForm: PaymentForm
    sellerId: number
    paymentDetails: string
    billingEmail: string
    billingPhone: string
  }
}
