import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdClose } from 'react-icons/md'
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri'
import { useHistory, useLocation } from 'react-router-dom'

import { useDebouncedCallback } from 'use-debounce'

import { useAppDispatch, useAppSelector } from 'src/presentation/hooks'
import { franchiseesActions } from 'src/presentation/store/ducks/franchisees'
import { headerSearchActions } from 'src/presentation/store/ducks/headerSearch'

const SearchBox: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const [freeTermSearchText, setFreeTermSearchText] = useState<string | null>(null)
  const [selectedFranchisee, setSelectedFranchisee] = useState<number | null>(null)

  const filtersBoxRef = useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()
  const { franchisees, loading: loadingFranchisees } = useAppSelector(state => state.franchisees)
  const { user } = useAppSelector(state => state.auth)

  const { pathname } = useLocation()
  const history = useHistory()

  const { t } = useTranslation()

  const loading = loadingFranchisees
  const isClientsPage = pathname == '/clients' || pathname == '/clients-izee'
  const isSalesPage = pathname == '/open-orders' || pathname == '/completed-orders' || pathname == '/canceled-orders'

  const setFreeTermTextDebounced = useDebouncedCallback((inputText: string) => {
    dispatch(headerSearchActions.freeTermText(inputText))
    dispatch(headerSearchActions.franchiseeId(null))
  }, 400)

  const handleClearSearchBoxFields = () => {
    setFreeTermSearchText(null)
    setSelectedFranchisee(null)
  }

  const handleClearHeaderSearchFilters = () => {
    dispatch(headerSearchActions.freeTermText(null))
    dispatch(headerSearchActions.franchiseeId(null))
  }

  const handleTriggerSearch = () => {
    freeTermSearchText && dispatch(headerSearchActions.freeTermText(freeTermSearchText))
    selectedFranchisee && dispatch(headerSearchActions.franchiseeId(selectedFranchisee))
  }

  const handleClickOutsideFiltersBox = (event: MouseEvent) => {
    const currentRef = filtersBoxRef.current

    currentRef && !currentRef.contains(event.target as Node) && setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFiltersBox)

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFiltersBox)
    }
  }, [])

  useEffect(() => {
    dispatch(franchiseesActions.fetchFranchisees())
  }, [dispatch])

  useEffect(() => {
    ;(!isClientsPage || !isSalesPage) && dispatch(headerSearchActions.franchiseeId(selectedFranchisee))
  }, [dispatch, selectedFranchisee])

  useEffect(() => {
    return history.listen(() => handleClearSearchBoxFields())
  }, [dispatch, history])

  let headerFreeTermsSearchInputProps: object = {
    readOnly: true,
    onClick: () => setIsOpen(!isOpen),
  }

  if (isClientsPage || isSalesPage) {
    headerFreeTermsSearchInputProps = {
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        setFreeTermSearchText(event.target.value)
        setFreeTermTextDebounced(event.target.value)
      },
    }
  }

  const isFilterBoxOpenClasses = isOpen
    ? 'opacity-100 visible translate-y-0'
    : 'opacity-0 invisible -translate-y-px translate-x-px lg:translate-x-0 lg:translate-y-0'

  return (
    <section className="relative flex items-center mr-4 lg:mr-4">
      <input
        className={`
          relative
          hidden
          px-4
          py-2
          border
          rounded-md
          lg:w-128
          lg:block
          ${!isOpen ? 'border-tertiary' : 'border-white'}
          ${(!isClientsPage || isSalesPage) && 'cursor-pointer outline-none'}
        `}
        type="text"
        placeholder={t('SEARCH')}
        value={freeTermSearchText || ''}
        {...headerFreeTermsSearchInputProps}
      />

      <button
        type="button"
        className="absolute hidden h-full border-none right-4 lg:inline-block"
        onClick={() => setIsOpen(!isOpen)}
      >
        <RiArrowDownSFill size={22} />
      </button>

      <AiOutlineSearch
        size={32}
        className="block text-base transition-all border-none cursor-pointer right-4 lg:hidden text-primary hover:opacity-80"
        onClick={() => setIsOpen(!isOpen)}
      />

      <div
        className={`
          pt-2.5
          pb-4
          px-4
          transition-all
          duration-200
          transform
          absolute
          top-0
          right-0
          flex
          flex-col
          items-start
          bg-white
          rounded-md
          w-56
          sm:w-80
          lg:w-full
          shadow-lg
          ${isFilterBoxOpenClasses}
        `}
        ref={filtersBoxRef}
      >
        <div className="flex items-center justify-between w-full mb-4 font-semibold">
          <h3 className="text-sm sm:text-base">
            <Trans i18nKey="SEARCH" />
          </h3>

          <RiArrowUpSFill
            className="hidden cursor-pointer lg:inline-block"
            size={22}
            onClick={() => setIsOpen(!isOpen)}
          />

          <MdClose className="inline-block cursor-pointer lg:hidden" size={22} onClick={() => setIsOpen(!isOpen)} />
        </div>

        {user?.isCommercial && (
          <>
            <label className="mb-1 text-xs sm:text-sm" htmlFor="franchisee-search">
              <Trans i18nKey="FRANCHISE_UNIT" />
            </label>

            <div className="relative w-full p-0 mb-3">
              <select
                id="franchisee-search"
                className="w-full px-2 py-2 text-xs bg-white border rounded-md appearance-none cursor-pointer border-tertiary sm:text-sm"
                name="franchisee-search"
                value={selectedFranchisee || ''}
                onChange={event => setSelectedFranchisee(Number(event.target.value))}
                disabled={loading}
              >
                <option value="" disabled>
                  {t('SEARCH')}
                </option>

                {!!franchisees.length &&
                  franchisees
                    .filter(franchisee => franchisee.enabled)
                    .map(franchisee => (
                      <option value={franchisee?.id} key={franchisee?.id}>
                        {franchisee?.name}
                      </option>
                    ))}
              </select>

              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <RiArrowDownSFill className="bg-white" size={16} />
              </div>
            </div>
          </>
        )}

        {(isClientsPage || isSalesPage) && (
          <>
            <label className="mb-1 text-xs sm:text-sm" htmlFor="order-id-search">
              <Trans i18nKey="FREE_TERM" />
            </label>

            <input
              id="order-id-search"
              className="w-full px-3 py-2 mb-5 text-xs border rounded-md sm:text-sm border-tertiary"
              name="order-id-search"
              type="text"
              placeholder={t('SEARCH')}
              value={freeTermSearchText || ''}
              onChange={event => setFreeTermSearchText(event.target.value)}
            />
          </>
        )}

        <div className="flex items-center justify-end w-full font-semibold">
          <button
            className={`
              px-4
              py-2
              text-xs
              font-semibold
              underline
              transition-opacity
              border-none
              sm:text-sm
              text-octonary
              hover:opacity-80
              disabled:opacity-30
              ${!isClientsPage || !isSalesPage ? 'mr-0 pr-2' : 'mr-4'}
            `}
            type="button"
            onClick={() => {
              handleClearSearchBoxFields()
              handleClearHeaderSearchFilters()
            }}
          >
            <Trans i18nKey="CLEAR_FILTER" />
          </button>

          {(isClientsPage || isSalesPage) && (
            <button
              className="px-4 py-2 text-xs font-semibold text-white transition-all border-none rounded-md sm:text-sm bg-octonary hover:bg-opacity-80 disabled:opacity-30 disabled:pointer-events-none"
              type="button"
              onClick={handleTriggerSearch}
              disabled={!selectedFranchisee && !freeTermSearchText}
            >
              <Trans i18nKey="SEARCH" />
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default SearchBox
