import { RemoteFetchClientsService } from 'src/data'

import { FetchClientsUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientsUseCase = (): FetchClientsUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchClientsService(authorizedHttpAdapter, '/clients/')
}
