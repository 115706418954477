export type ClientByCnpjEntity = {
  cep: string
  cityCode: string
  companyName: string
  legalName: string
  email: string
  complement: string
  firstPhone: string
  neighborhood: string
  number: string
  secondPhone: string
  state: string
  street: string
}

export type ClientEntity = {
  id: number
  franchisee: string
  name: string
  phone: string
  email: string
  type: string
  createdAt: Date
  balance: number
  lastOrder: Date
  disabled: boolean
}

export namespace ClientDetailsEntity {
  export enum AssignmentEmailRule {
    CREATOR = 'CREATOR',
    CUSTOM = 'CUSTOM',
    RESPONSIBLE = 'RESPONSIBLE',
    NONE = 'NONE',
  }

  export enum BillingModality {
    SEPARATE = 'SEPARATE',
    PRE_PAID = 'PRE_PAID',
    POST_PAID = 'POST_PAID',
  }

  export enum BillingModalityPanel {
    PAY_PER_USE = 'PAY_PER_USE',
    BASIC_PLAN = 'BASIC_PLAN',
    BASIC_LEGACY_PLAN = 'BASIC_LEGACY_PLAN',
    ADVANCED_PLAN = 'ADVANCED_PLAN',
    PROFESSIONAL_PLAN = 'PROFESSIONAL_PLAN',
  }

  export enum Classification {
    IMOBILIARIA = 'IMOBILIARIA',
    CORRETOR_DE_IMOVEIS = 'CORRETOR_DE_IMOVEIS',
    PROPRIETARIO = 'PROPRIETARIO',
    CONDOMINIO = 'CONDOMINIO',
    ADMINISTRADORA_DE_CONDOMINIOS = 'ADMINISTRADORA_DE_CONDOMINIOS',
    SINDICO_PROFISSIONAL = 'SINDICO_PROFISSIONAL',
    CONSTRUTORA_INCORPORADORA = 'CONSTRUTORA_INCORPORADORA',
    EMPREITEIRA = 'EMPREITEIRA',
    INSTITUICAO_FINANCEIRA = 'INSTITUICAO_FINANCEIRA',
    REDE_DE_VAREJO = 'REDE_DE_VAREJO',
    PESSOA_FISICA = 'PESSOA_FISICA',
    OUTROS = 'OUTROS',
  }

  export enum ClientType {
    PF = 'pf',
    PJ = 'pj',
  }

  export type Entity = {
    id: number
    name: string
    username: string
    auth: {
      franchisee: number
      clientId: number
      username: string
    }
    billingModality: BillingModality
    modality: BillingModalityPanel
    searchTokens: string
    accountId: string
    approvedAt: Date
    creatorClientId: string
    type: Type
    acceptedTerms: boolean
    category: string
    hotsiteConfiguration: {
      ccEmail: string
      expirationTime: string
      canRegisterMaintenances: boolean | string
    }
    contact: {
      email: string
      cellphone: string
      phone: string
      website: string
      assignmentEmail: string
      assignmentEmailRule: AssignmentEmailRule
    }
    address: {
      city: string
      cityId: number
      complement: string
      neighborhood: string
      state: string
      stateId: number
      street: string
      zipcode: string
      number: string
    }
    invalidAddress: boolean
    status: string
    document: string
    clientType: ClientType
    companyName: string
    responsible: {
      name: string
      document: string
      phone: string
      cellphone: string
      email: string
    }
    classification: Classification
    origin: Origin
    dashboardUri: string
    billingAddress: {
      city?: string
      cityId?: number
      complement?: string
      neighborhood?: string
      state?: string
      stateId?: number
      street?: string
      zipcode?: string
      number?: string
    }
    propertiesWallet: {
      potentialInspections?: number
      propertiesPurchaseSell?: number
      propertiesRent?: number
    }
    franchiseeId?: number
    responsibleCommercialUserId?: number
    responsibleCommercialUserName?: string
    billingContact?: string
    billingEmail?: string
    billingPhone?: string
  }

  export enum Origin {
    SA = 'SA',
    UNIDADE = 'UNIDADE',
    FRANQUIA = 'FRANQUIA',
  }

  export enum Type {
    LEGAL_PERSON = 'LEGAL_PERSON',
    NATURAL_PERSON = 'NATURAL_PERSON',
  }
}

export type ClientPriceTableEntity = {
  id: number
  active: boolean
  basePrice: number
  description: string
  expressRatio: number
  minimalPrice: number
}

export type ClientSignatureEntity = {
  id: number
  value: number
}

export enum ClientExtractFormats {
  CONSOLIDATED_EXTRACT = 'consolidated',
  CONSOLIDATED_POST_PAID_EXTRACT = 'consolidated-post-paid',
  DETAILED_EXTRACT = 'account',
  POST_PAID_EXTRACT = 'post-paid',
}
