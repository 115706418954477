import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ClientIzeeEntity } from 'src/domain'

import { makeFetchClientIzeeUseCase } from 'src/main'

interface FetchClientIzeeParams {
  client: number
}

const fetchClientIzee = createAsyncThunk('@clientIzee/fetchClientIzee', async ({ client }: FetchClientIzeeParams) => {
  const fetchClientIzeeUseCase = makeFetchClientIzeeUseCase()

  return fetchClientIzeeUseCase.fetchClientIzee(client)
})

interface ClientIzeeState {
  client: ClientIzeeEntity | null
  loading: boolean
}

const initialState: ClientIzeeState = {
  client: null,
  loading: false,
}

export const clientSlice = createSlice({
  name: '@clientIzee',
  initialState,
  reducers: {
    clearClient: state => {
      state.client = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchClientIzee.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchClientIzee.fulfilled, (state, action) => {
      state.loading = false
      state.client = action.payload
    })

    builder.addCase(fetchClientIzee.rejected, state => {
      state.loading = false
    })
  },
})

export const clientActions = { ...clientSlice.actions, fetchClientIzee }

export const clientIzeeReducer = clientSlice.reducer
