import { RemoteCreateOrderIzeeService } from 'src/data'

import { CreateOrderIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeCreateOrderIzeeUseCase = (): CreateOrderIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteCreateOrderIzeeService(authorizedHttpAdapter, '/izee-sales/')
}
