import { OrderEntity } from 'src/domain'

export type OrdersPagination = {
  offset?: number
  orderBy: string
  orderByDir: 'asc' | 'desc'
  limit: number
}

export interface FetchNegociationTypesUseCase {
  fetchNegociationTypes(): Promise<OrderEntity.NegociationType[]>
}

export interface FetchCompletedOrdersUseCase {
  fetchCompletedOrders(
    pagination: OrdersPagination,
    franchisee?: number,
    search?: string
  ): Promise<OrderEntity.Entity[]>
}

export interface FetchCompletedOrderUseCase {
  fetchCompletedOrder(order: string): Promise<OrderEntity.Entity>
}

export interface FetchOpenOrdersUseCase {
  fetchOpenOrders(pagination: OrdersPagination, franchisee?: number, search?: string): Promise<OrderEntity.Entity[]>
}

export interface FetchOpenOrderUseCase {
  fetchOpenOrder(order: string): Promise<OrderEntity.Entity>
}

export interface FetchCanceledOrdersUseCase {
  fetchCanceledOrders(pagination: OrdersPagination, franchisee?: number, search?: string): Promise<OrderEntity.Entity[]>
}

export interface FetchCanceledOrderUseCase {
  fetchCanceledOrder(order: string): Promise<OrderEntity.Entity>
}

export interface FetchSellersUseCase {
  fetchSellers(): Promise<OrderEntity.Seller[]>
}

export namespace UpdateOpenOrderUseCase {
  export type Params = {
    sellerId: number | null
    credits: number
    price: number
    numberOfInstallments: number
    billingMode: OrderEntity.BillingMode
    billingModality: OrderEntity.BillingModality
    paymentForm: OrderEntity.PaymentForm
    firstBillingMonth: OrderEntity.FirstBillingMonth
    billingDay: number | null
    paymentDetails: string | null
  }
}

export interface UpdateOpenOrderUseCase {
  updateOpenOrder(order: string, orderData: UpdateOpenOrderUseCase.Params): Promise<void>
}

export enum ConfirmOpenOrderErrors {
  NOT_ALLOWED = 'NOT_ALLOWED',
  SALE_WITHOUT_SELLER = 'SALE_WITHOUT_SELLER',
  INTEGRATION_REQUEST_ERROR = 'INTEGRATION_REQUEST_ERROR',
  SALE_WITHOUT_PRICE = 'SALE_WITHOUT_PRICE',
  SALE_WITHOUT_BILLING_DAY = 'SALE_WITHOUT_BILLING_DAY',
  SALE_WITHOUT_BILLING_MODE = 'SALE_WITHOUT_BILLING_MODE',
  SALE_WITHOUT_FIRST_BILLING_MONTH = 'SALE_WITHOUT_FIRST_BILLING_MONTH',
  SALE_WITHOUT_PAYMENT_FORM = 'SALE_WITHOUT_PAYMENT_FORM',
  SALE_ALREADY_CONFIRMED = 'SALE_ALREADY_CONFIRMED',
}

export interface ConfirmOpenOrderUseCase {
  confirmOpenOrder(order: string): Promise<void>
}
export interface OpenOrderCancelUseCase {
  openOrderCancel(params: OrderEntity.ParamsOpenOrderCancel): Promise<void>
}
export interface ResendOrderUseCase {
  resendOrder(params: OrderEntity.ParamsResendOrder): Promise<void>
}

export enum OrderCancelErrors {
  SALE_ALREADY_INVOICED = 'SALE_ALREADY_INVOICED',
}
