import { RemoteUpdateClientService } from 'src/data'

import { UpdateClientUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeUpdateClientUseCase = (): UpdateClientUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteUpdateClientService(authorizedHttpAdapter, '/clients/')
}
