import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { OrderIzeeEntity } from 'src/domain'

export const useOrderIzeeValidator = () => {
  const { t } = useTranslation()

  return {
    shape: Yup.object().shape({
      setupPrice: Yup.string()
        .test('is_required', t('REQUIRED'), setupPrice => {
          const priceWithOnlyNumbers = String(setupPrice).replace(/\D/g, '')

          if (
            Number.isNaN(Number(priceWithOnlyNumbers)) ||
            Number(priceWithOnlyNumbers) <= 0 ||
            !priceWithOnlyNumbers
          ) {
            return false
          }

          return true
        })
        .test('is_decimal', t('ENTER_DECIMAL_PART'), setupPrice => {
          if (setupPrice) {
            const dotOccurrences = String(setupPrice).split('.').length - 1

            if (dotOccurrences === 1) return true

            return setupPrice.includes(',')
          }

          return false
        })
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      monthlyPrice: Yup.string()
        .test('is_required', t('REQUIRED'), monthlyPrice => {
          const priceWithOnlyNumbers = String(monthlyPrice).replace(/\D/g, '')

          if (
            Number.isNaN(Number(priceWithOnlyNumbers)) ||
            Number(priceWithOnlyNumbers) <= 0 ||
            !priceWithOnlyNumbers
          ) {
            return false
          }

          return true
        })
        .test('is_decimal', t('ENTER_DECIMAL_PART'), monthlyPrice => {
          if (monthlyPrice) {
            const dotOccurrences = String(monthlyPrice).split('.').length - 1

            if (dotOccurrences === 1) return true

            return monthlyPrice.includes(',')
          }

          return false
        })
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      setupNumberOfInstallments: Yup.number().required(t('REQUIRED')).typeError(t('INVALID')),
      billingDay: Yup.number().required(t('REQUIRED')).typeError(t('INVALID')),
      paymentForm: Yup.string()
        .oneOf([OrderIzeeEntity.PaymentForm.PIX, OrderIzeeEntity.PaymentForm.TICKET], t('INVALID_FEMALE'))
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      sellerId: Yup.number().required(t('REQUIRED')).typeError(t('INVALID')),
      paymentDetails: Yup.string().nullable(true).typeError(t('INVALID')),
      billingEmail: Yup.string().required(t('REQUIRED')).typeError(t('INVALID')),
      billingPhone: Yup.string().required(t('REQUIRED')).typeError(t('INVALID')),
    }),
  }
}
