import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { OrderEntity } from 'src/domain'

import { makeFetchSellersUseCase } from 'src/main'

const fetchSellers = createAsyncThunk('@sellers/fetchSellers', async () => {
  const fetchSellersUseCase = makeFetchSellersUseCase()

  return fetchSellersUseCase.fetchSellers()
})

interface SellersState {
  sellers: OrderEntity.Seller[]
  loading: boolean
}

const initialState: SellersState = {
  sellers: [],
  loading: false,
}

export const sellersSlice = createSlice({
  name: '@sellers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSellers.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchSellers.fulfilled, (state, action) => {
      state.sellers = action.payload || []
      state.loading = false
    })

    builder.addCase(fetchSellers.rejected, state => {
      state.loading = false
    })
  },
})

export const sellersActions = { ...sellersSlice.actions, fetchSellers }

export const sellersReducer = sellersSlice.reducer
