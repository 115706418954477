import { HttpClient, HttpStatusCode, PlanModel } from 'src/data'

import { FetchPlansUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchPlansService implements FetchPlansUseCase {
  constructor(private readonly httpClient: HttpClient<PlanModel[]>, private readonly url: string) {}

  async fetchPlans() {
    const response = await this.httpClient.request({ method: 'get', url: this.url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return PlanModel.fromJsonCollection(response.body || []).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
