export enum HttpStatusCode {
  ok = 200,
  created = 201,
  noContent = 204,
  notModified = 304,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  conflict = 409,
  serverError = 500,
  serverValidation = 422,
}

export type HttpRequest = {
  url: string
  method: 'post' | 'get' | 'put' | 'delete'
  body?: object
  params?: object
}

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode
  body?: T
}

export interface HttpClient<R = any> {
  readonly baseUrl?: string

  request(params: HttpRequest): Promise<HttpResponse<R>>
}
