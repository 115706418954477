export namespace UserEntity {
  export class Entity {
    constructor(
      readonly id: string,
      readonly name: string,
      readonly role: Roles,
      readonly features: Features[],
      readonly details: Details | null,
      readonly country: 'BR' | 'PT'
    ) {}

    get isAuthorized() {
      return this.role in Roles
    }

    get isCommercial() {
      return this.role === Roles.ROLE_COMMERCIAL && this.features.includes(Features.CLIENT)
    }

    get isFranchisee() {
      return this.role === Roles.ROLE_FRANCHISEE && this.features.includes(Features.CLIENT)
    }

    get isClient() {
      return this.features.includes(Features.CLIENT)
    }

    get isAdmin() {
      return this.features.includes(Features.COMMERCIAL_ADMIN)
    }

    get hasPackageFeature() {
      return this.features.includes(Features.PACKAGE)
    }

    get commercialReport() {
      return this.features.includes(Features.COMMERCIAL_REPORT)
    }

    get commercialIzee() {
      return this.features.includes(Features.COMMERCIAL_IZEE)
    }

    get isOfficeFranchisee() {
      return this.details?.type === FranchiseeType.OFFICE
    }
  }

  export enum Features {
    PDV = 'PDV',
    CLIENT = 'CLIENT',
    USER = 'USER',
    PACKAGE = 'PACKAGE',
    COMMERCIAL_IZEE = 'COMMERCIAL_IZEE',
    COMMERCIAL_REPORT = 'COMMERCIAL_REPORT',
    COMMERCIAL_ADMIN = 'COMMERCIAL_ADMIN',
  }

  export enum Roles {
    ROLE_FRANCHISEE = 'ROLE_FRANCHISEE',
    ROLE_COMMERCIAL = 'ROLE_COMMERCIAL',
  }

  export class Details {
    constructor(readonly franchiseeId: string | null, readonly type: FranchiseeType | null) {}
  }

  export enum FranchiseeType {
    OWNED = 'OWNED',
    OFFICE = 'OFFICE',
    TEST = 'TEST',
  }
}
