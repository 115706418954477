import React from 'react'

import { makeFetchCommercialResponsiblesUseCase, makeFetchClientByCnpjUseCase } from 'src/main'

import { ClientCreateEditOrViewPage } from 'src/presentation/pages'

import { CnpjValidator } from 'src/validation'

export const makeClientCreateEditOrViewPage = (action: 'create' | 'edit' | 'view'): React.ReactElement => {
  const cnpjValidator = new CnpjValidator.Validator()
  const fetchCommercialResponsiblesUseCase = makeFetchCommercialResponsiblesUseCase()
  const fetchClientByCnpjUseCase = makeFetchClientByCnpjUseCase()

  return (
    <ClientCreateEditOrViewPage
      action={action}
      cnpjValidator={cnpjValidator}
      fetchCommercialResponsiblesUseCase={fetchCommercialResponsiblesUseCase}
      fetchClientByCnpjUseCase={fetchClientByCnpjUseCase}
    />
  )
}
