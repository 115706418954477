import React, { SelectHTMLAttributes } from 'react'
import { RiArrowDownSFill } from 'react-icons/ri'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  error?: string
  label?: string | React.ReactElement
  loading?: boolean
}

const Select: React.FC<Props> = ({ children, error, label, loading, ...props }) => (
  <>
    {label && (
      <label
        className="block mb-2 overflow-hidden text-xs font-semibold overflow-ellipsis whitespace-nowrap md:text-sm"
        htmlFor={props?.name}
      >
        {label}
      </label>
    )}

    <div className="relative p-0">
      <select
        id={props?.name}
        className={`
          block
          w-full
          border
          border-quinary
          text-tertiary
          rounded
          py-3
          px-4
          disabled:opacity-60
          disabled:bg-background
          bg-white
          appearance-none
          text-xs
          md:text-sm
          ${error && 'border-error outline-error'}
        `}
        data-cy={props?.name}
        {...props}
      >
        {children}
      </select>

      <div className="absolute right-0 flex items-center px-2 pointer-events-none top-3.5">
        <RiArrowDownSFill className={`bg-white ${props?.disabled && 'bg-[#f2f2f2]'}`} size={16} />
      </div>

      <div
        className={`
          absolute
          top-0
          left-0
          w-full
          h-full
          bg-senary
          rounded
          animate-pulse
          transition-opacity
          duration-200
          ${loading ? 'opacity-100 visible' : 'opacity-0 invisible'}
        `}
      />

      {error && (
        <span
          role="alert"
          className="block mt-2 overflow-hidden text-xs outline-none text-error overflow-ellipsis whitespace-nowrap md:text-sm"
          data-cy="alert"
        >
          {error}
        </span>
      )}
    </div>
  </>
)

export default Select
