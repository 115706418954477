import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { MdVerticalAlignBottom } from 'react-icons/md'
import Select, { MultiValue } from 'react-select'

import { useFormik } from 'formik'

import { PlanEntity } from 'src/domain'

import { makeExportExtractUseCase } from 'src/main'

import { Button, LoadClientsSelect, Input } from 'src/presentation/components'
import { masks } from 'src/presentation/constants'
import { useAppDispatch, useAppSelector, useDate, useLanguage, useToast } from 'src/presentation/hooks'
import { billingModeMapper } from 'src/presentation/mappers'
import { franchiseesActions } from 'src/presentation/store/ducks/franchisees'
import { sellersActions } from 'src/presentation/store/ducks/sellers'

interface Props {
  closeModal: () => void
}

const ExportExtract: React.FC<Props> = ({ closeModal }) => {
  const { throwToast } = useToast()

  const dispatch = useAppDispatch()
  const { franchisees, loading: loadingFranchisees } = useAppSelector(state => state.franchisees)
  const { loading: loadingSellers, sellers } = useAppSelector(state => state.sellers)
  const { user } = useAppSelector(state => state.auth)

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const exportExtractUseCase = makeExportExtractUseCase()

  const handleSelectClient = (option: any) => {
    setFieldValue('client', option)
  }

  useEffect(() => {
    dispatch(franchiseesActions.fetchFranchisees())
    dispatch(sellersActions.fetchSellers())
  }, [dispatch])

  const handleSelectChange = (
    options: MultiValue<{
      label: any
      value: any
    }>,
    field: string
  ) => {
    if (!options) setFieldValue(field, [])
    else
      setFieldValue(
        field,
        options.map(item => item.value)
      )
  }
  const { formatStringDate } = useDate()

  const {
    values: formValues,
    errors: formErrors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      franchiseeId: [],
      client: [],
      seller: [],
      billingMode: [],
      startDate: '',
      endDate: '',
    },
    onSubmit: (submitValues, { setSubmitting }) => {
      async function handleSubmit() {
        try {
          const payload = {
            franchisee_id: submitValues.franchiseeId,
            client_id: submitValues?.client?.map((item: { value: any }) => item.value),
            seller_id: submitValues.seller,
            billing_mode: submitValues.billingMode,
            start_date: formatStringDate(submitValues.startDate, 'yyyy-MM-dd'),
            end_date: formatStringDate(submitValues.endDate, 'yyyy-MM-dd'),
          }

          const extract = await exportExtractUseCase.exportExtract(payload)
          if (extract?.file) {
            window.open(extract.file, '_blank')
          }
        } catch {
          throwToast({ message: t('UNABLE_TO_GENERATE_STATEMENT') })
        } finally {
          setTimeout(() => setSubmitting(false), 2000)
        }
      }

      handleSubmit()
    },
  })

  return (
    <div className="w-full">
      <section className="bg-white">
        <div className="flex gap-1 mb-6 m-[-1.5rem] px-[1.5rem] py-[1rem] border-b-[#DFE0E2] border-b text-[20px] font-semibold text-black">
          <MdVerticalAlignBottom size={30} />

          <span>
            <Trans i18nKey="EXPORT_STATEMENT" />
          </span>
        </div>

        <p className="mb-6">
          <Trans i18nKey="SELECT_ITEMS_EXPORT_STATEMENT" />
        </p>

        <form className="w-full" onSubmit={handleSubmit}>
          {!user?.isFranchisee && (
            <div className="flex-1 mb-4 mr-4">
              <label htmlFor="franchiseeId">
                <Trans i18nKey="FRANCHISE_UNIT" />
              </label>

              <div className="relative">
                <Select
                  name="franchiseeId"
                  options={franchisees.map((option: { name: any; id: any }) => ({
                    label: option?.name,
                    value: option.id,
                  }))}
                  isDisabled={loadingFranchisees}
                  isLoading={loadingFranchisees}
                  onChange={options => handleSelectChange(options, 'franchiseeId')}
                  placeholder={t('ALL')}
                  isMulti
                />
              </div>
            </div>
          )}

          <div className="flex-1 mb-4 mr-4">
            <label htmlFor="clients">
              <Trans i18nKey="CLIENT" />
            </label>

            <LoadClientsSelect handleSelectClient={handleSelectClient} />
          </div>

          <div className="flex-1 mb-4 mr-4">
            <label htmlFor="seller">
              <Trans i18nKey="SELLER" />
            </label>

            <div className="relative">
              <Select
                name="seller"
                options={sellers.map((option: { name: any; id: any }) => ({
                  label: option?.name,
                  value: option.id,
                }))}
                isDisabled={loadingSellers}
                isLoading={loadingSellers}
                placeholder={t('ALL')}
                onChange={options => handleSelectChange(options, 'seller')}
                isMulti
              />
            </div>
          </div>

          <div className="flex-1 mb-4 mr-4">
            <label htmlFor="billingMode">
              <Trans i18nKey="PAYMENT_TYPE" />
            </label>

            <div className="relative">
              <Select
                name="billingMode"
                options={[
                  {
                    label: billingModeMapper(currentLanguage, PlanEntity.BillingMode.IN_CASH),
                    value: PlanEntity.BillingMode.IN_CASH,
                  },
                  {
                    label: billingModeMapper(currentLanguage, PlanEntity.BillingMode.ON_TERM),
                    value: PlanEntity.BillingMode.ON_TERM,
                  },
                ]}
                placeholder={t('ALL')}
                onChange={options => handleSelectChange(options, 'billingMode')}
                isMulti
              />
            </div>
          </div>

          <label className="font-bold mb-2" htmlFor="startDate">
            <Trans i18nKey="PERIOD" />
          </label>

          <div className="flex gap-4 mb-4 mr-4">
            <div className="w-full">
              <label htmlFor="startDate">
                <Trans i18nKey="FROM" />
              </label>

              <Input
                name="startDate"
                value={formValues?.startDate}
                onChange={handleChange}
                error={formErrors?.startDate}
                mask={masks.DATE_MASK}
              />
            </div>

            <div className="w-full">
              <label htmlFor="endDate">
                <Trans i18nKey="TO" />
              </label>

              <Input
                name="endDate"
                value={formValues?.endDate}
                onChange={handleChange}
                error={formErrors?.endDate}
                mask={masks.DATE_MASK}
              />
            </div>
          </div>

          <div className="flex gap-4 items-center justify-center pt-6 mt-2">
            <Button
              name="cancel-button"
              className="bg-tertiary md:w-auto md:mb-0"
              type="button"
              onClick={() => closeModal()}
            >
              <Trans i18nKey="CANCEL" />
            </Button>

            <Button
              name="confirm-button"
              className="bg-primary md:w-auto md:mb-0"
              type="submit"
              disabled={isSubmitting}
            >
              <Trans i18nKey="EXPORT_STATEMENT" />
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default ExportExtract
