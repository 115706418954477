import { useCallback, useRef, useState } from 'react'

interface UseScrollProps {
  hasMoreItems: boolean
  loading: boolean
}

export const useScroll = ({ hasMoreItems, loading }: UseScrollProps) => {
  const [currentPage, setCurrentPage] = useState(0)

  const observer = useRef<IntersectionObserver | null>(null)

  const lastElementRef = useCallback(
    (node: HTMLElement) => {
      if (loading) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreItems) {
          setCurrentPage(state => state + 1)
        }
      })

      if (node) observer.current.observe(node)
    },
    [hasMoreItems, loading]
  )

  return [currentPage, lastElementRef, setCurrentPage] as const
}
