import { RemoteFetchAutoBillingConfigService } from 'src/data'

import { FetchAutoBillingConfigUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchAutoBillingConfigUseCase = (): FetchAutoBillingConfigUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlClientPlaceholder = '{client}'
  const url = `/clients/${urlClientPlaceholder}/auto-billing-config`

  return new RemoteFetchAutoBillingConfigService(authorizedHttpAdapter, url, urlClientPlaceholder)
}
