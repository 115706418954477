import { useTranslation } from 'react-i18next'

type AvailableLanguages = 'BR' | 'PT'

export const useLanguage = () => {
  const { i18n } = useTranslation()

  const handleChangeLanguage = (language: string) => i18n.changeLanguage(language)

  return {
    currentLanguage: i18n.language as AvailableLanguages,
    handleChangeLanguage,
  }
}
