import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { BiPlusCircle } from 'react-icons/bi'
import { BsClipboard2CheckFill, BsClipboard2XFill } from 'react-icons/bs'
import { FaUser, FaUsers, FaFileDownload } from 'react-icons/fa'
import { IoDocumentText } from 'react-icons/io5'
import { MdClose, MdReceipt, MdInsertChart } from 'react-icons/md'
import { Link, NavLink, useHistory } from 'react-router-dom'

import rvLogo from 'src/presentation/assets/images/rv-logo.svg'
import Modal from 'src/presentation/components/Modal/Modal'
import ExportExtract from 'src/presentation/components/Sidebar/ExportExtract/ExportExtract'
import { useAppDispatch, useAppSelector } from 'src/presentation/hooks'
import { headerSearchActions } from 'src/presentation/store/ducks/headerSearch'
import { uiActions } from 'src/presentation/store/ducks/ui'

const Sidebar: React.FC = () => {
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { isSidebarOpen } = useAppSelector(state => state.ui)
  const { isSearchActive } = useAppSelector(state => state.headerSearch)
  const { user } = useAppSelector(state => state.auth)
  const [showExportExtract, setShowExportExtract] = useState(false)

  useEffect(() => {
    return history.listen(() => {
      dispatch(uiActions.toggleSidebar(false))

      dispatch(headerSearchActions.franchiseeId(null))
      dispatch(headerSearchActions.freeTermText(null))
      dispatch(headerSearchActions.searchActive(false))
    })
  }, [dispatch, history])

  const sidebarToggleClasses = isSidebarOpen ? 'translate-x-0' : '-translate-x-64 lg:translate-x-0'

  const ifSidebarIsOpenClasses = isSidebarOpen ? 'block' : 'hidden'

  const exportExtract = () => {
    setShowExportExtract(true)
  }

  const handleCloseExportExtract = () => {
    setShowExportExtract(false)
  }

  return (
    <>
      <div
        className={`
          absolute
          top-0
          left-0
          bg-black
          opacity-50
          w-full
          h-full
          overflow-hidden
          z-40
          cursor-pointer
          ${ifSidebarIsOpenClasses}
        `}
        onClick={() => dispatch(uiActions.toggleSidebar(false))}
      />

      <div
        className={`
          flex-shrink-0
          h-screen
          w-56
          py-6
          bg-primary
          text-white
          shadow-md
          transform
          transition-all
          overflow-y-auto
          duration-200
          ease-in-out
          z-50
          top-0
          absolute
          lg:sticky
          ${sidebarToggleClasses}
        `}
      >
        <div className="flex items-center justify-between px-4 mb-12 lg:justify-center lg:px-12">
          <Link className={`w-full text-white ${isSidebarOpen && 'pointer-events-none'}`} to="/open-orders">
            <img className="w-32 m-0 lg:m-auto lg:w-full" src={rvLogo} alt="RV Logo" />
          </Link>

          <MdClose
            className="block text-white transition-colors cursor-pointer hover:text-tertiary lg:hidden"
            size={24}
            onClick={() => dispatch(uiActions.toggleSidebar(false))}
          />
        </div>

        <div className="flex items-center justify-start px-6 mb-4">
          <div className="flex items-center justify-center mr-2 border-2 rounded-full border-quaternary h-9 w-9 lg:h-10 lg:w-10">
            <MdReceipt className="text-white" size={16} />
          </div>

          <h2 className="text-sm font-semibold text-quaternary lg:text-base">
            <Trans i18nKey="ORDERS" />
          </h2>
        </div>

        <nav className="mb-6">
          <NavLink
            className="flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
            to="/open-orders"
            activeClassName="bg-secundary text-white border-white"
            isActive={(_, location) => location.pathname === '/open-orders' && !isSearchActive}
          >
            <IoDocumentText className="mr-5 text-white" size={16} />

            <span className="text-xs text-white lg:text-sm">
              <Trans i18nKey="OPEN" />
            </span>
          </NavLink>

          <NavLink
            className="flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
            to="/completed-orders"
            activeClassName="bg-secundary text-white border-white"
            isActive={(_, location) => location.pathname === '/completed-orders' && !isSearchActive}
          >
            <BsClipboard2CheckFill className="mr-5 text-white" size={16} />

            <span className="text-xs text-white lg:text-sm">
              <Trans i18nKey="COMPLETED" />
            </span>
          </NavLink>

          <NavLink
            className="flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
            to="/canceled-orders"
            activeClassName="bg-secundary text-white border-white"
            isActive={(_, location) => location.pathname === '/canceled-orders' && !isSearchActive}
          >
            <BsClipboard2XFill className="mr-5 text-white" size={16} />

            <span className="text-xs text-white lg:text-sm">
              <Trans i18nKey="CANCELLED" />
            </span>
          </NavLink>
        </nav>

        {user?.isClient && (
          <>
            <div className="flex items-center justify-start px-6 mb-4">
              <div className="flex items-center justify-center mr-2 border-2 rounded-full border-quaternary h-9 w-9 lg:h-10 lg:w-10">
                <FaUser className="text-white" size={16} />
              </div>

              <h2 className="text-sm font-semibold text-quaternary lg:text-base">
                <Trans i18nKey="CLIENTS" />
              </h2>

              <Link className="ml-auto" to="/clients/new">
                <BiPlusCircle size={22} />
              </Link>
            </div>

            <nav>
              <NavLink
                className="mb-6 flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
                to="/clients"
                activeClassName="bg-secundary text-white border-white"
                isActive={(_, location) => location.pathname === '/clients' && !isSearchActive}
              >
                <FaUsers className="mr-5 text-white" size={16} />

                <span className="text-xs text-white lg:text-sm">
                  <Trans i18nKey="ALL" />
                </span>
              </NavLink>
            </nav>
          </>
        )}

        {user?.commercialReport && (
          <>
            <div className="flex items-center justify-start px-6 mb-4">
              <div className="flex items-center justify-center mr-2 border-2 rounded-full border-quaternary h-9 w-9 lg:h-10 lg:w-10">
                <MdInsertChart className="text-white" size={16} />
              </div>

              <h2 className="text-sm font-semibold text-quaternary lg:text-base">
                <Trans i18nKey="ADMINISTRATIVE" />
              </h2>
            </div>

            <nav>
              <button
                onClick={() => exportExtract()}
                className="flex justify-start items-center w-full py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
              >
                <FaFileDownload className="mr-5 text-white" size={16} />

                <span className="text-xs text-white lg:text-sm">
                  <Trans i18nKey="EXPORT_STATEMENT" />
                </span>
              </button>
            </nav>
          </>
        )}

        {user?.commercialIzee && (
          <>
            <div className="flex items-center justify-start px-6 pt-4 mb-4 mt-6 border-t border-quaternary">
              <div className="flex items-center justify-center mr-2 border-2 rounded-full border-quaternary h-9 w-9 lg:h-10 lg:w-10">
                <MdReceipt className="text-white" size={16} />
              </div>

              <h2 className="text-sm font-semibold text-quaternary lg:text-base">
                <Trans i18nKey="IZEE_ORDERS" />
              </h2>
            </div>

            <nav className="mb-6">
              <NavLink
                className="flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
                to="/open-orders-izee"
                activeClassName="bg-secundary text-white border-white"
                isActive={(_, location) => location.pathname === '/open-orders-izee' && !isSearchActive}
              >
                <IoDocumentText className="mr-5 text-white" size={16} />

                <span className="text-xs text-white lg:text-sm">
                  <Trans i18nKey="OPEN" />
                </span>
              </NavLink>

              <NavLink
                className="flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
                to="/completed-orders-izee"
                activeClassName="bg-secundary text-white border-white"
                isActive={(_, location) => location.pathname === '/completed-orders-izee' && !isSearchActive}
              >
                <BsClipboard2CheckFill className="mr-5 text-white" size={16} />

                <span className="text-xs text-white lg:text-sm">
                  <Trans i18nKey="COMPLETED" />
                </span>
              </NavLink>
            </nav>

            <div className="flex items-center justify-start px-6 mb-4">
              <div className="flex items-center justify-center mr-2 border-2 rounded-full border-quaternary h-9 w-9 lg:h-10 lg:w-10">
                <FaUser className="text-white" size={16} />
              </div>

              <h2 className="text-sm font-semibold text-quaternary lg:text-base">
                <Trans i18nKey="IZEE_CLIENTS" />
              </h2>
            </div>

            <nav>
              <NavLink
                className="mb-6 flex justify-start items-center py-2.5 px-4 pl-8 transition duration-200 border-l-2 border-primary hover:bg-secundary hover:text-white hover:border-white"
                to="/clients-izee"
                activeClassName="bg-secundary text-white border-white"
                isActive={(_, location) => location.pathname === '/clients-izee' && !isSearchActive}
              >
                <FaUsers className="mr-5 text-white" size={16} />

                <span className="text-xs text-white lg:text-sm">
                  <Trans i18nKey="ALL" />
                </span>
              </NavLink>
            </nav>
          </>
        )}
      </div>

      <Modal
        closeClickEsc={false}
        closeClickOutside={false}
        isOpen={showExportExtract}
        closeModal={handleCloseExportExtract}
      >
        <div className="max-w-[800px]">
          <ExportExtract closeModal={handleCloseExportExtract} />
        </div>
      </Modal>
    </>
  )
}

export default Sidebar
