import React from 'react'

const PriceTablesLoading: React.FC = () => (
  <>
    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>

    <tr>
      <td className="p-4 pb-0 pl-1">
        <div className="w-5 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-14 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-32 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0">
        <div className="w-16 bg-senary rounded h-5 animate-pulse" />
      </td>
      <td className="p-4 pb-0 pr-1 max-w-xs">
        <div className="w-64 bg-senary rounded h-5 animate-pulse" />
      </td>
    </tr>
  </>
)

export default PriceTablesLoading
