import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AutoBillingConfigEntity } from 'src/domain'

import { makeCreateAutoBillingConfigUseCase, makeFetchAutoBillingConfigUseCase } from 'src/main'

interface FetchAutoBillingConfigParams {
  client: number
}

interface CreateAutoBillingConfigParams {
  client: number
  data: AutoBillingConfigEntity
}

const fetchAutoBillingConfig = createAsyncThunk(
  '@autoBillingConfig/fetchAutoBillingConfig',
  async ({ client }: FetchAutoBillingConfigParams) => {
    return makeFetchAutoBillingConfigUseCase().fetchAutoBillingConfig(client)
  }
)

const createAutoBillingConfig = createAsyncThunk(
  '@autoBillingConfig/createAutoBillingConfig',
  async ({ client, data }: CreateAutoBillingConfigParams, { rejectWithValue }) => {
    try {
      await makeCreateAutoBillingConfigUseCase().createAutoBillingConfig(client, data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

interface AutoBillingConfigState {
  autoBillingConfig: AutoBillingConfigEntity | null
  loadingCreateAutoBillingConfig: boolean
  loadingFetchAutoBillingConfig: boolean
  createAutoBillingConfigState: 'success' | 'error' | null
}

const initialState: AutoBillingConfigState = {
  autoBillingConfig: null,
  loadingCreateAutoBillingConfig: false,
  loadingFetchAutoBillingConfig: false,
  createAutoBillingConfigState: null,
}

export const autoBillingConfigSlice = createSlice({
  name: '@autoBillingConfig',
  initialState,
  reducers: {
    clearAutoBillingConfig: state => {
      state.autoBillingConfig = null
    },

    clearCreateAutoBillingConfigState: state => {
      state.createAutoBillingConfigState = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAutoBillingConfig.pending, state => {
      state.loadingFetchAutoBillingConfig = true
    })

    builder.addCase(fetchAutoBillingConfig.fulfilled, (state, action) => {
      state.loadingFetchAutoBillingConfig = false
      state.autoBillingConfig = action.payload
    })

    builder.addCase(fetchAutoBillingConfig.rejected, state => {
      state.loadingFetchAutoBillingConfig = false
      state.autoBillingConfig = null
    })

    builder.addCase(createAutoBillingConfig.pending, state => {
      state.loadingCreateAutoBillingConfig = true
    })

    builder.addCase(createAutoBillingConfig.fulfilled, state => {
      state.loadingCreateAutoBillingConfig = false
      state.createAutoBillingConfigState = 'success'
    })

    builder.addCase(createAutoBillingConfig.rejected, state => {
      state.loadingCreateAutoBillingConfig = false
      state.createAutoBillingConfigState = 'error'
    })
  },
})

export const autoBillingConfigActions = {
  ...autoBillingConfigSlice.actions,
  createAutoBillingConfig,
  fetchAutoBillingConfig,
}

export const autoBillingConfigReducer = autoBillingConfigSlice.reducer
