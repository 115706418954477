import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { makePurchasePackageUseCase } from 'src/main'

interface PurchasePackageParams {
  client: number
  credits: number
}

const purchasePackage = createAsyncThunk(
  '@package/purchasePackage',
  async ({ client, credits }: PurchasePackageParams) => {
    const purchasePackageUseCase = makePurchasePackageUseCase()

    const { saleId } = await purchasePackageUseCase.purchasePackage({ credits, client })

    return saleId
  }
)

interface PackageState {
  loading: boolean
  purchaseSuccess: boolean
  purchaseError: boolean
  redirectOrderId: number | null
}

const initialState: PackageState = {
  loading: false,
  purchaseSuccess: false,
  purchaseError: false,
  redirectOrderId: null,
}

export const packageSlice = createSlice({
  name: '@package',
  initialState,
  reducers: {
    purchaseSuccess: state => {
      state.purchaseSuccess = false
    },

    purchaseError: state => {
      state.purchaseError = false
    },

    redirectOrderId: state => {
      state.redirectOrderId = null
    },
  },
  extraReducers: builder => {
    builder.addCase(purchasePackage.pending, state => {
      state.loading = true
    })

    builder.addCase(purchasePackage.fulfilled, (state, action) => {
      state.loading = false
      state.purchaseSuccess = true
      state.purchaseError = false
      state.redirectOrderId = action.payload
    })

    builder.addCase(purchasePackage.rejected, state => {
      state.loading = false
      state.purchaseSuccess = false
      state.purchaseError = true
    })
  },
})

export const packageActions = { ...packageSlice.actions, purchasePackage }

export const packageReducer = packageSlice.reducer
