import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UiState {
  isSidebarOpen: boolean
  packageModal: {
    client: number | null
    isOpen: boolean
  }
  priceTablesModal: {
    client: number | null
    isOpen: boolean
  }
  signatureModal: {
    client: number | null
    isOpen: boolean
  }
  autoBillingConfigModal: {
    client: number | null
    clientName: string | null
    isOpen: boolean
  }
  exportClientExtractModal: {
    client: number | null
    isOpen: boolean
  }
}

const initialState: UiState = {
  isSidebarOpen: false,
  packageModal: {
    client: null,
    isOpen: false,
  },
  priceTablesModal: {
    client: null,
    isOpen: false,
  },
  signatureModal: {
    client: null,
    isOpen: false,
  },
  autoBillingConfigModal: {
    client: null,
    clientName: null,
    isOpen: false,
  },
  exportClientExtractModal: {
    client: null,
    isOpen: false,
  },
}

type ToggleModalAction = {
  client: number | null
  isOpen?: boolean
}

type ToggleAutoBillingConfigModalAction = ToggleModalAction & {
  clientName: string | null
}

export const uiSlice = createSlice({
  name: '@ui',
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean | undefined>) => {
      let isOpen = action.payload

      if (typeof isOpen === 'undefined') {
        isOpen = !state.isSidebarOpen
      }

      state.isSidebarOpen = isOpen
    },

    togglePackageModal: (state, action: PayloadAction<ToggleModalAction>) => {
      const { client } = action.payload
      let { isOpen } = action.payload

      if (typeof isOpen === 'undefined') {
        isOpen = !state.packageModal.isOpen
      }

      state.packageModal.client = client
      state.packageModal.isOpen = isOpen
    },

    togglePriceTablesModal: (state, action: PayloadAction<ToggleModalAction>) => {
      const { client } = action.payload
      let { isOpen } = action.payload

      if (typeof isOpen === 'undefined') {
        isOpen = !state.priceTablesModal.isOpen
      }

      state.priceTablesModal.client = client
      state.priceTablesModal.isOpen = isOpen
    },

    toggleSignatureModal: (state, action: PayloadAction<ToggleModalAction>) => {
      const { client } = action.payload
      let { isOpen } = action.payload

      if (typeof isOpen === 'undefined') {
        isOpen = !state.signatureModal.isOpen
      }

      state.signatureModal.client = client
      state.signatureModal.isOpen = isOpen
    },

    toggleAutoBillingConfigModal: (state, action: PayloadAction<ToggleAutoBillingConfigModalAction>) => {
      const { client, clientName } = action.payload
      let { isOpen } = action.payload

      if (typeof isOpen === 'undefined') {
        isOpen = !state.autoBillingConfigModal.isOpen
      }

      state.autoBillingConfigModal.client = client
      state.autoBillingConfigModal.clientName = clientName
      state.autoBillingConfigModal.isOpen = isOpen
    },

    toggleExportClientExtractModal: (state, action: PayloadAction<ToggleModalAction>) => {
      const { client } = action.payload
      let { isOpen } = action.payload

      if (typeof isOpen === 'undefined') {
        isOpen = !state.exportClientExtractModal.isOpen
      }

      state.exportClientExtractModal.client = client
      state.exportClientExtractModal.isOpen = isOpen
    },
  },
})

export const uiActions = uiSlice.actions

export const uiReducer = uiSlice.reducer
