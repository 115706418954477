import React from 'react'
import { Trans } from 'react-i18next'
import { FaAngleDown, FaUserCircle } from 'react-icons/fa'

import rvSmallLogo from 'src/presentation/assets/images/rv-small-logo.svg'
import { useAppSelector, useLogout } from 'src/presentation/hooks'

import 'src/presentation/components/Header/DropdownMenu/styles.css'

const DropdownMenu: React.FC = () => {
  const { user } = useAppSelector(state => state.auth)

  const logout = useLogout()

  return (
    <button
      id="dropdown-menu"
      className="relative flex items-center justify-start px-2 py-2 transition-colors rounded-full bg-primary focus:bg-secundary hover:bg-secundary lg:px-6 lg:py-3"
      type="button"
    >
      <img className="hidden w-5 mr-4 lg:inline-block" src={rvSmallLogo} alt="RV Logo" />

      <span className="hidden mr-3 text-sm font-semibold text-white lg:inline-block">
        {user?.name || <Trans i18nKey="USER" />}
      </span>

      <FaAngleDown id="header-dropdown-menu-arrow" className="text-white transition-transform" size={16} />

      <div
        id="header-dropdown-menu"
        className="invisible transition-all duration-200 origin-center transform scale-95 -translate-y-2 opacity-0"
      >
        <div className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-lg shadow-lg outline-none">
          <span
            className="flex items-center justify-start w-full px-4 py-3 text-sm font-semibold leading-5 text-left text-white rounded-t-lg flex-nowrap bg-primary lg:hidden"
            role="menuitem"
          >
            <FaUserCircle className="mr-4 text-white" size={20} />

            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
              {user?.name || <Trans i18nKey="USER" />}
            </span>
          </span>

          <span
            className="flex justify-between w-full px-4 py-3 text-sm font-medium leading-5 text-left text-black transition-colors rounded-lg rounded-t-none cursor-pointer lg:rounded-t-lg lg:text-base hover:bg-background"
            role="menuitem"
            onClick={logout}
          >
            <Trans i18nKey="LOGOUT" />
          </span>
        </div>
      </div>
    </button>
  )
}

export default DropdownMenu
