import { OrdersPagination } from 'src/domain'

export class FetchOrdersMapper {
  static toJson(params: OrdersPagination) {
    return {
      offset: params.offset,
      limit: params.limit,
      order_by: params.orderBy,
      order_by_dir: params.orderByDir,
    }
  }
}
