import { FetchClientsMapper, HttpClient, HttpStatusCode, ClientModel } from 'src/data'

import { ClientsPagination, FetchClientsUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchClientsService implements FetchClientsUseCase {
  constructor(private readonly httpClient: HttpClient<ClientModel[]>, private readonly url: string) {}

  async fetchClients(pagination: ClientsPagination, franchisee?: number, search?: string) {
    const mappedPagination = FetchClientsMapper.toJson(pagination)

    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: {
        ...mappedPagination,
        franchisee_id: franchisee,
        search,
      },
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ClientModel.fromJsonCollection(response.body as ClientModel[]).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
