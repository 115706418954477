import { UpdateOpenOrderUseCase } from 'src/domain'

export class UpdateOpenOrderMapper {
  static toJson(params: UpdateOpenOrderUseCase.Params) {
    return {
      seller_id: params?.sellerId,
      credits: params?.credits,
      price: params?.price,
      number_of_installments: params?.numberOfInstallments,
      billing_mode: params?.billingMode,
      billing_modality: params.billingModality,
      first_billing_month: params?.firstBillingMonth,
      billing_day: params?.billingDay,
      payment_details: params?.paymentDetails,
      payment_form: params?.paymentForm,
    }
  }
}
