import { HttpClient, HttpStatusCode } from 'src/data'
import { ExportExtractModel } from 'src/data/models/ExportExtractModel'

import { ExportExtractUseCase, ParamsExportExtractEntity, UnexpectedError } from 'src/domain'

export class RemoteExportExtractService implements ExportExtractUseCase {
  constructor(private readonly httpClient: HttpClient<ExportExtractModel>, private readonly url: string) {}

  async exportExtract(params: ParamsExportExtractEntity) {
    const response = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: params,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ExportExtractModel.fromJson(response.body as ExportExtractModel).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
