import { RemoteCancelOpenOrderService } from 'src/data'

import { OpenOrderCancelUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeCancelOpenOrderUseCaseFactory = (): OpenOrderCancelUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteCancelOpenOrderService(authorizedHttpAdapter, '/sales')
}
