import { RemoteFetchCommercialResponsiblesService } from 'src/data'

import { FetchCommercialResponsiblesUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCommercialResponsiblesUseCase = (): FetchCommercialResponsiblesUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchCommercialResponsiblesService(authorizedHttpAdapter, '/commercial-responsibles')
}
