import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface HeaderSearchState {
  franchiseeId: number | null
  freeTermText: string | null
  isSearchActive: boolean
}

const initialState: HeaderSearchState = {
  franchiseeId: null,
  freeTermText: null,
  isSearchActive: false,
}

export const headerSearchSlice = createSlice({
  name: '@headerSearch',
  initialState,
  reducers: {
    franchiseeId: (state, action: PayloadAction<number | null>) => {
      state.franchiseeId = action.payload
    },

    freeTermText: (state, action: PayloadAction<string | null>) => {
      state.freeTermText = action.payload
    },

    searchActive: (state, action: PayloadAction<boolean>) => {
      state.isSearchActive = action.payload
    },
  },
})

export const headerSearchActions = headerSearchSlice.actions

export const headerSearchReducer = headerSearchSlice.reducer
