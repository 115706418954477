import { RemoteAuthenticateUserService } from 'src/data'

import { AuthenticateUserUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeAuthenticateUserUseCase = (): AuthenticateUserUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteAuthenticateUserService(authorizedHttpAdapter, '/me')
}
