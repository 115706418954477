import { PlanEntity } from 'src/domain'

export class PlanModel {
  private static plans: PlanModel[] = []

  constructor(
    readonly annualDiscount: string,
    readonly annualPrice: string,
    readonly annualPriceWithDiscount: string,
    readonly details: PlanEntity.Detail[],
    readonly includedInspections: number,
    readonly name: PlanEntity.Modality,
    readonly negotiable: boolean,
    readonly price: string,
    readonly publicId: string,
    readonly quarterlyPrice: string,
    readonly semiannualPrice: string,
    readonly type: string
  ) {}

  static fromJsonCollection(json: any[]) {
    this.plans = json.map(planJson => {
      return new PlanModel(
        planJson?.annual_discount,
        planJson?.annual_price,
        planJson?.annual_price_with_discount,
        planJson?.details,
        planJson?.included_inspections,
        planJson?.name,
        planJson?.negotiable,
        planJson?.price,
        planJson?.public_id,
        planJson?.quarterly_price,
        planJson?.semiannual_price,
        planJson?.type
      )
    })

    return this
  }

  static toEntityCollection(): PlanEntity.Entity[] {
    return this.plans.map(planModel => ({
      annualDiscount: planModel.annualDiscount,
      annualPrice: planModel.annualPrice,
      annualPriceWithDiscount: planModel.annualPriceWithDiscount,
      details: planModel.details,
      includedInspections: planModel.includedInspections,
      name: planModel.name,
      negotiable: planModel.negotiable,
      price: planModel.price,
      publicId: planModel.publicId,
      quarterlyPrice: planModel.quarterlyPrice,
      semiannualPrice: planModel.semiannualPrice,
      type: planModel.type,
    }))
  }
}
