import { UserEntity } from 'src/domain'

export class UserModel {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly role: UserEntity.Roles,
    readonly features: UserEntity.Features[],
    readonly details: UserEntity.Details,
    readonly country: 'BR' | 'PT'
  ) {}

  static fromJson(json: any) {
    return new UserModel(
      json?.unique_id,
      json?.name,
      json?.role,
      json?.features,
      json?.details,
      json?.profile?.country || 'BR'
    )
  }

  toEntity() {
    return new UserEntity.Entity(this.id, this.name, this.role, this.features, this.details, this.country)
  }
}
