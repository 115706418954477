const firstBillingMonthTypes = {
  BR: {
    CURRENT: 'Mês atual',
    NEXT: 'Mês subsequente',
  },
  PT: {
    CURRENT: 'Mês atual',
    NEXT: 'Mês subsequente',
  },
}

export const firstBillingMonthMapper = (language: 'BR' | 'PT', firstBillingMonth: 'CURRENT' | 'NEXT') => {
  return firstBillingMonthTypes?.[language]?.[firstBillingMonth] || ''
}
