import React, { forwardRef } from 'react'
import Select, { Props as SelectProps } from 'react-select'

import { selectStyles, selectTheme } from 'src/presentation/components/CustomSelect/styles'

interface Props extends SelectProps {
  error?: string
  label?: string | React.ReactElement
  loading: boolean
}

const CustomSelect = forwardRef<any, Props>(({ error, label, loading, ...props }, ref) => (
  <>
    {label && (
      <label
        className="block mb-2 overflow-hidden text-xs font-semibold overflow-ellipsis whitespace-nowrap md:text-sm"
        htmlFor={props?.name}
      >
        {label}
      </label>
    )}

    <div className="relative p-0">
      <Select
        id={props?.name}
        className="text-xs md:text-sm text-tertiary disabled:opacity-60"
        ref={ref}
        data-cy={props?.name}
        styles={selectStyles(!!error)}
        theme={selectTheme}
        {...props}
      />

      <div
        className={`
          absolute
          top-0
          left-0
          w-full
          h-full
          bg-senary
          rounded
          animate-pulse
          transition-opacity
          duration-200
          ${loading ? 'opacity-100 visible' : 'opacity-0 invisible'}
        `}
      />

      {error && (
        <span
          role="alert"
          className="block mt-2 overflow-hidden text-xs outline-none text-error overflow-ellipsis whitespace-nowrap md:text-sm"
          data-cy="alert"
        >
          {error}
        </span>
      )}
    </div>
  </>
))

CustomSelect.displayName = 'CustomSelect'

export default CustomSelect
