import { OrderEntity } from 'src/domain'

export class OrderModel {
  private static orders: OrderModel[] = []

  constructor(
    readonly billingDay: number,
    readonly franchiseeId: number,
    readonly clientId: number,
    readonly client: OrderEntity.Client,
    readonly createdAt: Date,
    readonly credits: number,
    readonly date: Date,
    readonly id: number,
    readonly numberOfInstallments: number,
    readonly packageData: OrderEntity.Package,
    readonly paymentDetails: string,
    readonly price: number,
    readonly status: OrderEntity.Status,
    readonly transactionId: number,
    readonly updatedAt: Date,
    readonly userId: number,
    readonly billingMode: OrderEntity.BillingMode,
    readonly billingModality: OrderEntity.BillingModality,
    readonly from_single_order: string,
    readonly paymentForm: OrderEntity.PaymentForm,
    readonly firstBillingMonth: OrderEntity.FirstBillingMonth,
    readonly negociationType: number,
    readonly origin: OrderEntity.Origin,
    readonly seller?: OrderEntity.Seller,
    readonly canceled_at?: Date,
    readonly cancellation_reason?: string,
    readonly cancellation_observation?: string,
    readonly canceled_by?: string,
    readonly franchisee_name?: string,
    readonly autoBillingExecution?: OrderEntity.AutoBillingExecution
  ) {}

  static fromJson(json: any) {
    return new OrderModel(
      json?.billing_day,
      json?.franchisee_id,
      json?.client_id,
      {
        active: json?.client?.active,
        address: {
          city: json?.client?.address?.city,
          cityId: json?.client?.address?.city_id,
          neighborhood: json?.client?.address?.neighborhood,
          number: json?.client?.address?.number,
          state: json?.client?.address?.state,
          stateId: json?.client?.address?.state_id,
          street: json?.client?.address?.street,
          zipcode: json?.client?.address?.zipcode,
          complement: json?.client?.address?.complement,
        },
        contactInfo: {
          email: json?.client?.contact_info?.email,
          phone: json?.client?.contact_info?.phone,
        },
        legalPersonInfo: {
          billingName: json?.client?.legal_person_info?.billing_name,
          cnpj: json?.client?.legal_person_info?.cnpj,
        },
        name: json?.client?.name,
        naturalPersonInfo: {
          cpf: json?.client?.natural_person_info?.cpf,
        },
        responsible: {
          cpf: json?.client?.responsible?.cpf,
          name: json?.client?.responsible?.name,
          email: json?.client?.responsible?.email,
          phone: json?.client?.responsible?.phone,
        },
        type: json?.client?.type,
      },
      json?.created_at,
      json?.credits,
      json?.date,
      json?.id,
      json?.number_of_installments,
      {
        area: json?.package_data?.area,
        credits: json?.package_data?.credits,
        size: json?.package_data?.size,
        value: json?.package_data?.value,
      },
      json?.payment_details,
      json?.price,
      json?.status,
      json?.transaction_id,
      json?.updated_at,
      json?.user_id,
      json?.billing_mode,
      json?.billing_modality,
      json?.from_single_order,
      json?.payment_form,
      json?.first_billing_month,
      json?.negociation_type,
      json?.origin,
      json?.seller,
      json?.canceled_at,
      json?.cancellation_reason,
      json?.cancellation_observation,
      json?.canceled_by,
      json?.franchisee_name,
      {
        summaryUrl: json?.auto_billing_execution?.summary_url,
      }
    )
  }

  static fromJsonCollection(json: any[]) {
    this.orders = json.map(orderJson => {
      return new OrderModel(
        orderJson?.billing_day,
        orderJson?.franchisee_id,
        orderJson?.client_id,
        {
          active: orderJson?.client?.active,
          address: {
            city: orderJson?.client?.address?.city,
            cityId: orderJson?.client?.address?.city_id,
            neighborhood: orderJson?.client?.address?.neighborhood,
            number: orderJson?.client?.address?.number,
            state: orderJson?.client?.address?.state,
            stateId: orderJson?.client?.address?.state_id,
            street: orderJson?.client?.address?.street,
            zipcode: orderJson?.client?.address?.zipcode,
            complement: orderJson?.client?.address?.complement,
          },
          contactInfo: {
            email: orderJson?.client?.contact_info?.email,
            phone: orderJson?.client?.contact_info?.phone,
          },
          legalPersonInfo: {
            billingName: orderJson?.client?.legal_person_info?.billing_name,
            cnpj: orderJson?.client?.legal_person_info?.cnpj,
          },
          name: orderJson?.client?.name,
          naturalPersonInfo: {
            cpf: orderJson?.client?.natural_person_info?.cpf,
          },
          responsible: {
            cpf: orderJson?.client?.responsible?.cpf,
            name: orderJson?.client?.responsible?.name,
            email: orderJson?.client?.responsible?.email,
            phone: orderJson?.client?.responsible?.phone,
          },
          type: orderJson?.client?.type,
        },
        orderJson?.created_at,
        orderJson?.credits,
        orderJson?.date,
        orderJson?.id,
        orderJson?.number_of_installments,
        {
          area: orderJson?.package_data?.area,
          credits: orderJson?.package_data?.credits,
          size: orderJson?.package_data?.size,
          value: orderJson?.package_data?.value,
        },
        orderJson?.payment_details,
        orderJson?.price,
        orderJson?.status,
        orderJson?.transaction_id,
        orderJson?.updated_at,
        orderJson?.user_id,
        orderJson?.billing_mode,
        orderJson?.billing_modality,
        orderJson?.from_single_order,
        orderJson?.payment_form,
        orderJson?.first_billing_month,
        orderJson?.negociation_type,
        orderJson?.origin,
        orderJson?.seller,
        orderJson?.canceled_at,
        orderJson?.cancellation_reason,
        orderJson?.cancellation_observation,
        orderJson?.canceled_by,
        orderJson?.franchisee_name,
        {
          summaryUrl: orderJson?.auto_billing_execution?.summary_url,
        }
      )
    })

    return this
  }

  toEntity(): OrderEntity.Entity {
    return {
      billingDay: this.billingDay,
      franchiseeId: this.franchiseeId,
      clientId: this.clientId,
      client: this.client,
      createdAt: this.createdAt,
      credits: this.credits,
      date: this.date,
      id: this.id,
      numberOfInstallments: this.numberOfInstallments,
      packageData: this.packageData,
      paymentDetails: this.paymentDetails,
      price: this.price,
      status: this.status,
      transactionId: this.transactionId,
      updatedAt: this.updatedAt,
      userId: this.userId,
      billingMode: this.billingMode,
      billingModality: this.billingModality,
      from_single_order: this.from_single_order,
      paymentForm: this.paymentForm,
      firstBillingMonth: this.firstBillingMonth,
      negociationType: this.negociationType,
      origin: this.origin,
      seller: this.seller,
      canceled_at: this.canceled_at,
      cancellation_reason: this.cancellation_reason,
      cancellation_observation: this.cancellation_observation,
      canceled_by: this.canceled_by,
      franchisee_name: this.franchisee_name,
      autoBillingExecution: this.autoBillingExecution,
    }
  }

  static toEntityCollection(): OrderEntity.Entity[] {
    return this.orders.map(orderModel => ({
      billingDay: orderModel.billingDay,
      franchiseeId: orderModel.franchiseeId,
      clientId: orderModel.clientId,
      client: orderModel.client,
      createdAt: orderModel.createdAt,
      credits: orderModel.credits,
      date: orderModel.date,
      id: orderModel.id,
      numberOfInstallments: orderModel.numberOfInstallments,
      packageData: orderModel.packageData,
      paymentDetails: orderModel.paymentDetails,
      price: orderModel.price,
      status: orderModel.status,
      transactionId: orderModel.transactionId,
      updatedAt: orderModel.updatedAt,
      userId: orderModel.userId,
      billingMode: orderModel.billingMode,
      billingModality: orderModel.billingModality,
      from_single_order: orderModel.from_single_order,
      paymentForm: orderModel.paymentForm,
      firstBillingMonth: orderModel.firstBillingMonth,
      negociationType: orderModel.negociationType,
      origin: orderModel.origin,
      seller: orderModel.seller,
      canceled_at: orderModel.canceled_at,
      cancellation_reason: orderModel.cancellation_reason,
      cancellation_observation: orderModel.cancellation_observation,
      canceled_by: orderModel.canceled_by,
      franchisee_name: orderModel.franchisee_name,
      autoBillingExecution: orderModel.autoBillingExecution,
    }))
  }
}
