export namespace PurchasePackageUseCase {
  export type Params = {
    credits: number
    client: number
  }

  export type Return = {
    saleId: number
  }
}

export interface PurchasePackageUseCase {
  purchasePackage(params: PurchasePackageUseCase.Params): Promise<PurchasePackageUseCase.Return>
}
