import { ExportExtractModel, HttpClient, HttpStatusCode } from 'src/data'

import { ExportClientExtractUseCase, UnexpectedError } from 'src/domain'

export class RemoteExportClientExtractService implements ExportClientExtractUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlClientPlaceholder: string
  ) {}

  async exportClientExtract(client: number, { startDate, endDate, format }: ExportClientExtractUseCase.Params) {
    const url = this.url.replace(this.urlClientPlaceholder, String(client))

    const response = await this.httpClient.request({
      method: 'get',
      url: `${url}/${format}`,
      params: { start_date: startDate, end_date: endDate },
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ExportExtractModel.fromJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
