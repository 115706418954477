import { RemoteFetchBrazilianAddressByZipcodeService, RemoteFetchPortugueseAddressByZipcodeService } from 'src/data'

import { FetchAddressByZipcodeUseCase } from 'src/domain'

import { makeAxiosHttpClient, Urls } from 'src/main'

export const makeFetchAddressByZipcodeUseCase = (language: 'BR' | 'PT'): FetchAddressByZipcodeUseCase => {
  if (language === 'BR') {
    const httpAdapter = makeAxiosHttpClient(Urls.ADDRESS_BASE_URL)

    return new RemoteFetchBrazilianAddressByZipcodeService(httpAdapter, '/locations')
  }

  if (language === 'PT') {
    const httpAdapter = makeAxiosHttpClient(Urls.PT_ADDRESS_BASE_URL)

    return new RemoteFetchPortugueseAddressByZipcodeService(httpAdapter, '/codigo_postal')
  }

  throw new Error('Invalid language')
}
