import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaUserCircle } from 'react-icons/fa'
import { MdAttachMoney, MdEdit, MdPayment } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { Link, useHistory, useParams } from 'react-router-dom'

import { OrderEntity } from 'src/domain'

import { Breadcrumb, CurrencyInput, Input, TextArea } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useLanguage, useToast } from 'src/presentation/hooks'
import {
  billingModeMapper,
  firstBillingMonthMapper,
  billingModalityMapper,
  paymentFormMapper,
} from 'src/presentation/mappers'
import { orderActions } from 'src/presentation/store/ducks/order'
import { sellersActions } from 'src/presentation/store/ducks/sellers'

interface Params {
  orderId: string
}

const OpenOrderDetailsPage: React.FC = () => {
  const { orderId } = useParams<Params>()
  const { push } = useHistory()

  const { throwToast } = useToast()

  const dispatch = useAppDispatch()
  const { loading: orderLoading, currentOpenOrder, fetchOpenOrderError } = useAppSelector(state => state.order)
  const { franchisees } = useAppSelector(state => state.franchisees)
  const { loading: sellersLoading, sellers } = useAppSelector(state => state.sellers)

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const loading = orderLoading || sellersLoading

  useEffect(() => {
    document.title = `${t('ORDER_OPENED_WITH_PARAM', { orderId })} - PDV`
  }, [orderId])

  useEffect(() => {
    if (fetchOpenOrderError) {
      throwToast({ message: t('UNABLE_TO_FETCH_OPEN_ORDER') })
    }

    return () => {
      dispatch(orderActions.fetchOpenOrderError())
    }
  }, [dispatch, fetchOpenOrderError])

  useEffect(() => {
    dispatch(orderActions.fetchOpenOrder({ order: orderId }))

    !sellers?.length && dispatch(sellersActions.fetchSellers())
  }, [dispatch, orderId])

  useEffect(() => {
    if (currentOpenOrder && currentOpenOrder?.status === OrderEntity.Status.CLOSE) {
      throwToast({ message: t('ORDER_ALREADY_COMPLETED_WITH_PARAM', { orderId: currentOpenOrder?.id || '' }) })

      push('/open-orders')

      dispatch(orderActions.clearCurrentOrders())
    }
    if (currentOpenOrder && currentOpenOrder?.status === OrderEntity.Status.CANCEL) {
      throwToast({ message: t('ORDER_IS_CANCELED_WITH_PARAM', { orderId: currentOpenOrder?.id || '' }) })

      push('/canceled-orders')

      dispatch(orderActions.clearCurrentOrders())
    }
  }, [currentOpenOrder, currentOpenOrder?.id, currentOpenOrder?.status, dispatch, push])

  const currentOpenOrderCreditPrice =
    currentOpenOrder?.credits && currentOpenOrder?.price
      ? (Number(currentOpenOrder?.price) / (Number(currentOpenOrder?.credits) / 100)).toFixed(2)
      : 0

  const lng =
    franchisees.find(franchisee => franchisee.id === currentOpenOrder?.franchiseeId)?.country || currentLanguage

  return (
    <div data-cy="open-order-details-page" className="w-full p-6">
      <Breadcrumb
        previousPageName={t('OPEN_ORDERS', { lng })}
        previousPageUrl="/open-orders"
        currentPageName={orderId}
      />

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-6 border-b-2 border-senary">
          <h2 className="inline-block text-base font-semibold text-black lg:text-lg">
            <Trans i18nKey="SALE_WITH_PARAM" values={{ orderId }} tOptions={{ lng }} />
          </h2>

          <Link to={`/open-orders/${orderId}/edit`} className="inline-block">
            <MdEdit className="transition-colors text-primary hover:text-secundary" size={26} />
          </Link>
        </div>

        <form className="w-full" onSubmit={() => null}>
          <div className="flex items-center justify-start mb-6">
            <FaUserCircle className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_IDENTIFICATION" tOptions={{ lng }} />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input
                name="client"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.name}
                loading={loading}
                label={t('CLIENT', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="user"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.userId}
                loading={loading}
                label={t('USER', { lng })}
              />
            </div>

            {currentOpenOrder?.client?.legalPersonInfo?.cnpj ? (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input
                  name="cnpj"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.legalPersonInfo?.cnpj}
                  loading={loading}
                  label={t('CNPJ', { lng })}
                />
              </div>
            ) : (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input
                  name="cpf"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.naturalPersonInfo?.cpf}
                  loading={loading}
                  label={t('CPF', { lng })}
                />
              </div>
            )}
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="zipcode"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.zipcode}
                  loading={loading}
                  label={t('ZIP_CODE', { lng })}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="state"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.state}
                  loading={loading}
                  label={t('STATE', { lng })}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="city"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.city}
                  loading={loading}
                  label={t('CITY', { lng })}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <Input
                  name="neighborhood"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.neighborhood}
                  loading={loading}
                  label={t('NEIGHBORHOOD', { lng })}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
              <Input
                name="street"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.street}
                loading={loading}
                label={t('STREET', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="number"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.number}
                loading={loading}
                label={t('NUMBER', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                name="complement"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.complement}
                loading={loading}
                label={t('COMPLEMENT', { lng })}
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 mb-4 mr-4 lg:mr-8">
              <Input
                name="email"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.contactInfo?.email}
                loading={loading}
                label={t('EMAIL', { lng })}
              />
            </div>

            <div className="w-1/2 mb-4">
              <Input
                name="phone"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.contactInfo?.phone}
                loading={loading}
                label={t('PHONE', { lng })}
              />
            </div>
          </div>

          {currentOpenOrder?.client?.legalPersonInfo?.cnpj && (
            <>
              <div className="flex items-center justify-start mt-6 mb-6">
                <RiUserSettingsLine className="mr-2 text-black" size={26} />

                <h3 className="text-sm font-semibold lg:text-base">
                  <Trans i18nKey="RESPONSIBLE_PERSON" tOptions={{ lng }} />
                </h3>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
                  <Input
                    name="responsibleName"
                    disabled
                    value={currentOpenOrder?.id && currentOpenOrder?.client?.responsible?.name}
                    loading={loading}
                    label={t('NAME', { lng })}
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
                  <Input
                    name="responsibleEmail"
                    disabled
                    value={currentOpenOrder?.id && currentOpenOrder?.client?.responsible?.email}
                    loading={loading}
                    label={t('EMAIL', { lng })}
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                  <Input
                    name="responsiblePhone"
                    disabled
                    value={currentOpenOrder?.id && currentOpenOrder?.client?.responsible?.phone}
                    loading={loading}
                    label={t('PHONE', { lng })}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdAttachMoney className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="SALE" tOptions={{ lng }} />
            </h3>
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="seller"
                  disabled
                  value={(currentOpenOrder?.id && currentOpenOrder?.seller?.name) || t('NOT_REGISTERED')}
                  loading={loading}
                  label={t('SELLER', { lng })}
                />
              </div>
            </div>

            {currentOpenOrder?.origin === OrderEntity.Origin.AUTO_BILLING_EXECUTION && (
              <div className="flex w-full lg:w-1/2">
                <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                  <p className="block mb-2 overflow-hidden text-xs font-semibold overflow-ellipsis whitespace-nowrap md:text-sm">
                    <Trans i18nKey="EXTRACT" tOptions={{ lng }} />
                  </p>

                  {currentOpenOrder?.autoBillingExecution?.summaryUrl ? (
                    <a
                      href={currentOpenOrder?.autoBillingExecution?.summaryUrl}
                      className="block text-primary underline text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Trans i18nKey="DOWNLOAD_LINK" tOptions={{ lng }} />
                    </a>
                  ) : (
                    <p className="block text-black text-sm">
                      <Trans i18nKey="NOT_AVAILABLE" tOptions={{ lng }} />
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <CurrencyInput
                  name="credits"
                  disabled
                  value={currentOpenOrder?.credits ? currentOpenOrder?.credits / 100 : 0}
                  loading={loading}
                  label={t('CREDIT_QUANTITY', { lng })}
                  prefix=" "
                  lng={lng}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <CurrencyInput
                  name="creditPrice"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrderCreditPrice}
                  loading={loading}
                  label={t('CREDIT_PRICE', { lng })}
                  lng={lng}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <CurrencyInput
                  name="price"
                  disabled
                  value={currentOpenOrder?.price ? currentOpenOrder?.price : 0}
                  loading={loading}
                  label={t('TOTAL_AMOUNT', { lng })}
                  lng={lng}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdPayment className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="PAYMENT" tOptions={{ lng }} />
            </h3>
          </div>

          <div className="flex mb-0 lg:mb-6">
            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Input
                name="billingModality"
                disabled
                value={
                  currentOpenOrder?.id && currentOpenOrder?.billingModality
                    ? billingModalityMapper(currentLanguage, currentOpenOrder?.billingModality)
                    : ''
                }
                loading={loading}
                label={t('MODALITY', { lng })}
              />
            </div>

            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Input
                name="billingMode"
                disabled
                value={
                  currentOpenOrder?.id && currentOpenOrder?.billingMode
                    ? billingModeMapper(currentLanguage, currentOpenOrder?.billingMode)
                    : ''
                }
                loading={loading}
                label={t('PAYMENT', { lng })}
              />
            </div>

            <div className="w-1/3 mb-4">
              <Input
                name="numberOfInstallments"
                disabled
                value={
                  currentOpenOrder?.id && currentOpenOrder?.numberOfInstallments
                    ? `${currentOpenOrder?.numberOfInstallments}x`
                    : ''
                }
                loading={loading}
                label={t('INSTALLMENT', { lng })}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="sm:flex-1 sm:w-1/2 w-full mb-4 sm:mr-4 lg:mr-8">
              <Input
                name="paymentForm"
                disabled
                value={
                  currentOpenOrder?.id && currentOpenOrder?.paymentForm
                    ? paymentFormMapper(currentLanguage, currentOpenOrder?.paymentForm)
                    : ''
                }
                loading={loading}
                label={t('PAYMENT_METHOD', { lng })}
              />
            </div>

            <div className="sm:flex-1 sm:w-1/2 w-full mb-4 sm:mr-4 lg:mr-8">
              <Input
                name="firstBillingMonth"
                disabled
                value={
                  currentOpenOrder?.id && currentOpenOrder?.firstBillingMonth
                    ? firstBillingMonthMapper(currentLanguage, currentOpenOrder?.firstBillingMonth)
                    : ''
                }
                loading={loading}
                label={t('FIRST_DUE_DATE', { lng })}
              />
            </div>

            <div className="sm:flex-1 sm:w-1/2 w-full mb-4">
              <Input
                name="billingDay"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.billingDay}
                loading={loading}
                label={t('DUE_DATE_BOLETO', { lng })}
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-full mb-4">
              <TextArea
                name="paymentDetails"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.paymentDetails ? currentOpenOrder?.paymentDetails : ''}
                loading={loading}
                label={t('PAYMENT_DETAILS', { lng })}
              />
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}

export default OpenOrderDetailsPage
