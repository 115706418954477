import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderEntity, OrdersPagination } from 'src/domain'

import { makeFetchCompletedOrdersUseCase, makeFetchOpenOrdersUseCase, makeFetchCanceledOrdersUseCase } from 'src/main'

import { AppState } from 'src/presentation/store'
import { headerSearchActions } from 'src/presentation/store/ducks/headerSearch'

const ORDERS_PER_PAGE = 15

interface FetchOrdersParams {
  page: number | null
  franchisee: number | null
  search: string | null
}

const fetchOpenOrders = createAsyncThunk(
  '@orders/fetchOpenOrders',
  async ({ franchisee, page, search }: FetchOrdersParams, { dispatch, getState }) => {
    page ? dispatch(ordersActions.loadingPagination()) : dispatch(ordersActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee || !!search))

    const fetchOpenOrdersUseCase = makeFetchOpenOrdersUseCase()

    const pagination: OrdersPagination = {
      limit: ORDERS_PER_PAGE,
      offset: page ? page * ORDERS_PER_PAGE : undefined,
      orderBy: 'created_at',
      orderByDir: 'desc',
    }

    const fetchedOpenOrders = await fetchOpenOrdersUseCase.fetchOpenOrders(
      pagination,
      franchisee || undefined,
      search || undefined
    )

    const {
      orders: { openOrders: existingOpenOrders },
    } = getState() as AppState

    dispatch(ordersActions.hasMoreOrders(fetchedOpenOrders.length === ORDERS_PER_PAGE))

    return page ? [...existingOpenOrders, ...fetchedOpenOrders] : fetchedOpenOrders
  }
)

const fetchCompletedOrders = createAsyncThunk(
  '@orders/fetchCompletedOrders',
  async ({ franchisee, page, search }: FetchOrdersParams, { dispatch, getState }) => {
    page ? dispatch(ordersActions.loadingPagination()) : dispatch(ordersActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee || !!search))

    const fetchCompletedOrdersUseCase = makeFetchCompletedOrdersUseCase()

    const pagination: OrdersPagination = {
      limit: ORDERS_PER_PAGE,
      offset: page ? page * ORDERS_PER_PAGE : undefined,
      orderBy: 'created_at',
      orderByDir: 'desc',
    }

    const fetchedCompletedOrders = await fetchCompletedOrdersUseCase.fetchCompletedOrders(
      pagination,
      franchisee || undefined,
      search || undefined
    )

    const {
      orders: { completedOrders: existingCompletedOrders },
    } = getState() as AppState

    dispatch(ordersActions.hasMoreOrders(fetchedCompletedOrders.length === ORDERS_PER_PAGE))

    return page ? [...existingCompletedOrders, ...fetchedCompletedOrders] : fetchedCompletedOrders
  }
)

const fetchCanceledOrders = createAsyncThunk(
  '@orders/fetchCanceledOrders',
  async ({ franchisee, page, search }: FetchOrdersParams, { dispatch, getState }) => {
    page ? dispatch(ordersActions.loadingPagination()) : dispatch(ordersActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee || !!search))

    const fetchCanceledOrdersUseCase = makeFetchCanceledOrdersUseCase()

    const pagination: OrdersPagination = {
      limit: ORDERS_PER_PAGE,
      offset: page ? page * ORDERS_PER_PAGE : undefined,
      orderBy: 'created_at',
      orderByDir: 'desc',
    }

    const fetchedCanceledOrders = await fetchCanceledOrdersUseCase.fetchCanceledOrders(
      pagination,
      franchisee || undefined,
      search || undefined
    )

    const {
      orders: { canceledOrders: existingCanceledOrders },
    } = getState() as AppState

    dispatch(ordersActions.hasMoreOrders(fetchedCanceledOrders.length === ORDERS_PER_PAGE))

    return page ? [...existingCanceledOrders, ...fetchedCanceledOrders] : fetchedCanceledOrders
  }
)

interface OrdersState {
  openOrders: OrderEntity.Entity[]
  completedOrders: OrderEntity.Entity[]
  canceledOrders: OrderEntity.Entity[]
  loading: boolean
  loadingPagination: boolean
  hasMoreOrders: boolean
}

const initialState: OrdersState = {
  openOrders: [],
  completedOrders: [],
  canceledOrders: [],
  loading: false,
  loadingPagination: false,
  hasMoreOrders: false,
}

export const ordersSlice = createSlice({
  name: '@orders',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
    },

    loadingPagination: state => {
      state.loadingPagination = true
    },

    hasMoreOrders: (state, action: PayloadAction<boolean>) => {
      state.hasMoreOrders = action.payload
    },

    clearOpenOrders: state => {
      state.openOrders = []
      state.hasMoreOrders = false
    },

    clearCompletedOrders: state => {
      state.completedOrders = []
      state.hasMoreOrders = false
    },

    clearCanceledOrders: state => {
      state.canceledOrders = []
      state.hasMoreOrders = false
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOpenOrders.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.openOrders = action.payload || []
    })

    builder.addCase(fetchOpenOrders.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreOrders = false
    })

    builder.addCase(fetchCompletedOrders.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.completedOrders = action.payload || []
    })

    builder.addCase(fetchCompletedOrders.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreOrders = false
    })

    builder.addCase(fetchCanceledOrders.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.canceledOrders = action.payload || []
    })

    builder.addCase(fetchCanceledOrders.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreOrders = false
    })
  },
})

export const ordersActions = { ...ordersSlice.actions, fetchOpenOrders, fetchCompletedOrders, fetchCanceledOrders }

export const ordersReducer = ordersSlice.reducer
