import { HttpClient, HttpStatusCode, UpdateClientSignatureMapper } from 'src/data'

import { UnexpectedError, UpdateClientSignatureUseCase } from 'src/domain'

export class RemoteUpdateClientSignatureService implements UpdateClientSignatureUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlClientPlaceholder: string
  ) {}

  async updateClientSignature(client: number, value: number) {
    const parsedUrl = this.url.replace(this.urlClientPlaceholder, String(client))

    const response = await this.httpClient.request({
      method: 'post',
      url: parsedUrl,
      body: UpdateClientSignatureMapper.toJson(value),
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return

      default:
        throw new UnexpectedError()
    }
  }
}
