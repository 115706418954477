import { RemoteFetchClientIzeeService } from 'src/data'

import { FetchClientIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientIzeeUseCase = (): FetchClientIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchClientIzeeService(authorizedHttpAdapter, '/companies/')
}
