import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BsFillChatFill } from 'react-icons/bs'
import { FaMapMarkerAlt, FaUser, FaUserPlus } from 'react-icons/fa'
import { IoIosPaper, IoIosWallet } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'

import { useFormik } from 'formik'

import {
  CityEntity,
  ClientDetailsEntity,
  CommercialResponsibleEntity,
  CreateOrUpdateClientPayloadError,
  CreateOrUpdateClientUseCase,
  FetchCommercialResponsiblesUseCase,
  FetchClientByCnpjUseCase,
} from 'src/domain'

import { makeFetchAddressByZipcodeUseCase, makeFetchCitiesByStateUseCase } from 'src/main'

import { Breadcrumb, Button, Input, Select } from 'src/presentation/components'
import {
  formLabelClasses,
  masks,
  masksByCountry as masksByCountryObject,
  states as statesObject,
} from 'src/presentation/constants'
import { useAppDispatch, useAppSelector, useLanguage, useToast } from 'src/presentation/hooks'
import {
  assignmentEmailRuleMapper,
  billingModalityMapper,
  classificationMapper,
  createOrUpdateClientPayloadErrorMapper,
  originMapper,
} from 'src/presentation/mappers'
import { billingModalityPanelMapper } from 'src/presentation/mappers/BillingModalityPanelMapper'
import { clientActions } from 'src/presentation/store/ducks/client'
import { uiActions } from 'src/presentation/store/ducks/ui'

import { FieldValidator, InvalidFieldError } from 'src/validation'

interface Props {
  action: 'create' | 'edit' | 'view'
  cnpjValidator: FieldValidator
  fetchCommercialResponsiblesUseCase: FetchCommercialResponsiblesUseCase
  fetchClientByCnpjUseCase: FetchClientByCnpjUseCase
}

interface Params {
  clientId: string
}

let formSubmitTimer: NodeJS.Timeout | null = null

const ClientCreateEditOrViewPage: React.FC<Props> = ({
  action,
  cnpjValidator,
  fetchCommercialResponsiblesUseCase,
  fetchClientByCnpjUseCase,
}) => {
  const [basicLegacyPlanAvailable, setBasicLegacyPlanAvailable] = useState(false)
  const [showBillingAddress, setShowBillingAddress] = useState(false)
  const [loadingFetchAddress, setLoadingFetchAddress] = useState(false)
  const [loadingFetchDocument, setLoadingFetchDocument] = useState(false)
  const [loadingFetchCities, setLoadingFetchCities] = useState(false)
  const [loadingFetchBillingAddress, setLoadingFetchBillingAddress] = useState(false)
  const [loadingFetchBillingCities, setLoadingFetchBillingCities] = useState(false)
  const [commercialResponsibles, setCommercialResponsibles] = useState<CommercialResponsibleEntity[]>([])

  const [states, setStates] = useState(statesObject['BR'])
  const [cities, setCities] = useState<CityEntity[]>([])
  const [billingCities, setBillingCities] = useState<CityEntity[]>([])

  const [stateFieldHasChangedManually, setStateFieldHasChangedManually] = useState(false)
  const [billingStateFieldHasChangedManually, setBillingStateFieldHasChangedManually] = useState(false)

  const [lng, setLng] = useState<'BR' | 'PT'>('BR')
  const [masksByCountry, setMasksByCountry] = useState(masksByCountryObject['BR'])

  const { clientId } = useParams<Params>()
  const { push } = useHistory()

  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { franchisees, loading: loadingFranchisees } = useAppSelector(state => state.franchisees)
  const {
    client,
    loading: loadingClient,
    createClientError,
    createClientSuccess,
    updateClientError,
    updateClientSuccess,
  } = useAppSelector(state => state.client)

  const { t } = useTranslation()

  const { throwToast } = useToast()
  const { currentLanguage: panelLanguage } = useLanguage()

  const fetchAddressByZipcodeUseCase = useMemo(() => makeFetchAddressByZipcodeUseCase(lng), [lng])
  const fetchCitiesByStateUseCase = useMemo(() => makeFetchCitiesByStateUseCase(lng), [lng])

  const isFieldDisabled = useMemo(() => {
    if (action === 'create' || action === 'edit') return false

    if (action === 'view' || !client?.id) return true

    return true
  }, [action, client])

  let canRegisterMaintenancesDefaultValue = 'true'

  if (action === 'edit' || action === 'view') {
    canRegisterMaintenancesDefaultValue = String(
      typeof client?.hotsiteConfiguration?.canRegisterMaintenances === 'boolean'
        ? client?.hotsiteConfiguration?.canRegisterMaintenances
        : true
    )
  }

  const {
    values: formValues,
    errors: formErrors,
    isValid,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: action === 'edit' || action === 'view' ? client?.id : '',
      name: action === 'edit' || action === 'view' ? client?.name : '',
      username: action === 'edit' || action === 'view' ? client?.username : '',
      auth: {
        franchisee: action === 'edit' || action === 'view' ? client?.auth?.franchisee : '',
        clientId: action === 'edit' || action === 'view' ? client?.auth?.clientId : '',
        username: action === 'edit' || action === 'view' ? client?.auth?.username : '',
      },
      billingModality: action === 'edit' || action === 'view' ? client?.billingModality : '',
      modality: action === 'edit' || action === 'view' ? client?.modality : '',
      searchTokens: action === 'edit' || action === 'view' ? client?.searchTokens : '',
      accountId: action === 'edit' || action === 'view' ? client?.accountId : '',
      approvedAt: action === 'edit' || action === 'view' ? client?.approvedAt : '',
      creatorClientId: action === 'edit' || action === 'view' ? client?.creatorClientId : '',
      type:
        action === 'edit' || action === 'view'
          ? client?.type || ClientDetailsEntity.Type.LEGAL_PERSON
          : ClientDetailsEntity.Type.LEGAL_PERSON,
      acceptedTerms: action === 'edit' || action === 'view' ? client?.acceptedTerms : '',
      category: action === 'edit' || action === 'view' ? client?.category : '',
      hotsiteConfiguration: {
        ccEmail: action === 'edit' || action === 'view' ? client?.hotsiteConfiguration?.ccEmail : '',
        expirationTime: action === 'edit' || action === 'view' ? client?.hotsiteConfiguration?.expirationTime : '',
        canRegisterMaintenances: canRegisterMaintenancesDefaultValue,
      },
      contact: {
        email: action === 'edit' || action === 'view' ? client?.contact?.email : '',
        cellphone: action === 'edit' || action === 'view' ? client?.contact?.cellphone : '',
        phone: action === 'edit' || action === 'view' ? client?.contact?.phone : '',
        website: action === 'edit' || action === 'view' ? client?.contact?.website : '',
        assignmentEmail: action === 'edit' || action === 'view' ? client?.contact?.assignmentEmail : '',
        assignmentEmailRule:
          action === 'edit' || action === 'view'
            ? client?.contact?.assignmentEmailRule
            : ClientDetailsEntity.AssignmentEmailRule.CUSTOM,
      },
      address: {
        city: action === 'edit' || action === 'view' ? client?.address?.city : '',
        cityId: action === 'edit' || action === 'view' ? client?.address?.cityId : '',
        complement: action === 'edit' || action === 'view' ? client?.address?.complement : '',
        neighborhood: action === 'edit' || action === 'view' ? client?.address?.neighborhood : '',
        state: action === 'edit' || action === 'view' ? client?.address?.state : '',
        stateId: action === 'edit' || action === 'view' ? client?.address?.stateId : '',
        street: action === 'edit' || action === 'view' ? client?.address?.street : '',
        zipcode: action === 'edit' || action === 'view' ? client?.address?.zipcode : '',
        number: action === 'edit' || action === 'view' ? client?.address?.number : '',
      },
      invalidAddress: action === 'edit' || action === 'view' ? client?.invalidAddress : '',
      status: action === 'edit' || action === 'view' ? client?.status : '',
      document: action === 'edit' || action === 'view' ? client?.document : '',
      clientType:
        action === 'edit' || action === 'view'
          ? client?.clientType || ClientDetailsEntity.ClientType.PJ
          : ClientDetailsEntity.ClientType.PJ,
      companyName: action === 'edit' || action === 'view' ? client?.companyName : '',
      responsible: {
        name: action === 'edit' || action === 'view' ? client?.responsible?.name : '',
        document: action === 'edit' || action === 'view' ? client?.responsible?.document : '',
        phone: action === 'edit' || action === 'view' ? client?.responsible?.phone : '',
        cellphone: action === 'edit' || action === 'view' ? client?.responsible?.cellphone : '',
        email: action === 'edit' || action === 'view' ? client?.responsible?.email : '',
      },
      billingAddress: {
        city: action === 'edit' || action === 'view' ? client?.billingAddress?.city : undefined,
        cityId: action === 'edit' || action === 'view' ? client?.billingAddress?.cityId : undefined,
        complement: action === 'edit' || action === 'view' ? client?.billingAddress?.complement : undefined,
        neighborhood: action === 'edit' || action === 'view' ? client?.billingAddress?.neighborhood : undefined,
        state: action === 'edit' || action === 'view' ? client?.billingAddress?.state : undefined,
        stateId: action === 'edit' || action === 'view' ? client?.billingAddress?.stateId : undefined,
        street: action === 'edit' || action === 'view' ? client?.billingAddress?.street : undefined,
        zipcode: action === 'edit' || action === 'view' ? client?.billingAddress?.zipcode : undefined,
        number: action === 'edit' || action === 'view' ? client?.billingAddress?.number : undefined,
      },
      classification:
        action === 'edit' || action === 'view' ? client?.classification : ClientDetailsEntity.Classification.CONDOMINIO,
      dashboardUri: action === 'edit' || action === 'view' ? client?.dashboardUri : '',
      franchiseeId: action === 'edit' || action === 'view' ? client?.franchiseeId : '',
      responsibleCommercialUserId: action === 'edit' || action === 'view' ? client?.responsibleCommercialUserId : '',
      propertiesWallet: {
        potentialInspections:
          action === 'edit' || action === 'view' ? client?.propertiesWallet?.potentialInspections || 0 : 0,
        propertiesPurchaseSell:
          action === 'edit' || action === 'view' ? client?.propertiesWallet?.propertiesPurchaseSell || 0 : 0,
        propertiesRent: action === 'edit' || action === 'view' ? client?.propertiesWallet?.propertiesRent || 0 : 0,
      },
      billingContact: action === 'edit' || action === 'view' ? client?.billingContact : '',
      billingEmail: action === 'edit' || action === 'view' ? client?.billingEmail : '',
      billingPhone: action === 'edit' || action === 'view' ? client?.billingPhone : '',
    },
    onSubmit: (submitValues, { setSubmitting }) => {
      async function handleSubmit() {
        const payload = { ...submitValues } as CreateOrUpdateClientUseCase.Params

        try {
          payload.type =
            payload.clientType === ClientDetailsEntity.ClientType.PF
              ? ClientDetailsEntity.Type.NATURAL_PERSON
              : ClientDetailsEntity.Type.LEGAL_PERSON

          payload.hotsiteConfiguration.canRegisterMaintenances =
            payload.hotsiteConfiguration.canRegisterMaintenances === 'true'

          const addressAndBillingAddressAreTheSame = !showBillingAddress

          if (addressAndBillingAddressAreTheSame) {
            payload.billingAddress = {}
          }

          if (action === 'create') {
            if (submitValues.clientType === ClientDetailsEntity.ClientType.PJ && lng === 'BR') {
              await cnpjValidator.validate({ cnpj: submitValues.document })
            }

            dispatch(clientActions.createClient({ data: payload }))
          } else if (action === 'edit' && clientId) {
            delete payload.franchiseeId

            dispatch(clientActions.updateClient({ client: Number(clientId), data: payload }))
          }
        } catch (err) {
          if (err instanceof InvalidFieldError) {
            throwToast({ message: t('INVALID_FIELD_WITH_PARAM', { field: t('CNPJ', { lng }), lng }) })
          } else {
            throwToast({ message: t('UNABLE_TO_REGISTER_UPDATE_CLIENT', { lng }) })
          }
        } finally {
          formSubmitTimer = setTimeout(() => setSubmitting(false), 3000)
        }
      }

      handleSubmit()
    },
  })

  const fetchCommercialResponsibles = async (franchiseeId: number) => {
    const commercialResponsibles = await fetchCommercialResponsiblesUseCase.fetchCommercialResponsibles(franchiseeId)

    setCommercialResponsibles(commercialResponsibles)
  }

  const handleFranchiseeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    handleChange(event)

    const clientFranchisee = franchisees.find(franchisee => franchisee.id === Number(event.target.value))
    const lng = clientFranchisee?.country || panelLanguage

    setLng(lng)
    setMasksByCountry(masksByCountryObject[lng])
    setStates(statesObject[lng])
    setCities([])
    setBillingCities([])
  }

  const handleCnpjChange = async (cnpj?: string) => {
    if (!cnpj || cnpj?.length < 18) return

    setLoadingFetchDocument(true)

    try {
      const formattedCnpj = String(cnpj).replace(/[^\d]+/g, '')
      const fetchedClient = await fetchClientByCnpjUseCase.fetchClientByCnpj(formattedCnpj)

      let phoneFormatted = ''

      if (fetchedClient?.firstPhone?.length === 11) {
        phoneFormatted = `(${fetchedClient?.firstPhone.slice(0, 2)}) ${fetchedClient?.firstPhone.slice(
          2,
          7
        )}-${fetchedClient?.firstPhone.slice(7)}`
      }

      if (fetchedClient?.firstPhone?.length === 10) {
        phoneFormatted = `(${fetchedClient?.firstPhone.slice(0, 2)}) ${fetchedClient?.firstPhone.slice(
          2,
          6
        )}-${fetchedClient?.firstPhone.slice(6)}`
      }

      const formattedZipcode = fetchedClient?.cep?.replace(/(\d{5})(\d)/, '$1-$2')

      setFieldValue('address.zipcode', formattedZipcode)
      setFieldValue('address.number', fetchedClient?.number || '')
      setFieldValue('address.complement', fetchedClient?.complement || '')
      setFieldValue('companyName', fetchedClient?.legalName || '')
      setFieldValue('name', fetchedClient?.companyName || '')
      setFieldValue('hotsiteConfiguration.ccEmail', fetchedClient?.email || '')
      setFieldValue('contact.email', fetchedClient?.email || '')
      setFieldValue('contact.phone', phoneFormatted || '')
    } catch {
      throwToast({ message: t('CNPJ_NOT_FOUND', { lng }), type: 'warning' })

      setFieldValue('address.zipcode', '')
      setFieldValue('address.stateId', '')
      setFieldValue('address.cityId', '')
      setFieldValue('address.neighborhood', '')
      setFieldValue('address.street', '')
      setFieldValue('address.number', '')
      setFieldValue('address.complement', '')
      setFieldValue('companyName', '')
      setFieldValue('name', '')
      setFieldValue('hotsiteConfiguration.ccEmail', '')
      setFieldValue('contact.email', '')
      setFieldValue('contact.phone', '')
    } finally {
      setLoadingFetchDocument(false)
    }
  }

  const handleZipcodeChange = async (
    addressType: 'address' | 'billingAddress',
    setLoading: Function,
    setStateFieldHasChangedManually: Function,
    zipcode?: string
  ) => {
    if (!zipcode) return

    const preparedZipcode = fetchAddressByZipcodeUseCase.validateAndPrepareZipcode(zipcode)

    if (!preparedZipcode) return

    setLoading(true)

    try {
      const fetchedAddress = await fetchAddressByZipcodeUseCase.fetchAddressByZipcode(preparedZipcode)

      setStateFieldHasChangedManually(false)

      setFieldValue(`${addressType}.state`, fetchedAddress?.state)
      setFieldValue(`${addressType}.stateId`, fetchedAddress?.stateId)
      setFieldValue(`${addressType}.city`, fetchedAddress?.city)
      setFieldValue(`${addressType}.cityId`, fetchedAddress?.cityId)
      setFieldValue(`${addressType}.neighborhood`, fetchedAddress?.neighborhood)
      setFieldValue(`${addressType}.street`, fetchedAddress?.street)
    } catch {
      setFieldError(`${addressType}.zipcode`, t('ERROR_FETCHING_ADDRESS', { lng }))
    } finally {
      setLoading(false)
    }
  }

  const handleStateChange = async (
    addressType: 'address' | 'billingAddress',
    setLoading: Function,
    setCities: Function,
    stateFieldHasChangedManually: boolean,
    stateId?: number | string
  ) => {
    if (!stateId) return

    setLoading(true)

    try {
      const selectedState = states.find(state => state.id === stateId)
      const fetchedCities = await fetchCitiesByStateUseCase.fetchCitiesByState(stateId)

      setFieldValue(`${addressType}.state`, selectedState?.name)
      setCities(fetchedCities)

      if (stateFieldHasChangedManually) {
        const [firstCity] = fetchedCities

        setFieldValue(`${addressType}.city`, firstCity?.name)
        setFieldValue(`${addressType}.cityId`, firstCity?.id)
      }
    } catch {
      setFieldError(`${addressType}.cityId`, t('ERROR_FETCHING_CITIES', { lng }))
    } finally {
      setLoading(false)
    }
  }

  const handleCityChange = (addressType: 'address' | 'billingAddress', cityId?: number | string) => {
    if (!cityId) return

    const selectedCity = cities.find(city => city.id === cityId)

    if (selectedCity) setFieldValue(`${addressType}.city`, selectedCity?.name)
  }

  useEffect(() => {
    if (action === 'create') setFieldValue('responsibleCommercialUserId', '')

    if (formValues?.franchiseeId && action !== 'view') {
      fetchCommercialResponsibles(Number(formValues?.franchiseeId))
    }

    setBasicLegacyPlanAvailable(
      formValues?.modality == ClientDetailsEntity.BillingModalityPanel.BASIC_LEGACY_PLAN ||
        (!!formValues?.franchiseeId &&
          franchisees.find(franchisee => franchisee?.id === Number(formValues?.franchiseeId))?.type == 'OFFICE')
    )
  }, [action, formValues?.franchiseeId, franchisees])

  useEffect(() => {
    if (action === 'view' || action === 'edit') {
      const clientFranchisee = franchisees.find(franchisee => franchisee.id === client?.franchiseeId)

      setLng(clientFranchisee?.country || panelLanguage)
    }

    if (action === 'create') setLng(panelLanguage)
  }, [action, client?.franchiseeId, franchisees])

  useEffect(() => {
    setShowBillingAddress(
      !!(
        client?.billingAddress?.city ||
        client?.billingAddress?.complement ||
        client?.billingAddress?.neighborhood ||
        client?.billingAddress?.state ||
        client?.billingAddress?.street ||
        client?.billingAddress?.zipcode ||
        client?.billingAddress?.number
      )
    )
  }, [client?.billingAddress])

  useEffect(() => {
    if (action === 'create' && lng === 'BR') handleCnpjChange(formValues?.document)
  }, [action, formValues?.document])

  useEffect(() => {
    handleZipcodeChange(
      'address',
      setLoadingFetchAddress,
      setStateFieldHasChangedManually,
      formValues?.address?.zipcode
    )
  }, [formValues?.address?.zipcode])

  useEffect(() => {
    handleZipcodeChange(
      'billingAddress',
      setLoadingFetchBillingAddress,
      setBillingStateFieldHasChangedManually,
      formValues?.billingAddress?.zipcode
    )
  }, [formValues?.billingAddress?.zipcode])

  useEffect(() => {
    handleStateChange(
      'address',
      setLoadingFetchCities,
      setCities,
      stateFieldHasChangedManually,
      formValues?.address?.stateId
    )
  }, [formValues?.address?.stateId])

  useEffect(() => {
    handleStateChange(
      'billingAddress',
      setLoadingFetchBillingCities,
      setBillingCities,
      billingStateFieldHasChangedManually,
      formValues?.billingAddress?.stateId
    )
  }, [formValues?.billingAddress?.stateId])

  useEffect(() => {
    handleCityChange('address', formValues?.address?.cityId)
  }, [formValues?.address?.cityId])

  useEffect(() => {
    handleCityChange('billingAddress', formValues?.billingAddress?.cityId)
  }, [formValues?.billingAddress?.cityId])

  useEffect(() => {
    document.title =
      action === 'create'
        ? `${t('NEW_CLIENT', { lng })} - PDV`
        : `${t('CLIENT_WITH_PARAM', { clientId: clientId || '', lng })}  - PDV`
  }, [action, clientId])

  useEffect(() => {
    if (action === 'edit' || action === 'view') dispatch(clientActions.fetchClient({ client: Number(clientId) }))

    return () => {
      dispatch(clientActions.clearClient())

      clearTimeout(formSubmitTimer as NodeJS.Timeout)
    }
  }, [action, clientId, dispatch])

  useEffect(() => {
    if (createClientSuccess && !createClientError) {
      throwToast({ message: t('CLIENT_REGISTERED_SUCCESSFULLY', { lng }), type: 'success' })
      push('/clients')

      return
    }

    if (createClientError && !createClientSuccess) {
      throwToast({
        message:
          createClientError instanceof CreateOrUpdateClientPayloadError
            ? createOrUpdateClientPayloadErrorMapper(lng, createClientError.message)
            : t('UNABLE_TO_REGISTER_CLIENT', { lng }),
      })

      dispatch(clientActions.createClientError())
    }

    return () => {
      dispatch(clientActions.createClientSuccess())
      dispatch(clientActions.createClientError())
    }
  }, [dispatch, createClientError, createClientSuccess])

  useEffect(() => {
    if (updateClientSuccess && !updateClientError) {
      throwToast({ message: t('CLIENT_UPDATED_SUCCESSFULLY', { lng }), type: 'success' })
      push('/clients')

      return
    }

    if (updateClientError && !updateClientSuccess) {
      throwToast({
        message:
          updateClientError instanceof CreateOrUpdateClientPayloadError
            ? createOrUpdateClientPayloadErrorMapper(lng, updateClientError.message)
            : t('UNABLE_TO_UPDATE_CLIENT', { lng }),
      })

      dispatch(clientActions.updateClientError())
    }

    return () => {
      dispatch(clientActions.updateClientSuccess())
      dispatch(clientActions.updateClientError())
    }
  }, [dispatch, updateClientError, updateClientSuccess])

  const isFormInvalidOrBlocked = !!(isSubmitting || !isValid || (action === 'edit' && !client?.id))

  return (
    <div data-cy="client-create-edit-or-view-page" className="relative w-full p-6">
      {(action === 'edit' || action === 'view') && (
        <Breadcrumb previousPageName={t('CLIENTS', { lng })} previousPageUrl="/clients" currentPageName={clientId} />
      )}

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex items-center justify-between mb-6 border-senary">
            <div className="flex items-center justify-start">
              <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
                <FaUserPlus className="ml-0.5 text-black" size={18} />
              </div>

              <h2 className="text-sm font-semibold lg:text-base">
                {action === 'edit' || action === 'view'
                  ? t('CLIENT_WITH_PARAM', { clientId: clientId || '', lng })
                  : t('NEW_CLIENT', { lng })}
              </h2>
            </div>

            {action === 'view' && (
              <Link to={`/clients/${clientId}/edit`} className="inline-block">
                <MdEdit className="transition-colors text-primary hover:text-secundary" size={26} />
              </Link>
            )}
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            {(action === 'edit' || action === 'view') && (
              <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
                <label className={formLabelClasses} htmlFor="origin">
                  <Trans i18nKey="ORIGIN" tOptions={{ lng }} />
                </label>

                <div className="relative p-0">
                  <Input
                    name="origin"
                    disabled
                    value={client?.id && originMapper(lng, client?.origin)}
                    loading={loadingClient}
                  />
                </div>
              </div>
            )}

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <label className={formLabelClasses} htmlFor="franchiseeId">
                <Trans i18nKey="FRANCHISE_UNIT" tOptions={{ lng }} />
              </label>

              <div className="relative">
                {action === 'create' ? (
                  <Select
                    name="franchiseeId"
                    onChange={handleFranchiseeChange}
                    value={formValues?.franchiseeId}
                    disabled={isFieldDisabled || loadingFranchisees}
                    loading={loadingClient}
                    error={formErrors?.franchiseeId}
                    required
                  >
                    <option value="" disabled>
                      {t('CHOOSE_FRANCHISE_UNIT', { lng })}
                    </option>

                    {!!franchisees.length &&
                      franchisees.map(franchisee => (
                        <option value={franchisee?.id} key={franchisee?.id}>
                          {franchisee?.name}
                        </option>
                      ))}
                  </Select>
                ) : (
                  <Input
                    name="franchisee"
                    type="text"
                    disabled
                    value={
                      client?.id &&
                      formValues?.franchiseeId &&
                      franchisees.find(franchisee => franchisee?.id === Number(formValues?.franchiseeId))?.name
                    }
                    loading={loadingClient}
                  />
                )}
              </div>
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <label className={formLabelClasses} htmlFor="clientTypeFields">
                <Trans i18nKey="CLIENT_TYPE" tOptions={{ lng }} />
              </label>

              <div
                id="clientTypeFields"
                className="relative flex flex-wrap items-center justify-start mt-4 text-xs gap-y-1 lg:flex-nowrap lg:text-sm xl:text-base"
              >
                <div className="sm:mr-6 md:mr-16">
                  <input
                    id="clientTypePj"
                    className="mr-2 cursor-pointer"
                    name="clientTypePj"
                    data-cy="clientTypePj"
                    type="radio"
                    onChange={() => setFieldValue('clientType', ClientDetailsEntity.ClientType.PJ)}
                    value={ClientDetailsEntity.ClientType.PJ}
                    disabled={isFieldDisabled || action === 'edit'}
                    checked={formValues?.clientType === ClientDetailsEntity.ClientType.PJ}
                  />

                  <label htmlFor="clientTypePj">
                    <Trans i18nKey="LEGAL_PERSON" tOptions={{ lng }} />
                  </label>
                </div>

                <div>
                  <input
                    id="clientTypePf"
                    className="mr-2 cursor-pointer"
                    name="clientTypePf"
                    data-cy="clientTypePf"
                    type="radio"
                    onChange={() => setFieldValue('clientType', ClientDetailsEntity.ClientType.PF)}
                    value={ClientDetailsEntity.ClientType.PF}
                    disabled={isFieldDisabled || action === 'edit'}
                    checked={formValues?.clientType === ClientDetailsEntity.ClientType.PF}
                  />

                  <label htmlFor="clientTypePf">
                    <Trans i18nKey="NATURAL_PERSON" tOptions={{ lng }} />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              {formValues?.clientType === ClientDetailsEntity.ClientType.PJ ? (
                <>
                  <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                    <Input
                      key={lng}
                      name="document"
                      disabled={isFieldDisabled || loadingFetchDocument || action === 'edit'}
                      value={formValues?.document}
                      onChange={handleChange}
                      loading={loadingClient}
                      error={formErrors?.document}
                      label={t('CNPJ', { lng })}
                      mask={masksByCountry.CNPJ_MASK}
                      required
                    />
                  </div>

                  <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                    <Input
                      name="companyName"
                      disabled={isFieldDisabled || action === 'edit'}
                      value={formValues?.companyName}
                      onChange={handleChange}
                      loading={loadingClient}
                      error={formErrors?.companyName}
                      label={t('LEGAL_ENTITY_NAME', { lng })}
                      required
                    />
                  </div>
                </>
              ) : (
                <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                  <Input
                    key={lng}
                    name="document"
                    disabled={isFieldDisabled || action === 'edit'}
                    value={formValues?.document}
                    onChange={handleChange}
                    loading={loadingClient}
                    error={formErrors?.document}
                    label={t('CPF', { lng })}
                    mask={masksByCountry.CPF_ONLY_MASK}
                    required
                  />
                </div>
              )}
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="name"
                  disabled={isFieldDisabled || action === 'edit'}
                  value={formValues?.name}
                  onChange={handleChange}
                  loading={loadingClient}
                  error={formErrors?.name}
                  label={
                    formValues?.clientType === ClientDetailsEntity.ClientType.PJ
                      ? t('LEGAL_NAME', { lng })
                      : t('NAME', { lng })
                  }
                  required
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <label className={formLabelClasses} htmlFor="classification">
                  <Trans i18nKey="CATEGORY" tOptions={{ lng }} />
                </label>

                <div className="relative">
                  {action === 'create' || action === 'edit' ? (
                    <Select
                      name="classification"
                      onChange={handleChange}
                      value={formValues?.classification}
                      disabled={isFieldDisabled}
                      loading={loadingClient}
                      error={formErrors?.classification}
                      required
                    >
                      <option value="" disabled>
                        {t('CHOOSE_CATEGORY', { lng })}
                      </option>

                      {Object.values(ClientDetailsEntity.Classification).map(classification => (
                        <option value={classification} key={classification}>
                          {classificationMapper(lng, classification)}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      name="classification"
                      disabled
                      value={client?.id && classificationMapper(lng, client?.classification)}
                      loading={loadingClient}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex mb-6">
            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Input
                name="username"
                disabled={isFieldDisabled || action === 'edit'}
                value={formValues?.username}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.username}
                label={t('USER', { lng })}
                mask={masks.USERNAME_MASK}
                required
              />
            </div>

            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Input
                name="contact.email"
                type="email"
                disabled={isFieldDisabled || action === 'edit'}
                value={formValues?.contact?.email}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.contact?.email}
                label={t('EMAIL', { lng })}
                required
              />
            </div>

            <div className="flex-1 w-1/3 mb-4">
              <label className={formLabelClasses} htmlFor="responsibleCommercialUserId">
                <Trans i18nKey="COMMERCIAL_RESPONSIBLE" tOptions={{ lng }} />
              </label>

              <div className="relative">
                {action === 'create' || action === 'edit' ? (
                  <Select
                    name="responsibleCommercialUserId"
                    onChange={handleChange}
                    value={formValues?.responsibleCommercialUserId}
                    disabled={isFieldDisabled || !formValues?.franchiseeId}
                    loading={loadingClient}
                    error={formErrors?.responsibleCommercialUserId}
                  >
                    <option value="">{t('CHOOSE_COMMERCIAL_RESPONSIBLE', { lng })}</option>

                    {!!commercialResponsibles.length &&
                      commercialResponsibles.map(commercialResponsible => (
                        <option value={commercialResponsible?.id} key={commercialResponsible?.id}>
                          {commercialResponsible?.username}
                        </option>
                      ))}
                  </Select>
                ) : (
                  <Input
                    name="responsibleCommercialUserId"
                    disabled
                    value={client?.responsibleCommercialUserName}
                    loading={loadingClient}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 mb-4 mr-4 lg:mr-8">
              {action === 'create' || action === 'edit' ? (
                <Select
                  name="billingModality"
                  onChange={handleChange}
                  value={formValues?.billingModality}
                  disabled={isFieldDisabled || !formValues?.franchiseeId}
                  loading={loadingClient}
                  error={formErrors?.billingModality}
                  label={t('BILLING_MODALITY', { lng })}
                  required
                >
                  <option value="" disabled>
                    {t('SELECT_MODALITY', { lng })}
                  </option>

                  <option value={ClientDetailsEntity.BillingModality.SEPARATE}>
                    {billingModalityMapper(lng, ClientDetailsEntity.BillingModality.SEPARATE)}
                  </option>

                  <option value={ClientDetailsEntity.BillingModality.PRE_PAID}>
                    {billingModalityMapper(lng, ClientDetailsEntity.BillingModality.PRE_PAID)}
                  </option>

                  <option value={ClientDetailsEntity.BillingModality.POST_PAID}>
                    {billingModalityMapper(lng, ClientDetailsEntity.BillingModality.POST_PAID)}
                  </option>
                </Select>
              ) : (
                <Input
                  name="billingModality"
                  disabled
                  value={billingModalityMapper(lng, client?.billingModality as ClientDetailsEntity.BillingModality)}
                  loading={loadingClient}
                  label={t('BILLING_MODALITY', { lng })}
                />
              )}
            </div>

            <div className="w-1/2 mb-4">
              {action === 'create' || action === 'edit' ? (
                <Select
                  name="modality"
                  onChange={handleChange}
                  value={formValues?.modality}
                  disabled={isFieldDisabled || !formValues?.franchiseeId}
                  loading={loadingClient}
                  error={formErrors?.modality}
                  label={t('PANEL_MODALITY', { lng })}
                  required
                >
                  <option value="" disabled>
                    {t('SELECT_MODALITY', { lng })}
                  </option>

                  <option value={ClientDetailsEntity.BillingModalityPanel.PAY_PER_USE}>
                    {billingModalityPanelMapper(lng, ClientDetailsEntity.BillingModalityPanel.PAY_PER_USE)}
                  </option>

                  <option value={ClientDetailsEntity.BillingModalityPanel.BASIC_PLAN}>
                    {billingModalityPanelMapper(lng, ClientDetailsEntity.BillingModalityPanel.BASIC_PLAN)}
                  </option>

                  {basicLegacyPlanAvailable && (
                    <option value={ClientDetailsEntity.BillingModalityPanel.BASIC_LEGACY_PLAN}>
                      {billingModalityPanelMapper(lng, ClientDetailsEntity.BillingModalityPanel.BASIC_LEGACY_PLAN)}
                    </option>
                  )}

                  <option value={ClientDetailsEntity.BillingModalityPanel.ADVANCED_PLAN}>
                    {billingModalityPanelMapper(lng, ClientDetailsEntity.BillingModalityPanel.ADVANCED_PLAN)}
                  </option>

                  <option value={ClientDetailsEntity.BillingModalityPanel.PROFESSIONAL_PLAN}>
                    {billingModalityPanelMapper(lng, ClientDetailsEntity.BillingModalityPanel.PROFESSIONAL_PLAN)}
                  </option>
                </Select>
              ) : (
                <Input
                  name="modality"
                  disabled
                  value={billingModalityPanelMapper(lng, client?.modality as ClientDetailsEntity.BillingModalityPanel)}
                  loading={loadingClient}
                  label={t('PANEL_MODALITY', { lng })}
                />
              )}
            </div>
          </div>

          <div className="flex items-center justify-start mb-6 border-senary">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <IoIosWallet className="text-black" size={16} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="PROPERTY_PORTFOLIO" tOptions={{ lng }} />
            </h2>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap mb-6">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="propertiesWallet.propertiesPurchaseSell"
                  type="number"
                  min="0"
                  disabled={isFieldDisabled}
                  value={formValues.propertiesWallet?.propertiesPurchaseSell}
                  onChange={handleChange}
                  loading={loadingClient}
                  error={formErrors.propertiesWallet?.propertiesPurchaseSell}
                  label={t('PROPERTY_SALE', { lng })}
                  placeholder={t('ENTER_AVAILABLE_QUANTITY_FOR_SALE', { lng })}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="propertiesWallet.propertiesRent"
                  type="number"
                  min="0"
                  disabled={isFieldDisabled}
                  value={formValues.propertiesWallet?.propertiesRent}
                  onChange={handleChange}
                  loading={loadingClient}
                  error={formErrors.propertiesWallet?.propertiesRent}
                  label={t('PROPERTY_RENTAL', { lng })}
                  placeholder={t('ENTER_AVAILABLE_QUANTITY_FOR_RENTAL', { lng })}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <Input
                  name="propertiesWallet.potentialInspections"
                  type="number"
                  min="0"
                  disabled={isFieldDisabled}
                  value={formValues.propertiesWallet?.potentialInspections}
                  onChange={handleChange}
                  loading={loadingClient}
                  error={formErrors.propertiesWallet?.potentialInspections}
                  label={t('MONTHLY_VISITATION_POTENTIAL', { lng })}
                  placeholder={t('ENTER_ESTIMATED_QUANTITY', { lng })}
                />
              </div>
            </div>
          </div>

          {formValues?.clientType === ClientDetailsEntity.ClientType.PJ && (
            <>
              <div className="flex items-center justify-start mb-6 border-senary">
                <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
                  <FaUser className="text-black" size={16} />
                </div>

                <h2 className="text-sm font-semibold lg:text-base">
                  <Trans i18nKey="RESPONSIBLE_PERSON" tOptions={{ lng }} />
                </h2>
              </div>

              <div className="flex flex-wrap lg:flex-nowrap">
                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                    <Input
                      name="responsible.name"
                      disabled={isFieldDisabled}
                      value={formValues?.responsible?.name}
                      onChange={handleChange}
                      loading={loadingClient}
                      error={formErrors?.responsible?.name}
                      label={t('NAME', { lng })}
                    />
                  </div>
                </div>

                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                    <Input
                      key={lng}
                      name="responsible.document"
                      disabled={isFieldDisabled}
                      value={formValues?.responsible?.document}
                      onChange={handleChange}
                      loading={loadingClient}
                      error={formErrors?.responsible?.document}
                      label={t('CPF', { lng })}
                      mask={masksByCountry.CPF_ONLY_MASK}
                      required
                    />
                  </div>
                </div>

                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                    <Input
                      key={lng}
                      name="responsible.phone"
                      disabled={isFieldDisabled}
                      value={formValues?.responsible?.phone}
                      onChange={handleChange}
                      loading={loadingClient}
                      error={formErrors?.responsible?.phone}
                      label={t('PHONE_MOBILE', { lng })}
                      mask={value =>
                        value.length >= masksByCountry.CELLPHONE_MASK_LENGTH
                          ? masksByCountry.CELLPHONE_MASK
                          : masksByCountry.PHONE_MASK
                      }
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full mb-4 lg:w-1/2">
                  <Input
                    name="responsible.email"
                    type="email"
                    disabled={isFieldDisabled}
                    value={formValues?.responsible?.email}
                    onChange={handleChange}
                    loading={loadingClient}
                    error={formErrors?.responsible?.email}
                    label={t('EMAIL', { lng })}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex items-center justify-start mb-6 border-senary">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <FaMapMarkerAlt className="text-black" size={16} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_ADDRESS" tOptions={{ lng }} />
            </h2>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  key={lng}
                  name="address.zipcode"
                  disabled={isFieldDisabled}
                  value={formValues?.address?.zipcode}
                  onChange={handleChange}
                  loading={loadingClient}
                  error={formErrors?.address?.zipcode}
                  label={t('ZIP_CODE', { lng })}
                  mask={masksByCountry.ZIPCODE_MASK}
                  required
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                {action === 'create' || action === 'edit' ? (
                  <>
                    <Select
                      name="address.stateId"
                      disabled={isFieldDisabled || loadingFetchAddress}
                      value={formValues?.address?.stateId}
                      onChange={event => {
                        setStateFieldHasChangedManually(true)
                        handleChange(event)
                      }}
                      loading={loadingClient}
                      error={formErrors?.address?.stateId}
                      label={t('STATE', { lng })}
                      required
                    >
                      <option value="" disabled>
                        {t('CHOOSE_STATE', { lng })}
                      </option>

                      {!!states.length &&
                        states.map(state => (
                          <option value={state?.id} key={state?.id}>
                            {state?.name}
                          </option>
                        ))}
                    </Select>

                    <Input
                      name="address.state"
                      type="hidden"
                      onChange={handleChange}
                      value={formValues?.address?.state}
                    />
                  </>
                ) : (
                  <Input
                    name="address.state"
                    disabled
                    value={formValues?.address?.state}
                    loading={loadingClient}
                    label={t('STATE', { lng })}
                  />
                )}
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                {action === 'create' || action === 'edit' ? (
                  <>
                    <Select
                      name="address.cityId"
                      disabled={isFieldDisabled || loadingFetchCities || loadingFetchAddress}
                      value={formValues?.address?.cityId}
                      onChange={handleChange}
                      loading={loadingClient}
                      error={formErrors?.address?.cityId}
                      label={t('CITY', { lng })}
                      required
                    >
                      <option value="" disabled>
                        {t('CHOOSE_CITY', { lng })}
                      </option>

                      {!!cities.length &&
                        cities.map(city => (
                          <option value={city?.id} key={city?.id}>
                            {city?.name}
                          </option>
                        ))}
                    </Select>

                    <Input
                      name="address.city"
                      type="hidden"
                      onChange={handleChange}
                      value={formValues?.address?.city}
                    />
                  </>
                ) : (
                  <Input
                    name="address.city"
                    disabled
                    value={client?.id && client?.address?.city}
                    loading={loadingClient}
                    label={t('CITY', { lng })}
                  />
                )}
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <Input
                  name="address.neighborhood"
                  disabled={isFieldDisabled || loadingFetchAddress}
                  value={formValues?.address?.neighborhood}
                  onChange={handleChange}
                  loading={loadingClient}
                  error={formErrors?.address?.neighborhood}
                  label={t('NEIGHBORHOOD', { lng })}
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
              <Input
                name="address.street"
                disabled={isFieldDisabled || loadingFetchAddress}
                value={formValues?.address?.street}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.address?.street}
                label={t('ADDRESS', { lng })}
                required
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="address.number"
                disabled={isFieldDisabled}
                value={formValues?.address?.number}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.address?.number}
                label={t('NUMBER', { lng })}
                required
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                name="address.complement"
                disabled={isFieldDisabled}
                value={formValues?.address?.complement}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.address?.complement}
                label={t('COMPLEMENT', { lng })}
              />
            </div>
          </div>

          <div className="flex items-center justify-start mb-6 border-senary">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <FaUser className="text-black" size={16} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="BILLING_DETAILS" tOptions={{ lng }} />
            </h2>
          </div>

          <div className="flex flex-wrap mb-6 text-xs gap-y-1 lg:mb-8 lg:flex-nowrap lg:text-sm xl:text-base">
            <div className="mr-6 md:mr-16">
              <input
                id="addressTypeSame"
                className="mr-2 cursor-pointer"
                name="addressTypeSame"
                data-cy="addressTypeSame"
                type="radio"
                onChange={() => setShowBillingAddress(false)}
                checked={!showBillingAddress}
                disabled={isFieldDisabled}
              />

              <label htmlFor="addressTypeSame">
                <Trans i18nKey="SAME_ADDRESS" tOptions={{ lng }} />
              </label>
            </div>

            <div>
              <input
                id="addressTypeDifferent"
                className="mr-2 cursor-pointer"
                name="addressTypeDifferent"
                data-cy="addressTypeDifferent"
                type="radio"
                onChange={() => setShowBillingAddress(true)}
                checked={showBillingAddress}
                disabled={isFieldDisabled}
              />

              <label htmlFor="addressTypeDifferent">
                <Trans i18nKey="DIFFERENT_ADDRESS" tOptions={{ lng }} />
              </label>
            </div>
          </div>

          {showBillingAddress && (
            <>
              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                    <Input
                      key={lng}
                      name="billingAddress.zipcode"
                      value={formValues?.billingAddress?.zipcode}
                      onChange={handleChange}
                      disabled={isFieldDisabled}
                      loading={loadingClient}
                      error={formErrors?.billingAddress?.zipcode}
                      label={t('ZIP_CODE', { lng })}
                      mask={masksByCountry.ZIPCODE_MASK}
                    />
                  </div>

                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                    {action === 'create' || action === 'edit' ? (
                      <>
                        <Select
                          name="billingAddress.stateId"
                          onChange={event => {
                            setBillingStateFieldHasChangedManually(true)
                            handleChange(event)
                          }}
                          value={formValues?.billingAddress?.stateId}
                          disabled={isFieldDisabled || loadingFetchBillingAddress}
                          loading={loadingClient}
                          error={formErrors?.billingAddress?.stateId}
                          label={t('STATE', { lng })}
                          required
                        >
                          <option value="" disabled>
                            {t('CHOOSE_STATE', { lng })}
                          </option>

                          {!!states.length &&
                            states.map(state => (
                              <option value={state?.id} key={state?.id}>
                                {state?.name}
                              </option>
                            ))}
                        </Select>

                        <Input
                          name="billingAddress.state"
                          type="hidden"
                          onChange={handleChange}
                          value={formValues?.billingAddress?.state}
                        />
                      </>
                    ) : (
                      <Input
                        name="billingAddress.state"
                        disabled
                        value={client?.id && client?.billingAddress?.state}
                        loading={loadingClient}
                        label={t('STATE', { lng })}
                      />
                    )}
                  </div>
                </div>

                <div className="flex w-full lg:w-1/2">
                  <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                    {action === 'create' || action === 'edit' ? (
                      <>
                        <Select
                          name="billingAddress.cityId"
                          onChange={handleChange}
                          value={formValues?.billingAddress?.cityId}
                          disabled={isFieldDisabled || loadingFetchBillingCities || loadingFetchBillingAddress}
                          loading={loadingClient}
                          error={formErrors?.billingAddress?.cityId}
                          label={t('CITY', { lng })}
                          required
                        >
                          <option value="" disabled>
                            {t('CHOOSE_CITY', { lng })}
                          </option>

                          {!!billingCities.length &&
                            billingCities.map(billingCity => (
                              <option value={billingCity?.id} key={billingCity?.id}>
                                {billingCity?.name}
                              </option>
                            ))}
                        </Select>

                        <Input
                          name="billingAddress.city"
                          type="hidden"
                          onChange={handleChange}
                          value={formValues?.billingAddress?.city}
                        />
                      </>
                    ) : (
                      <Input
                        name="billingAddress.city"
                        disabled
                        value={client?.id && client?.billingAddress?.city}
                        loading={loadingClient}
                        label={t('CITY', { lng })}
                      />
                    )}
                  </div>

                  <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                    <Input
                      name="billingAddress.neighborhood"
                      onChange={handleChange}
                      value={formValues?.billingAddress?.neighborhood}
                      disabled={isFieldDisabled || loadingFetchBillingAddress}
                      loading={loadingClient}
                      error={formErrors?.billingAddress?.neighborhood}
                      label={t('NEIGHBORHOOD', { lng })}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-6 lg:flex-nowrap">
                <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
                  <Input
                    name="billingAddress.street"
                    onChange={handleChange}
                    value={formValues?.billingAddress?.street}
                    disabled={isFieldDisabled || loadingFetchBillingAddress}
                    loading={loadingClient}
                    error={formErrors?.billingAddress?.street}
                    label={t('ADDRESS', { lng })}
                    required
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
                  <Input
                    name="billingAddress.number"
                    onChange={handleChange}
                    value={formValues?.billingAddress?.number}
                    disabled={isFieldDisabled}
                    loading={loadingClient}
                    error={formErrors?.billingAddress?.number}
                    label={t('NUMBER', { lng })}
                    required
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                  <Input
                    name="billingAddress.complement"
                    onChange={handleChange}
                    value={formValues?.billingAddress?.complement}
                    disabled={isFieldDisabled}
                    loading={loadingClient}
                    error={formErrors?.billingAddress?.complement}
                    label={t('COMPLEMENT', { lng })}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
              <Input
                name="billingContact"
                disabled={isFieldDisabled}
                value={formValues?.billingContact}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.billingContact}
                label={t('BILLING_CONTACT', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="billingEmail"
                type="email"
                disabled={isFieldDisabled}
                value={formValues?.billingEmail}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.billingEmail}
                label={t('BILLING_EMAIL', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                key={lng}
                name="billingPhone"
                disabled={isFieldDisabled}
                value={formValues?.billingPhone}
                onChange={handleChange}
                loading={loadingClient}
                error={formErrors?.billingPhone}
                label={t('BILLING_PHONE', { lng })}
                mask={value =>
                  value.length >= masksByCountry.CELLPHONE_MASK_LENGTH
                    ? masksByCountry.CELLPHONE_MASK
                    : masksByCountry.PHONE_MASK
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-start mb-6 border-senary">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <BsFillChatFill className="text-black" size={16} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CONTACT" tOptions={{ lng }} />
            </h2>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex flex-wrap w-full sm:flex-nowrap">
              <div className="w-full mb-4 mr-0 sm:mr-4 lg:mr-8 sm:w-1/2">
                <label className={formLabelClasses} htmlFor="contact.assignmentEmailRule">
                  <Trans i18nKey="ASSIGNMENT_EMAIL_RULE" tOptions={{ lng }} />
                </label>

                <div className="relative">
                  {action === 'create' || action === 'edit' ? (
                    <Select
                      name="contact.assignmentEmailRule"
                      onChange={handleChange}
                      value={formValues?.contact?.assignmentEmailRule}
                      disabled={isFieldDisabled}
                      loading={loadingClient}
                      error={formErrors?.contact?.assignmentEmailRule}
                    >
                      <option value="" disabled>
                        {t('CHOOSE_RULE', { lng })}
                      </option>

                      <option value={ClientDetailsEntity.AssignmentEmailRule.CREATOR}>
                        {assignmentEmailRuleMapper(lng, ClientDetailsEntity.AssignmentEmailRule.CREATOR)}
                      </option>

                      <option value={ClientDetailsEntity.AssignmentEmailRule.CUSTOM}>
                        {assignmentEmailRuleMapper(lng, ClientDetailsEntity.AssignmentEmailRule.CUSTOM)}
                      </option>

                      <option value={ClientDetailsEntity.AssignmentEmailRule.RESPONSIBLE}>
                        {assignmentEmailRuleMapper(lng, ClientDetailsEntity.AssignmentEmailRule.RESPONSIBLE)}
                      </option>

                      <option value={ClientDetailsEntity.AssignmentEmailRule.NONE}>
                        {assignmentEmailRuleMapper(lng, ClientDetailsEntity.AssignmentEmailRule.NONE)}
                      </option>
                    </Select>
                  ) : (
                    <Input
                      name="contact.assignmentEmailRule"
                      disabled
                      value={client?.id && assignmentEmailRuleMapper(lng, client?.contact?.assignmentEmailRule)}
                      loading={loadingClient}
                    />
                  )}
                </div>
              </div>

              <div className="w-full mb-4 sm:w-1/2">
                <Input
                  key={lng}
                  name="contact.phone"
                  onChange={handleChange}
                  value={formValues?.contact?.phone}
                  disabled={isFieldDisabled}
                  loading={loadingClient}
                  error={formErrors?.contact?.phone}
                  label={t('PHONE_MOBILE', { lng })}
                  mask={value =>
                    value.length >= masksByCountry.CELLPHONE_MASK_LENGTH
                      ? masksByCountry.CELLPHONE_MASK
                      : masksByCountry.PHONE_MASK
                  }
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-full mb-6">
            <div className="w-full mb-4 lg:w-1/2">
              <Input
                name="contact.assignmentEmail"
                type="email"
                onChange={handleChange}
                value={formValues?.contact?.assignmentEmail}
                disabled={
                  isFieldDisabled ||
                  formValues?.contact?.assignmentEmailRule !== ClientDetailsEntity.AssignmentEmailRule.CUSTOM
                }
                loading={loadingClient}
                error={formErrors?.contact?.assignmentEmail}
                label={t('ASSIGNMENT_ORDER_EMAIL', { lng })}
              />
            </div>
          </div>

          <div className="flex items-center justify-start mb-6 border-senary">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <IoIosPaper className="text-black" size={16} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="ORDER_PAGE" tOptions={{ lng }} />
            </h2>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 lg:mr-8">
              <Input
                name="hotsiteConfiguration.expirationTime"
                type="number"
                min="0"
                onChange={handleChange}
                value={formValues?.hotsiteConfiguration?.expirationTime}
                disabled={isFieldDisabled}
                loading={loadingClient}
                error={formErrors?.hotsiteConfiguration?.expirationTime}
                label={t('DIVERGENCE_REPORTING_DEADLINE', { lng })}
              />
            </div>

            <div className="w-full mb-4">
              <Input
                name="hotsiteConfiguration.ccEmail"
                type="email"
                onChange={handleChange}
                value={formValues?.hotsiteConfiguration?.ccEmail}
                disabled={isFieldDisabled}
                loading={loadingClient}
                error={formErrors?.hotsiteConfiguration?.ccEmail}
                label={t('ADMINISTRATOR_EMAIL', { lng })}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 sm:mr-4 lg:mr-8 sm:w-1/2">
              <label className={formLabelClasses} htmlFor="hotsiteConfiguration.canRegisterMaintenances">
                <Trans i18nKey="REPAIR_REQUESTS_CHANGES" tOptions={{ lng }} />
              </label>

              <div className="relative">
                {action === 'create' || action === 'edit' ? (
                  <Select
                    name="hotsiteConfiguration.canRegisterMaintenances"
                    onChange={handleChange}
                    value={formValues?.hotsiteConfiguration?.canRegisterMaintenances}
                    disabled={isFieldDisabled}
                    loading={loadingClient}
                    error={formErrors?.hotsiteConfiguration?.canRegisterMaintenances}
                  >
                    <option value="true">{t('AVAILABLE_IF_SUPPORTED_BY_PLAN', { lng })}</option>

                    <option value="false">{t('DISABLED_FEMALE', { lng })}</option>
                  </Select>
                ) : (
                  <Input
                    name="hotsiteConfiguration.canRegisterMaintenances"
                    disabled
                    value={
                      client?.id && client?.hotsiteConfiguration?.canRegisterMaintenances === true
                        ? t('AVAILABLE_IF_SUPPORTED_BY_PLAN', { lng })
                        : t('DISABLED_FEMALE', { lng })
                    }
                    loading={loadingClient}
                  />
                )}
              </div>
            </div>
          </div>

          {(action === 'create' || action === 'edit') && (
            <div className="flex flex-col items-center justify-center pt-6 mt-2 border-t-2 border-senary md:flex-row">
              <Link
                className="order-1 w-full px-12 py-2 mr-0 text-sm font-semibold text-center text-white transition-colors rounded bg-error hover:bg-opacity-80 md:mr-4 md:w-auto md:order-none"
                to="/clients"
              >
                <Trans i18nKey="CANCEL" tOptions={{ lng }} />
              </Link>

              <Button
                name="save-button"
                type="submit"
                className="bg-success md:mr-4 md:w-auto md:mb-0 md:order-none"
                disabled={isFormInvalidOrBlocked}
              >
                <Trans i18nKey="SAVE" tOptions={{ lng }} />
              </Button>

              {action === 'edit' && user?.hasPackageFeature && (
                <Button
                  name="add-package-button"
                  className="bg-tertiary md:w-auto md:mb-0"
                  type="button"
                  onClick={() => dispatch(uiActions.togglePackageModal({ client: Number(clientId), isOpen: true }))}
                >
                  <Trans i18nKey="ADD_PACKAGE" tOptions={{ lng }} />
                </Button>
              )}
            </div>
          )}
        </form>
      </section>
    </div>
  )
}

export default ClientCreateEditOrViewPage
