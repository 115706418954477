import { HttpClient, HttpStatusCode } from 'src/data'
import { ClientIzeeModel } from 'src/data/models/ClientIzeeModel'

import { FetchClientIzeeUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchClientIzeeService implements FetchClientIzeeUseCase {
  constructor(private readonly httpClient: HttpClient<ClientIzeeModel>, private readonly url: string) {}

  async fetchClientIzee(client: number) {
    const response = await this.httpClient.request({
      method: 'get',
      url: `${this.url}find/${client}`,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ClientIzeeModel.fromJson(response?.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
