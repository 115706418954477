import { AutoBillingConfigEntity } from 'src/domain'

export class AutoBillingConfigModel {
  constructor(
    readonly paymentForm: string,
    readonly billingDueDay: number,
    readonly pricePerCredit: number,
    readonly sellerId: number,
    readonly enabled: boolean
  ) {}

  static fromJson(json: any) {
    return new AutoBillingConfigModel(
      json?.payment_form,
      json?.billing_due_day,
      json?.price_per_credit,
      json?.seller_id,
      json?.enabled
    )
  }

  toEntity(): AutoBillingConfigEntity {
    return {
      paymentForm: this.paymentForm,
      billingDueDay: this.billingDueDay,
      pricePerCredit: this.pricePerCredit,
      sellerId: this.sellerId,
      enabled: this.enabled,
    }
  }
}
