import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaUserPlus, FaMapMarkerAlt, FaShoppingCart } from 'react-icons/fa'
import { useParams, Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { Breadcrumb, Input, CurrencyInput } from 'src/presentation/components'
import { formLabelClasses } from 'src/presentation/constants'
import { useAppDispatch, useAppSelector, useLanguage } from 'src/presentation/hooks'
import { clientActions } from 'src/presentation/store/ducks/clientIzee'

interface Params {
  clientId: string
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return format(date, 'dd/MM/yyyy', { locale: ptBR })
}

const ClientIzeeDetailsPage: React.FC = () => {
  const { clientId } = useParams<Params>()

  const dispatch = useAppDispatch()

  const { client, loading: loadingClient } = useAppSelector(state => state.clientIzee)

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  useEffect(() => {
    dispatch(clientActions.fetchClientIzee({ client: Number(clientId) }))

    return () => {
      dispatch(clientActions.clearClient())
    }
  }, [clientId, dispatch])

  return (
    <div data-cy="client-create-edit-or-view-page" className="relative w-full p-6">
      <Breadcrumb previousPageName="Clientes Izee" previousPageUrl="/clients-izee" currentPageName={clientId} />

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <div className="w-full">
          <div className="flex items-center justify-between mb-6 border-senary">
            <div className="flex items-center justify-start">
              <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
                <FaUserPlus className="ml-0.5 text-black" size={18} />
              </div>

              <h2 className="text-sm font-semibold lg:text-base">
                <Trans i18nKey="CLIENT_WITH_PARAM" values={{ clientId: clientId || '' }} />
              </h2>
            </div>
          </div>

          <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
            <label className={formLabelClasses}>
              <Trans i18nKey="CLIENT_TYPE" />
            </label>

            <div className="relative flex flex-wrap items-center justify-start mt-4 text-xs gap-y-1 lg:flex-nowrap lg:text-sm xl:text-base">
              <div className="sm:mr-6 md:mr-16">
                <input
                  className="mr-2 cursor-pointer"
                  name="clientType"
                  data-cy="clientType"
                  type="radio"
                  disabled
                  checked={client?.cnpj ? true : false}
                />

                <label htmlFor="clientTypePj">
                  <Trans i18nKey="LEGAL_PERSON" />
                </label>
              </div>

              <div>
                <input
                  className="mr-2 cursor-pointer"
                  name="clientType"
                  data-cy="clientType"
                  type="radio"
                  disabled
                  checked={client?.cpf ? true : false}
                />

                <label htmlFor="clientTypePf">
                  <Trans i18nKey="NATURAL_PERSON" />
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-1/2 mb-4 mr-0  lg:mr-8">
              <label className={formLabelClasses} htmlFor="origin">
                <Trans i18nKey="NAME" />
              </label>

              <div className="relative p-0">
                <Input name="origin" disabled value={client?.name} loading={loadingClient} />
              </div>
            </div>

            {client?.cnpj && (
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8">
                <label className={formLabelClasses} htmlFor="franchiseeId">
                  <Trans i18nKey="CNPJ" />
                </label>

                <div className="relative">
                  <Input name="franchisee" type="text" disabled value={client?.cnpj} loading={loadingClient} />
                </div>
              </div>
            )}

            {client?.cpf && (
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8">
                <label className={formLabelClasses} htmlFor="franchiseeId">
                  <Trans i18nKey="CPF" />
                </label>

                <div className="relative">
                  <Input name="franchisee" type="text" disabled value={client?.cpf} loading={loadingClient} />
                </div>
              </div>
            )}
          </div>

          {client?.cnpj && (
            <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
              <div className="w-1/3 mb-4 mr-0  lg:mr-8">
                <label className={formLabelClasses} htmlFor="origin">
                  <Trans i18nKey="LEGAL_ENTITY_NAME" />
                </label>

                <div className="relative p-0">
                  <Input name="origin" disabled value={client?.tradingName} loading={loadingClient} />
                </div>
              </div>

              <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                <label className={formLabelClasses} htmlFor="franchiseeId">
                  <Trans i18nKey="MUNICIPAL_REGISTRATION" />
                </label>

                <div className="relative">
                  <Input
                    name="franchisee"
                    type="text"
                    disabled
                    value={client?.municipalEnrollment}
                    loading={loadingClient}
                  />
                </div>
              </div>

              <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                <label className={formLabelClasses} htmlFor="franchiseeId">
                  <Trans i18nKey="STATE_REGISTRATION" />
                </label>

                <div className="relative">
                  <Input
                    name="franchisee"
                    type="text"
                    disabled
                    value={client?.stateEnrollment}
                    loading={loadingClient}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center justify-start mb-6 border-senary">
            <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
              <FaMapMarkerAlt className="text-black" size={16} />
            </div>

            <h2 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_ADDRESS" />
            </h2>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-1/2 mb-4 mr-0  lg:mr-8">
              <label className={formLabelClasses} htmlFor="origin">
                <Trans i18nKey="ZIP_CODE" />
              </label>

              <div className="relative p-0">
                <Input name="origin" disabled value={client?.address?.zipCode} loading={loadingClient} />
              </div>
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8">
              <label className={formLabelClasses} htmlFor="franchiseeId">
                <Trans i18nKey="STATE" />
              </label>

              <div className="relative">
                <Input name="franchisee" type="text" disabled value={client?.address?.state} loading={loadingClient} />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-1/2 mb-4 mr-0  lg:mr-8">
              <label className={formLabelClasses} htmlFor="origin">
                <Trans i18nKey="ADDRESS" />
              </label>

              <div className="relative p-0">
                <Input name="origin" disabled value={client?.address?.address} loading={loadingClient} />
              </div>
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8">
              <label className={formLabelClasses} htmlFor="franchiseeId">
                <Trans i18nKey="CITY" />
              </label>

              <div className="relative">
                <Input name="franchisee" type="text" disabled value={client?.address?.city} loading={loadingClient} />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-1/2 mb-4 mr-0  lg:mr-8">
              <label className={formLabelClasses} htmlFor="origin">
                <Trans i18nKey="NUMBER" />
              </label>

              <div className="relative p-0">
                <Input name="origin" disabled value={client?.address?.number} loading={loadingClient} />
              </div>
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8">
              <label className={formLabelClasses} htmlFor="franchiseeId">
                <Trans i18nKey="NEIGHBORHOOD" />
              </label>

              <div className="relative">
                <Input
                  name="franchisee"
                  type="text"
                  disabled
                  value={client?.address?.neighborhood}
                  loading={loadingClient}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0  lg:mr-8">
              <label className={formLabelClasses} htmlFor="origin">
                <Trans i18nKey="COMPLEMENT" />
              </label>

              <div className="relative p-0">
                <Input name="origin" disabled value={client?.address?.additionalAddress} loading={loadingClient} />
              </div>
            </div>
          </div>

          {client?.last_sale && (
            <>
              <div className="flex items-center justify-start mb-6 border-senary">
                <div className="flex items-center justify-center w-8 h-8 mr-2 border-2 border-black rounded-full">
                  <FaShoppingCart className="text-black" size={16} />
                </div>

                <h2 className="text-sm font-semibold lg:text-base">
                  <Trans i18nKey="LAST_SALE" />:
                  <Link to={`/izee-sales/${client?.last_sale.id}/view`}>{client?.last_sale?.id}</Link>
                </h2>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-1/3 mb-4 mr-0  lg:mr-8">
                  <label className={formLabelClasses} htmlFor="seller">
                    <Trans i18nKey="SELLER" />
                  </label>

                  <div className="relative p-0">
                    <Input
                      name="seller"
                      disabled
                      value={client?.last_sale?.seller?.name || ''}
                      loading={loadingClient}
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                  <label className={formLabelClasses} htmlFor="createdAt">
                    <Trans i18nKey="DATE" />
                  </label>

                  <div className="relative">
                    <Input
                      name="createdAt"
                      type="text"
                      disabled
                      value={formatDate(client?.last_sale?.created_at)}
                      loading={loadingClient}
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                  <label className={formLabelClasses} htmlFor="status">
                    <Trans i18nKey="STATUS" />
                  </label>

                  <div className="relative">
                    <Input
                      name="status"
                      type="text"
                      disabled
                      value={client?.last_sale?.status === 'OPEN' ? 'Em aberto' : 'Finalizada'}
                      loading={loadingClient}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-1/3 mb-4 mr-0  lg:mr-8">
                  <label className={formLabelClasses} htmlFor="billingPhone">
                    <Trans i18nKey="BILLING_PHONE" />
                  </label>

                  <div className="relative p-0">
                    <Input
                      name="billingPhone"
                      disabled
                      value={client?.last_sale?.billing_phone}
                      loading={loadingClient}
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                  <label className={formLabelClasses} htmlFor="billingEmail">
                    <Trans i18nKey="BILLING_EMAIL" />
                  </label>

                  <div className="relative">
                    <Input
                      name="billingEmail"
                      type="text"
                      disabled
                      value={client?.last_sale?.billing_email}
                      loading={loadingClient}
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                  <label className={formLabelClasses} htmlFor="billingDay">
                    <Trans i18nKey="DUE_DATE_BOLETO" />
                  </label>

                  <div className="relative">
                    <Input
                      name="billingDay"
                      type="text"
                      disabled
                      value={client?.last_sale?.billing_day}
                      loading={loadingClient}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-1/3 mb-4 mr-0  lg:mr-8">
                  <div className="relative p-0">
                    <CurrencyInput
                      name="monthlyPrice"
                      value={client?.last_sale?.monthly_price}
                      loading={loadingClient}
                      label={t('SETUP_AMOUNT')}
                      lng={currentLanguage}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                  <div className="relative">
                    <CurrencyInput
                      name="setupPrice"
                      value={client?.last_sale?.setup_price}
                      loading={loadingClient}
                      label={t('SETUP_AMOUNT')}
                      lng={currentLanguage}
                      disabled
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/3 mb-4 mr-4 lg:mr-8">
                  <label className={formLabelClasses} htmlFor="setupNumberOfInstallments">
                    <Trans i18nKey="SETUP_INSTALLMENT" />
                  </label>

                  <div className="relative">
                    <Input
                      name="setupNumberOfInstallments"
                      type="text"
                      disabled
                      value={client?.last_sale?.setup_number_of_installments}
                      loading={loadingClient}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-1/2 mb-4 mr-0  lg:mr-8">
                  <label className={formLabelClasses} htmlFor="billingSignatureId">
                    <Trans i18nKey="SUBSCRIPTION_BILLING_ID" />
                  </label>

                  <div className="relative p-0">
                    <Input
                      name="billingSignatureId"
                      disabled
                      value={client?.last_sale?.billing_signature_id || ''}
                      loading={loadingClient}
                    />
                  </div>
                </div>

                <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8">
                  <label className={formLabelClasses} htmlFor="billingSetupId">
                    <Trans i18nKey="SETUP_BILLING_ID" />
                  </label>

                  <div className="relative">
                    <Input
                      name="billingSetupId"
                      type="text"
                      disabled
                      value={
                        client?.last_sale?.billing_setup_billet_id || client?.last_sale?.billing_setup_carnet_id || ''
                      }
                      loading={loadingClient}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  )
}

export default ClientIzeeDetailsPage
