import { HttpClient, HttpStatusCode, AddClientPlanMapper } from 'src/data'

import { AddClientPlanUseCase, UnexpectedError } from 'src/domain'

export class RemoteAddClientPlanService implements AddClientPlanUseCase {
  constructor(private readonly httpClient: HttpClient<void>, private readonly url: string) {}

  async addPlan({ planUuid, ...params }: AddClientPlanUseCase.Params) {
    const url = this.url.replace('{plan_uuid}', planUuid)

    const planToJson = AddClientPlanMapper.fromDomainToJson(params)

    const response = await this.httpClient.request({ method: 'post', url, body: planToJson })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return

      default:
        throw new UnexpectedError()
    }
  }
}
