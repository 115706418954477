import { RemoteAddClientPlanService } from 'src/data'

import { AddClientPlanUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeAddClientPlanUseCase = (): AddClientPlanUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteAddClientPlanService(authorizedHttpAdapter, '/plans/{plan_uuid}/clients')
}
