import { HttpClient, HttpStatusCode } from 'src/data'

import { ResendOrderUseCase, OrderEntity, UnexpectedError } from 'src/domain'

export class RemoteResendOrderService implements ResendOrderUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async resendOrder(params: OrderEntity.ParamsResendOrder) {
    const id = params.id

    const response = await this.httpClient.request({
      method: 'post',
      url: `${this.url}/${id}/resend`,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.forbidden: {
        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
