export type AutoBillingConfigEntity = {
  paymentForm: string
  billingDueDay: number
  pricePerCredit: number
  sellerId: number
  enabled: boolean
}

export enum PaymentForm {
  TICKET = 'TICKET',
  PIX = 'PIX',
}
