import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiSync } from 'react-icons/bi'

import { useFormik } from 'formik'

import { makeResendOrderUseCaseFactory } from 'src/main'

import { Button } from 'src/presentation/components'
import { useToast } from 'src/presentation/hooks'

interface Props {
  closeModal: () => void
  idOrderResend: number | string
}

const OrderResend: React.FC<Props> = ({ closeModal, idOrderResend }) => {
  const { throwToast } = useToast()
  const { t } = useTranslation()

  const orderResendUseCase = makeResendOrderUseCaseFactory()

  const { isSubmitting, handleSubmit } = useFormik({
    initialValues: {
      id: idOrderResend,
      reason: '',
      observation: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await orderResendUseCase.resendOrder({ id: values.id })

        throwToast({ message: t('ORDER_RE_SENT_SUCCESSFULLY'), type: 'success' })

        closeModal()
      } catch (error) {
        throwToast({ message: t('UNABLE_TO_RESEND_ORDER') })
      } finally {
        setTimeout(() => setSubmitting(false), 2000)
      }
    },
  })

  return (
    <div className="w-full">
      <section className="bg-white">
        <div className="flex gap-1 mb-6 m-[-1.5rem] px-[1.5rem] py-[1rem] border-b-[#DFE0E2] border-b text-[20px] font-semibold text-black">
          <BiSync size={30} />

          <span>
            <Trans i18nKey="RESEND_SANKHYA_SALE" />
          </span>
        </div>

        <form className="w-full" onSubmit={handleSubmit}>
          <p className="mb-6">
            <Trans i18nKey="SALE_IS_GOING_TO_BE_SYNC_AGAIN" />
          </p>

          <div className="flex gap-4 items-center justify-center pt-6 mt-2">
            <Button name="cancel-button" className="bg-tertiary md:w-auto md:mb-0" type="button" onClick={closeModal}>
              <Trans i18nKey="CANCEL" />
            </Button>

            <Button
              name="confirm-button"
              className="bg-primary md:w-auto md:mb-0"
              type="submit"
              disabled={isSubmitting}
            >
              <Trans i18nKey="RESEND_SALE" />
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default OrderResend
