import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { PaymentForm } from 'src/domain'

export const useAutoBillingConfigValidator = () => {
  const { t } = useTranslation()

  return {
    shape: Yup.object().shape({
      paymentForm: Yup.string()
        .oneOf([PaymentForm.PIX, PaymentForm.TICKET], t('INVALID'))
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      billingDueDay: Yup.number().required(t('REQUIRED')).typeError(t('INVALID')),
      pricePerCredit: Yup.string()
        .test('is_required', t('REQUIRED'), creditPrice => {
          const priceWithOnlyNumbers = String(creditPrice).replace(/\D/g, '')

          if (
            Number.isNaN(Number(priceWithOnlyNumbers)) ||
            Number(priceWithOnlyNumbers) <= 0 ||
            !priceWithOnlyNumbers
          ) {
            return false
          }

          return true
        })
        .test('is_decimal', t('ENTER_DECIMAL_PART'), creditPrice => {
          if (creditPrice) {
            const dotOccurrences = String(creditPrice).split('.').length - 1

            if (dotOccurrences === 1) return true

            return creditPrice.includes(',')
          }

          return false
        })
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      sellerId: Yup.number().required(t('REQUIRED')).typeError(t('INVALID')),
      enabled: Yup.string().oneOf(['true', 'false'], t('INVALID')).required(t('REQUIRED')).typeError(t('INVALID')),
    }),
  }
}
