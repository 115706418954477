import { ClientExtractFormats } from 'src/domain'

const clientExtractFormatToDisplay = {
  BR: {
    consolidated: 'Extrato consolidado',
    'consolidated-post-paid': 'Extrato consolidado pós-pago',
    account: 'Extrato detalhado',
    'post-paid': 'Extrato pedidos entregues',
  },
  PT: {
    consolidated: 'Extrato consolidado',
    'consolidated-post-paid': 'Extrato consolidado pós-pago',
    account: 'Extrato detalhado',
    'post-paid': 'Extrato pedidos entregues',
  },
}

export const clientExtractFormatMapper = (language: 'BR' | 'PT', format: ClientExtractFormats) => {
  return clientExtractFormatToDisplay?.[language]?.[format] || ''
}
