import React from 'react'
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from 'react-icons/fa'

import { useLanguage, UseToast } from 'src/presentation/hooks'

const toastTitle = {
  BR: {
    success: 'Sucesso',
    error: 'Erro',
    warning: 'Atenção',
  },
  PT: {
    success: 'Sucesso',
    error: 'Erro',
    warning: 'Atenção',
  },
}

const toastIcons = {
  success: <FaCheckCircle className="text-success" size={32} />,
  error: <FaTimesCircle className="text-error" size={32} />,
  warning: <FaExclamationCircle className="text-warning" size={32} />,
}

const Toast: React.FC<UseToast.Params> = ({ message, type = 'error', handleOnClick = () => null }) => {
  const { currentLanguage } = useLanguage()

  return (
    <div id="toast" className="px-2 bg-white" data-cy="toast" onClick={handleOnClick}>
      <div className="flex items-center justify-start text-sm xl:text-base">
        <div className="mr-4">{toastIcons[type]}</div>

        <div className="flex flex-col items-start justify-center">
          <h4 className="text-base font-semibold leading-6 text-nonary">{toastTitle?.[currentLanguage]?.[type]}</h4>

          <p className="m-0 text-sm font-medium leading-4 text-nonary">{message}</p>
        </div>
      </div>
    </div>
  )
}

export default Toast
