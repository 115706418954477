import React, { RefObject, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PaginateLoading, TableLoading } from 'src/presentation/components'
import { OrderCancelModal } from 'src/presentation/components'
import Modal from 'src/presentation/components/Modal/Modal'
import {
  useAppDispatch,
  useAppSelector,
  useDate,
  useLanguage,
  usePersistedPreviousDataQuantity,
  useScroll,
} from 'src/presentation/hooks'
import {
  orderIzeeStatusColorMapper,
  orderIzeeStatusIconMapper,
  orderIzeeStatusTextMapper,
} from 'src/presentation/mappers'
import { OpenOrderIzeeMenuItem } from 'src/presentation/pages'
import { ordersIzeeActions } from 'src/presentation/store/ducks/ordersIzee'

const OpenOrdersIzeePage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading, loadingPagination, hasMoreOrders, openOrders } = useAppSelector(state => state.ordersIzee)
  const { franchiseeId, isSearchActive } = useAppSelector(state => state.headerSearch)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [idOrderCancel, setIdOrderCancel] = useState<number | string>('')

  const { formatDate } = useDate()

  const [currentPage, lastElementRef] = useScroll({ hasMoreItems: hasMoreOrders, loading })

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const storageKey = isSearchActive ? '@rvpdv:openOrdersSearchQuantity' : '@rvpdv:openOrdersQuantity'

  const openOrdersPreviousQuantity = usePersistedPreviousDataQuantity(storageKey, openOrders)

  useEffect(() => {
    document.title = `${t('OPEN_ORDERS')} - PDV`
  }, [])

  useEffect(() => {
    dispatch(ordersIzeeActions.fetchOpenOrdersIzee({ franchisee: franchiseeId, page: null }))

    return () => {
      dispatch(ordersIzeeActions.clearOpenOrders())
    }
  }, [dispatch, franchiseeId])

  useEffect(() => {
    if (hasMoreOrders && openOrders.length) {
      dispatch(ordersIzeeActions.fetchOpenOrdersIzee({ franchisee: franchiseeId, page: currentPage }))
    }
  }, [currentPage, dispatch])

  const handleCancel = (orderId: number | string) => {
    setIdOrderCancel(orderId)
    setShowCancelModal(true)
  }

  const closeModal = () => {
    setShowCancelModal(false)
    setIdOrderCancel('')
    dispatch(ordersIzeeActions.fetchOpenOrdersIzee({ franchisee: franchiseeId, page: currentPage }))
  }

  return (
    <div data-cy="open-orders-page" className="flex flex-col w-full p-6">
      <div className="flex items-center justify-end" />

      <div className="relative mt-6 overflow-auto bg-white rounded shadow-md h-[calc(100vh-196px)] md:h-[calc(100vh-172px)]">
        <div className="relative flex flex-1 w-full bg-white rounded flex-nowrap">
          <div className="w-full bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={openOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-30 p-3 overflow-hidden text-sm font-semibold border-4 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="ORDER_ID" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!openOrders.length &&
                    openOrders.map((order, index) => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                        {...(openOrders.length === index + 1 && {
                          ref: lastElementRef as unknown as RefObject<HTMLDivElement>,
                        })}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.id}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={openOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-30 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="CLIENT" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!openOrders.length &&
                    openOrders.map(order => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full w-44 h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.company?.name}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full px-0 bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={openOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-30 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="DATE" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!openOrders.length &&
                    openOrders.map(order => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {formatDate(order?.date)}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full px-0 bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={openOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-30 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="STATUS" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!openOrders.length &&
                    openOrders.map(order => {
                      const color = orderIzeeStatusColorMapper(order?.status)
                      const icon = orderIzeeStatusIconMapper(order?.status)
                      const text = orderIzeeStatusTextMapper(currentLanguage, order?.status)

                      return (
                        <div
                          id={`order-item-${order?.id}`}
                          className="flex items-center py-4 text-left xl:w-full h-14"
                          key={order?.id}
                          data-cy={`order-item-${order?.id}`}
                        >
                          <div
                            className={`flex justify-start items-center rounded-full text-white px-4 py-2 w-full ${color}`}
                          >
                            <span className="inline-block mr-2">{icon}</span>

                            <span className="overflow-hidden text-sm xl:text-base overflow-ellipsis whitespace-nowrap">
                              {text}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </>
            )}
          </div>

          <div className="w-full pl-0 bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} mostRightElement isHeaderCell />

                <TableLoading numberOfItems={openOrdersPreviousQuantity} mostRightElement />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-30 p-3 overflow-hidden text-sm font-semibold text-center border-4 border-l-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="ACTIONS" />
                </div>

                <div className="w-full px-4 py-0">
                  {!!openOrders.length &&
                    openOrders.map((order, index) => {
                      const isOneOfLastItems =
                        (index === openOrders.length - 1 || index === openOrders.length - 2) && openOrders.length > 3

                      return (
                        <div
                          id={`order-item-${order?.id}`}
                          className="relative flex items-center justify-center py-4 xl:w-full h-14"
                          key={order?.id}
                          data-cy={`order-item-${order?.id}`}
                        >
                          <OpenOrderIzeeMenuItem
                            isOneOfLastItems={isOneOfLastItems && openOrders.length > 1}
                            orderId={order?.id}
                            handleCancel={handleCancel}
                          />
                        </div>
                      )
                    })}
                </div>
              </>
            )}
          </div>
        </div>

        {!openOrders.length && !loading && !loadingPagination && (
          <div className="absolute inset-x-0 mt-12 bg-white" data-cy="not-found-message">
            <p className="text-lg font-semibold text-center">
              <Trans i18nKey="NO_OPEN_ORDERS_FOUND" />
            </p>
          </div>
        )}
      </div>

      <PaginateLoading loading={loadingPagination} />

      <Modal closeClickEsc={true} closeClickOutside={false} isOpen={showCancelModal} closeModal={closeModal}>
        <div className="max-w-[600px]">
          <OrderCancelModal closeModal={closeModal} idOrderCancel={idOrderCancel}></OrderCancelModal>
        </div>
      </Modal>
    </div>
  )
}

export default OpenOrdersIzeePage
