import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BsClipboard2XFill } from 'react-icons/bs'

import { useFormik } from 'formik'

import { OrderCancelError } from 'src/domain'

import { makeCancelOpenOrderUseCaseFactory } from 'src/main'

import { Button, Select, TextArea } from 'src/presentation/components'
import { useLanguage, useToast } from 'src/presentation/hooks'
import { OrderCancelMappers } from 'src/presentation/mappers'

interface Props {
  closeModal: () => void
  idOrderCancel: number | string
}

const OrderCancel: React.FC<Props> = ({ closeModal, idOrderCancel }) => {
  const { throwToast } = useToast()
  const { currentLanguage } = useLanguage()
  const { t } = useTranslation()

  const orderCancelUseCase = makeCancelOpenOrderUseCaseFactory()

  const {
    values: formValues,
    errors: formErrors,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: idOrderCancel,
      reason: '',
      observation: '',
    },
    onSubmit: (submitValues, { setSubmitting }) => {
      async function handleSubmit() {
        try {
          const payload = {
            id: submitValues.id,
            reason: submitValues.reason,
            observation: submitValues.observation,
          }

          await orderCancelUseCase.openOrderCancel(payload)

          throwToast({ message: t('ORDER_CANCELLED'), type: 'success' })

          closeModal()
        } catch (err) {
          if (err instanceof OrderCancelError) {
            throwToast({ message: OrderCancelMappers(currentLanguage, err.message) })
          } else {
            throwToast({ message: t('UNABLE_TO_CANCEL_ORDER') })
          }
        } finally {
          setTimeout(() => setSubmitting(false), 2000)
        }
      }

      handleSubmit()
    },
  })

  return (
    <div className="w-full">
      <section className="bg-white">
        <div className="flex gap-1 mb-6 m-[-1.5rem] px-[1.5rem] py-[1rem] border-b-[#DFE0E2] border-b text-[20px] font-semibold text-black">
          <BsClipboard2XFill size={30} />

          <span>
            <Trans i18nKey="CANCEL_SALE" />
          </span>
        </div>

        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex-1 mb-4 mr-4">
            <label htmlFor="reason">
              <Trans i18nKey="REASON" />*
            </label>

            <Select
              name="reason"
              value={formValues.reason}
              onChange={handleChange}
              error={formErrors.reason}
              loading={false}
              required
            >
              <option value="" disabled>
                {t('SELECT')}
              </option>

              <option value="Desistência do cliente">{t('CUSTOMER_WITHDRAWAL')}</option>

              <option value="Cliente cancelou o plano">{t('CUSTOMER_CANCELED_THE_PLAN')}</option>

              <option value="Valor incorreto">{t('INCORRECT_VALUE')}</option>

              <option value="Inadimplência">{t('DEFAULT')}</option>

              <option value="Outros">{t('OTHERS')}</option>
            </Select>
          </div>

          <div className="flex-1 mb-4 mr-4">
            <label htmlFor="observation">
              <Trans i18nKey="OBSERVATIONS" />
            </label>

            <TextArea
              name="observation"
              value={formValues.observation}
              onChange={handleChange}
              loading={false}
              error={formErrors.observation}
              placeholder={`${t('TYPE')}...`}
            />
          </div>

          <p className="mb-6">
            <span className="text-[#dc2626]">
              *<Trans i18nKey="ATTENTION" />:
            </span>{' '}
            <Trans i18nKey="ONCE_YOU_CANCEL_A_SALES_ORDER" />
          </p>

          <div className="flex gap-4 items-center justify-center pt-6 mt-2">
            <Button
              name="cancel-button"
              className="bg-tertiary md:w-auto md:mb-0"
              type="button"
              onClick={() => closeModal()}
            >
              <Trans i18nKey="CANCEL" />
            </Button>

            <Button
              name="confirm-button"
              className="bg-primary md:w-auto md:mb-0"
              type="submit"
              disabled={isSubmitting}
            >
              <Trans i18nKey="CANCEL_SALE" />
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default OrderCancel
