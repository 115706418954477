import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ClientIzeeEntity, ClientsIzeePagination } from 'src/domain'

import { makeFetchClientsIzeeUseCase } from 'src/main'

import { AppState } from 'src/presentation/store'
import { headerSearchActions } from 'src/presentation/store/ducks/headerSearch'

const CLIENTS_PER_PAGE = 15

interface FetchClientsIzeeParams {
  page: number | null
  franchisee: number | null
  search: string | null
}

const fetchClientsIzee = createAsyncThunk(
  '@clientsIzee/fetchClientsIzee',
  async ({ franchisee, page, search }: FetchClientsIzeeParams, { dispatch, getState }) => {
    page ? dispatch(clientsIzeeActions.loadingPagination()) : dispatch(clientsIzeeActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee || !!search))

    const fetchClientsIzeeUseCase = makeFetchClientsIzeeUseCase()

    const pagination: ClientsIzeePagination = {
      limit: CLIENTS_PER_PAGE,
      offset: page ? page * CLIENTS_PER_PAGE : undefined,
      orderBy: 'created_at',
      orderByDir: 'desc',
    }

    const fetchedClients = await fetchClientsIzeeUseCase.fetchClientsIzee(
      pagination,
      franchisee || undefined,
      search || undefined
    )

    const {
      clientsIzee: { clients: existingClients },
    } = getState() as AppState

    dispatch(clientsIzeeActions.hasMoreClients(fetchedClients.length === CLIENTS_PER_PAGE))

    return page ? [...existingClients, ...fetchedClients] : fetchedClients
  }
)

interface ClientsIzeeState {
  clients: ClientIzeeEntity[]
  loading: boolean
  loadingPagination: boolean
  hasMoreClients: boolean
}

const initialState: ClientsIzeeState = {
  clients: [],
  loading: false,
  loadingPagination: false,
  hasMoreClients: false,
}

export const clientsIzeeSlice = createSlice({
  name: '@clientsIzee',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
    },

    loadingPagination: state => {
      state.loadingPagination = true
    },

    hasMoreClients: (state, action: PayloadAction<boolean>) => {
      state.hasMoreClients = action.payload
    },

    clearClients: state => {
      state.clients = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchClientsIzee.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.clients = action.payload || []
    })

    builder.addCase(fetchClientsIzee.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreClients = false
    })
  },
})

export const clientsIzeeActions = { ...clientsIzeeSlice.actions, fetchClientsIzee }

export const clientsIzeeReducer = clientsIzeeSlice.reducer
