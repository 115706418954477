import { RemoteFetchOpenOrderService } from 'src/data'

import { FetchOpenOrderUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchOpenOrderUseCase = (): FetchOpenOrderUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchOpenOrderService(authorizedHttpAdapter, '/sales/')
}
