import { RemoteFetchClientSignatureService } from 'src/data'

import { FetchClientSignatureUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientSignatureUseCase = (): FetchClientSignatureUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlClientPlaceholder = '{client}'
  const url = `/clients/${urlClientPlaceholder}/signatures`

  return new RemoteFetchClientSignatureService(authorizedHttpAdapter, url, urlClientPlaceholder)
}
