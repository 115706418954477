import { RemoteFetchCompletedOrdersService } from 'src/data'

import { FetchCompletedOrdersUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCompletedOrdersUseCase = (): FetchCompletedOrdersUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const params = {
    status: 'CLOSE',
  }

  return new RemoteFetchCompletedOrdersService(authorizedHttpAdapter, '/sales/', params)
}
