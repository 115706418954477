import React from 'react'
import { toast, ToastOptions as Options } from 'react-toastify'

import { Toast } from 'src/presentation/components'

export namespace UseToast {
  export type Params = {
    message: string
    type?: Type
    handleOnClick?: () => void
    options?: Options
  }

  export type Type = 'success' | 'error' | 'warning'
}

export const useToast = () => ({
  throwToast: ({ message, type = 'error', handleOnClick, options }: UseToast.Params) => {
    toast(<Toast message={message} type={type} handleOnClick={handleOnClick} />, options)
  },
})
