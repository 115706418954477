import React, { ButtonHTMLAttributes } from 'react'

type Props = ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<Props> = ({ children, className, ...props }) => (
  <button
    id={props?.name}
    className={`
      w-full
      px-12
      py-2
      mb-2
      text-sm
      font-semibold
      text-center
      text-white
      transition-all
      rounded
      hover:bg-opacity-80
      disabled:opacity-30
      ${props?.disabled && 'pointer-events-none'}
      ${className}
    `}
    data-cy={props?.name}
    {...props}
  >
    {children}
  </button>
)

export default Button
