import { RemoteFetchCanceledOrderService } from 'src/data'

import { FetchCanceledOrderUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchCanceledOrderUseCase = (): FetchCanceledOrderUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchCanceledOrderService(authorizedHttpAdapter, '/sales')
}
