import React from 'react'
import { Trans } from 'react-i18next'

import { Burguer, DropdownMenu, SearchBox } from 'src/presentation/components'

const Header: React.FC = () => (
  <header className="z-30 flex items-center justify-between w-full px-6 py-4 bg-white shadow-md">
    <Burguer />

    <h1
      className={`
        hidden
        text-lg
        font-semibold
        xl:block
        xl:text-xl
        lg:block
        lg:min-w-fit
      `}
    >
      <Trans i18nKey="RV_SALES_PANEL" />
    </h1>

    <div
      className={`
        relative
        flex
        items-center
        justify-end
        w-full
        xl:w-auto
        lg:justify-between
      `}
    >
      <SearchBox />

      <DropdownMenu />
    </div>
  </header>
)

export default Header
