import React from 'react'
import { FaRegFileExcel } from 'react-icons/fa6'
import { HiDocumentSearch } from 'react-icons/hi'
import { RiFileEditFill } from 'react-icons/ri'

import { OrderIzeeEntity } from 'src/domain'

const icons = {
  OPEN: function getIcon(iconSize = 16) {
    return <HiDocumentSearch size={iconSize} />
  },
  CONFIRMED: function getIcon(iconSize = 16) {
    return <RiFileEditFill size={iconSize} />
  },
  CANCELED: function getIcon(iconSize = 16) {
    return <FaRegFileExcel size={iconSize} />
  },
}

const colors = {
  OPEN: 'bg-primary',
  CONFIRMED: 'bg-warning',
  CANCELED: 'bg-error',
}

const messages = {
  BR: {
    OPEN: 'Aberto',
    CONFIRMED: 'Aguardando Revisão',
    CANCELED: 'Cancelado',
  },
  PT: {
    OPEN: 'Aberto',
    CONFIRMED: 'Aguardando Revisão',
    CANCELED: 'Cancelado',
  },
}

export const orderIzeeStatusIconMapper = (status: string, iconSize = 16) => {
  return icons[status as OrderIzeeEntity.Status](iconSize)
}

export const orderIzeeStatusColorMapper = (status: string) => colors[status as OrderIzeeEntity.Status]

export const orderIzeeStatusTextMapper = (language: 'BR' | 'PT', status: string) => {
  return messages?.[language]?.[status as OrderIzeeEntity.Status]
}
