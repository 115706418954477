import { HttpClient, HttpStatusCode } from 'src/data'
import { FranchiseeModel } from 'src/data/models/FranchiseeModel'

import { FetchFranchiseesUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchFranchiseesService implements FetchFranchiseesUseCase {
  constructor(private readonly httpClient: HttpClient<FranchiseeModel[]>, private readonly url: string) {}

  async fetchFranchisees() {
    const response = await this.httpClient.request({ method: 'get', url: this.url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return FranchiseeModel.fromJsonCollection(response.body as FranchiseeModel[]).toEntityCollection()

      default:
        throw new UnexpectedError()
    }
  }
}
