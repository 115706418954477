import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { ClientExtractFormats } from 'src/domain'

export const useExportClientExtractValidator = () => {
  const { t } = useTranslation()

  return {
    shape: Yup.object().shape({
      format: Yup.string()
        .oneOf(
          [
            ClientExtractFormats.CONSOLIDATED_EXTRACT,
            ClientExtractFormats.CONSOLIDATED_POST_PAID_EXTRACT,
            ClientExtractFormats.DETAILED_EXTRACT,
            ClientExtractFormats.POST_PAID_EXTRACT,
          ],
          t('INVALID')
        )
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      startDate: Yup.date().nullable().typeError(t('INVALID')),
      endDate: Yup.date().nullable().typeError(t('INVALID')),
    }),
  }
}
