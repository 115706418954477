import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { OrderIzeeEntity, CreateOrUpdateOrderIzeeUseCase } from 'src/domain'

import {
  makeCreateOrderIzeeUseCase,
  makeFetchOrderIzeeUseCase,
  makeUpdateOrderIzeeUseCase,
  makeConfirmOrderIzeeUseCase,
} from 'src/main'

interface FetchOrderIzeeParams {
  order: number
}

interface CreateOrderIzeeParams {
  data: CreateOrUpdateOrderIzeeUseCase.Params
}

interface UpdateOrderIzeeParams {
  order: number
  data: CreateOrUpdateOrderIzeeUseCase.Params
}

interface ConfirmOrderParams {
  order: number
  data: CreateOrUpdateOrderIzeeUseCase.Params
}

const fetchOrderIzee = createAsyncThunk('@orderIzee/fetchOrderIzee', async ({ order }: FetchOrderIzeeParams) => {
  const fetchOrderIzeeUseCase = makeFetchOrderIzeeUseCase()

  return fetchOrderIzeeUseCase.fetchOrderIzee(order)
})

const createOrderIzee = createAsyncThunk(
  '@orderIzee/createOrderIzee',
  async ({ data }: CreateOrderIzeeParams, { rejectWithValue }) => {
    try {
      const createOrderIzeeUseCase = makeCreateOrderIzeeUseCase()

      return await createOrderIzeeUseCase.createOrderIzee(data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const updateOrderIzee = createAsyncThunk(
  '@orderIzee/updateOrderIzee',
  async ({ order, data }: UpdateOrderIzeeParams, { rejectWithValue }) => {
    try {
      const updateOrderIzeeUseCase = makeUpdateOrderIzeeUseCase()

      await updateOrderIzeeUseCase.updateOrderIzee(order, data)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const confirmOrderIzee = createAsyncThunk(
  '@orderIzee/confirmOrderIzee',
  async ({ order, data }: ConfirmOrderParams, { rejectWithValue }) => {
    try {
      const updateOrderIzeeUseCase = makeUpdateOrderIzeeUseCase()
      const confirmOrderIzeeUseCase = makeConfirmOrderIzeeUseCase()

      await updateOrderIzeeUseCase.updateOrderIzee(order, data)
      await confirmOrderIzeeUseCase.confirmOrderIzee(order)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

interface OrderIzeeState {
  order: OrderIzeeEntity.Entity | null
  loading: boolean
  createOrderIzeeSuccess: boolean
  createOrderIzeeError: Error | null
  updateOrderIzeeSuccess: boolean
  updateOrderIzeeError: Error | null
  confirmOrderIzeeSuccess: boolean
  confirmOrderIzeeError: null | Error
}

const initialState: OrderIzeeState = {
  order: null,
  loading: false,
  createOrderIzeeSuccess: false,
  createOrderIzeeError: null,
  updateOrderIzeeSuccess: false,
  updateOrderIzeeError: null,
  confirmOrderIzeeSuccess: false,
  confirmOrderIzeeError: null,
}

export const orderSlice = createSlice({
  name: '@orderIzee',
  initialState,
  reducers: {
    createOrderIzeeSuccess: state => {
      state.createOrderIzeeSuccess = false
    },

    createOrderIzeeError: state => {
      state.createOrderIzeeError = null
    },

    updateOrderIzeeSuccess: state => {
      state.updateOrderIzeeSuccess = false
    },

    updateOrderIzeeError: state => {
      state.updateOrderIzeeError = null
    },

    confirmOrderIzeeSuccess: state => {
      state.confirmOrderIzeeSuccess = false
    },

    confirmOrderIzeeError: state => {
      state.confirmOrderIzeeError = null
    },

    clearOrderIzee: state => {
      state.order = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderIzee.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchOrderIzee.fulfilled, (state, action) => {
      state.loading = false
      state.order = action.payload
    })

    builder.addCase(fetchOrderIzee.rejected, state => {
      state.loading = false
    })

    builder.addCase(createOrderIzee.fulfilled, (state, action) => {
      state.createOrderIzeeSuccess = true
      state.createOrderIzeeError = null
      state.order = action.payload
    })

    builder.addCase(createOrderIzee.rejected, (state, action) => {
      state.createOrderIzeeSuccess = false
      state.createOrderIzeeError = action.payload as Error
    })

    builder.addCase(updateOrderIzee.fulfilled, state => {
      state.updateOrderIzeeSuccess = true
      state.updateOrderIzeeError = null
    })

    builder.addCase(updateOrderIzee.rejected, (state, action) => {
      state.updateOrderIzeeSuccess = false
      state.updateOrderIzeeError = action.payload as Error
    })

    builder.addCase(confirmOrderIzee.pending, state => {
      state.loading = true
    })

    builder.addCase(confirmOrderIzee.fulfilled, state => {
      state.loading = false
      state.confirmOrderIzeeSuccess = true
      state.confirmOrderIzeeError = null
    })

    builder.addCase(confirmOrderIzee.rejected, (state, action) => {
      state.loading = false
      state.confirmOrderIzeeSuccess = false
      state.confirmOrderIzeeError = action.payload as Error
    })
  },
})

export const orderIzeeActions = {
  ...orderSlice.actions,
  fetchOrderIzee,
  createOrderIzee,
  updateOrderIzee,
  confirmOrderIzee,
}

export const orderIzeeReducer = orderSlice.reducer
