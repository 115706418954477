const billingModalityPanelTypes = {
  BR: {
    PAY_PER_USE: 'Avulso',
    BASIC_LEGACY_PLAN: 'Plano Básico (legado)',
    BASIC_PLAN: 'Plano Básico',
    ADVANCED_PLAN: 'Plano Avançado',
    PROFESSIONAL_PLAN: 'Plano Profissional',
  },
  PT: {
    PAY_PER_USE: 'Avulso',
    BASIC_LEGACY_PLAN: 'Plano Básico (legado)',
    BASIC_PLAN: 'Plano Básico',
    ADVANCED_PLAN: 'Plano Avançado',
    PROFESSIONAL_PLAN: 'Plano Profissional',
  },
}

export const billingModalityPanelMapper = (
  language: 'BR' | 'PT',
  billingModality: 'PAY_PER_USE' | 'BASIC_LEGACY_PLAN' | 'BASIC_PLAN' | 'ADVANCED_PLAN' | 'PROFESSIONAL_PLAN'
) => {
  return billingModalityPanelTypes?.[language]?.[billingModality] || ''
}
