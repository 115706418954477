import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BsClipboard2XFill } from 'react-icons/bs'
import { FaUserCircle } from 'react-icons/fa'
import { MdAttachMoney, MdPayment } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { useHistory, useParams } from 'react-router-dom'

import { OrderEntity } from 'src/domain'

import { Breadcrumb, CurrencyInput, Input, TextArea } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useToast, useDate, useLanguage, useMask } from 'src/presentation/hooks'
import {
  billingModeMapper,
  firstBillingMonthMapper,
  billingModalityMapper,
  paymentFormMapper,
} from 'src/presentation/mappers'
import { orderActions } from 'src/presentation/store/ducks/order'

interface Params {
  orderId: string
}

const CanceledOrderDetailsPage: React.FC = () => {
  const { orderId } = useParams<Params>()
  const { push } = useHistory()

  const { throwToast } = useToast()
  const { formatDate } = useDate()

  const dispatch = useAppDispatch()
  const { loading: orderLoading, currentCanceledOrder, fetchCanceledOrderError } = useAppSelector(state => state.order)

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()
  const { MONEY_SIGN } = useMask()

  const loading = orderLoading

  useEffect(() => {
    document.title = t('ORDER_CANCELLED_WITH_PARAM', { orderId: orderId || '' })
  }, [orderId])

  useEffect(() => {
    if (fetchCanceledOrderError) {
      throwToast({ message: t('UNABLE_TO_FETCH_CANCELLED_ORDER') })
    }

    return () => {
      dispatch(orderActions.fetchCanceledOrderError())
    }
  }, [dispatch, fetchCanceledOrderError])

  useEffect(() => {
    dispatch(orderActions.fetchCanceledOrder({ order: orderId }))
  }, [dispatch, orderId])

  useEffect(() => {
    if (currentCanceledOrder && currentCanceledOrder?.status === OrderEntity.Status.OPEN) {
      throwToast({ message: t('ORDER_OPEN_WITH_PARAM', { orderId: currentCanceledOrder?.id || '' }) })

      push('/canceled-orders')

      dispatch(orderActions.clearCurrentOrders())
    }
    if (currentCanceledOrder && currentCanceledOrder?.status === OrderEntity.Status.CLOSE) {
      throwToast({ message: t('ORDER_IS_COMPLETED_WITH_PARAM', { orderId: currentCanceledOrder?.id || '' }) })

      push('/completed-orders')

      dispatch(orderActions.clearCurrentOrders())
    }
  }, [currentCanceledOrder, currentCanceledOrder?.id, currentCanceledOrder?.status, dispatch, push])

  const currentCanceledOrderCreditPrice =
    currentCanceledOrder?.credits && currentCanceledOrder?.price
      ? (Number(currentCanceledOrder?.price) / (Number(currentCanceledOrder?.credits) / 100)).toFixed(2)
      : 0

  return (
    <div data-cy="canceled-order-details-page" className="w-full p-6">
      <Breadcrumb previousPageName="Pedidos cancelados" previousPageUrl="/canceled-orders" currentPageName={orderId} />

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-6 border-b-2 border-senary">
          <h2 className="inline-block text-base font-semibold text-black lg:text-lg">
            <Trans i18nKey="SALE_WITH_PARAM" values={{ orderId }} />
          </h2>
        </div>

        <form className="w-full" onSubmit={() => null}>
          <div className="flex items-center justify-start mb-6">
            <BsClipboard2XFill className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="ORDER_CANCELLED" />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className=" w-full lg:w-1/2 mb-4 mr-0 lg:mr-8">
              <Input
                name="cancellation_reason"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.cancellation_reason}
                loading={loading}
                label={t('REASON')}
              />
            </div>

            <div className="w-full mb-4 lg:w-1/2">
              <Input
                name="cancellation_observation"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.cancellation_observation}
                loading={loading}
                label={t('OBSERVATIONS')}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex-1 w-1/2 mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input
                name="franchisee_name"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.franchisee_name}
                loading={loading}
                label={t('UNIT')}
              />
            </div>

            <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input
                name="canceled_by"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.canceled_by}
                loading={loading}
                label={t('CANCELLED_BY')}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                name="cnpj"
                disabled
                value={currentCanceledOrder?.id && formatDate(currentCanceledOrder?.canceled_at || '')}
                loading={loading}
                label={t('CANCELLATION_DATE')}
              />
            </div>
          </div>

          <div className="flex items-center justify-start mb-6">
            <FaUserCircle className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_IDENTIFICATION" />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input
                name="client"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.client?.name}
                loading={loading}
                label={t('CLIENT')}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="user"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.userId}
                loading={loading}
                label={t('USER')}
              />
            </div>

            {currentCanceledOrder?.client?.legalPersonInfo?.cnpj ? (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input
                  name="cnpj"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrder?.client?.legalPersonInfo?.cnpj}
                  loading={loading}
                  label={t('CNPJ')}
                />
              </div>
            ) : (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input
                  name="cpf"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrder?.client?.naturalPersonInfo?.cpf}
                  loading={loading}
                  label={t('CPF')}
                />
              </div>
            )}
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="zipcode"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.zipcode}
                  loading={loading}
                  label={t('ZIP_CODE')}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="state"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.state}
                  loading={loading}
                  label={t('STATE')}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="city"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.city}
                  loading={loading}
                  label={t('CITY')}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <Input
                  name="neighborhood"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.neighborhood}
                  loading={loading}
                  label={t('NEIGHBORHOOD')}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
              <Input
                name="street"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.street}
                loading={loading}
                label={t('STREET')}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="number"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.number}
                loading={loading}
                label={t('NUMBER')}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                name="complement"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.client?.address?.complement}
                loading={loading}
                label={t('COMPLEMENT')}
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 mb-4 mr-4 lg:mr-8">
              <Input
                name="email"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.client?.contactInfo?.email}
                loading={loading}
                label={t('EMAIL')}
              />
            </div>

            <div className="w-1/2 mb-4">
              <Input
                name="phone"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.client?.contactInfo?.phone}
                loading={loading}
                label={t('PHONE')}
              />
            </div>
          </div>

          {currentCanceledOrder?.client?.legalPersonInfo?.cnpj && (
            <>
              <div className="flex items-center justify-start mt-6 mb-6">
                <RiUserSettingsLine className="mr-2 text-black" size={26} />

                <h3 className="text-sm font-semibold lg:text-base">
                  <Trans i18nKey="RESPONSIBLE_PERSON" />
                </h3>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
                  <Input
                    name="responsibleName"
                    disabled
                    value={currentCanceledOrder?.id && currentCanceledOrder?.client?.responsible?.name}
                    loading={loading}
                    label={t('NAME')}
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
                  <Input
                    name="responsibleEmail"
                    disabled
                    value={currentCanceledOrder?.id && currentCanceledOrder?.client?.responsible?.email}
                    loading={loading}
                    label={t('EMAIL')}
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                  <Input
                    name="responsiblePhone"
                    disabled
                    value={currentCanceledOrder?.id && currentCanceledOrder?.client?.responsible?.phone}
                    loading={loading}
                    label={t('PHONE')}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdAttachMoney className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="SALE" />
            </h3>
          </div>

          <div className="flex flex-wrap w-full mb-0 lg:mb-6">
            <div className="w-full mb-4 lg:w-1/2">
              <Input
                name="seller"
                disabled
                value={(currentCanceledOrder?.id && currentCanceledOrder?.seller?.name) || 'Não cadastrado'}
                loading={loading}
                label={t('SELLER')}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <CurrencyInput
                  name="credits"
                  disabled
                  value={currentCanceledOrder?.credits ? currentCanceledOrder?.credits / 100 : 0}
                  loading={loading}
                  label={t('CREDIT_QUANTITY')}
                  prefix=" "
                  lng={currentLanguage}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <CurrencyInput
                  name="creditPrice"
                  disabled
                  value={currentCanceledOrder?.id && currentCanceledOrderCreditPrice}
                  placeholder={`${MONEY_SIGN} 0,00`}
                  loading={loading}
                  label={t('CREDIT_PRICE')}
                  lng={currentLanguage}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <CurrencyInput
                  name="price"
                  disabled
                  value={currentCanceledOrder?.price ? currentCanceledOrder?.price : 0}
                  placeholder={`${MONEY_SIGN} 0,00`}
                  loading={loading}
                  label={t('TOTAL_AMOUNT')}
                  lng={currentLanguage}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdPayment className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="PAYMENT" />
            </h3>
          </div>

          <div className="flex mb-0 lg:mb-6">
            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Input
                name="billingModality"
                disabled
                value={
                  currentCanceledOrder?.id && currentCanceledOrder?.billingModality
                    ? billingModalityMapper(currentLanguage, currentCanceledOrder?.billingModality)
                    : ''
                }
                loading={loading}
                label={t('MODALITY')}
              />
            </div>

            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Input
                name="billingMode"
                disabled
                value={
                  currentCanceledOrder?.id && currentCanceledOrder?.billingMode
                    ? billingModeMapper(currentLanguage, currentCanceledOrder?.billingMode)
                    : ''
                }
                loading={loading}
                label={t('PAYMENT')}
              />
            </div>

            <div className="w-1/3 mb-4">
              <Input
                name="numberOfInstallments"
                disabled
                value={
                  currentCanceledOrder?.id && currentCanceledOrder?.numberOfInstallments
                    ? `${currentCanceledOrder?.numberOfInstallments}x`
                    : ''
                }
                loading={loading}
                label={t('INSTALLMENT')}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="sm:flex-1 sm:w-1/2 w-full mb-4 sm:mr-4 lg:mr-8">
              <Input
                name="paymentForm"
                disabled
                value={
                  currentCanceledOrder?.id && currentCanceledOrder?.paymentForm
                    ? paymentFormMapper(currentLanguage, currentCanceledOrder?.paymentForm)
                    : ''
                }
                loading={loading}
                label={t('PAYMENT_METHOD')}
              />
            </div>

            <div className="sm:flex-1 sm:w-1/2 w-full mb-4 sm:mr-4 lg:mr-8">
              <Input
                name="firstBillingMonth"
                disabled
                value={
                  currentCanceledOrder?.id && currentCanceledOrder?.firstBillingMonth
                    ? firstBillingMonthMapper(currentLanguage, currentCanceledOrder?.firstBillingMonth)
                    : ''
                }
                loading={loading}
                label={t('FIRST_DUE_DATE')}
              />
            </div>

            <div className="sm:flex-1 sm:w-1/2 w-full mb-4">
              <Input
                name="billingDay"
                disabled
                value={currentCanceledOrder?.id && currentCanceledOrder?.billingDay}
                loading={loading}
                label={t('DUE_DATE_BOLETO')}
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-full mb-4">
              <TextArea
                name="paymentDetails"
                disabled
                value={
                  currentCanceledOrder?.id && currentCanceledOrder?.paymentDetails
                    ? currentCanceledOrder?.paymentDetails
                    : ''
                }
                loading={loading}
                label={t('PAYMENT_DETAILS')}
              />
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}

export default CanceledOrderDetailsPage
