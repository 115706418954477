import { RemoteCreateClientService } from 'src/data'

import { CreateClientUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeCreateClientUseCase = (): CreateClientUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteCreateClientService(authorizedHttpAdapter, '/clients/')
}
