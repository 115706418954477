import React, { RefObject, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { ClientIzeeEntity } from 'src/domain'

import locationIcon from 'src/presentation/assets/images/location.svg'
import planIcon from 'src/presentation/assets/images/plan-check.svg'
import shopIcon from 'src/presentation/assets/images/shop-icon.svg'
import { PaginateLoading } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, usePersistedPreviousDataQuantity, useScroll } from 'src/presentation/hooks'
import { ClientIzeeLoading, ClientIzeeMenuItem } from 'src/presentation/pages'
import { clientsIzeeActions } from 'src/presentation/store/ducks/clientsIzee'

const formatDate = (dateString: string) => {
  const date = new Date(dateString)

  return format(date, 'dd/MM/yyyy', { locale: ptBR })
}

const ClientsIzeePage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { clients, loading, loadingPagination, hasMoreClients } = useAppSelector(state => state.clientsIzee)
  const { isSearchActive, franchiseeId, freeTermText } = useAppSelector(state => state.headerSearch)

  const [currentPage, lastElementRef, setCurrentPage] = useScroll({ hasMoreItems: hasMoreClients, loading })

  const { t } = useTranslation()

  const storageKey = isSearchActive ? '@rvpdv:clientsIzeeSearchQuantity' : '@rvpdv:clientsIzeeQuantity'

  const clientsPreviousQuantity = usePersistedPreviousDataQuantity(storageKey, clients)

  useEffect(() => {
    document.title = t('IZEE_CLIENTS')
  }, [])

  useEffect(() => {
    setCurrentPage(0)
  }, [franchiseeId, freeTermText])

  useEffect(() => {
    if (hasMoreClients && clients.length) {
      dispatch(
        clientsIzeeActions.fetchClientsIzee({ franchisee: franchiseeId, search: freeTermText, page: currentPage })
      )
    }
  }, [currentPage, dispatch])

  useEffect(() => {
    dispatch(clientsIzeeActions.fetchClientsIzee({ franchisee: franchiseeId, search: freeTermText, page: null }))

    return () => {
      dispatch(clientsIzeeActions.clearClients())
    }
  }, [dispatch, franchiseeId, freeTermText])

  const formatAddress = (client: ClientIzeeEntity) => {
    let address = ''

    if (client?.address?.address) address = client?.address?.address
    if (client?.address?.number) address = address + ', ' + client?.address?.number
    if (client?.address?.neighborhood) address = address + ', ' + client?.address?.neighborhood
    if (client?.address?.city) address = address + ', ' + client?.address?.city

    return address
  }

  return (
    <div data-cy="clients-page" className="relative z-0 flex flex-col w-full p-6 overflow-hidden">
      <div className="flex flex-col items-start justify-start relative mt-6 px-4 py-6 overflow-auto bg-white rounded shadow-md h-[calc(100vh-196px)] md:h-[calc(100vh-172px)]">
        {loading ? (
          Array.from({ length: clientsPreviousQuantity }).map((_, index) => <ClientIzeeLoading key={index} />)
        ) : clients.length ? (
          clients.map((client, index) => {
            const isOneOfLastItems =
              (index === clients.length - 1 || index === clients.length - 2) && clients.length > 1

            return (
              <div
                key={client?.id}
                className="relative flex h-20 flex-none items-center justify-between md:w-full p-4 mb-4 border-2 rounded border-tertiary last-of-type:mb-0 w-[732px]"
                data-cy={`client-item-${client?.id}`}
                {...(clients.length === index + 1 && {
                  ref: lastElementRef as unknown as RefObject<HTMLDivElement>,
                })}
              >
                <div className="flex items-center justify-start mr-2">
                  <img className="hidden w-10 h-10 mr-4 lg:block" src={shopIcon} alt="Shop Icon" />

                  <div className="flex flex-col items-start justify-start">
                    <h4
                      className="overflow-hidden text-lg font-semibold w-128 text-nonary overflow-ellipsis whitespace-nowrap"
                      title={client?.name}
                    >
                      {client?.name}
                    </h4>

                    {client?.last_sale && (
                      <Link
                        to={`/izee-sales/${client?.last_sale.id}/view`}
                        className="text-xs font-semibold text-tertiary"
                      >
                        <Trans i18nKey="LAST_SALE" />: id {client?.last_sale?.id} <Trans i18nKey="IN" />{' '}
                        {formatDate(client?.last_sale?.created_at)}
                        {client?.last_sale?.status === 'OPEN' && (
                          <span className="font-bold text-nonary">
                            (<Trans i18nKey="OPEN_FEMALE" />)
                          </span>
                        )}
                      </Link>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-start w-96">
                  <div className="flex flex-col items-start justify-center mr-2">
                    <span className="flex justify-start mb-2 text-xs font-semibold text-tertiary">
                      <img className="w-4 h-4 mr-1" src={locationIcon} alt="Shop Icon" />

                      {formatAddress(client)}
                    </span>

                    <span className="flex justify-start text-xs font-semibold text-tertiary">
                      <img className="w-4 h-4 mr-1" src={planIcon} alt="Shop Icon" />

                      {client?.subscriptionPlan.label}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start ml-auto">
                    <ClientIzeeMenuItem clientId={client?.id} isOneOfLastItems={isOneOfLastItems} />
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="absolute inset-x-0 mt-12 bg-white" data-cy="not-found-message">
            <p className="text-lg font-semibold text-center">
              <Trans i18nKey="NO_CLIENTS_FOUND" />
            </p>
          </div>
        )}
      </div>

      <PaginateLoading loading={loadingPagination} />
    </div>
  )
}

export default ClientsIzeePage
