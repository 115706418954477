import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaUserCircle } from 'react-icons/fa'
import { MdAttachMoney, MdPayment } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { Link, useHistory, useParams } from 'react-router-dom'

import { getDate, getDaysInMonth } from 'date-fns'
import { useFormik } from 'formik'

import { OpenOrderConfirmError, OrderEntity } from 'src/domain'

import { Breadcrumb, Button, CurrencyInput, Input, Select, TextArea } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useLanguage, useToast } from 'src/presentation/hooks'
import {
  billingModeMapper,
  firstBillingMonthMapper,
  billingModalityMapper,
  paymentFormMapper,
  confirmOpenOrderErrorMapper,
} from 'src/presentation/mappers'
import { orderActions } from 'src/presentation/store/ducks/order'
import { sellersActions } from 'src/presentation/store/ducks/sellers'

import { useOpenOrderEditValidator } from 'src/validation'

interface Params {
  orderId: string
}

enum SubmitType {
  SAVE = 'SAVE',
  UPDATE = 'UPDATE',
}

let formSubmitTimer: NodeJS.Timeout | null = null

const OpenOrderEditPage: React.FC = () => {
  const [numberOfInstallmentsOptions, setNumberOfInstallmentsOptions] = useState<number[]>([])
  const [isNumberOfInstallmentsFieldDisabled, setIsNumberOfInstallmentsFieldDisabled] = useState(false)
  const [billingDayOptions, setBillingDayOptions] = useState<number[]>([])

  const { orderId } = useParams<Params>()
  const { push } = useHistory()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { loading: sellersLoading, sellers } = useAppSelector(state => state.sellers)
  const { franchisees } = useAppSelector(state => state.franchisees)
  const { user } = useAppSelector(state => state.auth)
  const {
    loading: orderLoading,
    currentOpenOrder,
    fetchOpenOrderError,
    updateOpenOrderError,
    updateOpenOrderSuccess,
    confirmOpenOrderError,
    confirmOpenOrderSuccess,
  } = useAppSelector(state => state.order)

  const { shape } = useOpenOrderEditValidator()
  const { currentLanguage } = useLanguage()
  const { throwToast } = useToast()

  const loading = orderLoading || sellersLoading
  let sellersOptions = sellers

  if (user?.isOfficeFranchisee) {
    sellersOptions = sellers.filter(
      seller =>
        seller.type == 'FRANCHISEE' ||
        currentOpenOrder?.seller?.id == seller.id ||
        (currentOpenOrder?.franchiseeId && seller.franchiseeIds.includes(currentOpenOrder?.franchiseeId))
    )
  } else if (!user?.isCommercial) {
    sellersOptions = sellers.filter(
      seller =>
        seller.type == 'FRANCHISEE' ||
        seller.type == 'INSIDE_SALES' ||
        currentOpenOrder?.seller?.id == seller.id ||
        (currentOpenOrder?.franchiseeId && seller.franchiseeIds.includes(currentOpenOrder?.franchiseeId))
    )
  }

  const {
    values: formValues,
    errors: formErrors,
    isValid,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: shape,
    initialValues: {
      submitType: SubmitType.UPDATE,
      seller: currentOpenOrder?.seller?.id,
      credits: Number(currentOpenOrder?.credits) / 100 || 0,
      creditPrice: (Number(currentOpenOrder?.price) / (Number(currentOpenOrder?.credits) / 100) || 0).toFixed(2),
      price: (Number(currentOpenOrder?.price) || 0).toFixed(2),
      numberOfInstallments: currentOpenOrder?.numberOfInstallments || 1,
      billingMode: currentOpenOrder?.billingMode || OrderEntity.BillingMode.ON_TERM,
      billingModality: currentOpenOrder?.billingModality || OrderEntity.BillingModality.PRE_PAID,
      paymentForm: currentOpenOrder?.paymentForm || OrderEntity.PaymentForm.TICKET,
      firstBillingMonth:
        currentOpenOrder?.firstBillingMonth ||
        (new Date().getDate() > 25 ? OrderEntity.FirstBillingMonth.NEXT : OrderEntity.FirstBillingMonth.CURRENT),
      billingDay: currentOpenOrder?.billingDay,
      paymentDetails: currentOpenOrder?.paymentDetails,
    },
    onSubmit: (submitValues, { setSubmitting }) => {
      async function handleSubmit() {
        try {
          const formattedSeller = Number(submitValues.seller) || null
          const formattedCredits = Math.round(Number(submitValues.credits) * 100)
          const formattedPrice = Number(submitValues.price?.replaceAll(',', '.'))
          const formattedNumberOfInstallments = Number(submitValues.numberOfInstallments) || 1
          const formattedBillingDay = Number(submitValues.billingDay) > 0 ? Number(submitValues.billingDay) : null

          if (!formattedBillingDay) {
            setFieldError('billingDay', t('REQUIRED'))

            return
          }

          const payload = {
            sellerId: formattedSeller,
            credits: formattedCredits,
            price: formattedPrice,
            numberOfInstallments: formattedNumberOfInstallments,
            billingMode: submitValues.billingMode as OrderEntity.BillingMode,
            billingModality: submitValues.billingModality as OrderEntity.BillingModality,
            paymentForm: submitValues.paymentForm as OrderEntity.PaymentForm,
            firstBillingMonth: submitValues.firstBillingMonth as OrderEntity.FirstBillingMonth,
            billingDay: formattedBillingDay,
            paymentDetails: submitValues.paymentDetails || null,
          }

          if (submitValues.submitType === SubmitType.UPDATE) {
            dispatch(orderActions.updateOpenOrder({ order: orderId, data: payload }))
          } else if (
            submitValues.submitType === SubmitType.SAVE &&
            currentOpenOrder?.status === OrderEntity.Status.OPEN
          ) {
            dispatch(orderActions.confirmOpenOrder({ order: orderId, data: payload }))
          }
        } catch {
          throwToast({ message: t('UNABLE_TO_UPDATE_CONFIRM_SALE') })
        } finally {
          formSubmitTimer = setTimeout(() => setSubmitting(false), 5000)
        }
      }

      handleSubmit()
    },
  })

  useEffect(() => {
    document.title = `${t('ORDER_OPENED_WITH_PARAM', { orderId })} - PDV`
  }, [orderId])

  const handleProduceMonthDays = (now: Date, produceForNextMonth = false) => {
    if (produceForNextMonth) {
      const nextMonth = now.getMonth() + 1 === 12 ? 0 : now.getMonth() + 1 // validates if current month is december
      const currentOrNextYear = nextMonth === 0 ? now.getFullYear() + 1 : now.getFullYear() // validates if is a new year

      const nextMonthDate = new Date(currentOrNextYear, nextMonth, 1)
      const nextMonthDays = Array.from({ length: getDaysInMonth(nextMonthDate) }, (_, index) => index + 1)

      return nextMonthDays
    }

    const currentDay = now.getDate()
    const currentMonthDays = Array.from(
      { length: getDaysInMonth(now) - (currentDay - 1) },
      (_, index) => index + currentDay
    )

    return currentMonthDays
  }

  useEffect(() => {
    const { billingMode } = formValues

    if (billingMode === OrderEntity.BillingMode.IN_CASH) {
      setIsNumberOfInstallmentsFieldDisabled(true)
      setNumberOfInstallmentsOptions([1])
      setFieldValue('numberOfInstallments', 1)

      return
    }

    setIsNumberOfInstallmentsFieldDisabled(false)
    setNumberOfInstallmentsOptions(Array.from({ length: 12 }, (_, index) => index + 1))
  }, [formValues.billingMode])

  useEffect(() => {
    const { firstBillingMonth } = formValues
    const now = new Date()

    if (firstBillingMonth === OrderEntity.FirstBillingMonth.CURRENT) {
      const currentMonthDaysCount = getDaysInMonth(now)
      const today = getDate(now)

      const remainingMonthDaysCount = currentMonthDaysCount - today

      if (remainingMonthDaysCount > 0) {
        setBillingDayOptions(handleProduceMonthDays(now))

        return
      }

      setBillingDayOptions([today])
      setFieldValue('billingDay', today)

      return
    }

    setBillingDayOptions(handleProduceMonthDays(now, true))
  }, [formValues.firstBillingMonth])

  useEffect(() => {
    if (fetchOpenOrderError) {
      throwToast({ message: t('UNABLE_TO_FETCH_OPEN_ORDER') })
    }

    return () => {
      dispatch(orderActions.fetchOpenOrderError())
    }
  }, [dispatch, fetchOpenOrderError])

  useEffect(() => {
    dispatch(orderActions.fetchOpenOrder({ order: orderId }))

    !sellers?.length && dispatch(sellersActions.fetchSellers())

    return () => {
      clearTimeout(formSubmitTimer as NodeJS.Timeout)
    }
  }, [dispatch, orderId])

  useEffect(() => {
    const { credits, price } = formValues

    if (!credits) return

    const priceWithoutComma = String(price || 0).replaceAll(',', '.')

    setFieldValue('creditPrice', (Number(priceWithoutComma) / Number(credits)).toFixed(2))
  }, [formValues?.credits, formValues?.price])

  useEffect(() => {
    if (updateOpenOrderSuccess && !updateOpenOrderError) {
      throwToast({ type: 'success', message: t('SALE_UPDATED_SUCCESSFULLY_WITH_PARAM', { orderId: orderId || '' }) })
      push('/open-orders')

      return
    }

    if (updateOpenOrderError && !updateOpenOrderSuccess) {
      throwToast({ message: t('UNABLE_TO_UPDATE_SALE') })
    }

    return () => {
      dispatch(orderActions.updateOpenOrderSuccess())
      dispatch(orderActions.updateOpenOrderError())
    }
  }, [dispatch, updateOpenOrderError, updateOpenOrderSuccess])

  useEffect(() => {
    if (confirmOpenOrderSuccess && !confirmOpenOrderError) {
      throwToast({ type: 'success', message: t('SALE_CONFIRMED_SUCCESSFULLY_WITH_PARAM', { orderId: orderId || '' }) })
      push('/open-orders')

      return
    }

    if (confirmOpenOrderError && !confirmOpenOrderSuccess) {
      throwToast({
        message:
          confirmOpenOrderError instanceof OpenOrderConfirmError
            ? confirmOpenOrderErrorMapper(currentLanguage, confirmOpenOrderError.message)
            : t('UNABLE_TO_CONFIRM_SALE'),
      })

      dispatch(orderActions.confirmOpenOrderError())
    }

    return () => {
      dispatch(orderActions.confirmOpenOrderSuccess())
      dispatch(orderActions.confirmOpenOrderError())
    }
  }, [dispatch, confirmOpenOrderError, confirmOpenOrderSuccess])

  useEffect(() => {
    if (currentOpenOrder && currentOpenOrder?.status === OrderEntity.Status.CLOSE) {
      throwToast({ message: t('ORDER_ALREADY_COMPLETED_WITH_PARAM', { orderId: currentOpenOrder?.id || '' }) })
      push('/open-orders')

      dispatch(orderActions.clearCurrentOrders())
    }
  }, [currentOpenOrder, currentOpenOrder?.id, currentOpenOrder?.status, dispatch, push])

  const isFormInvalidOrBlocked = !!(
    isSubmitting ||
    !currentOpenOrder?.id ||
    !isValid ||
    currentOpenOrder?.status === OrderEntity.Status.CLOSE
  )

  const lng =
    franchisees.find(franchisee => franchisee.id === currentOpenOrder?.franchiseeId)?.country || currentLanguage

  return (
    <div data-cy="open-order-edit-page" className="w-full p-6">
      <Breadcrumb
        previousPageName={t('OPEN_ORDERS', { lng })}
        previousPageUrl="/open-orders"
        currentPageName={orderId}
      />

      <section className="w-full px-4 py-6 bg-white rounded shadow-sm">
        <div className="mb-6 border-b-2 border-senary">
          <h2 className="mb-4 text-base font-semibold text-black lg:text-lg">
            <Trans i18nKey="SALE_WITH_PARAM" values={{ orderId }} tOptions={{ lng }} />
          </h2>
        </div>

        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex items-center justify-start mb-6">
            <FaUserCircle className="mr-2 text-black disabled:" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="CLIENT_IDENTIFICATION" tOptions={{ lng }} />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
              <Input
                name="client"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.name}
                loading={loading}
                label={t('CLIENT', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="user"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.userId}
                loading={loading}
                label={t('USER', { lng })}
              />
            </div>

            {currentOpenOrder?.client?.legalPersonInfo?.cnpj ? (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input
                  name="cnpj"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.legalPersonInfo?.cnpj}
                  loading={loading}
                  label={t('CNPJ', { lng })}
                />
              </div>
            ) : (
              <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                <Input
                  name="cpf"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.naturalPersonInfo?.cpf}
                  loading={loading}
                  label={t('CPF', { lng })}
                />
              </div>
            )}
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="zipcode"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.zipcode}
                  loading={loading}
                  label={t('ZIP_CODE', { lng })}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="state"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.state}
                  loading={loading}
                  label={t('STATE', { lng })}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 mr-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="city"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.city}
                  loading={loading}
                  label={t('CITY', { lng })}
                />
              </div>

              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <Input
                  name="neighborhood"
                  disabled
                  value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.neighborhood}
                  loading={loading}
                  label={t('NEIGHBORHOOD', { lng })}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="w-full mb-4 lg:mr-8 lg:w-1/3">
              <Input
                name="street"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.street}
                loading={loading}
                label={t('STREET', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
              <Input
                name="number"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.number}
                loading={loading}
                label={t('NUMBER', { lng })}
              />
            </div>

            <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
              <Input
                name="complement"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.address?.complement}
                loading={loading}
                label={t('COMPLEMENT', { lng })}
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 mb-4 mr-4 lg:mr-8">
              <Input
                name="email"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.contactInfo?.email}
                loading={loading}
                label={t('EMAIL', { lng })}
              />
            </div>

            <div className="w-1/2 mb-4">
              <Input
                name="phone"
                disabled
                value={currentOpenOrder?.id && currentOpenOrder?.client?.contactInfo?.phone}
                loading={loading}
                label={t('PHONE', { lng })}
              />
            </div>
          </div>

          {currentOpenOrder?.client?.legalPersonInfo?.cnpj && (
            <>
              <div className="flex items-center justify-start mt-6 mb-6">
                <RiUserSettingsLine className="mr-2 text-black" size={26} />

                <h3 className="text-sm font-semibold lg:text-base">
                  <Trans i18nKey="RESPONSIBLE_PERSON" tOptions={{ lng }} />
                </h3>
              </div>

              <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
                <div className="w-full mb-4 mr-0 lg:mr-8 lg:w-1/3">
                  <Input
                    name="responsibleName"
                    disabled
                    value={currentOpenOrder?.id && currentOpenOrder?.client?.responsible?.name}
                    loading={loading}
                    label={t('NAME', { lng })}
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 mr-4 lg:mr-8 lg:w-1/3">
                  <Input
                    name="responsibleEmail"
                    disabled
                    value={currentOpenOrder?.id && currentOpenOrder?.client?.responsible?.email}
                    loading={loading}
                    label={t('EMAIL', { lng })}
                  />
                </div>

                <div className="flex-1 w-1/2 mb-4 lg:w-1/3">
                  <Input
                    name="responsiblePhone"
                    disabled
                    value={currentOpenOrder?.id && currentOpenOrder?.client?.responsible?.phone}
                    loading={loading}
                    label={t('PHONE', { lng })}
                  />
                </div>
              </div>
            </>
          )}

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdAttachMoney className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="SALE" tOptions={{ lng }} />
            </h3>
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Select
                  name="seller"
                  disabled={!currentOpenOrder?.id}
                  value={formValues.seller}
                  onChange={handleChange}
                  loading={loading}
                  error={formErrors.seller}
                  label={t('SELLER', { lng })}
                  required
                >
                  <option value="" selected={!formValues.seller} disabled>
                    {t('CHOOSE_SELLER', { lng })}
                  </option>

                  {!!sellersOptions?.length &&
                    sellersOptions.map(seller => (
                      <option key={seller?.id} value={seller?.id}>
                        {seller?.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>

            {currentOpenOrder?.origin === OrderEntity.Origin.AUTO_BILLING_EXECUTION && (
              <div className="flex w-full lg:w-1/2">
                <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                  <p className="block mb-2 overflow-hidden text-xs font-semibold overflow-ellipsis whitespace-nowrap md:text-sm">
                    <Trans i18nKey="EXTRACT" tOptions={{ lng }} />
                  </p>

                  {currentOpenOrder?.autoBillingExecution?.summaryUrl ? (
                    <a
                      href={currentOpenOrder?.autoBillingExecution?.summaryUrl}
                      className="block text-primary underline text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Trans i18nKey="DOWNLOAD_LINK" tOptions={{ lng }} />
                    </a>
                  ) : (
                    <p className="block text-black text-sm">
                      <Trans i18nKey="NOT_AVAILABLE" tOptions={{ lng }} />
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-wrap mb-6 lg:flex-nowrap">
            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <Input
                  name="credits"
                  type="number"
                  value={formValues.credits}
                  onChange={handleChange}
                  loading={loading}
                  error={formErrors.credits}
                  label={t('CREDIT_QUANTITY', { lng })}
                  disabled={!!currentOpenOrder?.transactionId}
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4 lg:mr-8">
                <CurrencyInput
                  name="creditPrice"
                  value={formValues.creditPrice}
                  onChange={handleChange}
                  loading={loading}
                  error={formErrors.creditPrice}
                  label={t('CREDIT_PRICE', { lng })}
                  lng={lng}
                  required
                  disabled
                />
              </div>
            </div>

            <div className="flex w-full lg:w-1/2">
              <div className="flex-1 w-1/2 mb-4 lg:w-1/4">
                <CurrencyInput
                  name="price"
                  disabled={!currentOpenOrder?.id}
                  value={formValues.price}
                  onValueChange={value => setFieldValue('price', value)}
                  loading={loading}
                  error={formErrors.price}
                  label={t('TOTAL_AMOUNT', { lng })}
                  lng={lng}
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdPayment className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="PAYMENT" tOptions={{ lng }} />
            </h3>
          </div>

          <div className="flex mb-0 lg:mb-6">
            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Select
                name="billingModality"
                disabled={!currentOpenOrder?.id}
                value={formValues.billingModality}
                onChange={handleChange}
                loading={loading}
                error={formErrors.billingModality}
                label={t('MODALITY', { lng })}
                required
              >
                <option value="" disabled>
                  {t('SELECT_MODALITY', { lng })}
                </option>

                <option value={OrderEntity.BillingModality.SEPARATE}>
                  {billingModalityMapper(currentLanguage, OrderEntity.BillingModality.SEPARATE)}
                </option>

                <option value={OrderEntity.BillingModality.PRE_PAID}>
                  {billingModalityMapper(currentLanguage, OrderEntity.BillingModality.PRE_PAID)}
                </option>

                <option value={OrderEntity.BillingModality.POST_PAID}>
                  {billingModalityMapper(currentLanguage, OrderEntity.BillingModality.POST_PAID)}
                </option>
              </Select>
            </div>

            <div className="w-1/3 mb-4 mr-4 lg:mr-8">
              <Select
                name="billingMode"
                disabled={!currentOpenOrder?.id}
                value={formValues.billingMode}
                onChange={handleChange}
                loading={loading}
                error={formErrors.billingMode}
                label={t('PAYMENT', { lng })}
                required
              >
                <option value="" disabled>
                  {t('CHOOSE_PAYMENT_METHOD', { lng })}
                </option>

                <option value={OrderEntity.BillingMode.IN_CASH}>
                  {billingModeMapper(currentLanguage, OrderEntity.BillingMode.IN_CASH)}
                </option>

                <option value={OrderEntity.BillingMode.ON_TERM}>
                  {billingModeMapper(currentLanguage, OrderEntity.BillingMode.ON_TERM)}
                </option>
              </Select>
            </div>

            <div className="w-1/3 mb-4">
              <Select
                name="numberOfInstallments"
                disabled={!currentOpenOrder?.id || isNumberOfInstallmentsFieldDisabled}
                value={formValues.numberOfInstallments}
                onChange={handleChange}
                loading={loading}
                error={formErrors.numberOfInstallments}
                label={t('INSTALLMENT', { lng })}
                required
              >
                <option value="" disabled>
                  {t('CHOOSE_NUMBER_OF_INSTALLMENTS', { lng })}
                </option>

                {numberOfInstallmentsOptions.length &&
                  numberOfInstallmentsOptions.map(numberOfInstallmentOption => (
                    <option key={numberOfInstallmentOption} value={numberOfInstallmentOption}>
                      {numberOfInstallmentOption}x
                    </option>
                  ))}
              </Select>
            </div>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="sm:flex-1 sm:w-1/3 w-full mb-4 sm:mr-4 lg:mr-8">
              <Select
                name="paymentForm"
                disabled={!currentOpenOrder?.id}
                value={formValues.paymentForm}
                onChange={handleChange}
                loading={loading}
                error={formErrors.paymentForm}
                label={t('PAYMENT_METHOD', { lng })}
                required
              >
                <option value="" disabled>
                  {t('SELECT_PAYMENT_METHOD', { lng })}
                </option>

                <option value={OrderEntity.PaymentForm.PIX}>
                  {paymentFormMapper(currentLanguage, OrderEntity.PaymentForm.PIX)}
                </option>

                <option value={OrderEntity.PaymentForm.TICKET}>
                  {paymentFormMapper(currentLanguage, OrderEntity.PaymentForm.TICKET)}
                </option>
              </Select>
            </div>

            <div className="sm:flex-1 sm:w-1/3 w-full mb-4 sm:mr-4 lg:mr-8">
              <Select
                name="firstBillingMonth"
                disabled={!currentOpenOrder?.id}
                value={formValues.firstBillingMonth}
                onChange={handleChange}
                loading={loading}
                error={formErrors.firstBillingMonth}
                label={t('FIRST_DUE_DATE', { lng })}
                required
              >
                <option value="" disabled>
                  {t('CHOOSE_FIRST_DUE_MONTH', { lng })}
                </option>

                <option value={OrderEntity.FirstBillingMonth.CURRENT}>
                  {firstBillingMonthMapper(currentLanguage, OrderEntity.FirstBillingMonth.CURRENT)}
                </option>

                <option value={OrderEntity.FirstBillingMonth.NEXT}>
                  {firstBillingMonthMapper(currentLanguage, OrderEntity.FirstBillingMonth.NEXT)}
                </option>
              </Select>
            </div>

            <div className="sm:flex-1 sm:w-1/3 w-full mb-4">
              <Select
                name="billingDay"
                disabled={!currentOpenOrder?.id}
                value={formValues.billingDay}
                onChange={handleChange}
                loading={loading}
                error={formErrors.billingDay}
                label={t('DUE_DATE_BOLETO', { lng })}
                required
              >
                <option value="">{t('CHOOSE_DUE_DAY', { lng })}</option>

                {!!billingDayOptions.length &&
                  billingDayOptions.map(billingDayOption => (
                    <option key={billingDayOption} value={billingDayOption}>
                      {billingDayOption}
                    </option>
                  ))}
              </Select>
            </div>
          </div>

          <div className="flex">
            <div className="w-full mb-4">
              <TextArea
                name="paymentDetails"
                disabled={!currentOpenOrder?.id}
                value={formValues.paymentDetails}
                onChange={handleChange}
                loading={loading}
                error={formErrors.paymentDetails}
                placeholder={t('DESCRIBE_DETAILS', { lng })}
                label={t('PAYMENT_DETAILS', { lng })}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-6 mt-2 border-t-2 border-senary md:flex-row">
            <Link
              className="order-1 w-full px-12 py-2 mr-0 text-sm font-semibold text-center text-white transition-colors rounded bg-error hover:bg-opacity-80 md:mr-4 md:w-auto md:order-none"
              to="/"
            >
              <Trans i18nKey="CANCEL" tOptions={{ lng }} />
            </Link>

            <Button
              name="update-button"
              className="bg-warning md:w-auto md:mb-0 md:mr-4 md:order-none"
              type="submit"
              disabled={isFormInvalidOrBlocked}
              onClick={() => setFieldValue('submitType', SubmitType.UPDATE)}
            >
              <Trans i18nKey="UPDATE" tOptions={{ lng }} />
            </Button>

            <Button
              name="confirm-button"
              className="bg-success md:w-auto md:mb-0"
              type="submit"
              disabled={isFormInvalidOrBlocked}
              onClick={() => setFieldValue('submitType', SubmitType.SAVE)}
            >
              <Trans i18nKey="CONFIRM" tOptions={{ lng }} />
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default OpenOrderEditPage
