import { configureStore } from '@reduxjs/toolkit'

import rootReducer from 'src/presentation/store/ducks'

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
})

export type AppState = ReturnType<typeof store.getState>
