import { ClientsIzeePagination } from 'src/domain'

export class FetchClientsIzeeMapper {
  static toJson(params: ClientsIzeePagination) {
    return {
      offset: params.offset,
      limit: params.limit,
      order_by: params.orderBy,
      order_by_dir: params.orderByDir,
    }
  }
}
