import { RemoteFetchFranchiseesService } from 'src/data'

import { FetchFranchiseesUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchFranchiseesUseCase = (): FetchFranchiseesUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchFranchiseesService(authorizedHttpAdapter, '/franchisees/')
}
