import { CreateOrUpdateClientMapper, HttpClient, HttpStatusCode } from 'src/data'

import {
  CreateOrUpdateClientErrors,
  CreateOrUpdateClientPayloadError,
  CreateOrUpdateClientUseCase,
  UnexpectedError,
  UpdateClientUseCase,
} from 'src/domain'

export class RemoteUpdateClientService implements UpdateClientUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async updateClient(client: number, params: CreateOrUpdateClientUseCase.Params) {
    const response = await this.httpClient.request({
      method: 'put',
      url: `${this.url}/${client}`,
      body: CreateOrUpdateClientMapper.fromDomainToJson(params),
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return

      case HttpStatusCode.badRequest:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
      case HttpStatusCode.notFound:
      case HttpStatusCode.serverError:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.conflict: {
        if (response.body?.message && response.body.message in CreateOrUpdateClientErrors) {
          throw new CreateOrUpdateClientPayloadError(response.body.message)
        }

        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
