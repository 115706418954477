import { HttpClient, HttpStatusCode } from 'src/data'
import { ClientDetailsModel } from 'src/data/models/ClientDetailsModel'

import { FetchClientUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchClientService implements FetchClientUseCase {
  constructor(private readonly httpClient: HttpClient<ClientDetailsModel>, private readonly url: string) {}

  async fetchClient(client: number) {
    const response = await this.httpClient.request({
      method: 'get',
      url: `${this.url}/${client}`,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ClientDetailsModel.fromJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
