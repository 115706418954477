import { ClientSignatureModel, HttpClient, HttpStatusCode } from 'src/data'

import { FetchClientSignatureUseCase, UnexpectedError } from 'src/domain'

export class RemoteFetchClientSignatureService implements FetchClientSignatureUseCase {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly url: string,
    private readonly urlClientPlaceholder: string
  ) {}

  async fetchClientSignature(client: number) {
    const parsedUrl = this.url.replace(this.urlClientPlaceholder, String(client))

    const response = await this.httpClient.request({
      method: 'get',
      url: parsedUrl,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return ClientSignatureModel.fromJson(response.body).toEntity()

      default:
        throw new UnexpectedError()
    }
  }
}
