import React, { RefObject, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PaginateLoading, TableLoading } from 'src/presentation/components'
import {
  useAppDispatch,
  useAppSelector,
  useDate,
  usePersistedPreviousDataQuantity,
  useScroll,
} from 'src/presentation/hooks'
import { CanceledOrderMenuItem } from 'src/presentation/pages'
import { ordersActions } from 'src/presentation/store/ducks/orders'

const CanceledOrdersPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading, loadingPagination, hasMoreOrders, canceledOrders } = useAppSelector(state => state.orders)
  const { franchiseeId, isSearchActive, freeTermText } = useAppSelector(state => state.headerSearch)

  const { formatDate } = useDate()

  const [currentPage, lastElementRef] = useScroll({ hasMoreItems: hasMoreOrders, loading })

  const { t } = useTranslation()

  const storageKey = isSearchActive ? '@rvpdv:canceledOrdersSearchQuantity' : '@rvpdv:canceledOrdersQuantity'

  const canceledOrdersPreviousQuantity = usePersistedPreviousDataQuantity(storageKey, canceledOrders)

  useEffect(() => {
    document.title = t('CANCELLED_ORDERS')
  }, [])

  useEffect(() => {
    dispatch(ordersActions.fetchCanceledOrders({ franchisee: franchiseeId, search: freeTermText, page: null }))

    return () => {
      dispatch(ordersActions.clearCanceledOrders())
    }
  }, [dispatch, franchiseeId, freeTermText])

  useEffect(() => {
    if (hasMoreOrders && canceledOrders.length) {
      dispatch(ordersActions.fetchCanceledOrders({ franchisee: franchiseeId, search: freeTermText, page: currentPage }))
    }
  }, [currentPage, dispatch])

  return (
    <div data-cy="canceled-orders-page" className="flex flex-col w-full p-6 overflow-hidden">
      <div className="flex items-center justify-end" />

      <div className="relative w-full mt-6 overflow-auto bg-white rounded shadow-md h-[calc(100vh-196px)] md:h-[calc(100vh-172px)]">
        <div className="relative flex flex-1 w-full bg-white rounded flex-nowrap">
          <div className="w-full bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold border-4 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="ORDER_ID" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!canceledOrders.length &&
                    canceledOrders.map((order, index) => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                        {...(canceledOrders.length === index + 1 && {
                          ref: lastElementRef as unknown as RefObject<HTMLDivElement>,
                        })}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.id}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="UNIT" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!canceledOrders.length &&
                    canceledOrders.map((order, index) => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                        {...(canceledOrders.length === index + 1 && {
                          ref: lastElementRef as unknown as RefObject<HTMLDivElement>,
                        })}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.franchisee_name}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="CLIENT" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!canceledOrders.length &&
                    canceledOrders.map(order => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full w-44 h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.client?.name}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full px-0 bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="CREDIT_AND_PACKAGE" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!canceledOrders.length &&
                    canceledOrders.map(order => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.credits ? order?.credits / 100 : 0}m<sup>2</sup>
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full px-0 bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="CANCELLATION_DATE" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!canceledOrders.length &&
                    canceledOrders.map(order => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {formatDate(order?.canceled_at || '')}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold border-4 border-l-0 border-r-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="USER" />
                </div>

                <div className="w-full px-4 py-0 overflow-hidden">
                  {!!canceledOrders.length &&
                    canceledOrders.map(order => (
                      <div
                        id={`order-item-${order?.id}`}
                        className="flex items-center py-4 text-left xl:w-full w-44 h-14"
                        key={order?.id}
                        data-cy={`order-item-${order?.id}`}
                      >
                        <p className="overflow-hidden text-sm overflow-ellipsis whitespace-nowrap xl:text-base">
                          {order?.canceled_by}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>

          <div className="w-full pl-0 bg-white rounded">
            {loading ? (
              <>
                <TableLoading numberOfItems={1} mostRightElement isHeaderCell />

                <TableLoading numberOfItems={canceledOrdersPreviousQuantity} mostRightElement />
              </>
            ) : (
              <>
                <div className="sticky top-0 z-20 p-3 overflow-hidden text-sm font-semibold text-center border-4 border-l-0 border-white bg-senary overflow-ellipsis whitespace-nowrap xl:text-base">
                  <Trans i18nKey="ACTIONS" />
                </div>

                <div className="w-full px-4 py-0">
                  {!!canceledOrders.length &&
                    canceledOrders.map((order, index) => {
                      const isOneOfLastItems =
                        (index === canceledOrders.length - 1 || index === canceledOrders.length - 2) &&
                        canceledOrders.length > 3

                      return (
                        <div
                          id={`order-item-${order?.id}`}
                          className="relative flex items-center justify-center py-4 xl:w-full h-14"
                          key={order?.id}
                          data-cy={`order-item-${order?.id}`}
                        >
                          <CanceledOrderMenuItem
                            isOneOfLastItems={isOneOfLastItems && canceledOrders.length > 1}
                            orderId={order?.id}
                          />
                        </div>
                      )
                    })}
                </div>
              </>
            )}
          </div>
        </div>

        {!canceledOrders.length && !loading && !loadingPagination && (
          <div className="absolute inset-x-0 mt-12 bg-white">
            <p className="text-lg font-semibold text-center">
              <Trans i18nKey="NO_CANCELLED_ORDERS_FOUND" />
            </p>
          </div>
        )}
      </div>

      <PaginateLoading loading={loadingPagination} />
    </div>
  )
}

export default CanceledOrdersPage
