import { ClientPriceTableEntity } from 'src/domain'

export class ClientPriceTableModel {
  private static clientPriceTables: ClientPriceTableEntity[] = []

  constructor(
    readonly id: number,
    readonly active: boolean,
    readonly basePrice: number,
    readonly description: string,
    readonly expressRatio: number,
    readonly minimalPrice: number
  ) {}

  static fromJsonCollection(json: any[]) {
    this.clientPriceTables = json.map(clientPriceTableJson => {
      return new ClientPriceTableModel(
        clientPriceTableJson?.id,
        clientPriceTableJson?.active,
        clientPriceTableJson?.base_price,
        clientPriceTableJson?.description,
        clientPriceTableJson?.express_ratio,
        clientPriceTableJson?.minimal_price
      )
    })

    return this
  }

  static toEntityCollection(): ClientPriceTableEntity[] {
    return this.clientPriceTables.map(clientPriceTable => ({
      id: clientPriceTable.id,
      active: clientPriceTable.active,
      basePrice: clientPriceTable.basePrice,
      description: clientPriceTable.description,
      expressRatio: clientPriceTable.expressRatio,
      minimalPrice: clientPriceTable.minimalPrice,
    }))
  }
}
