import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { OrderEntity } from 'src/domain'

export const useOpenOrderEditValidator = () => {
  const { t } = useTranslation()

  return {
    shape: Yup.object().shape({
      seller: Yup.number().required(t('REQUIRED')).typeError(t('INVALID')),
      credits: Yup.number().positive(t('INVALID')).required(t('REQUIRED')).typeError(t('INVALID')),
      creditPrice: Yup.string()
        .test('is_required', t('REQUIRED'), creditPrice => {
          const priceWithOnlyNumbers = String(creditPrice).replace(/\D/g, '')

          if (
            Number.isNaN(Number(priceWithOnlyNumbers)) ||
            Number(priceWithOnlyNumbers) <= 0 ||
            !priceWithOnlyNumbers
          ) {
            return false
          }

          return true
        })
        .test('is_decimal', t('ENTER_DECIMAL_PART'), creditPrice => {
          if (creditPrice) {
            const dotOccurrences = String(creditPrice).split('.').length - 1

            if (dotOccurrences === 1) return true

            return creditPrice.includes(',')
          }

          return false
        })
        .test('is_equal_or_less_to_10', t('MUST_BE_LESS_THAN_OR_EQUAL_TO_10'), creditPrice => {
          if (!creditPrice) return false

          const [integerNumberPart, decimalNumberPart] = String(creditPrice).split(',')

          if (Number(decimalNumberPart) > 0) return Number(integerNumberPart) < 10

          return parseFloat(creditPrice) <= 10
        })
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      price: Yup.string()
        .test('is_required', t('REQUIRED'), creditPrice => {
          const priceWithOnlyNumbers = String(creditPrice).replace(/\D/g, '')

          if (
            Number.isNaN(Number(priceWithOnlyNumbers)) ||
            Number(priceWithOnlyNumbers) <= 0 ||
            !priceWithOnlyNumbers
          ) {
            return false
          }

          return true
        })
        .test('is_decimal', t('ENTER_DECIMAL_PART'), creditPrice => {
          if (creditPrice) {
            const dotOccurrences = String(creditPrice).split('.').length - 1

            if (dotOccurrences === 1) return true

            return creditPrice.includes(',')
          }

          return false
        })
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      billingMode: Yup.string()
        .oneOf([OrderEntity.BillingMode.IN_CASH, OrderEntity.BillingMode.ON_TERM], t('INVALID_FEMALE'))
        .required(t('REQUIRED'))
        .typeError(t('INVALID')),
      numberOfInstallments: Yup.number()
        .positive(t('INVALID_FEMALE'))
        .required(t('REQUIRED'))
        .typeError(t('INVALID_FEMALE')),
      paymentDetails: Yup.string().nullable(true).typeError(t('INVALID')),
    }),
  }
}
