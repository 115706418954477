import { RemoteExportClientExtractService } from 'src/data'

import { ExportClientExtractUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeExportClientExtractUseCase = (): ExportClientExtractUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  const urlClientPlaceholder = '{client}'
  const url = `/clients/${urlClientPlaceholder}/accounts/export`

  return new RemoteExportClientExtractService(authorizedHttpAdapter, url, urlClientPlaceholder)
}
