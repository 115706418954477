import { ConfirmOpenOrderErrors } from 'src/domain'

const confirmOpenOrderErrorToDisplay = {
  BR: {
    NOT_ALLOWED: 'Você não possui permissão para executar a ação',
    SALE_WITHOUT_SELLER: 'Um vendedor deve estar selecionado',
    INTEGRATION_REQUEST_ERROR: 'Erro na integração com o Sankhya, por favor tente novamente mais tarde',
    SALE_WITHOUT_PRICE: 'O preço da venda deve ser maior do que 0',
    SALE_WITHOUT_BILLING_DAY: 'A data de vencimento deve estar selecionada',
    SALE_WITHOUT_BILLING_MODE: 'A modalide de cobrança deve estar selecionada',
    SALE_WITHOUT_FIRST_BILLING_MONTH: 'O mês do primeiro vencimento deve estar selecionado',
    SALE_WITHOUT_PAYMENT_FORM: 'A forma de pagamento deve estar selecionada',
    SALE_ALREADY_CONFIRMED: 'A venda já foi confirmada',
  },
  PT: {
    NOT_ALLOWED: 'Não tem permissão para executar a ação',
    SALE_WITHOUT_SELLER: 'Deve selecionar um vendedor',
    INTEGRATION_REQUEST_ERROR: 'Erro na integração com o Sankhya, por favor, tente novamente mais tarde',
    SALE_WITHOUT_PRICE: 'O preço da venda deve ser superior a 0',
    SALE_WITHOUT_BILLING_DAY: 'Deve selecionar a data de vencimento',
    SALE_WITHOUT_BILLING_MODE: 'Deve selecionar a modalidade de cobrança',
    SALE_WITHOUT_FIRST_BILLING_MONTH: 'Deve selecionar o mês do primeiro vencimento',
    SALE_WITHOUT_PAYMENT_FORM: 'Deve selecionar a forma de pagamento',
    SALE_ALREADY_CONFIRMED: 'A venda já foi confirmada',
  },
}

export const confirmOpenOrderErrorMapper = (language: 'BR' | 'PT', err: ConfirmOpenOrderErrors) => {
  return confirmOpenOrderErrorToDisplay?.[language]?.[err] || ''
}
