import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import ptBr from 'src/main/config/i18n/translations/pt-BR.json'
import ptPt from 'src/main/config/i18n/translations/pt-PT.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'BR',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    BR: {
      translation: ptBr,
    },
    PT: {
      translation: ptPt,
    },
  },
})
