import { AutoBillingConfigEntity } from 'src/domain'

export class CreateAutoBillingConfigMapper {
  static toJson(params: AutoBillingConfigEntity) {
    return {
      payment_form: params.paymentForm,
      billing_due_day: params.billingDueDay,
      price_per_credit: params.pricePerCredit,
      seller_id: params.sellerId,
      enabled: params.enabled,
    }
  }
}
