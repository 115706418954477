import { CreateOrUpdateOrderIzeeUseCase } from 'src/domain'

export class CreateOrUpdateOrderIzeeMapper {
  static toJson(params: CreateOrUpdateOrderIzeeUseCase.Params) {
    return {
      company_id: params.companyId,
      setup_price: params.setupPrice,
      monthly_price: params.monthlyPrice,
      setup_number_of_installments: params.setupNumberOfInstallments,
      billing_day: params.billingDay,
      payment_form: params.paymentForm,
      seller_id: params.sellerId,
      payment_details: params.paymentDetails,
      billing_email: params.billingEmail,
      billing_phone: params.billingPhone,
    }
  }
}
