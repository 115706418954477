import { RemoteFetchPlansService } from 'src/data'

import { FetchPlansUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchPlansUseCase = (): FetchPlansUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchPlansService(authorizedHttpAdapter, '/plans')
}
