import { CreateOrUpdateClientErrors } from 'src/domain'

const createOrUpdateClientPayloadErrorToDisplay = {
  BR: {
    DOCUMENT_ALREADY_USED: 'Esse CNPJ/CPF já está em uso',
    EMAIL_ALREADY_USED: 'O e-mail informado já está em uso',
    EMAIL_IS_NOT_VALID: 'O e-mail informado está inválido',
    USERNAME_ALREADY_USED: 'Esse usuário já está em uso',
    USERNAME_IS_INVALID: 'O nome de usuário está inválido',
    USERNAME_NOT_FOUND: 'Usuário não encontrado',
  },
  PT: {
    DOCUMENT_ALREADY_USED: 'Esse CNPJ/CPF já está em uso',
    EMAIL_ALREADY_USED: 'O e-mail informado já está em uso',
    EMAIL_IS_NOT_VALID: 'O e-mail informado é inválido',
    USERNAME_ALREADY_USED: 'Esse nome de utilizador já está em uso',
    USERNAME_IS_INVALID: 'O nome de utilizador é inválido',
    USERNAME_NOT_FOUND: 'Nome de utilizador não encontrado',
  },
}

export const createOrUpdateClientPayloadErrorMapper = (language: 'BR' | 'PT', err: CreateOrUpdateClientErrors) => {
  return createOrUpdateClientPayloadErrorToDisplay?.[language]?.[err] || ''
}
