const billingModeTypes = {
  BR: {
    IN_CASH: 'À vista',
    ON_TERM: 'À prazo',
  },
  PT: {
    IN_CASH: 'À vista',
    ON_TERM: 'A prazo',
  },
}

export const billingModeMapper = (language: 'BR' | 'PT', billingMode: 'IN_CASH' | 'ON_TERM') => {
  return billingModeTypes?.[language]?.[billingMode] || ''
}
