import { combineReducers } from '@reduxjs/toolkit'

import { authReducer } from 'src/presentation/store/ducks/auth'
import { autoBillingConfigReducer } from 'src/presentation/store/ducks/autoBillingConfig'
import { clientReducer } from 'src/presentation/store/ducks/client'
import { clientIzeeReducer } from 'src/presentation/store/ducks/clientIzee'
import { clientsReducer } from 'src/presentation/store/ducks/clients'
import { clientsIzeeReducer } from 'src/presentation/store/ducks/clientsIzee'
import { franchiseesReducer } from 'src/presentation/store/ducks/franchisees'
import { headerSearchReducer } from 'src/presentation/store/ducks/headerSearch'
import { negociationTypesReducer } from 'src/presentation/store/ducks/negociationTypes'
import { orderReducer } from 'src/presentation/store/ducks/order'
import { orderIzeeReducer } from 'src/presentation/store/ducks/orderIzee'
import { ordersReducer } from 'src/presentation/store/ducks/orders'
import { ordersIzeeReducer } from 'src/presentation/store/ducks/ordersIzee'
import { packageReducer } from 'src/presentation/store/ducks/package'
import { planReducer } from 'src/presentation/store/ducks/plan'
import { plansReducer } from 'src/presentation/store/ducks/plans'
import { priceTableReducer } from 'src/presentation/store/ducks/priceTable'
import { sellersReducer } from 'src/presentation/store/ducks/sellers'
import { signatureReducer } from 'src/presentation/store/ducks/signature'
import { uiReducer } from 'src/presentation/store/ducks/ui'

export default combineReducers({
  auth: authReducer,
  autoBillingConfig: autoBillingConfigReducer,
  client: clientReducer,
  clients: clientsReducer,
  clientIzee: clientIzeeReducer,
  clientsIzee: clientsIzeeReducer,
  franchisees: franchiseesReducer,
  headerSearch: headerSearchReducer,
  negociationTypes: negociationTypesReducer,
  orderIzee: orderIzeeReducer,
  ordersIzee: ordersIzeeReducer,
  order: orderReducer,
  orders: ordersReducer,
  package: packageReducer,
  plan: planReducer,
  plans: plansReducer,
  priceTable: priceTableReducer,
  sellers: sellersReducer,
  signature: signatureReducer,
  ui: uiReducer,
})
