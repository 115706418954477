import { NegociationTypeModel, HttpClient, HttpStatusCode } from 'src/data'

import { FetchNegociationTypesUseCase, OrderEntity, UnexpectedError } from 'src/domain'

export class RemoteFetchNegociationTypesService implements FetchNegociationTypesUseCase {
  constructor(private readonly httpClient: HttpClient<OrderEntity.NegociationType[]>, private readonly url: string) {}

  async fetchNegociationTypes() {
    const response = await this.httpClient.request({ method: 'get', url: this.url })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return NegociationTypeModel.fromJsonToDomain(response.body || [])

      default:
        throw new UnexpectedError()
    }
  }
}
