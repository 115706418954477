import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ClientSignatureEntity } from 'src/domain'

import { makeFetchClientSignatureUseCase, makeUpdateClientSignatureUseCase } from 'src/main'

interface FetchClientSignatureParams {
  client: number
}

interface UpdateClientSignatureParams {
  client: number
  value: number
}

const fetchClientSignature = createAsyncThunk(
  '@priceTable/fetchClientSignature',
  async ({ client }: FetchClientSignatureParams) => {
    const fetchClientSignatureUseCase = makeFetchClientSignatureUseCase()

    return fetchClientSignatureUseCase.fetchClientSignature(client)
  }
)

const updateClientSignature = createAsyncThunk(
  '@priceTable/updateClientSignature',
  async ({ client, value }: UpdateClientSignatureParams) => {
    const updateClientSignatureUseCase = makeUpdateClientSignatureUseCase()

    await updateClientSignatureUseCase.updateClientSignature(client, value)
  }
)

interface SignatureState {
  loadingFetchClientSignature: boolean
  loadingUpdateClientSignature: boolean
  signature: ClientSignatureEntity | null
  updateClientSignatureState: 'success' | 'error' | null
}

const initialState: SignatureState = {
  loadingFetchClientSignature: false,
  loadingUpdateClientSignature: false,
  signature: null,
  updateClientSignatureState: null,
}

export const signatureSlice = createSlice({
  name: '@signature',
  initialState,
  reducers: {
    clearSignature: state => {
      state.signature = null
    },

    clearUpdateClientSignatureState: state => {
      state.updateClientSignatureState = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchClientSignature.pending, state => {
      state.loadingFetchClientSignature = true
    })

    builder.addCase(fetchClientSignature.fulfilled, (state, action) => {
      state.loadingFetchClientSignature = false
      state.signature = action.payload
    })

    builder.addCase(fetchClientSignature.rejected, state => {
      state.loadingFetchClientSignature = false
      state.signature = null
    })

    builder.addCase(updateClientSignature.pending, state => {
      state.loadingUpdateClientSignature = true
    })

    builder.addCase(updateClientSignature.fulfilled, (state, action) => {
      state.loadingUpdateClientSignature = false
      state.updateClientSignatureState = 'success'
    })

    builder.addCase(updateClientSignature.rejected, state => {
      state.loadingUpdateClientSignature = false
      state.updateClientSignatureState = 'error'
    })
  },
})

export const signatureActions = { ...signatureSlice.actions, fetchClientSignature, updateClientSignature }

export const signatureReducer = signatureSlice.reducer
