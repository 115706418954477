import { RemoteFetchNegociationTypesService } from 'src/data'

import { FetchNegociationTypesUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchNegociationTypesUseCase = (): FetchNegociationTypesUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchNegociationTypesService(authorizedHttpAdapter, '/sales/negociation-type')
}
