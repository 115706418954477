import { ClientByCnpjEntity } from 'src/domain'

export class ClientByCnpjModel {
  constructor(
    readonly cep: string,
    readonly cityCode: string,
    readonly companyName: string,
    readonly legalName: string,
    readonly email: string,
    readonly complement: string,
    readonly firstPhone: string,
    readonly neighborhood: string,
    readonly number: string,
    readonly secondPhone: string,
    readonly state: string,
    readonly street: string
  ) {}

  static fromJson(json: any) {
    return new ClientByCnpjModel(
      json?.cep,
      json?.city_code,
      json?.company_name,
      json?.legal_name,
      json?.email,
      json?.complement,
      json?.first_phone,
      json?.neighborhood,
      json?.number,
      json?.second_phone,
      json?.state,
      json?.street
    )
  }

  toEntity(): ClientByCnpjEntity {
    return {
      cep: this.cep,
      cityCode: this.cityCode,
      companyName: this.companyName,
      legalName: this.legalName,
      email: this.email,
      complement: this.complement,
      firstPhone: this.firstPhone,
      neighborhood: this.neighborhood,
      number: this.number,
      secondPhone: this.secondPhone,
      state: this.state,
      street: this.street,
    }
  }
}
