import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { OrderEntity } from 'src/domain'

import { makeFetchNegociationTypesUseCase } from 'src/main'

const fetchNegociationTypes = createAsyncThunk('@negociationTypes/fetchNegociationTypes', async () => {
  const fetchNegociationTypesUseCase = makeFetchNegociationTypesUseCase()

  return fetchNegociationTypesUseCase.fetchNegociationTypes()
})

interface NegociationTypesState {
  negociationTypes: OrderEntity.NegociationType[]
  loading: boolean
}

const initialState: NegociationTypesState = {
  negociationTypes: [],
  loading: false,
}

export const negociationTypesSlice = createSlice({
  name: '@negociationTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNegociationTypes.pending, state => {
      state.loading = true
    })

    builder.addCase(fetchNegociationTypes.fulfilled, (state, action) => {
      state.negociationTypes = action.payload || []
      state.loading = false
    })

    builder.addCase(fetchNegociationTypes.rejected, state => {
      state.loading = false
    })
  },
})

export const negociationTypesActions = {
  ...negociationTypesSlice.actions,
  fetchNegociationTypes,
}

export const negociationTypesReducer = negociationTypesSlice.reducer
