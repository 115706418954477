import { CreateOrUpdateOrderIzeeMapper, HttpClient, HttpStatusCode, OrderIzeeModel } from 'src/data'

import {
  CreateOrUpdateOrderIzeePayloadError,
  CreateOrderIzeeUseCase,
  UnexpectedError,
  CreateOrUpdateOrderIzeeErrors,
  CreateOrUpdateOrderIzeeUseCase,
} from 'src/domain'

export class RemoteCreateOrderIzeeService implements CreateOrderIzeeUseCase {
  constructor(private readonly httpClient: HttpClient, private readonly url: string) {}

  async createOrderIzee(params: CreateOrUpdateOrderIzeeUseCase.Params) {
    const response = await this.httpClient.request({
      method: 'post',
      url: this.url,
      body: CreateOrUpdateOrderIzeeMapper.toJson(params),
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return OrderIzeeModel.fromJson(response.body).toEntity()

      case HttpStatusCode.badRequest:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
      case HttpStatusCode.notFound:
      case HttpStatusCode.serverError:
      case HttpStatusCode.serverValidation:
      case HttpStatusCode.conflict: {
        if (response.body?.message && response.body.message in CreateOrUpdateOrderIzeeErrors) {
          throw new CreateOrUpdateOrderIzeePayloadError(response.body.message)
        }

        throw new UnexpectedError()
      }

      default:
        throw new UnexpectedError()
    }
  }
}
