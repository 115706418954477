import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiDownload } from 'react-icons/hi'
import { ImSpinner8 } from 'react-icons/im'

import { setDate, subMonths } from 'date-fns'
import { useFormik } from 'formik'

import { ClientExtractFormats } from 'src/domain'

import { Button, DatePicker, Select } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useDate, useLanguage, useToast } from 'src/presentation/hooks'
import { clientExtractFormatMapper } from 'src/presentation/mappers'
import { clientActions } from 'src/presentation/store/ducks/client'
import { uiActions } from 'src/presentation/store/ducks/ui'

import { useExportClientExtractValidator } from 'src/validation'

type FormInitialValues = {
  startDate: Date | null
  endDate: Date | null
  format: ClientExtractFormats
}

const ExportClientExtractModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { client, isOpen } = useAppSelector(state => state.ui.exportClientExtractModal)
  const { exportClientExtractState, exportedExtract, loadingExportClientExtract } = useAppSelector(
    state => state.client
  )

  const { throwToast } = useToast()
  const { formatDate } = useDate()
  const { shape } = useExportClientExtractValidator()

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  const {
    values: formValues,
    errors: formErrors,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik<FormInitialValues>({
    enableReinitialize: true,
    validationSchema: shape,
    initialValues: {
      startDate: null,
      endDate: null,
      format: ClientExtractFormats.POST_PAID_EXTRACT,
    },
    onSubmit: ({ startDate, endDate, format }) => {
      if (!client) return

      dispatch(
        clientActions.exportClientExtract({
          client,
          data: {
            startDate: startDate ? formatDate(startDate, 'yyyy-MM-dd') : undefined,
            endDate: endDate ? formatDate(endDate, 'yyyy-MM-dd') : undefined,
            format,
          },
        })
      )
    },
  })

  const handleCloseModal = () => {
    resetForm()

    dispatch(clientActions.clearExportedExtract())
    dispatch(uiActions.toggleExportClientExtractModal({ client: null, isOpen: false }))
  }

  useEffect(() => {
    if (exportClientExtractState === 'error') {
      throwToast({ message: t('UNABLE_TO_EXPORT_STATEMENT') })

      return
    }

    if (exportClientExtractState === 'success' && exportedExtract) {
      throwToast({
        message: t('STATEMENT_EXPORTED'),
        type: 'success',
        options: { autoClose: false },
        handleOnClick() {
          window.open(exportedExtract, '_blank')
        },
      })

      return
    }

    if (exportClientExtractState === 'success' && !exportedExtract) {
      throwToast({ message: t('NO_DATA_FOUND_FOR_STATEMENT_EXPORT') })
    }

    return () => {
      dispatch(clientActions.clearExportClientExtractState())
    }
  }, [dispatch, exportClientExtractState])

  useEffect(() => {
    if (!isOpen) return

    const today = new Date()
    const dayOfMonth = today.getDate()

    let initialStateDate: Date
    let initialEndDate: Date

    if (dayOfMonth > 25) {
      initialStateDate = setDate(subMonths(today, 1), 26) // sets day 26 of previous month
      initialEndDate = setDate(today, 25) // sets day 25 of current month
    } else {
      initialStateDate = setDate(subMonths(today, 2), 26) // sets day 26 of two months ago
      initialEndDate = setDate(subMonths(today, 1), 25) // sets day 25 of previous month
    }

    setFieldValue('startDate', initialStateDate)
    setFieldValue('endDate', initialEndDate)
  }, [isOpen])

  if (!isOpen || !client) return null

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black opacity-50 cursor-pointer"
        data-cy="export-client-extract-modal-overlay"
        onClick={handleCloseModal}
      />

      <div
        className="fixed inset-0 z-50 flex flex-col items-start w-[calc(100%-22px)] py-6 m-auto bg-white border-4 rounded lg:w-1/2 border-tertiary max-h-[calc(100%-22px)] h-min"
        data-cy="export-client-extract-modal"
      >
        <h4 className="w-full px-6 pb-6 border-b-tertiary border-b-2 text-xl flex justify-start gap-4 items-center font-medium text-center lg:text-left">
          <HiDownload className="text-black" size={20} />

          <strong className="font-semibold text-nonary">
            <Trans i18nKey="EXPORT_STATEMENT" />
          </strong>
        </h4>

        <form className="relative px-6 pt-6 self-center mx-auto w-full" onSubmit={handleSubmit}>
          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex-1">
              <Select
                name="format"
                value={formValues.format}
                onChange={handleChange}
                error={formErrors.format}
                label={`${t('FORMAT')}*`}
              >
                <option value="" disabled>
                  {t('SELECT')}
                </option>

                <option value={ClientExtractFormats.CONSOLIDATED_EXTRACT}>
                  {clientExtractFormatMapper(currentLanguage, ClientExtractFormats.CONSOLIDATED_EXTRACT)}
                </option>

                <option value={ClientExtractFormats.CONSOLIDATED_POST_PAID_EXTRACT}>
                  {clientExtractFormatMapper(currentLanguage, ClientExtractFormats.CONSOLIDATED_POST_PAID_EXTRACT)}
                </option>

                <option value={ClientExtractFormats.DETAILED_EXTRACT}>
                  {clientExtractFormatMapper(currentLanguage, ClientExtractFormats.DETAILED_EXTRACT)}
                </option>

                <option value={ClientExtractFormats.POST_PAID_EXTRACT}>
                  {clientExtractFormatMapper(currentLanguage, ClientExtractFormats.POST_PAID_EXTRACT)}
                </option>
              </Select>
            </div>
          </div>

          <h3 className="text-sm font-semibold lg:text-base my-6">
            <Trans i18nKey="PERIOD" />
          </h3>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap gap-x-6">
            <div className="flex mb-6 w-full lg:w-1/2">
              <div className="flex-1">
                <DatePicker
                  name="startDate"
                  selected={formValues?.startDate}
                  startDate={formValues?.startDate}
                  endDate={formValues?.endDate}
                  onChange={value => setFieldValue('startDate', value)}
                  error={formErrors?.startDate}
                  label={t('FROM')}
                  selectsStart
                />
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/2">
              <div className="flex-1">
                <DatePicker
                  name="endDate"
                  selected={formValues?.endDate}
                  endDate={formValues?.endDate}
                  minDate={formValues?.startDate}
                  onChange={value => setFieldValue('endDate', value)}
                  error={formErrors?.endDate}
                  label={t('TO')}
                  selectsEnd
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center self-center justify-center md:flex-row md:gap-4">
            <Button
              name="cancel-export-client-extract-button"
              className={`
                !text-xs
                bg-tertiary
                md:w-auto
                md:order-none
                order-1
                ${loadingExportClientExtract && 'pointer-events-none'}
              `}
              type="button"
              disabled={loadingExportClientExtract}
              onClick={handleCloseModal}
            >
              <Trans i18nKey="CANCEL" />
            </Button>

            <Button
              name="confirm-export-client-extract-button"
              className="!text-xs bg-primary md:w-auto m-0"
              type="submit"
              disabled={loadingExportClientExtract}
            >
              {loadingExportClientExtract ? (
                <ImSpinner8 size={16} className="m-auto text-white animate-spin" />
              ) : (
                <span>
                  <Trans i18nKey="EXPORT_STATEMENT" />
                </span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ExportClientExtractModal
