import { ClientSignatureEntity } from 'src/domain'

export class ClientSignatureModel {
  constructor(readonly id: number, readonly value: number) {}

  static fromJson(json: any) {
    return new ClientSignatureModel(json?.id, json?.value)
  }

  toEntity(): ClientSignatureEntity {
    return {
      id: this.id,
      value: this.value,
    }
  }
}
