import React from 'react'
import { Slide, ToastContainer as ToastifyContainer } from 'react-toastify'

const ToastContainer: React.FC = () => (
  <ToastifyContainer
    position="bottom-right"
    autoClose={5000}
    newestOnTop={false}
    rtl={false}
    closeOnClick={false}
    transition={Slide}
    hideProgressBar
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton
  />
)

export default ToastContainer
