import { ControlProps, CSSObjectWithLabel, Theme } from 'react-select'

const errorColor = '#BE1104'
const defaultColor = '#D1D1D1'
const blackColor = '#000000'

const border = (color: string) => `1px solid ${color}`
const boxShadow = (color?: string) => {
  return color ? `0px 0px 0px ${color === blackColor ? '2px' : '1px'} ${color}` : 'none'
}

export const selectStyles = (error: boolean) => ({
  control: (styles: CSSObjectWithLabel, state: ControlProps) => ({
    ...styles,
    padding: '4px 8px',
    paddingRight: 0,
    transition: 'none',
    border: border(error ? errorColor : defaultColor),
    boxShadow: boxShadow(state.isFocused && !error ? blackColor : state.isFocused && error ? errorColor : undefined),
    '&:hover': {
      border: border(error ? errorColor : defaultColor),
    },
  }),
})

export const selectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: blackColor,
  },
})
