import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ImSpinner8 } from 'react-icons/im'
import { MdAttachMoney, MdPayment } from 'react-icons/md'

import { useFormik } from 'formik'

import { PaymentForm } from 'src/domain'

import shopIcon from 'src/presentation/assets/images/shop-icon.svg'
import { Button, CurrencyInput, Select } from 'src/presentation/components'
import { useAppDispatch, useAppSelector, useLanguage, useMask, useToast } from 'src/presentation/hooks'
import { paymentFormMapper } from 'src/presentation/mappers'
import { autoBillingConfigActions } from 'src/presentation/store/ducks/autoBillingConfig'
import { sellersActions } from 'src/presentation/store/ducks/sellers'
import { uiActions } from 'src/presentation/store/ducks/ui'

import { useAutoBillingConfigValidator } from 'src/validation'

const AutoBillingConfigModal: React.FC = () => {
  const [billingDueDayOptions, setBillingDueDayOptions] = useState<number[]>([])

  const dispatch = useAppDispatch()
  const { client, clientName, isOpen } = useAppSelector(state => state.ui.autoBillingConfigModal)
  const {
    autoBillingConfig,
    createAutoBillingConfigState,
    loadingCreateAutoBillingConfig,
    loadingFetchAutoBillingConfig,
  } = useAppSelector(state => state.autoBillingConfig)
  const { loading: loadingSellers, sellers } = useAppSelector(state => state.sellers)

  const { throwToast } = useToast()
  const { shape } = useAutoBillingConfigValidator()

  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()
  const { MONEY_SIGN } = useMask()

  const {
    values: formValues,
    errors: formErrors,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: shape,
    initialValues: {
      paymentForm: autoBillingConfig?.paymentForm,
      billingDueDay: autoBillingConfig?.billingDueDay,
      pricePerCredit: autoBillingConfig?.pricePerCredit,
      sellerId: autoBillingConfig?.sellerId,
      enabled: autoBillingConfig?.enabled,
    },
    onSubmit: submitValues => {
      try {
        const pricePerCredit = String(submitValues.pricePerCredit)
        const formattedPricePerCredit = Number(pricePerCredit.replaceAll(',', '.'))

        const payload = {
          paymentForm: submitValues.paymentForm as PaymentForm,
          billingDueDay: Number(submitValues?.billingDueDay),
          pricePerCredit: formattedPricePerCredit,
          sellerId: Number(submitValues?.sellerId),
          enabled: String(submitValues.enabled) === 'true',
        }

        if (client) {
          dispatch(autoBillingConfigActions.createAutoBillingConfig({ client, data: payload }))

          return
        }

        throwToast({ message: t('UNABLE_TO_UPDATE_AUTOMATIC_CLOSURE') })
      } catch {
        throwToast({ message: t('UNABLE_TO_UPDATE_AUTOMATIC_CLOSURE') })
      }
    },
  })

  const handleCloseModal = () => {
    resetForm()

    dispatch(uiActions.toggleAutoBillingConfigModal({ client: null, clientName: null, isOpen: false }))
  }

  useEffect(() => {
    const currentMonthDays = Array.from({ length: 30 }, (_, index) => index + 1)

    setBillingDueDayOptions(currentMonthDays)
  }, [])

  useEffect(() => {
    !sellers?.length && dispatch(sellersActions.fetchSellers())
  }, [dispatch])

  useEffect(() => {
    client && dispatch(autoBillingConfigActions.fetchAutoBillingConfig({ client }))

    return () => {
      dispatch(autoBillingConfigActions.clearAutoBillingConfig())
    }
  }, [client, dispatch])

  useEffect(() => {
    if (createAutoBillingConfigState === 'error') {
      throwToast({ message: t('UNABLE_TO_UPDATE_AUTOMATIC_CLOSURE') })

      return
    }

    if (createAutoBillingConfigState === 'success') {
      handleCloseModal()

      throwToast({ message: t('AUTOMATIC_CLOSURE_UPDATED'), type: 'success' })
    }

    return () => {
      dispatch(autoBillingConfigActions.clearCreateAutoBillingConfigState())
    }
  }, [createAutoBillingConfigState, dispatch])

  const enabledValueToString = typeof formValues.enabled === 'boolean' ? formValues.enabled.toString() : undefined

  if (!isOpen || !client) return null

  return (
    <>
      <div
        className="fixed inset-0 z-50 bg-black opacity-50 cursor-pointer"
        data-cy="auto-billing-config-modal-overlay"
        onClick={handleCloseModal}
      />

      <div
        className="fixed inset-0 z-50 flex flex-col items-start w-[calc(100%-22px)] py-6 m-auto bg-white border-4 rounded lg:max-w-screen-lg border-tertiary max-h-[calc(100%-22px)] h-min"
        data-cy="auto-billing-config-modal"
      >
        <h4 className="w-full px-6 pb-6 border-b-tertiary border-b-2 text-xl flex justify-start gap-4 items-center font-medium text-center lg:text-left">
          <img className="hidden w-6 h-6 lg:block" src={shopIcon} alt="Shop Icon" />

          <strong className="font-semibold text-nonary">{clientName}</strong>
        </h4>

        <form className="relative px-6 pt-6 self-center mx-auto w-full overflow-auto" onSubmit={handleSubmit}>
          <div className="flex items-center justify-start mb-6 border-senary">
            <MdAttachMoney className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="SALE" />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex mb-6 w-full lg:w-1/2">
              <div className="flex-1 lg:mr-8">
                <Select
                  name="sellerId"
                  value={formValues.sellerId}
                  onChange={handleChange}
                  loading={loadingSellers}
                  error={formErrors.sellerId}
                  label={`${t('SELLER')}*`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t('SELECT')}
                  </option>

                  {sellers.map(seller => (
                    <option key={seller?.id} value={seller?.id}>
                      {seller?.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="w-full lg:w-1/2 lg:mb-6">
              <CurrencyInput
                name="pricePerCredit"
                value={formValues?.pricePerCredit}
                placeholder={`${MONEY_SIGN} 0,00`}
                onValueChange={value => setFieldValue('pricePerCredit', value)}
                loading={loadingFetchAutoBillingConfig}
                error={formErrors?.pricePerCredit}
                label={`${t('CREDIT_PRICE')}*`}
                decimalScale={2}
                lng={currentLanguage}
              />
            </div>
          </div>

          <div className="flex items-center justify-start mt-6 mb-6">
            <MdPayment className="mr-2 text-black" size={26} />

            <h3 className="text-sm font-semibold lg:text-base">
              <Trans i18nKey="PAYMENT" />
            </h3>
          </div>

          <div className="flex flex-wrap mb-0 lg:mb-6 lg:flex-nowrap">
            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <Select
                  name="paymentForm"
                  value={formValues?.paymentForm}
                  onChange={handleChange}
                  loading={loadingFetchAutoBillingConfig}
                  error={formErrors?.paymentForm}
                  label={`${t('PAYMENT_METHOD')}*`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t('SELECT_PAYMENT_METHOD')}
                  </option>

                  <option value={PaymentForm.PIX}>{paymentFormMapper(currentLanguage, PaymentForm.PIX)}</option>

                  <option value={PaymentForm.TICKET}>{paymentFormMapper(currentLanguage, PaymentForm.TICKET)}</option>
                </Select>
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1 lg:mr-8">
                <Select
                  name="billingDueDay"
                  value={formValues?.billingDueDay}
                  onChange={handleChange}
                  loading={loadingFetchAutoBillingConfig}
                  error={formErrors?.billingDueDay}
                  label={`${t('DUE_DATE_BOLETO')}*`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t('CHOOSE_DUE_DAY')}
                  </option>

                  {billingDueDayOptions.map(billingDueDayOption => (
                    <option key={billingDueDayOption} value={billingDueDayOption}>
                      {billingDueDayOption}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="flex mb-6 w-full lg:w-1/3">
              <div className="flex-1">
                <Select
                  name="enabled"
                  value={enabledValueToString}
                  onChange={handleChange}
                  loading={loadingFetchAutoBillingConfig}
                  error={formErrors?.enabled}
                  label={`${t('GENERATE_AUTOMATIC_SALE_ON_CLOSURE')}*`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {t('ENABLED_DISABLED')}
                  </option>

                  <option value="true">{t('ENABLED')}</option>

                  <option value="false">{t('DISABLED')}</option>
                </Select>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center self-center justify-center md:flex-row md:gap-4">
            <Button
              name="cancel-auto-billing-config-button"
              className={`
                !text-xs
                bg-tertiary
                md:w-auto
                md:order-none
                order-1
                ${loadingCreateAutoBillingConfig && 'pointer-events-none'}
              `}
              type="button"
              disabled={loadingCreateAutoBillingConfig}
              onClick={handleCloseModal}
            >
              <Trans i18nKey="CANCEL" />
            </Button>

            <Button
              name="confirm-auto-billing-config-button"
              className="!text-xs bg-success md:w-auto m-0"
              type="submit"
              disabled={loadingCreateAutoBillingConfig}
            >
              {loadingCreateAutoBillingConfig ? (
                <ImSpinner8 size={16} className="m-auto text-white animate-spin" />
              ) : (
                <span>
                  <Trans i18nKey="CONFIRM_CHANGES" />
                </span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AutoBillingConfigModal
