import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderIzeeEntity, OrdersIzeePagination } from 'src/domain'

import {
  makeFetchOpenOrdersIzeeUseCase,
  makeFetchCompletedOrdersIzeeUseCase,
  makeFetchCanceledOrdersIzeeUseCase,
} from 'src/main'

import { AppState } from 'src/presentation/store'
import { headerSearchActions } from 'src/presentation/store/ducks/headerSearch'

const ORDERS_PER_PAGE = 15

interface FetchOrdersIzeeParams {
  page: number | null
  franchisee: number | null
}

const fetchOpenOrdersIzee = createAsyncThunk(
  '@ordersIzee/fetchOpenOrdersIzee',
  async ({ franchisee, page }: FetchOrdersIzeeParams, { dispatch, getState }) => {
    page ? dispatch(ordersIzeeActions.loadingPagination()) : dispatch(ordersIzeeActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee))

    const fetchOpenOrdersIzeeUseCase = makeFetchOpenOrdersIzeeUseCase()

    const pagination: OrdersIzeePagination = {
      limit: ORDERS_PER_PAGE,
      offset: page ? page * ORDERS_PER_PAGE : undefined,
      // orderBy: 'created_at',
      // orderByDir: 'desc',
    }

    const fetchedOpenOrders = await fetchOpenOrdersIzeeUseCase.fetchOpenOrdersIzee(pagination, franchisee || undefined)

    const {
      ordersIzee: { openOrders: existingOpenOrders },
    } = getState() as AppState

    dispatch(ordersIzeeActions.hasMoreOrders(fetchedOpenOrders.length === ORDERS_PER_PAGE))

    return page ? [...existingOpenOrders, ...fetchedOpenOrders] : fetchedOpenOrders
  }
)

const fetchCompletedOrdersIzee = createAsyncThunk(
  '@ordersIzee/fetchCompletedOrdersIzee',
  async ({ franchisee, page }: FetchOrdersIzeeParams, { dispatch, getState }) => {
    page ? dispatch(ordersIzeeActions.loadingPagination()) : dispatch(ordersIzeeActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee))

    const fetchCompletedOrdersIzeeUseCase = makeFetchCompletedOrdersIzeeUseCase()

    const pagination: OrdersIzeePagination = {
      limit: ORDERS_PER_PAGE,
      offset: page ? page * ORDERS_PER_PAGE : undefined,
      // orderBy: 'created_at',
      // orderByDir: 'desc',
    }

    const fetchedCompletedOrdersIzee = await fetchCompletedOrdersIzeeUseCase.fetchCompletedOrdersIzee(
      pagination,
      franchisee || undefined
    )

    const {
      ordersIzee: { completedOrders: existingCompletedOrders },
    } = getState() as AppState

    dispatch(ordersIzeeActions.hasMoreOrders(fetchedCompletedOrdersIzee.length === ORDERS_PER_PAGE))

    return page ? [...existingCompletedOrders, ...fetchedCompletedOrdersIzee] : fetchedCompletedOrdersIzee
  }
)

const fetchCanceledOrdersIzee = createAsyncThunk(
  '@ordersIzee/fetchCanceledOrdersIzee',
  async ({ franchisee, page }: FetchOrdersIzeeParams, { dispatch, getState }) => {
    page ? dispatch(ordersIzeeActions.loadingPagination()) : dispatch(ordersIzeeActions.loading())

    dispatch(headerSearchActions.searchActive(!!franchisee))

    const fetchCanceledOrdersIzeeUseCase = makeFetchCanceledOrdersIzeeUseCase()

    const pagination: OrdersIzeePagination = {
      limit: ORDERS_PER_PAGE,
      offset: page ? page * ORDERS_PER_PAGE : undefined,
      // orderBy: 'created_at',
      // orderByDir: 'desc',
    }

    const fetchedCanceledOrdersIzee = await fetchCanceledOrdersIzeeUseCase.fetchCanceledOrdersIzee(
      pagination,
      franchisee || undefined
    )

    const {
      ordersIzee: { canceledOrders: existingCanceledOrders },
    } = getState() as AppState

    dispatch(ordersIzeeActions.hasMoreOrders(fetchedCanceledOrdersIzee.length === ORDERS_PER_PAGE))

    return page ? [...existingCanceledOrders, ...fetchedCanceledOrdersIzee] : fetchedCanceledOrdersIzee
  }
)

interface OrdersIzeeState {
  openOrders: OrderIzeeEntity.Entity[]
  completedOrders: OrderIzeeEntity.Entity[]
  canceledOrders: OrderIzeeEntity.Entity[]
  loading: boolean
  loadingPagination: boolean
  hasMoreOrders: boolean
}

const initialState: OrdersIzeeState = {
  openOrders: [],
  completedOrders: [],
  canceledOrders: [],
  loading: false,
  loadingPagination: false,
  hasMoreOrders: false,
}

export const ordersSlice = createSlice({
  name: '@ordersIzee',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
    },

    loadingPagination: state => {
      state.loadingPagination = true
    },

    hasMoreOrders: (state, action: PayloadAction<boolean>) => {
      state.hasMoreOrders = action.payload
    },

    clearOpenOrders: state => {
      state.openOrders = []
      state.hasMoreOrders = false
    },

    clearCompletedOrders: state => {
      state.completedOrders = []
      state.hasMoreOrders = false
    },

    clearCanceledOrders: state => {
      state.canceledOrders = []
      state.hasMoreOrders = false
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOpenOrdersIzee.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.openOrders = action.payload || []
    })

    builder.addCase(fetchOpenOrdersIzee.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreOrders = false
    })

    builder.addCase(fetchCompletedOrdersIzee.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.completedOrders = action.payload || []
    })

    builder.addCase(fetchCompletedOrdersIzee.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreOrders = false
    })

    builder.addCase(fetchCanceledOrdersIzee.fulfilled, (state, action) => {
      state.loading = false
      state.loadingPagination = false
      state.canceledOrders = action.payload || []
    })

    builder.addCase(fetchCanceledOrdersIzee.rejected, state => {
      state.loading = false
      state.loadingPagination = false
      state.hasMoreOrders = false
    })
  },
})

export const ordersIzeeActions = {
  ...ordersSlice.actions,
  fetchOpenOrdersIzee,
  fetchCompletedOrdersIzee,
  fetchCanceledOrdersIzee,
}

export const ordersIzeeReducer = ordersSlice.reducer
