import { RemoteFetchClientsIzeeService } from 'src/data'

import { FetchClientsIzeeUseCase } from 'src/domain'

import { makeAxiosAuthorizedHttpClientDecorator, Urls } from 'src/main'

export const makeFetchClientsIzeeUseCase = (): FetchClientsIzeeUseCase => {
  const authorizedHttpAdapter = makeAxiosAuthorizedHttpClientDecorator(Urls.COMMERCIAL_API_BASE_URL)

  return new RemoteFetchClientsIzeeService(authorizedHttpAdapter, '/companies/')
}
